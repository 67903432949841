import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ref, Storage} from '@angular/fire/storage';
import {from, Subscription} from "rxjs";
import {getDownloadURL} from "@firebase/storage";

@Component({
  selector: 'app-filestore-background-video',
  template: `

    <div
      [ngClass]="{'background-pane': !landscape}"
      class=" d-flex flex-column align-items-start" *ngIf="url">

        <video
          (loadedmetadata)="loadMetata($event)"
          [ngClass]="{'full-width-height-container' : true,'video-landscape' : landscape}"
          controls [attr.src]="url" *ngIf="showControls">
        </video>
        <video
          (loadedmetadata)="loadMetata($event)"
          [ngClass]="{'full-width-height-container' : true,'video-landscape' : landscape}"
          [attr.src]="url+'#t=1.0'" *ngIf="!showControls">
        </video>


      <div *ngIf="deleteButton" class="deleteSplashScreen" (click)="deleteClickedEvent($event)">
        <mat-icon class="deleteIcon" matPrefix>delete</mat-icon>
      </div>
      <ng-content></ng-content>
    </div>
    <div class="background-pane" *ngIf="!url">
      <div *ngIf="deleteButton" class="deleteSplashScreen" (click)="deleteClickedEvent($event)">
        <mat-icon class="deleteIcon" matPrefix>delete</mat-icon>
      </div>
    </div>
  `,
  styles: [`
    video.full-width-height-container {
      object-fit: cover;

      height: 100%;
      width: 100%;
    }

    video.full-width-height-container.video-landscape {
      object-fit: contain;
    }

    .background-pane {
      background-size: cover;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      height: 100%;
    }

    .deleteSplashScreen {
      z-index: 10;
      cursor: pointer;
      position: absolute;
      top: 59px;
      right: 15px;
      width: 48px;
      height: 48px;
      background: #FAFAFA 0% 0% no-repeat padding-box;
      border: 1px solid #FFFFFF;
      border-radius: 2px;
      opacity: 1;
    }

    .deleteIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

    }

  `]
})
export class FilestoreBackgroundVideoComponent implements OnInit, OnDestroy, OnChanges {

  @Input() paths: string[];
  @Input() deleteButton = false;
  @Input() showControls = true;
  @Output() delete = new EventEmitter<boolean>();

  landscape = false;
  subscription = new Subscription();
  url;

  constructor(private storage: Storage) {
  }

  ngOnInit() {
    this.load(this.paths);
  }

  load(paths: string[]) {
    if (paths.length !== 0) {
      this.subscription.add(
        from(getDownloadURL(ref(this.storage, paths[0])))
          .subscribe({
            next: (avatarUrl) => {
              this.url = avatarUrl;
            },
            error: (error) => {
              this.load(paths.splice(1));
            }
          })
      );
    }

  }

  deleteClickedEvent(event) {
    event.stopPropagation();
    this.delete.emit(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadMetata(event: any) {


    const width = event.target.videoWidth;
    const height = event.target.videoHeight;
    const aspectRatio = width/height
    const aspectRatioScreen = 360/596
    if (aspectRatio > aspectRatioScreen) {
      this.landscape = true;
    } else {
      this.landscape = false;
    }
    // console.log('load metadata', aspectRatio, aspectRatioScreen );

    //360 x 596
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paths && changes.paths.previousValue && changes.paths.currentValue) {
      if (changes.paths.previousValue[0] != changes.paths.currentValue[0]) {
        this.load(this.paths);
      }
    }
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {getEditMessageSelector} from "../../store/game-message.selector";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-mobile-preview-openurl',
  template: `
    <div class="h-100 d-flex flex-column justify-content-between">
      <app-preview-navbar></app-preview-navbar>
      <div
        style="background-color: white"
        class="flex-grow-1 d-flex align-items-center justify-content-center"
      >
        <i class="fas fa-browser" style="font-size: 100px"></i>
      </div>
    </div>

  `,
  styles: [
  ]
})
export class MobilePreviewOpenurlComponent  {


}

import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Observable} from "rxjs";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {Game} from "../../games-management/store/game.state";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-screen-detail-new',
  template: `
    <div class="full-width-container maxwidth">
      <div class="edit-message-2-col-pane d-flex flex-row justify-content-around">
        <div class="preview mr-2" *ngIf="game$ | async as game">
          <div class="d-flex flex-row">
            <app-message-type-icon
            ></app-message-type-icon>
            <div class="flex-grow-1">

              <app-screen-editor-type-select
                class="w-100"
              ></app-screen-editor-type-select>


<!--              <app-preview-content-body [scale]="0.75"></app-preview-content-body>-->

              <div class="d-flex flex-row">
                <app-preview-smartphone [scale]="1">
                  <app-preview-content-body [scale]="1"></app-preview-content-body>
                </app-preview-smartphone>
              </div>
            </div>
          </div>
        </div>
        <div class="form">

          <app-screen-editor
            class="screen-editor-center"></app-screen-editor>

        </div>
      </div>
    </div>
  `,
  styles: []
})
export class ScreenDetailNewComponent implements OnInit {

  game$: Observable<Game> = this.store.select(getCurrentGame).pipe(tap(console.log));

  constructor(private store: Store<State>,) {
  }

  ngOnInit(): void {
  }

}

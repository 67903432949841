import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-message-overlay',
  template: `
    <div class="position-relative message-overlay">
      <div [ngClass]="navTo ? ['position-absolute', 'overlay'] :['position-absolute']" >
        <div
          *ngIf="!!navTo"
          class="d-flex flex-column align-items-center justify-content-center h-100 button-container">

          <button
            color="white"
            [routerLink]="navTo"
            class="pos-middle-button" mat-stroked-button>
            {{'MESSAGE.EDIT_MESSAGE' | translate}}
          </button>
        </div>
      </div>
      <div class="position-absolute w-100 h-100">
        <ng-content></ng-content>
      </div>

    </div>
  `,
  styles: [`

    .button-container {
      color:white;
    }

    .button-container > .mat-stroked-button {
      border: 1px solid white;
    }

  .overlay {
    display: none;
    z-index: 10;
    width: 360px;
    height: 640px;
    background: rgba(0, 0, 0, 0.4) 0% 0% no-repeat padding-box;
    border-radius: 2px 2px 0px 0px;
    opacity: 0.8;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);


  }
  .message-overlay:hover .overlay{
    display: block;
  }
  `
  ]
})
export class MessageOverlayComponent {
  @Input() navTo;


}

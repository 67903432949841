import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {GameList} from "../../games-management/store/game.service";
import {Game} from "../../games-management/store/game.state";

export const UserLibraryPageActions = createActionGroup({
  source: 'User Library Page',
  events: {
    'Get Featured Games':  emptyProps(),
    'Get Recent Games':  emptyProps(),
    'Resume Recent Games':  emptyProps(),
    'Get Library Game':  props<{ gameId?: string }>(),
    'Search Games': props<{ query: string }>(),
    'reset query': emptyProps(),
  },
});


export const UserLibraryApiActions = createActionGroup({
  source: 'User Library Api',
  events: {
    'Get Featured Success':  props<{ games: Game[] }>(),
    'Set Recent Games':  props<{ gameList: GameList }>(),
    'Add Library Game Success':  props<{ game: Game}>(),
    'Add Library Games Success':  props<{ games: Game[]}>(),
  },
});

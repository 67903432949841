import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../core/reducers';
import {MatDialog} from '@angular/material/dialog';
import {GameRunsPageActions} from '../store/game-runs.actions';
import {combineLatestWith, Observable} from 'rxjs';
import {getCurrentGame} from "../../games-management/store/game.selector";
import {GamePageActions} from "../../games-management/store/game.actions";
import {GameAuthorActions} from "../../game-authors/store/game-author.actions";
import {currentGamePlayers} from "../../run-players/store/run-players.selectors";
import {map} from "rxjs/operators";
import {currentGameAccess} from "../../run-authors/store/run-author.selectors";
import {RunAuthor} from "../../run-authors/store/run-author.state";
import {GameRun, RunUser} from "../store/game-runs.state";
import {selectEntities} from "../store/game-runs.selector";
import {RunPlayerPageActions} from "../../run-players/store/run-players.actions";
import {Game} from "../../games-management/store/game.state";
import Dict = NodeJS.Dict;

@Component({
  selector: 'app-run-overview',
  template: `
    <app-game-detail-navbar [game]="game$|async">
    </app-game-detail-navbar>

    <div class="full-width-container maxwidth" *ngIf="game$|async as game">
      <div class="bibendo-center top70">
        <app-new-run-tile
        ></app-new-run-tile>
        <div class="tile-dimensions m-2" *ngFor="let run of (runs$|async)">
          <app-run-tile
            [run]="run"

            [navTo]="'/game/' +game.gameId+ '/screens'"
          ></app-run-tile>
        </div>
      </div>
    </div>
  `
})
export class RunOverviewComponent implements OnInit {
  public game$: Observable<Game> = this.store.select(getCurrentGame);

  public runs$: Observable<GameRun[]> = this.store.select(currentGamePlayers).pipe(
    combineLatestWith(this.store.select(currentGameAccess)),
    map(([players, authors]: [RunUser[], RunAuthor[]]) => {
      return Array.from(new Set([
        ...players.map(p => p.runId + ''),
        ...authors.map(a => a.runId)
      ]));
    }),
    combineLatestWith(this.store.select(selectEntities)),
    map(([runIds, runDict]: [string[], Dict<GameRun>]) =>
      runIds
        .filter(runId => {
          if (!runDict[runId]) {
            this.store.dispatch(GameRunsPageActions.getRun({runId}));
          }
          return !!runDict[runId] && !runDict[runId].deleted;
        })
        .map(runId => runDict[runId])
    ),
    map(runs => {
      runs.sort((r1, r2) => r2.lastModificationDate - r1.lastModificationDate)
      return runs;
    })
  );

  constructor(
    private store: Store<State>,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.store.dispatch(GamePageActions.getGame({}));
    this.store.dispatch(GameAuthorActions.loadGameAuthors());
    // this.store.dispatch(new LoadGameAuthorRequestAction());
    this.store.dispatch(RunPlayerPageActions.getPlayersForGame());
  }


}

import {Action} from "@ngrx/store";
import {Credentials, User} from "./ngrx-auth.state";

export enum AuthActionTypes {
  Authenticated = '[Auth] Authenticated',
  NotAuthenticated = '[Auth] Not Authenticated',
  AuthError = '[Auth] Error',
  CredentialsLogin = '[Auth] Credentials Login Attempt',
  GetUser = '[Auth] Get User',
  CredentialsRegistration = '[Auth] Credentials Registration Attempt',
  Logout = '[Auth] Logout',

  ResetPasswordRequest = '[Auth/Password] Reset Password Request',
  ResetPasswordRequestError = '[Auth/Password] Reset Password Request Error',
  ResetPasswordRequestSuccess = '[Auth/Password] Reset Password Request Success'
}

export class CredentialsLogin implements Action {
  readonly type = AuthActionTypes.CredentialsLogin;

  constructor(public email: string, public password: string, public remember?: boolean) {
  }
}

export class GetUser implements Action {
  readonly type = AuthActionTypes.GetUser;

  constructor(public payload?: any) {
  }
}


export class Authenticated implements Action {
  readonly type = AuthActionTypes.Authenticated;

  constructor(public payload: { user: User }) {

  }
}


export class NotAuthenticated implements Action {
  readonly type = AuthActionTypes.NotAuthenticated;

  constructor(public payload?: any) {
  }
}

export class CredentialsRegistration implements Action {
  readonly type = AuthActionTypes.CredentialsRegistration;

  constructor(public payload: Credentials) {
  }
}


export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;

  constructor(public payload?: any) {
  }
}

export class AuthError implements Action {
  readonly type = AuthActionTypes.AuthError;

  constructor(public payload?: any) {
  }
}


export class ResetPasswordRequest implements Action {
  readonly type = AuthActionTypes.ResetPasswordRequest;

  constructor(public payload: { email: string, redirectUrl: string }) {
  }

}


export class ResetPasswordRequestError implements Action {
  readonly type = AuthActionTypes.ResetPasswordRequestError;

  constructor(public payload: { code: string, message: string }) {
  }
}

export class ResetPasswordRequestSuccess implements Action {
  readonly type = AuthActionTypes.ResetPasswordRequestSuccess;

}

export type AuthActionsUnion = GetUser
  | Authenticated
  | NotAuthenticated
  | CredentialsRegistration
  | Logout
  | ResetPasswordRequest
  | ResetPasswordRequestSuccess
  | ResetPasswordRequestError;

import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {RouterModule} from '@angular/router';
import {MatExpansionModule} from '@angular/material/expansion';
import {FormsModule} from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../../environments/environment';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {GameDetailSettingsComponent} from './pages/game-detail-settings.component';
import {GameDetailNavbarComponent} from './components/game-detail-navbar.component';
import {GameSettingsFieldsComponent} from "./components/game-settings-fields.component";
import {GameSettingsPreviewComponent} from "./components/game-settings-preview.component";
import {GameThemeSelectorComponent} from "./components/game-theme-selector.component";
import {GameDetailCollaboratorsComponent} from "./components/game-detail/game-detail-collaborators/game-detail-collaborators.component";
import {GameDetailCollaboratorEntryComponent} from "./components/game-detail/game-detail-collaborator-entry/game-detail-collaborator-entry.component";
import {GameDetailAccessComponent} from "./components/game-detail/game-detail-access/game-detail-access.component";
import {GameDetailCreativeCommonsComponent} from "./components/game-detail/game-detail-creative-commons/game-detail-creative-commons.component";
import {GameDetailLocationComponent} from "./components/game-detail/game-detail-location/game-detail-location.component";
import {GameLandingPageComponent} from './pages/game-landing-page.component';
import {UserLibraryModule} from "../user-library/user-library.module";
import {QRCodeModule} from "angular2-qrcode";
import {NgrxAuthModule} from "src/app/ngrx-auth/ngrx-auth.module";
import {GameDetailMessageListTypeComponent} from './components/game-detail/game-detail-message-list-type.component';
import {MediaLibraryModule} from "../media-library/media-library.module";
import {GameThemeSelectorLightComponent} from './components/game-theme-selector-light.component';
import { GameDetailChapterComponent } from './components/game-detail/game-detail-chapter.component';

@NgModule({
  imports: [
    SharedModule.forRoot(),
    MatTableModule, MatPaginatorModule, MatSortModule, MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    BrowserAnimationsModule,
    CdkAccordionModule,
    RouterModule,
    MatSidenavModule,
    MatExpansionModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatChipsModule,
    MatSelectModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey
    }),
    UserLibraryModule, QRCodeModule,
    NgrxAuthModule,
    MediaLibraryModule

  ],
    exports: [GameDetailSettingsComponent, GameDetailNavbarComponent, GameDetailCollaboratorsComponent, GameThemeSelectorComponent, GameDetailAccessComponent, GameThemeSelectorLightComponent], //, GameDetailPanelComponent
  declarations: [
    GameDetailSettingsComponent,
    GameDetailNavbarComponent,
    GameSettingsFieldsComponent,
    GameSettingsPreviewComponent,
    GameThemeSelectorComponent,
    GameDetailCollaboratorsComponent, GameDetailLocationComponent,
    GameDetailCollaboratorEntryComponent, GameDetailCreativeCommonsComponent,
    GameDetailAccessComponent,
    GameLandingPageComponent,
    GameDetailMessageListTypeComponent,
    GameThemeSelectorLightComponent,
    GameDetailChapterComponent
  ]
})
export class GameManagementModule {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {runPlayerReducer} from "./store/run-players.reducer";
import {RunPlayersEffects} from "./store/run-players.effects";
import {RunPlayersEffectsLoadPlayers} from "./store/run-players.effects.load-players";
import {RunPlayersEffectsLoadGamePlayers} from "./store/run-players.effects.load-game-players";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('run-players', runPlayerReducer),
    EffectsModule.forFeature([RunPlayersEffects, RunPlayersEffectsLoadPlayers, RunPlayersEffectsLoadGamePlayers]),
  ]
})
export class RunPlayersModule { }

import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {getEditMessageSelector, selectedColor} from "../../store/game-message.selector";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";

@Component({
  selector: 'app-mobile-preview-narrator',
  template: `
    <app-background-image-selector
      [hideControls]="hideControls" *ngIf="message$|async as message"
    >
      <div class="h-100 d-flex flex-column justify-content-between">
        <app-preview-navbar></app-preview-navbar>
        <div class="text-box-preview d-flex flex-column justify-content-end m-3 p-2"
        *ngIf="message.heading || message.description || message.richText"
        >
          <div class="p-2 d-flex flex-column justify-content-between">
            <div
              class="heading font-bold-20px-24px-gentium-basic ">{{(message$|async)?.heading}}</div>
            <div class="mt-2 mb-2">{{(message$|async)?.richText}}</div>
            <div
              *ngIf="(message$|async)?.description"
              [ngStyle]="{'background-color': (selectedColor$ |async) }"
              class="mobile-button-solid d-flex flex-row justify-content-center align-items-center mb-3">

              <div >{{(message$|async)?.description}}</div>
            </div>
          </div>
        </div>

      </div>
    </app-background-image-selector>
  `,
  styles: [`
    .text-preview {
      margin: 24px;
      margin-top: 24px;

      text-align: left;
      color: #0000008A;
    }

    .pos-button {
      right: 5px;
      bottom: -10px;
      color: white;
    }
  `]
})
export class MobilePreviewNarratorComponent implements OnInit {
  @Input() hideControls = true;
  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
  selectedColor$: Observable<string> = this.store.select(selectedColor);

  constructor(public store: Store<State>) {
  }

  ngOnInit() {
  }


}

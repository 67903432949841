import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from "rxjs/operators";
import {RunAuthor} from "../../run-authors/store/run-author.state";
import {RunResponse} from "./run-responses.state";

@Injectable({
  providedIn: 'root',
})
export class RunResponsesService {

    constructor(private http: HttpClient) {
    }

    getAllResponsesForItem(runId: string, itemId: string, cursor: string) : Observable<RunResponseList> {
        if (cursor == null) {
            cursor = '*';
        }
        return this.http
            .get<any>(environment.api_url + `/run/response/runId/${runId}/item/${itemId}/${cursor}/all`)
            .pipe(
                map(res => {
                    if (res.responses) {

                        res.responses = res.responses.map(responseTransform);


                    } else {
                        res.responses = [];
                    }
                    return res;
                })
            );
    }

  getResponseMessageUntil(runId: string, itemId: string, from: number, cursor: string): Observable<RunResponseList> {
    if (cursor == null) {
      cursor = '*';
    }
    return this.http
      .get<any>(environment.api_url + `/run/response/runId/${runId}/item/${itemId}/from/${from}/until/${Date.now()}/cursor/${cursor}`)
      // .get<any>(environment.api_url + `/run/response/runId/${runId}/from/${from}/until/${Date.now()}/cursor/${cursor}`)
      .pipe(
        map(res => {
          if (res.responses) {
            res.responses = res.responses.map(responseTransform);
          } else {
            res.responses = [];
          }
          res.from = from;
          return res;
          // return res.responses ?  res.responses.map(responseTransform) : [];
        })
      );
  }

    getResponsesUntil(runId: string, from: number, cursor: string): Observable<RunResponseList> {
        if (cursor == null) {
            cursor = '*';
        }
        return this.http
            .get<any>(environment.api_url + `/run/response/runId/${runId}/from/${from}/until/${Date.now()}/cursor/${cursor}`)
            .pipe(
                map(res => {
                    if (res.responses) {
                        res.responses = res.responses.map(responseTransform);

                    } else {
                        res.responses = [];
                    }
                    return res;
                    // return res.responses ?  res.responses.map(responseTransform) : [];
                })
            );
    }


}


export interface RunResponseList {
  responses: RunResponse[];
  serverTime: number;
  resumptionToken?: string;
  from?: number;
}
// {
//   "type": "org.celstec.arlearn2.beans.run.ResponseList",
//   "deleted": false,
//   "serverTime": "1666813375140"
// }

const responseTransform = (response: any) => {
    if (response.generalItemId) {
        response.generalItemId = Number.parseInt(response.generalItemId, 10);
    }
    if (response.timestamp) {
        response.timestamp = Number.parseInt(response.timestamp, 10);
    }
    if (response.runId) {
        response.runId = Number.parseInt(response.runId, 10);
    }
    return response;
};




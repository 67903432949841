import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {MediaLibraryPageActions} from "../../store/media-lib.actions";
import {MatDialog} from "@angular/material/dialog";
import {CreateFolderComponent} from "../../../portal-image-management/modals/create-folder.component";

@Component({
  selector: 'app-media-lib-tab-bar',
  template: `
    <div class="media-tab-bar ">
      <div class=" maxwidth">
        <div class="upload-buttons">
          <button mat-button color="primary" (click)="newFolder()" class="new-folder-text">
            <mat-icon aria-hidden="false">create_new_folder</mat-icon>
            {{'MEDIA.NEW_FOLDER' |translate}}
          </button>
          <button mat-raised-button color="primary" (click)="uploadFiles()">
            <mat-icon aria-hidden="false">cloud_upload</mat-icon>
          </button>
        </div>


      </div>
    </div>
  `,
  styleUrls: ['./media-lib-tab-bar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MediaLibTabBarComponent implements OnInit {

  @Input() gameId: number;

  constructor(
    public store: Store<State>,
    public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  uploadFiles() {
    this.store.dispatch(MediaLibraryPageActions.setUploadModus({modus: true}));
  }

  newFolder() {

    const dialogRef = this.dialog.open(CreateFolderComponent, {
      height: '200px',
      width: '420px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(MediaLibraryPageActions.createFolder(result));
      }
    });
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {environment} from "../../../../../../environments/environment";
import {GameMessagePageActions} from "../../../../store/game-message.actions";
import {Store} from "@ngrx/store";
import {State} from "../../../../../core/reducers";
import {Observable} from "rxjs";
import {canWriteCurrentGame} from "../../../../../game-authors/store/game-author.selectors";

export interface GpsPosition {
    coords: { lat: number, lng: number }
}
@Component({
    selector: 'app-pick-location-on-map',
    template: `
        <div>
            <div class="gi-edit-field-label">{{'GAME.LOCATION_BASED' | translate}}</div>
            <mat-slide-toggle
                    [disabled]="!(iCanWrite|async)"
                    [(ngModel)]="locationBased"
                    (change)="slideChange($event)">
              <div *ngIf="locationBased">
                {{'COMMON.ACTIVE' |translate}}
              </div>

              <div *ngIf="!locationBased">
                {{'COMMON.INACTIVE' |translate}}
              </div>

            </mat-slide-toggle>
            <div *ngIf="locationBased" class="gi-edit-field-label">{{'MESSAGE.SHOW_IN_LIST' | translate}}</div>
            <mat-slide-toggle
                    *ngIf="locationBased"
                    [disabled]="!(iCanWrite|async) "
                    [(ngModel)]="showInList"
                    (change)="listSlideChange($event)">
              <div *ngIf="showInList">
                {{'MESSAGE.SHOW_IN_LIST_ACTIVE' |translate}}
              </div>
              <div *ngIf="!showInList">
                {{'MESSAGE.SHOW_IN_LIST_INACTIVE' |translate}}
              </div>

            </mat-slide-toggle>

            <div *ngIf="locationBased" class="map-area">

                <agm-map class = "map"
                         [latitude]="lat"
                         [longitude]="lng">
                    <agm-marker
                            [markerDraggable] = "iCanWrite|async"
                            [markerClickable] = "false"
                            [latitude]="lat"
                            [longitude]="lng"
                            (dragEnd)="newLocation($event)"
                    >

                    </agm-marker>
                </agm-map>
            </div>
        </div>

    `
})
export class PickLocationOnMapComponent implements OnInit {
    public iCanWrite: Observable<boolean> = this.store.select(canWriteCurrentGame);

    @Input() locationBased = false;
    @Input() showInList = false;
    @Input() lat = environment.homeLocation.latitude;
    @Input() lng = environment.homeLocation.longitude;
    @Output() onLocationChange = new EventEmitter<GpsPosition>();
    @Output() disableLocation = new EventEmitter<boolean>();

    constructor(public store: Store<State>) {
    }

    ngOnInit() {
        if (!this.lat) this.lat = environment.homeLocation.latitude;
        if (!this.lng) this.lng = environment.homeLocation.longitude;
    }

    slideChange(changeEvent: MatSlideToggleChange) {
        if (!changeEvent.checked) {
            this.store.dispatch(GameMessagePageActions.clearLocation());
            this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{showInList: true}}));
        } else {
            this.ngOnInit();
            this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{showInList: true}}));
            this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{lat: this.lat, lng: this.lng}}));
        }
    }

    listSlideChange(changeEvent: MatSlideToggleChange) {
        this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{showInList: changeEvent.checked}}));
    }

    newLocation($event: GpsPosition) {
        this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{lat: $event.coords.lat, lng: $event.coords.lng}}));

    }
}

import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {GameMessage, MessageFilter} from './game-messages.state';

export const GameMessagesPageActions = createActionGroup({
  source: 'Game Messages Page',
  events: {
    'Get Messages': props<{ gameId?: string }>(),
    'Get Message': emptyProps(),
    'Delete Message': props<{ messageId: number }>(),
    'New Message': props<{ gameMessage: Partial<GameMessage> }>(),
    'Set filter': props<MessageFilter>(),
    'Set Loading': props<{loading: boolean}>(),
  },
});

export const GameMessagesApiActions = createActionGroup({
  source: 'Game Messages Api',
  events: {
    'Game Message Save Complete': props<{ gameMessage: GameMessage }>(),
    'Add Message': props<{ gameMessage: GameMessage }>(),
    'Get Messages Complete': props<{ gameId?: string, cursor: string, items: GameMessage[] }>(),
    'Remove Message': props<{ gameMessage: GameMessage }>(),
  },
});

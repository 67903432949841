import {createFeatureSelector, createSelector,} from '@ngrx/store';

import {Game, GameState} from './game.state';
import * as fromRoot from 'src/app/core/reducers';
import {gameAdapter} from "./game.reducer";
import {selectRouteParam} from "../../core/selectors/router.selector";
import {AllMyGameAuthorIds} from "../../game-authors/store/game-author.selectors";
import {allMyPlayerGameIds} from "../../run-players/store/run-players.selectors";
import {allMyRunAuthorGameIds} from "../../run-authors/store/run-author.selectors";


export interface State extends fromRoot.State {
  game: GameState;
}

export const getGameFeature = createFeatureSelector<GameState>('game');
export const getInSyncTill = createSelector(getGameFeature, (state: GameState) => state.lastSyncTime);
export const getInSyncTillParticipate = createSelector(getGameFeature, (state: GameState) => state.lastSyncTimeParticipate);
const getGames = (state: GameState) => state.games;
const getFilters = (state: GameState) => state.filter;

export const getGamesAdapter = createSelector(getGameFeature, getGames);
export const getGamesFilters = createSelector(getGameFeature, getFilters);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = gameAdapter.getSelectors(getGamesAdapter);

// export const getGameFeature = createFeatureSelector<State, any>('game');

const _getLoading = (state: GameState) => state.loading;
export const getLoading = createSelector(getGameFeature, _getLoading);

export const allNotDeletedGames = createSelector(selectAll,
  (games) => games
    .filter(
      game => !game.deleted
    )
);

export const allGameIdsAuthorAndGameIdsPlayer = createSelector(
  AllMyGameAuthorIds, allMyPlayerGameIds, allMyRunAuthorGameIds,
  allNotDeletedGames, (gameIds, gameIdsPlayers, gameIdsRunAuthors, games) => {
    const allGameIds = Array.from(new Set([...gameIds, ...gameIdsPlayers, ...gameIdsRunAuthors]));

    // console.log("all game ids", allGameIds.filter(x=> x != '6330495876988928'));
    // console.log("all gameIds ids", gameIds.filter(x=> x != '6330495876988928'));
    // console.log("all gameIdsPlayers ids", gameIdsPlayers.filter(x=> x == '6330495876988928'));
    // console.log("all game gameIdsRunAuthors", gameIdsRunAuthors.filter(x=> x+'' == '6330495876988928'));
    // console.log("search for 6194831332212736");
    return games.filter(game => allGameIds.indexOf(game.gameId) !== -1);
  })

export const getFilteredGamesSelector = createSelector(allGameIdsAuthorAndGameIdsPlayer, getGamesFilters,
  (games: Game[], filters: string[]) => {

    games.sort((a, b) => b.lastModificationDate - a.lastModificationDate);
    return games
      .filter(game => {
        if (!filters) return true;
        for (let i = 0; i < filters.length; i++) {
          if ((game.title.toUpperCase().indexOf(filters[0].toUpperCase()) === -1)) {
            return false;
          }
        }
        return true;
      });
  });
export const getCurrentGameId = createSelector(selectRouteParam('gameId'), gameId => Number.parseInt(gameId, 10))

export const getCurrentGame = createSelector(selectEntities, getCurrentGameId, (entities, gameId) => {
  return entities[gameId];
})


import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {Observable} from "rxjs";
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'app-font-awesome-picker',
  template: `
    <div class="d-flex flex-row align-items-center">
<!--      <div class="flex-grow-0 mr-3">-->
<!--        <i [ngClass]="selected" style="font-size: 50px"></i>-->
<!--      </div>-->

      <mat-form-field
        *hasOnePermission="['advanced']"
        class="gl-pos-field-full-width flex-grow-1" >
<!--        <mat-icon matPrefix>sentiment_very_satisfied</mat-icon>-->


        <i matPrefix [ngClass]="selected" style="font-size: 18px" class="m-1 mr-2 color-bbb color-o54"></i>

        <input matInput [placeholder]="'MESSAGE.ICON_TEXT'|translate"
               [disabled]="disabled"
               [ngModel]="message?.icon"
               (ngModelChange)="select($event)"
               [matAutocomplete]="auto"
               [formControl]="stateCtrl"
        >
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let state of filteredStates | async" [value]="state.icon">
            <i [ngClass]="state.class" ></i>

            <span class="ml-2">{{state.name}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
<!--    <mat-form-field-->
<!--      *hasOnePermission="['advanced']"-->
<!--      class="gl-pos-field-full-width gl-pos-between-fields" >-->

<!--      <input matInput [placeholder]="'MESSAGE.ICON_TEXT'|translate"-->
<!--             [disabled]="disabled"-->
<!--             [ngModel]="message?.icon"-->
<!--             (ngModelChange)="iconChange.emit($event)"-->
<!--             [matAutocomplete]="auto"-->
<!--             [formControl]="stateCtrl"-->
<!--      >-->
<!--      <mat-autocomplete #auto="matAutocomplete">-->
<!--        <mat-option *ngFor="let state of filteredStates | async" [value]="state.icon">-->
<!--          <i [ngClass]="state.class" ></i>-->

<!--          <span class="ml-2">{{state.name}}</span>-->
<!--        </mat-option>-->
<!--      </mat-autocomplete>-->
<!--    </mat-form-field>-->
  `,
  styles: [
  ]
})
export class FontAwesomePickerComponent implements OnInit {
  @Input() message: GameMessage;
  @Input() disabled: true;
  @Output() iconChange = new EventEmitter<any>();

  @Input() selected = 'fas fa-plane';
  stateCtrl = new FormControl();

  filteredStates: Observable<any[]>;

  states: any[] = [
    {
      class:"fas fa-plane",
      name: 'plane, airplane, destination, fly, location, mode, travel, trip',
      icon: 'plane',
    },
    {class:"fas fa-ad", name:"advertisement, media, newspaper, promotion, publicity", icon:"ad"},
    {class:"fas fa-address-book", name:"contact, directory, index, little black book, rolodex", icon:"solidAddressBook"},
    {class:"fas fa-address-card", name:"about, contact, id, identification, postcard, profile", icon:"solidAddressCard"},
    {class:"fas fa-adjust", name:"contrast, dark, light, saturation", icon:"adjust"},
    {class:"fas fa-air-freshener", name:"car, deodorize, fresh, pine, scent", icon:"airFreshener"},
    {class:"fas fa-align-center", name:"format, middle, paragraph, text", icon:"alignCenter"},
    {class:"fas fa-align-justify", name:"format, paragraph, text", icon:"alignJustify"},
    {class:"fas fa-align-left", name:"format, paragraph, text", icon:"alignLeft"},
    {class:"fas fa-align-right", name:"format, paragraph, text", icon:"alignRight"},
    {class:"fas fa-allergies", name:"allergy, freckles, hand, hives, pox, skin, spots", icon:"allergies"},
    {class:"fas fa-ambulance", name:"covid-19, emergency, emt, er, help, hospital, support, vehicle", icon:"ambulance"},
    {class:"fas fa-american-sign-language-interpreting", name:"asl, deaf, finger, hand, interpret, speak", icon:"americanSignLanguageInterpreting"},
    {class:"fas fa-anchor", name:"berth, boat, dock, embed, link, maritime, moor, secure", icon:"anchor"},
    {class:"fas fa-angle-double-down", name:"arrows, caret, download, expand", icon:"angleDoubleDown"},
    {class:"fas fa-angle-double-left", name:"arrows, back, caret, laquo, previous, quote", icon:"angleDoubleLeft"},
    {class:"fas fa-angle-double-right", name:"arrows, caret, forward, more, next, quote, raquo", icon:"angleDoubleRight"},
    {class:"fas fa-angle-double-up", name:"arrows, caret, collapse, upload", icon:"angleDoubleUp"},
    {class:"fas fa-angle-down", name:"arrow, caret, download, expand", icon:"angleDown"},
    {class:"fas fa-angle-left", name:"arrow, back, caret, less, previous", icon:"angleLeft"},
    {class:"fas fa-angle-right", name:"arrow, care, forward, more, next", icon:"angleRight"},
    {class:"fas fa-angle-up", name:"arrow, caret, collapse, upload", icon:"angleUp"},
    {class:"fas fa-angry", name:"disapprove, emoticon, face, mad, upset", icon:"solidAngry"},
    {class:"fas fa-ankh", name:"amulet, copper, coptic christianity, copts, crux ansata, egypt, venus", icon:"ankh"},
    {class:"fas fa-apple-alt", name:"fall, fruit, fuji, macintosh, orchard, seasonal, vegan", icon:"appleAlt"},
    {class:"fas fa-archive", name:"box, package, save, storage", icon:"archive"},
    {class:"fas fa-archway", name:"arc, monument, road, street, tunnel", icon:"archway"},
    {class:"fas fa-arrow-alt-circle-down", name:"arrow-circle-o-down, download", icon:"solidArrowAltCircleDown"},
    {class:"fas fa-arrow-alt-circle-left", name:"arrow-circle-o-left, back, previous", icon:"solidArrowAltCircleLeft"},
    {class:"fas fa-arrow-alt-circle-right", name:"arrow-circle-o-right, forward, next", icon:"solidArrowAltCircleRight"},
    {class:"fas fa-arrow-alt-circle-up", name:"arrow-circle-o-up", icon:"solidArrowAltCircleUp"},
    {class:"fas fa-arrow-circle-down", name:"download", icon:"arrowCircleDown"},
    {class:"fas fa-arrow-circle-left", name:"back, previous", icon:"arrowCircleLeft"},
    {class:"fas fa-arrow-circle-right", name:"forward, next", icon:"arrowCircleRight"},
    {class:"fas fa-arrow-circle-up", name:"upload", icon:"arrowCircleUp"},
    {class:"fas fa-arrow-down", name:"download", icon:"arrowDown"},
    {class:"fas fa-arrow-left", name:"back, previous", icon:"arrowLeft"},
    {class:"fas fa-arrow-right", name:"forward, next", icon:"arrowRight"},
    {class:"fas fa-arrow-up", name:"forward, upload", icon:"arrowUp"},
    {class:"fas fa-arrows-alt", name:"arrow, arrows, bigger, enlarge, expand, fullscreen, move, position, reorder, resize", icon:"arrowsAlt"},
    {class:"fas fa-arrows-alt-h", name:"arrows-h, expand, horizontal, landscape, resize, wide", icon:"arrowsAltH"},
    {class:"fas fa-arrows-alt-v", name:"arrows-v, expand, portrait, resize, tall, vertical", icon:"arrowsAltV"},
    {class:"fas fa-assistive-listening-systems", name:"amplify, audio, deaf, ear, headset, hearing, sound", icon:"assistiveListeningSystems"},
    {class:"fas fa-asterisk", name:"annotation, details, reference, star", icon:"asterisk"},
    {class:"fas fa-at", name:"address, author, e-mail, email, handle", icon:"at"},
    {class:"fas fa-atlas", name:"book, directions, geography, globe, map, travel, wayfinding", icon:"atlas"},
    {class:"fas fa-atom", name:"atheism, chemistry, electron, ion, isotope, neutron, nuclear, proton, science", icon:"atom"},
    {class:"fas fa-audio-description", name:"blind, narration, video, visual", icon:"audioDescription"},
    {class:"fas fa-award", name:"honor, praise, prize, recognition, ribbon, trophy", icon:"award"},
    {class:"fas fa-baby", name:"child, diaper, doll, human, infant, kid, offspring, person, sprout", icon:"baby"},
    {class:"fas fa-baby-carriage", name:"buggy, carrier, infant, push, stroller, transportation, walk, wheels", icon:"babyCarriage"},
    {class:"fas fa-backspace", name:"command, delete, erase, keyboard, undo", icon:"backspace"},
    {class:"fas fa-backward", name:"previous, rewind", icon:"backward"},
    {class:"fas fa-bacon", name:"blt, breakfast, ham, lard, meat, pancetta, pork, rasher", icon:"bacon"},
    {class:"fas fa-bacteria", name:"antibiotic, antibody, covid-19, health, organism, sick", icon:"bacteria"},
    {class:"fas fa-bacterium", name:"antibiotic, antibody, covid-19, health, organism, sick", icon:"bacterium"},
    {class:"fas fa-bahai", name:"bahai, bahá'í, star", icon:"bahai"},
    {class:"fas fa-balance-scale", name:"balanced, justice, legal, measure, weight", icon:"balanceScale"},
    {class:"fas fa-balance-scale-left", name:"justice, legal, measure, unbalanced, weight", icon:"balanceScaleLeft"},
    {class:"fas fa-balance-scale-right", name:"justice, legal, measure, unbalanced, weight", icon:"balanceScaleRight"},
    {class:"fas fa-ban", name:"abort, ban, block, cancel, delete, hide, prohibit, remove, stop, trash", icon:"ban"},
    {class:"fas fa-band-aid", name:"bandage, boo boo, first aid, ouch", icon:"bandAid"},
    {class:"fas fa-barcode", name:"info, laser, price, scan, upc", icon:"barcode"},
    {class:"fas fa-bars", name:"checklist, drag, hamburger, list, menu, nav, navigation, ol, reorder, settings, todo, ul", icon:"bars"},
    {class:"fas fa-baseball-ball", name:"foul, hardball, league, leather, mlb, softball, sport", icon:"baseballBall"},
    {class:"fas fa-basketball-ball", name:"dribble, dunk, hoop, nba", icon:"basketballBall"},
    {class:"fas fa-bath", name:"clean, shower, tub, wash", icon:"bath"},
    {class:"fas fa-battery-empty", name:"charge, dead, power, status", icon:"batteryEmpty"},
    {class:"fas fa-battery-full", name:"charge, power, status", icon:"batteryFull"},
    {class:"fas fa-battery-half", name:"charge, power, status", icon:"batteryHalf"},
    {class:"fas fa-battery-quarter", name:"charge, low, power, status", icon:"batteryQuarter"},
    {class:"fas fa-battery-three-quarters", name:"charge, power, status", icon:"batteryThreeQuarters"},
    {class:"fas fa-bed", name:"lodging, mattress, rest, sleep, travel", icon:"bed"},
    {class:"fas fa-beer", name:"alcohol, ale, bar, beverage, brewery, drink, lager, liquor, mug, stein", icon:"beer"},
    {class:"fas fa-bell", name:"alarm, alert, chime, notification, reminder", icon:"solidBell"},
    {class:"fas fa-bell-slash", name:"alert, cancel, disabled, notification, off, reminder", icon:"solidBellSlash"},
    {class:"fas fa-bezier-curve", name:"curves, illustrator, lines, path, vector", icon:"bezierCurve"},
    {class:"fas fa-bible", name:"book, catholicism, christianity, god, holy", icon:"bible"},
    {class:"fas fa-bicycle", name:"bike, gears, pedal, transportation, vehicle", icon:"bicycle"},
    {class:"fas fa-biking", name:"bicycle, bike, cycle, cycling, ride, wheel", icon:"biking"},
    {class:"fas fa-binoculars", name:"glasses, magnify, scenic, spyglass, view", icon:"binoculars"},
    {class:"fas fa-biohazard", name:"covid-19, danger, dangerous, hazmat, medical, radioactive, toxic, waste, zombie", icon:"biohazard"},
    {class:"fas fa-birthday-cake", name:"anniversary, bakery, candles, celebration, dessert, frosting, holiday, party, pastry", icon:"birthdayCake"},
    {class:"fas fa-blender", name:"cocktail, milkshake, mixer, puree, smoothie", icon:"blender"},
    {class:"fas fa-blender-phone", name:"appliance, cocktail, communication, fantasy, milkshake, mixer, puree, silly, smoothie", icon:"blenderPhone"},
    {class:"fas fa-blind", name:"cane, disability, person, sight", icon:"blind"},
    {class:"fas fa-blog", name:"journal, log, online, personal, post, web 2.0, wordpress, writing", icon:"blog"},
    {class:"fas fa-bold", name:"emphasis, format, text", icon:"bold"},
    {class:"fas fa-bolt", name:"electricity, lightning, weather, zap", icon:"bolt"},
    {class:"fas fa-bomb", name:"error, explode, fuse, grenade, warning", icon:"bomb"},
    {class:"fas fa-bone", name:"calcium, dog, skeletal, skeleton, tibia", icon:"bone"},
    {class:"fas fa-bong", name:"aparatus, cannabis, marijuana, pipe, smoke, smoking", icon:"bong"},
    {class:"fas fa-book", name:"diary, documentation, journal, library, read", icon:"book"},
    {class:"fas fa-book-dead", name:"Dungeons & Dragons, crossbones, d&d, dark arts, death, dnd, documentation, evil, fantasy, halloween, holiday, necronomicon, read, skull, spell", icon:"bookDead"},
    {class:"fas fa-book-medical", name:"diary, documentation, health, history, journal, library, read, record", icon:"bookMedical"},
    {class:"fas fa-book-open", name:"flyer, library, notebook, open book, pamphlet, reading", icon:"bookOpen"},
    {class:"fas fa-book-reader", name:"flyer, library, notebook, open book, pamphlet, reading", icon:"bookReader"},
    {class:"fas fa-bookmark", name:"favorite, marker, read, remember, save", icon:"solidBookmark"},
    {class:"fas fa-border-all", name:"cell, grid, outline, stroke, table", icon:"borderAll"},
    {class:"fas fa-border-none", name:"cell, grid, outline, stroke, table", icon:"borderNone"},
    {class:"fas fa-border-style", name:"borderStyle", icon:"borderStyle"},
    {class:"fas fa-bowling-ball", name:"alley, candlepin, gutter, lane, strike, tenpin", icon:"bowlingBall"},
    {class:"fas fa-box", name:"archive, container, package, storage", icon:"box"},
    {class:"fas fa-box-open", name:"archive, container, package, storage, unpack", icon:"boxOpen"},
    {class:"fas fa-box-tissue", name:"cough, covid-19, kleenex, mucus, nose, sneeze, snot", icon:"boxTissue"},
    {class:"fas fa-boxes", name:"archives, inventory, storage, warehouse", icon:"boxes"},
    {class:"fas fa-braille", name:"alphabet, blind, dots, raised, vision", icon:"braille"},
    {class:"fas fa-brain", name:"cerebellum, gray matter, intellect, medulla oblongata, mind, noodle, wit", icon:"brain"},
    {class:"fas fa-bread-slice", name:"bake, bakery, baking, dough, flour, gluten, grain, sandwich, sourdough, toast, wheat, yeast", icon:"breadSlice"},
    {class:"fas fa-briefcase", name:"bag, business, luggage, office, work", icon:"briefcase"},
    {class:"fas fa-briefcase-medical", name:"doctor, emt, first aid, health", icon:"briefcaseMedical"},
    {class:"fas fa-broadcast-tower", name:"airwaves, antenna, radio, reception, waves", icon:"broadcastTower"},
    {class:"fas fa-broom", name:"clean, firebolt, fly, halloween, nimbus 2000, quidditch, sweep, witch", icon:"broom"},
    {class:"fas fa-brush", name:"art, bristles, color, handle, paint", icon:"brush"},
    {class:"fas fa-bug", name:"beetle, error, insect, report", icon:"bug"},
    {class:"fas fa-building", name:"apartment, business, city, company, office, work", icon:"solidBuilding"},
    {class:"fas fa-bullhorn", name:"announcement, broadcast, louder, megaphone, share", icon:"bullhorn"},
    {class:"fas fa-bullseye", name:"archery, goal, objective, target", icon:"bullseye"},
    {class:"fas fa-burn", name:"caliente, energy, fire, flame, gas, heat, hot", icon:"burn"},
    {class:"fas fa-bus", name:"public transportation, transportation, travel, vehicle", icon:"bus"},
    {class:"fas fa-bus-alt", name:"mta, public transportation, transportation, travel, vehicle", icon:"busAlt"},
    {class:"fas fa-business-time", name:"alarm, briefcase, business socks, clock, flight of the conchords, reminder, wednesday", icon:"businessTime"},
    {class:"fas fa-calculator", name:"abacus, addition, arithmetic, counting, math, multiplication, subtraction", icon:"calculator"},
    {class:"fas fa-calendar", name:"calendar-o, date, event, schedule, time, when", icon:"solidCalendar"},
    {class:"fas fa-calendar-alt", name:"calendar, date, event, schedule, time, when", icon:"solidCalendarAlt"},
    {class:"fas fa-calendar-check", name:"accept, agree, appointment, confirm, correct, date, done, event, ok, schedule, select, success, tick, time, todo, when", icon:"solidCalendarCheck"},
    {class:"fas fa-calendar-day", name:"date, detail, event, focus, schedule, single day, time, today, when", icon:"calendarDay"},
    {class:"fas fa-calendar-minus", name:"calendar, date, delete, event, negative, remove, schedule, time, when", icon:"solidCalendarMinus"},
    {class:"fas fa-calendar-plus", name:"add, calendar, create, date, event, new, positive, schedule, time, when", icon:"solidCalendarPlus"},
    {class:"fas fa-calendar-times", name:"archive, calendar, date, delete, event, remove, schedule, time, when, x", icon:"solidCalendarTimes"},
    {class:"fas fa-calendar-week", name:"date, detail, event, focus, schedule, single week, time, today, when", icon:"calendarWeek"},
    {class:"fas fa-camera", name:"image, lens, photo, picture, record, shutter, video", icon:"camera"},
    {class:"fas fa-camera-retro", name:"image, lens, photo, picture, record, shutter, video", icon:"cameraRetro"},
    {class:"fas fa-campground", name:"camping, fall, outdoors, teepee, tent, tipi", icon:"campground"},
    {class:"fas fa-candy-cane", name:"candy, christmas, holiday, mint, peppermint, striped, xmas", icon:"candyCane"},
    {class:"fas fa-cannabis", name:"bud, chronic, drugs, endica, endo, ganja, marijuana, mary jane, pot, reefer, sativa, spliff, weed, whacky-tabacky", icon:"cannabis"},
    {class:"fas fa-capsules", name:"drugs, medicine, pills, prescription", icon:"capsules"},
    {class:"fas fa-car", name:"auto, automobile, sedan, transportation, travel, vehicle", icon:"car"},
    {class:"fas fa-car-alt", name:"auto, automobile, sedan, transportation, travel, vehicle", icon:"carAlt"},
    {class:"fas fa-car-battery", name:"auto, electric, mechanic, power", icon:"carBattery"},
    {class:"fas fa-car-crash", name:"accident, auto, automobile, insurance, sedan, transportation, vehicle, wreck", icon:"carCrash"},
    {class:"fas fa-car-side", name:"auto, automobile, sedan, transportation, travel, vehicle", icon:"carSide"},
    {class:"fas fa-caravan", name:"camper, motor home, rv, trailer, travel", icon:"caravan"},
    {class:"fas fa-caret-down", name:"arrow, dropdown, expand, menu, more, triangle", icon:"caretDown"},
    {class:"fas fa-caret-left", name:"arrow, back, previous, triangle", icon:"caretLeft"},
    {class:"fas fa-caret-right", name:"arrow, forward, next, triangle", icon:"caretRight"},
    {class:"fas fa-caret-square-down", name:"arrow, caret-square-o-down, dropdown, expand, menu, more, triangle", icon:"solidCaretSquareDown"},
    {class:"fas fa-caret-square-left", name:"arrow, back, caret-square-o-left, previous, triangle", icon:"solidCaretSquareLeft"},
    {class:"fas fa-caret-square-right", name:"arrow, caret-square-o-right, forward, next, triangle", icon:"solidCaretSquareRight"},
    {class:"fas fa-caret-square-up", name:"arrow, caret-square-o-up, collapse, triangle, upload", icon:"solidCaretSquareUp"},
    {class:"fas fa-caret-up", name:"arrow, collapse, triangle", icon:"caretUp"},
    {class:"fas fa-carrot", name:"bugs bunny, orange, vegan, vegetable", icon:"carrot"},
    {class:"fas fa-cart-arrow-down", name:"download, save, shopping", icon:"cartArrowDown"},
    {class:"fas fa-cart-plus", name:"add, create, new, positive, shopping", icon:"cartPlus"},
    {class:"fas fa-cash-register", name:"buy, cha-ching, change, checkout, commerce, leaerboard, machine, pay, payment, purchase, store", icon:"cashRegister"},
    {class:"fas fa-cat", name:"feline, halloween, holiday, kitten, kitty, meow, pet", icon:"cat"},
    {class:"fas fa-certificate", name:"badge, star, verified", icon:"certificate"},
    {class:"fas fa-chair", name:"furniture, seat, sit", icon:"chair"},
    {class:"fas fa-chalkboard", name:"blackboard, learning, school, teaching, whiteboard, writing", icon:"chalkboard"},
    {class:"fas fa-chalkboard-teacher", name:"blackboard, instructor, learning, professor, school, whiteboard, writing", icon:"chalkboardTeacher"},
    {class:"fas fa-charging-station", name:"electric, ev, tesla, vehicle", icon:"chargingStation"},
    {class:"fas fa-chart-area", name:"analytics, area, chart, graph", icon:"chartArea"},
    {class:"fas fa-chart-bar", name:"analytics, bar, chart, graph", icon:"solidChartBar"},
    {class:"fas fa-chart-line", name:"activity, analytics, chart, dashboard, gain, graph, increase, line", icon:"chartLine"},
    {class:"fas fa-chart-pie", name:"analytics, chart, diagram, graph, pie", icon:"chartPie"},
    {class:"fas fa-check", name:"accept, agree, checkmark, confirm, correct, done, notice, notification, notify, ok, select, success, tick, todo, yes", icon:"check"},
    {class:"fas fa-check-circle", name:"accept, agree, confirm, correct, done, ok, select, success, tick, todo, yes", icon:"solidCheckCircle"},
    {class:"fas fa-check-double", name:"accept, agree, checkmark, confirm, correct, done, notice, notification, notify, ok, select, success, tick, todo", icon:"checkDouble"},
    {class:"fas fa-check-square", name:"accept, agree, checkmark, confirm, correct, done, ok, select, success, tick, todo, yes", icon:"solidCheckSquare"},
    {class:"fas fa-cheese", name:"cheddar, curd, gouda, melt, parmesan, sandwich, swiss, wedge", icon:"cheese"},
    {class:"fas fa-chess", name:"board, castle, checkmate, game, king, rook, strategy, tournament", icon:"chess"},
    {class:"fas fa-chess-bishop", name:"board, checkmate, game, strategy", icon:"chessBishop"},
    {class:"fas fa-chess-board", name:"board, checkmate, game, strategy", icon:"chessBoard"},
    {class:"fas fa-chess-king", name:"board, checkmate, game, strategy", icon:"chessKing"},
    {class:"fas fa-chess-knight", name:"board, checkmate, game, horse, strategy", icon:"chessKnight"},
    {class:"fas fa-chess-pawn", name:"board, checkmate, game, strategy", icon:"chessPawn"},
    {class:"fas fa-chess-queen", name:"board, checkmate, game, strategy", icon:"chessQueen"},
    {class:"fas fa-chess-rook", name:"board, castle, checkmate, game, strategy", icon:"chessRook"},
    {class:"fas fa-chevron-circle-down", name:"arrow, download, dropdown, menu, more", icon:"chevronCircleDown"},
    {class:"fas fa-chevron-circle-left", name:"arrow, back, previous", icon:"chevronCircleLeft"},
    {class:"fas fa-chevron-circle-right", name:"arrow, forward, next", icon:"chevronCircleRight"},
    {class:"fas fa-chevron-circle-up", name:"arrow, collapse, upload", icon:"chevronCircleUp"},
    {class:"fas fa-chevron-down", name:"arrow, download, expand", icon:"chevronDown"},
    {class:"fas fa-chevron-left", name:"arrow, back, bracket, previous", icon:"chevronLeft"},
    {class:"fas fa-chevron-right", name:"arrow, bracket, forward, next", icon:"chevronRight"},
    {class:"fas fa-chevron-up", name:"arrow, collapse, upload", icon:"chevronUp"},
    {class:"fas fa-child", name:"boy, girl, kid, toddler, young", icon:"child"},
    {class:"fas fa-church", name:"building, cathedral, chapel, community, religion", icon:"church"},
    {class:"fas fa-circle", name:"circle-thin, diameter, dot, ellipse, notification, round", icon:"solidCircle"},
    {class:"fas fa-circle-notch", name:"circle-o-notch, diameter, dot, ellipse, round, spinner", icon:"circleNotch"},
    {class:"fas fa-city", name:"buildings, busy, skyscrapers, urban, windows", icon:"city"},
    {class:"fas fa-clinic-medical", name:"covid-19, doctor, general practitioner, hospital, infirmary, medicine, office, outpatient", icon:"clinicMedical"},
    {class:"fas fa-clipboard", name:"copy, notes, paste, record", icon:"solidClipboard"},
    {class:"fas fa-clipboard-check", name:"accept, agree, confirm, done, ok, select, success, tick, todo, yes", icon:"clipboardCheck"},
    {class:"fas fa-clipboard-list", name:"checklist, completed, done, finished, intinerary, ol, schedule, tick, todo, ul", icon:"clipboardList"},
    {class:"fas fa-clock", name:"date, late, schedule, time, timer, timestamp, watch", icon:"solidClock"},
    {class:"fas fa-clone", name:"arrange, copy, duplicate, paste", icon:"solidClone"},
    {class:"fas fa-closed-captioning", name:"cc, deaf, hearing, subtitle, subtitling, text, video", icon:"solidClosedCaptioning"},
    {class:"fas fa-cloud", name:"atmosphere, fog, overcast, save, upload, weather", icon:"cloud"},
    {class:"fas fa-cloud-download-alt", name:"download, export, save", icon:"cloudDownloadAlt"},
    {class:"fas fa-cloud-meatball", name:"FLDSMDFR, food, spaghetti, storm", icon:"cloudMeatball"},
    {class:"fas fa-cloud-moon", name:"crescent, evening, lunar, night, partly cloudy, sky", icon:"cloudMoon"},
    {class:"fas fa-cloud-moon-rain", name:"crescent, evening, lunar, night, partly cloudy, precipitation, rain, sky, storm", icon:"cloudMoonRain"},
    {class:"fas fa-cloud-rain", name:"precipitation, rain, sky, storm", icon:"cloudRain"},
    {class:"fas fa-cloud-showers-heavy", name:"precipitation, rain, sky, storm", icon:"cloudShowersHeavy"},
    {class:"fas fa-cloud-sun", name:"clear, day, daytime, fall, outdoors, overcast, partly cloudy", icon:"cloudSun"},
    {class:"fas fa-cloud-sun-rain", name:"day, overcast, precipitation, storm, summer, sunshower", icon:"cloudSunRain"},
    {class:"fas fa-cloud-upload-alt", name:"cloud-upload, import, save, upload", icon:"cloudUploadAlt"},
    {class:"fas fa-cocktail", name:"alcohol, beverage, drink, gin, glass, margarita, martini, vodka", icon:"cocktail"},
    {class:"fas fa-code", name:"brackets, code, development, html", icon:"code"},
    {class:"fas fa-code-branch", name:"branch, code-fork, fork, git, github, rebase, svn, vcs, version", icon:"codeBranch"},
    {class:"fas fa-coffee", name:"beverage, breakfast, cafe, drink, fall, morning, mug, seasonal, tea", icon:"coffee"},
    {class:"fas fa-cog", name:"gear, mechanical, settings, sprocket, wheel", icon:"cog"},
    {class:"fas fa-cogs", name:"gears, mechanical, settings, sprocket, wheel", icon:"cogs"},
    {class:"fas fa-coins", name:"currency, dime, financial, gold, money, penny", icon:"coins"},
    {class:"fas fa-columns", name:"browser, dashboard, organize, panes, split", icon:"columns"},
    {class:"fas fa-comment", name:"bubble, chat, commenting, conversation, feedback, message, note, notification, sms, speech, texting", icon:"solidComment"},
    {class:"fas fa-comment-alt", name:"bubble, chat, commenting, conversation, feedback, message, note, notification, sms, speech, texting", icon:"solidCommentAlt"},
    {class:"fas fa-comment-dollar", name:"bubble, chat, commenting, conversation, feedback, message, money, note, notification, pay, sms, speech, spend, texting, transfer", icon:"commentDollar"},
    {class:"fas fa-comment-dots", name:"bubble, chat, commenting, conversation, feedback, message, more, note, notification, reply, sms, speech, texting", icon:"solidCommentDots"},
    {class:"fas fa-comment-medical", name:"advice, bubble, chat, commenting, conversation, diagnose, feedback, message, note, notification, prescription, sms, speech, texting", icon:"commentMedical"},
    {class:"fas fa-comment-slash", name:"bubble, cancel, chat, commenting, conversation, feedback, message, mute, note, notification, quiet, sms, speech, texting", icon:"commentSlash"},
    {class:"fas fa-comments", name:"bubble, chat, commenting, conversation, feedback, message, note, notification, sms, speech, texting", icon:"solidComments"},
    {class:"fas fa-comments-dollar", name:"bubble, chat, commenting, conversation, feedback, message, money, note, notification, pay, sms, speech, spend, texting, transfer", icon:"commentsDollar"},
    {class:"fas fa-compact-disc", name:"album, bluray, cd, disc, dvd, media, movie, music, record, video, vinyl", icon:"compactDisc"},
    {class:"fas fa-compass", name:"directions, directory, location, menu, navigation, safari, travel", icon:"solidCompass"},
    {class:"fas fa-compress", name:"collapse, fullscreen, minimize, move, resize, shrink, smaller", icon:"compress"},
    {class:"fas fa-compress-alt", name:"collapse, fullscreen, minimize, move, resize, shrink, smaller", icon:"compressAlt"},
    {class:"fas fa-compress-arrows-alt", name:"collapse, fullscreen, minimize, move, resize, shrink, smaller", icon:"compressArrowsAlt"},
    {class:"fas fa-concierge-bell", name:"attention, hotel, receptionist, service, support", icon:"conciergeBell"},
    {class:"fas fa-cookie", name:"baked good, chips, chocolate, eat, snack, sweet, treat", icon:"cookie"},
    {class:"fas fa-cookie-bite", name:"baked good, bitten, chips, chocolate, eat, snack, sweet, treat", icon:"cookieBite"},
    {class:"fas fa-copy", name:"clone, duplicate, file, files-o, paper, paste", icon:"solidCopy"},
    {class:"fas fa-copyright", name:"brand, mark, register, trademark", icon:"solidCopyright"},
    {class:"fas fa-couch", name:"chair, cushion, furniture, relax, sofa", icon:"couch"},
    {class:"fas fa-credit-card", name:"buy, checkout, credit-card-alt, debit, money, payment, purchase", icon:"solidCreditCard"},
    {class:"fas fa-crop", name:"design, frame, mask, resize, shrink", icon:"crop"},
    {class:"fas fa-crop-alt", name:"design, frame, mask, resize, shrink", icon:"cropAlt"},
    {class:"fas fa-cross", name:"catholicism, christianity, church, jesus", icon:"cross"},
    {class:"fas fa-crosshairs", name:"aim, bullseye, gpd, picker, position", icon:"crosshairs"},
    {class:"fas fa-crow", name:"bird, bullfrog, fauna, halloween, holiday, toad", icon:"crow"},
    {class:"fas fa-crown", name:"award, favorite, king, queen, royal, tiara", icon:"crown"},
    {class:"fas fa-crutch", name:"cane, injury, mobility, wheelchair", icon:"crutch"},
    {class:"fas fa-cube", name:"3d, block, dice, package, square, tesseract", icon:"cube"},
    {class:"fas fa-cubes", name:"3d, block, dice, package, pyramid, square, stack, tesseract", icon:"cubes"},
    {class:"fas fa-cut", name:"clip, scissors, snip", icon:"cut"},
    {class:"fas fa-database", name:"computer, development, directory, memory, storage", icon:"database"},
    {class:"fas fa-deaf", name:"ear, hearing, sign language", icon:"deaf"},
    {class:"fas fa-democrat", name:"american, democratic party, donkey, election, left, left-wing, liberal, politics, usa", icon:"democrat"},
    {class:"fas fa-desktop", name:"computer, cpu, demo, desktop, device, imac, machine, monitor, pc, screen", icon:"desktop"},
    {class:"fas fa-dharmachakra", name:"buddhism, buddhist, wheel of dharma", icon:"dharmachakra"},
    {class:"fas fa-diagnoses", name:"analyze, detect, diagnosis, examine, medicine", icon:"diagnoses"},
    {class:"fas fa-dice", name:"chance, gambling, game, roll", icon:"dice"},
    {class:"fas fa-dice-d20", name:"Dungeons & Dragons, chance, d&d, dnd, fantasy, gambling, game, roll", icon:"diceD20"},
    {class:"fas fa-dice-d6", name:"Dungeons & Dragons, chance, d&d, dnd, fantasy, gambling, game, roll", icon:"diceD6"},
    {class:"fas fa-dice-five", name:"chance, gambling, game, roll", icon:"diceFive"},
    {class:"fas fa-dice-four", name:"chance, gambling, game, roll", icon:"diceFour"},
    {class:"fas fa-dice-one", name:"chance, gambling, game, roll", icon:"diceOne"},
    {class:"fas fa-dice-six", name:"chance, gambling, game, roll", icon:"diceSix"},
    {class:"fas fa-dice-three", name:"chance, gambling, game, roll", icon:"diceThree"},
    {class:"fas fa-dice-two", name:"chance, gambling, game, roll", icon:"diceTwo"},
    {class:"fas fa-digital-tachograph", name:"data, distance, speed, tachometer", icon:"digitalTachograph"},
    {class:"fas fa-directions", name:"map, navigation, sign, turn", icon:"directions"},
    {class:"fas fa-disease", name:"bacteria, cancer, covid-19, illness, infection, sickness, virus", icon:"disease"},
    {class:"fas fa-divide", name:"arithmetic, calculus, division, math", icon:"divide"},
    {class:"fas fa-dizzy", name:"dazed, dead, disapprove, emoticon, face", icon:"solidDizzy"},
    {class:"fas fa-dna", name:"double helix, genetic, helix, molecule, protein", icon:"dna"},
    {class:"fas fa-dog", name:"animal, canine, fauna, mammal, pet, pooch, puppy, woof", icon:"dog"},
    {class:"fas fa-dollar-sign", name:"$, cost, dollar-sign, money, price, usd", icon:"dollarSign"},
    {class:"fas fa-dolly", name:"carry, shipping, transport", icon:"dolly"},
    {class:"fas fa-dolly-flatbed", name:"carry, inventory, shipping, transport", icon:"dollyFlatbed"},
    {class:"fas fa-donate", name:"contribute, generosity, gift, give", icon:"donate"},
    {class:"fas fa-door-closed", name:"enter, exit, locked", icon:"doorClosed"},
    {class:"fas fa-door-open", name:"enter, exit, welcome", icon:"doorOpen"},
    {class:"fas fa-dot-circle", name:"bullseye, notification, target", icon:"solidDotCircle"},
    {class:"fas fa-dove", name:"bird, fauna, flying, peace, war", icon:"dove"},
    {class:"fas fa-download", name:"export, hard drive, save, transfer", icon:"download"},
    {class:"fas fa-drafting-compass", name:"design, map, mechanical drawing, plot, plotting", icon:"draftingCompass"},
    {class:"fas fa-dragon", name:"Dungeons & Dragons, d&d, dnd, fantasy, fire, lizard, serpent", icon:"dragon"},
    {class:"fas fa-draw-polygon", name:"anchors, lines, object, render, shape", icon:"drawPolygon"},
    {class:"fas fa-drum", name:"instrument, music, percussion, snare, sound", icon:"drum"},
    {class:"fas fa-drum-steelpan", name:"calypso, instrument, music, percussion, reggae, snare, sound, steel, tropical", icon:"drumSteelpan"},
    {class:"fas fa-drumstick-bite", name:"bone, chicken, leg, meat, poultry, turkey", icon:"drumstickBite"},
    {class:"fas fa-dumbbell", name:"exercise, gym, strength, weight, weight-lifting", icon:"dumbbell"},
    {class:"fas fa-dumpster", name:"alley, bin, commercial, trash, waste", icon:"dumpster"},
    {class:"fas fa-dumpster-fire", name:"alley, bin, commercial, danger, dangerous, euphemism, flame, heat, hot, trash, waste", icon:"dumpsterFire"},
    {class:"fas fa-dungeon", name:"Dungeons & Dragons, building, d&d, dnd, door, entrance, fantasy, gate", icon:"dungeon"},
    {class:"fas fa-edit", name:"edit, pen, pencil, update, write", icon:"solidEdit"},
    {class:"fas fa-egg", name:"breakfast, chicken, easter, shell, yolk", icon:"egg"},
    {class:"fas fa-eject", name:"abort, cancel, cd, discharge", icon:"eject"},
    {class:"fas fa-ellipsis-h", name:"dots, drag, kebab, list, menu, nav, navigation, ol, reorder, settings, ul", icon:"ellipsisH"},
    {class:"fas fa-ellipsis-v", name:"dots, drag, kebab, list, menu, nav, navigation, ol, reorder, settings, ul", icon:"ellipsisV"},
    {class:"fas fa-envelope", name:"e-mail, email, letter, mail, message, notification, support", icon:"solidEnvelope"},
    {class:"fas fa-envelope-open", name:"e-mail, email, letter, mail, message, notification, support", icon:"solidEnvelopeOpen"},
    {class:"fas fa-envelope-open-text", name:"e-mail, email, letter, mail, message, notification, support", icon:"envelopeOpenText"},
    {class:"fas fa-envelope-square", name:"e-mail, email, letter, mail, message, notification, support", icon:"envelopeSquare"},
    {class:"fas fa-equals", name:"arithmetic, even, match, math", icon:"equals"},
    {class:"fas fa-eraser", name:"art, delete, remove, rubber", icon:"eraser"},
    {class:"fas fa-ethernet", name:"cable, cat 5, cat 6, connection, hardware, internet, network, wired", icon:"ethernet"},
    {class:"fas fa-euro-sign", name:"currency, dollar, exchange, money", icon:"euroSign"},
    {class:"fas fa-exchange-alt", name:"arrow, arrows, exchange, reciprocate, return, swap, transfer", icon:"exchangeAlt"},
    {class:"fas fa-exclamation", name:"alert, danger, error, important, notice, notification, notify, problem, warning", icon:"exclamation"},
    {class:"fas fa-exclamation-circle", name:"alert, danger, error, important, notice, notification, notify, problem, warning", icon:"exclamationCircle"},
    {class:"fas fa-exclamation-triangle", name:"alert, danger, error, important, notice, notification, notify, problem, warning", icon:"exclamationTriangle"},
    {class:"fas fa-expand", name:"bigger, enlarge, fullscreen, resize", icon:"expand"},
    {class:"fas fa-expand-alt", name:"arrows, bigger, enlarge, fullscreen, resize", icon:"expandAlt"},
    {class:"fas fa-expand-arrows-alt", name:"bigger, enlarge, fullscreen, move, resize", icon:"expandArrowsAlt"},
    {class:"fas fa-external-link-alt", name:"external-link, new, open, share", icon:"externalLinkAlt"},
    {class:"fas fa-external-link-square-alt", name:"external-link-square, new, open, share", icon:"externalLinkSquareAlt"},
    {class:"fas fa-eye", name:"look, optic, see, seen, show, sight, views, visible", icon:"solidEye"},
    {class:"fas fa-eye-dropper", name:"beaker, clone, color, copy, eyedropper, pipette", icon:"eyeDropper"},
    {class:"fas fa-eye-slash", name:"blind, hide, show, toggle, unseen, views, visible, visiblity", icon:"solidEyeSlash"},
    {class:"fas fa-fan", name:"ac, air conditioning, blade, blower, cool, hot", icon:"fan"},
    {class:"fas fa-fast-backward", name:"beginning, first, previous, rewind, start", icon:"fastBackward"},
    {class:"fas fa-fast-forward", name:"end, last, next", icon:"fastForward"},
    {class:"fas fa-faucet", name:"covid-19, drip, house, hygiene, kitchen, sink, water", icon:"faucet"},
    {class:"fas fa-fax", name:"business, communicate, copy, facsimile, send", icon:"fax"},
    {class:"fas fa-feather", name:"bird, light, plucked, quill, write", icon:"feather"},
    {class:"fas fa-feather-alt", name:"bird, light, plucked, quill, write", icon:"featherAlt"},
    {class:"fas fa-female", name:"human, person, profile, user, woman", icon:"female"},
    {class:"fas fa-fighter-jet", name:"airplane, fast, fly, goose, maverick, plane, quick, top gun, transportation, travel", icon:"fighterJet"},
    {class:"fas fa-file", name:"document, new, page, pdf, resume", icon:"solidFile"},
    {class:"fas fa-file-alt", name:"document, file-text, invoice, new, page, pdf", icon:"solidFileAlt"},
    {class:"fas fa-file-archive", name:".zip, bundle, compress, compression, download, zip", icon:"solidFileArchive"},
    {class:"fas fa-file-audio", name:"document, mp3, music, page, play, sound", icon:"solidFileAudio"},
    {class:"fas fa-file-code", name:"css, development, document, html", icon:"solidFileCode"},
    {class:"fas fa-file-contract", name:"agreement, binding, document, legal, signature", icon:"fileContract"},
    {class:"fas fa-file-csv", name:"document, excel, numbers, spreadsheets, table", icon:"fileCsv"},
    {class:"fas fa-file-download", name:"document, export, save", icon:"fileDownload"},
    {class:"fas fa-file-excel", name:"csv, document, numbers, spreadsheets, table", icon:"solidFileExcel"},
    {class:"fas fa-file-export", name:"download, save", icon:"fileExport"},
    {class:"fas fa-file-image", name:"document, image, jpg, photo, png", icon:"solidFileImage"},
    {class:"fas fa-file-import", name:"copy, document, send, upload", icon:"fileImport"},
    {class:"fas fa-file-invoice", name:"account, bill, charge, document, payment, receipt", icon:"fileInvoice"},
    {class:"fas fa-file-invoice-dollar", name:"$, account, bill, charge, document, dollar-sign, money, payment, receipt, usd", icon:"fileInvoiceDollar"},
    {class:"fas fa-file-medical", name:"document, health, history, prescription, record", icon:"fileMedical"},
    {class:"fas fa-file-medical-alt", name:"document, health, history, prescription, record", icon:"fileMedicalAlt"},
    {class:"fas fa-file-pdf", name:"acrobat, document, preview, save", icon:"solidFilePdf"},
    {class:"fas fa-file-powerpoint", name:"display, document, keynote, presentation", icon:"solidFilePowerpoint"},
    {class:"fas fa-file-prescription", name:"document, drugs, medical, medicine, rx", icon:"filePrescription"},
    {class:"fas fa-file-signature", name:"John Hancock, contract, document, name", icon:"fileSignature"},
    {class:"fas fa-file-upload", name:"document, import, page, save", icon:"fileUpload"},
    {class:"fas fa-file-video", name:"document, m4v, movie, mp4, play", icon:"solidFileVideo"},
    {class:"fas fa-file-word", name:"document, edit, page, text, writing", icon:"solidFileWord"},
    {class:"fas fa-fill", name:"bucket, color, paint, paint bucket", icon:"fill"},
    {class:"fas fa-fill-drip", name:"bucket, color, drop, paint, paint bucket, spill", icon:"fillDrip"},
    {class:"fas fa-film", name:"cinema, movie, strip, video", icon:"film"},
    {class:"fas fa-filter", name:"funnel, options, separate, sort", icon:"filter"},
    {class:"fas fa-fingerprint", name:"human, id, identification, lock, smudge, touch, unique, unlock", icon:"fingerprint"},
    {class:"fas fa-fire", name:"burn, caliente, flame, heat, hot, popular", icon:"fire"},
    {class:"fas fa-fire-alt", name:"burn, caliente, flame, heat, hot, popular", icon:"fireAlt"},
    {class:"fas fa-fire-extinguisher", name:"burn, caliente, fire fighter, flame, heat, hot, rescue", icon:"fireExtinguisher"},
    {class:"fas fa-first-aid", name:"emergency, emt, health, medical, rescue", icon:"firstAid"},
    {class:"fas fa-fish", name:"fauna, gold, seafood, swimming", icon:"fish"},
    {class:"fas fa-fist-raised", name:"Dungeons & Dragons, d&d, dnd, fantasy, hand, ki, monk, resist, strength, unarmed combat", icon:"fistRaised"},
    {class:"fas fa-flag", name:"country, notice, notification, notify, pole, report, symbol", icon:"solidFlag"},
    {class:"fas fa-flag-checkered", name:"notice, notification, notify, pole, racing, report, symbol", icon:"flagCheckered"},
    {class:"fas fa-flag-usa", name:"betsy ross, country, old glory, stars, stripes, symbol", icon:"flagUsa"},
    {class:"fas fa-flask", name:"beaker, experimental, labs, science", icon:"flask"},
    {class:"fas fa-flushed", name:"embarrassed, emoticon, face", icon:"solidFlushed"},
    {class:"fas fa-folder", name:"archive, directory, document, file", icon:"solidFolder"},
    {class:"fas fa-folder-minus", name:"archive, delete, directory, document, file, negative, remove", icon:"folderMinus"},
    {class:"fas fa-folder-open", name:"archive, directory, document, empty, file, new", icon:"solidFolderOpen"},
    {class:"fas fa-folder-plus", name:"add, archive, create, directory, document, file, new, positive", icon:"folderPlus"},
    {class:"fas fa-font", name:"alphabet, glyph, text, type, typeface", icon:"font"},
    {class:"fas fa-football-ball", name:"ball, fall, nfl, pigskin, seasonal", icon:"footballBall"},
    {class:"fas fa-forward", name:"forward, next, skip", icon:"forward"},
    {class:"fas fa-frog", name:"amphibian, bullfrog, fauna, hop, kermit, kiss, prince, ribbit, toad, wart", icon:"frog"},
    {class:"fas fa-frown", name:"disapprove, emoticon, face, rating, sad", icon:"solidFrown"},
    {class:"fas fa-frown-open", name:"disapprove, emoticon, face, rating, sad", icon:"solidFrownOpen"},
    {class:"fas fa-funnel-dollar", name:"filter, money, options, separate, sort", icon:"funnelDollar"},
    {class:"fas fa-futbol", name:"ball, football, mls, soccer", icon:"solidFutbol"},
    {class:"fas fa-gamepad", name:"arcade, controller, d-pad, joystick, video, video game", icon:"gamepad"},
    {class:"fas fa-gas-pump", name:"car, fuel, gasoline, petrol", icon:"gasPump"},
    {class:"fas fa-gavel", name:"hammer, judge, law, lawyer, opinion", icon:"gavel"},
    {class:"fas fa-gem", name:"diamond, jewelry, sapphire, stone, treasure", icon:"solidGem"},
    {class:"fas fa-genderless", name:"androgynous, asexual, sexless", icon:"genderless"},
    {class:"fas fa-ghost", name:"apparition, blinky, clyde, floating, halloween, holiday, inky, pinky, spirit", icon:"ghost"},
    {class:"fas fa-gift", name:"christmas, generosity, giving, holiday, party, present, wrapped, xmas", icon:"gift"},
    {class:"fas fa-gifts", name:"christmas, generosity, giving, holiday, party, present, wrapped, xmas", icon:"gifts"},
    {class:"fas fa-glass-cheers", name:"alcohol, bar, beverage, celebration, champagne, clink, drink, holiday, new year's eve, party, toast", icon:"glassCheers"},
    {class:"fas fa-glass-martini", name:"alcohol, bar, beverage, drink, liquor", icon:"glassMartini"},
    {class:"fas fa-glass-martini-alt", name:"alcohol, bar, beverage, drink, liquor", icon:"glassMartiniAlt"},
    {class:"fas fa-glass-whiskey", name:"alcohol, bar, beverage, bourbon, drink, liquor, neat, rye, scotch, whisky", icon:"glassWhiskey"},
    {class:"fas fa-glasses", name:"hipster, nerd, reading, sight, spectacles, vision", icon:"glasses"},
    {class:"fas fa-globe", name:"all, coordinates, country, earth, global, gps, language, localize, location, map, online, place, planet, translate, travel, world", icon:"globe"},
    {class:"fas fa-globe-africa", name:"all, country, earth, global, gps, language, localize, location, map, online, place, planet, translate, travel, world", icon:"globeAfrica"},
    {class:"fas fa-globe-americas", name:"all, country, earth, global, gps, language, localize, location, map, online, place, planet, translate, travel, world", icon:"globeAmericas"},
    {class:"fas fa-globe-asia", name:"all, country, earth, global, gps, language, localize, location, map, online, place, planet, translate, travel, world", icon:"globeAsia"},
    {class:"fas fa-globe-europe", name:"all, country, earth, global, gps, language, localize, location, map, online, place, planet, translate, travel, world", icon:"globeEurope"},
    {class:"fas fa-golf-ball", name:"caddy, eagle, putt, tee", icon:"golfBall"},
    {class:"fas fa-gopuram", name:"building, entrance, hinduism, temple, tower", icon:"gopuram"},
    {class:"fas fa-graduation-cap", name:"ceremony, college, graduate, learning, school, student", icon:"graduationCap"},
    {class:"fas fa-greater-than", name:"arithmetic, compare, math", icon:"greaterThan"},
    {class:"fas fa-greater-than-equal", name:"arithmetic, compare, math", icon:"greaterThanEqual"},
    {class:"fas fa-grimace", name:"cringe, emoticon, face, teeth", icon:"solidGrimace"},
    {class:"fas fa-grin", name:"emoticon, face, laugh, smile", icon:"solidGrin"},
    {class:"fas fa-grin-alt", name:"emoticon, face, laugh, smile", icon:"solidGrinAlt"},
    {class:"fas fa-grin-beam", name:"emoticon, face, laugh, smile", icon:"solidGrinBeam"},
    {class:"fas fa-grin-beam-sweat", name:"embarass, emoticon, face, smile", icon:"solidGrinBeamSweat"},
    {class:"fas fa-grin-hearts", name:"emoticon, face, love, smile", icon:"solidGrinHearts"},
    {class:"fas fa-grin-squint", name:"emoticon, face, laugh, smile", icon:"solidGrinSquint"},
    {class:"fas fa-grin-squint-tears", name:"emoticon, face, happy, smile", icon:"solidGrinSquintTears"},
    {class:"fas fa-grin-stars", name:"emoticon, face, star-struck", icon:"solidGrinStars"},
    {class:"fas fa-grin-tears", name:"LOL, emoticon, face", icon:"solidGrinTears"},
    {class:"fas fa-grin-tongue", name:"LOL, emoticon, face", icon:"solidGrinTongue"},
    {class:"fas fa-grin-tongue-squint", name:"LOL, emoticon, face", icon:"solidGrinTongueSquint"},
    {class:"fas fa-grin-tongue-wink", name:"LOL, emoticon, face", icon:"solidGrinTongueWink"},
    {class:"fas fa-grin-wink", name:"emoticon, face, flirt, laugh, smile", icon:"solidGrinWink"},
    {class:"fas fa-grip-horizontal", name:"affordance, drag, drop, grab, handle", icon:"gripHorizontal"},
    {class:"fas fa-grip-lines", name:"affordance, drag, drop, grab, handle", icon:"gripLines"},
    {class:"fas fa-grip-lines-vertical", name:"affordance, drag, drop, grab, handle", icon:"gripLinesVertical"},
    {class:"fas fa-grip-vertical", name:"affordance, drag, drop, grab, handle", icon:"gripVertical"},
    {class:"fas fa-guitar", name:"acoustic, instrument, music, rock, rock and roll, song, strings", icon:"guitar"},
    {class:"fas fa-h-square", name:"directions, emergency, hospital, hotel, map", icon:"hSquare"},
    {class:"fas fa-hamburger", name:"bacon, beef, burger, burger king, cheeseburger, fast food, grill, ground beef, mcdonalds, sandwich", icon:"hamburger"},
    {class:"fas fa-hammer", name:"admin, fix, repair, settings, tool", icon:"hammer"},
    {class:"fas fa-hamsa", name:"amulet, christianity, islam, jewish, judaism, muslim, protection", icon:"hamsa"},
    {class:"fas fa-hand-holding", name:"carry, lift", icon:"handHolding"},
    {class:"fas fa-hand-holding-heart", name:"carry, charity, gift, lift, package", icon:"handHoldingHeart"},
    {class:"fas fa-hand-holding-medical", name:"care, covid-19, donate, help", icon:"handHoldingMedical"},
    {class:"fas fa-hand-holding-usd", name:"$, carry, dollar sign, donation, giving, lift, money, price", icon:"handHoldingUsd"},
    {class:"fas fa-hand-holding-water", name:"carry, covid-19, drought, grow, lift", icon:"handHoldingWater"},
    {class:"fas fa-hand-lizard", name:"game, roshambo", icon:"solidHandLizard"},
    {class:"fas fa-hand-middle-finger", name:"flip the bird, gesture, hate, rude", icon:"handMiddleFinger"},
    {class:"fas fa-hand-paper", name:"game, halt, roshambo, stop", icon:"solidHandPaper"},
    {class:"fas fa-hand-peace", name:"rest, truce", icon:"solidHandPeace"},
    {class:"fas fa-hand-point-down", name:"finger, hand-o-down, point", icon:"solidHandPointDown"},
    {class:"fas fa-hand-point-left", name:"back, finger, hand-o-left, left, point, previous", icon:"solidHandPointLeft"},
    {class:"fas fa-hand-point-right", name:"finger, forward, hand-o-right, next, point, right", icon:"solidHandPointRight"},
    {class:"fas fa-hand-point-up", name:"finger, hand-o-up, point", icon:"solidHandPointUp"},
    {class:"fas fa-hand-pointer", name:"arrow, cursor, select", icon:"solidHandPointer"},
    {class:"fas fa-hand-rock", name:"fist, game, roshambo", icon:"solidHandRock"},
    {class:"fas fa-hand-scissors", name:"cut, game, roshambo", icon:"solidHandScissors"},
    {class:"fas fa-hand-sparkles", name:"clean, covid-19, hygiene, magic, soap, wash", icon:"handSparkles"},
    {class:"fas fa-hand-spock", name:"live long, prosper, salute, star trek, vulcan", icon:"solidHandSpock"},
    {class:"fas fa-hands", name:"carry, hold, lift", icon:"hands"},
    {class:"fas fa-hands-helping", name:"aid, assistance, handshake, partnership, volunteering", icon:"handsHelping"},
    {class:"fas fa-hands-wash", name:"covid-19, hygiene, soap, wash", icon:"handsWash"},
    {class:"fas fa-handshake", name:"agreement, greeting, meeting, partnership", icon:"solidHandshake"},
    {class:"fas fa-handshake-alt-slash", name:"broken, covid-19, social distance", icon:"handshakeAltSlash"},
    {class:"fas fa-handshake-slash", name:"broken, covid-19, social distance", icon:"handshakeSlash"},
    {class:"fas fa-hanukiah", name:"candle, hanukkah, jewish, judaism, light", icon:"hanukiah"},
    {class:"fas fa-hard-hat", name:"construction, hardhat, helmet, safety", icon:"hardHat"},
    {class:"fas fa-hashtag", name:"Twitter, instagram, pound, social media, tag", icon:"hashtag"},
    {class:"fas fa-hat-cowboy", name:"buckaroo, horse, jackeroo, john b., old west, pardner, ranch, rancher, rodeo, western, wrangler", icon:"hatCowboy"},
    {class:"fas fa-hat-cowboy-side", name:"buckaroo, horse, jackeroo, john b., old west, pardner, ranch, rancher, rodeo, western, wrangler", icon:"hatCowboySide"},
    {class:"fas fa-hat-wizard", name:"Dungeons & Dragons, accessory, buckle, clothing, d&d, dnd, fantasy, halloween, head, holiday, mage, magic, pointy, witch", icon:"hatWizard"},
    {class:"fas fa-hdd", name:"cpu, hard drive, harddrive, machine, save, storage", icon:"solidHdd"},
    {class:"fas fa-head-side-cough", name:"cough, covid-19, germs, lungs, respiratory, sick", icon:"headSideCough"},
    {class:"fas fa-head-side-cough-slash", name:"cough, covid-19, germs, lungs, respiratory, sick", icon:"headSideCoughSlash"},
    {class:"fas fa-head-side-mask", name:"breath, covid-19, filter, respirator, virus", icon:"headSideMask"},
    {class:"fas fa-head-side-virus", name:"cold, covid-19, flu, sick", icon:"headSideVirus"},
    {class:"fas fa-heading", name:"format, header, text, title", icon:"heading"},
    {class:"fas fa-headphones", name:"audio, listen, music, sound, speaker", icon:"headphones"},
    {class:"fas fa-headphones-alt", name:"audio, listen, music, sound, speaker", icon:"headphonesAlt"},
    {class:"fas fa-headset", name:"audio, gamer, gaming, listen, live chat, microphone, shot caller, sound, support, telemarketer", icon:"headset"},
    {class:"fas fa-heart", name:"favorite, like, love, relationship, valentine", icon:"solidHeart"},
    {class:"fas fa-heart-broken", name:"breakup, crushed, dislike, dumped, grief, love, lovesick, relationship, sad", icon:"heartBroken"},
    {class:"fas fa-heartbeat", name:"ekg, electrocardiogram, health, lifeline, vital signs", icon:"heartbeat"},
    {class:"fas fa-helicopter", name:"airwolf, apache, chopper, flight, fly, travel", icon:"helicopter"},
    {class:"fas fa-highlighter", name:"edit, marker, sharpie, update, write", icon:"highlighter"},
    {class:"fas fa-hiking", name:"activity, backpack, fall, fitness, outdoors, person, seasonal, walking", icon:"hiking"},
    {class:"fas fa-hippo", name:"animal, fauna, hippopotamus, hungry, mammal", icon:"hippo"},
    {class:"fas fa-history", name:"Rewind, clock, reverse, time, time machine", icon:"history"},
    {class:"fas fa-hockey-puck", name:"ice, nhl, sport", icon:"hockeyPuck"},
    {class:"fas fa-holly-berry", name:"catwoman, christmas, decoration, flora, halle, holiday, ororo munroe, plant, storm, xmas", icon:"hollyBerry"},
    {class:"fas fa-home", name:"abode, building, house, main", icon:"home"},
    {class:"fas fa-horse", name:"equus, fauna, mammmal, mare, neigh, pony", icon:"horse"},
    {class:"fas fa-horse-head", name:"equus, fauna, mammmal, mare, neigh, pony", icon:"horseHead"},
    {class:"fas fa-hospital", name:"building, covid-19, emergency room, medical center", icon:"solidHospital"},
    {class:"fas fa-hospital-alt", name:"building, covid-19, emergency room, medical center", icon:"hospitalAlt"},
    {class:"fas fa-hospital-symbol", name:"clinic, covid-19, emergency, map", icon:"hospitalSymbol"},
    {class:"fas fa-hospital-user", name:"covid-19, doctor, network, patient, primary care", icon:"hospitalUser"},
    {class:"fas fa-hot-tub", name:"bath, jacuzzi, massage, sauna, spa", icon:"hotTub"},
    {class:"fas fa-hotdog", name:"bun, chili, frankfurt, frankfurter, kosher, polish, sandwich, sausage, vienna, weiner", icon:"hotdog"},
    {class:"fas fa-hotel", name:"building, inn, lodging, motel, resort, travel", icon:"hotel"},
    {class:"fas fa-hourglass", name:"hour, minute, sand, stopwatch, time", icon:"solidHourglass"},
    {class:"fas fa-hourglass-end", name:"hour, minute, sand, stopwatch, time", icon:"hourglassEnd"},
    {class:"fas fa-hourglass-half", name:"hour, minute, sand, stopwatch, time", icon:"hourglassHalf"},
    {class:"fas fa-hourglass-start", name:"hour, minute, sand, stopwatch, time", icon:"hourglassStart"},
    {class:"fas fa-house-damage", name:"building, devastation, disaster, home, insurance", icon:"houseDamage"},
    {class:"fas fa-house-user", name:"covid-19, home, isolation, quarantine", icon:"houseUser"},
    {class:"fas fa-hryvnia", name:"currency, money, ukraine, ukrainian", icon:"hryvnia"},
    {class:"fas fa-i-cursor", name:"editing, i-beam, type, writing", icon:"iCursor"},
    {class:"fas fa-ice-cream", name:"chocolate, cone, dessert, frozen, scoop, sorbet, vanilla, yogurt", icon:"iceCream"},
    {class:"fas fa-icicles", name:"cold, frozen, hanging, ice, seasonal, sharp", icon:"icicles"},
    {class:"fas fa-icons", name:"bolt, emoji, heart, image, music, photo, symbols", icon:"icons"},
    {class:"fas fa-id-badge", name:"address, contact, identification, license, profile", icon:"solidIdBadge"},
    {class:"fas fa-id-card", name:"contact, demographics, document, identification, issued, profile", icon:"solidIdCard"},
    {class:"fas fa-id-card-alt", name:"contact, demographics, document, identification, issued, profile", icon:"idCardAlt"},
    {class:"fas fa-igloo", name:"dome, dwelling, eskimo, home, house, ice, snow", icon:"igloo"},
    {class:"fas fa-image", name:"album, landscape, photo, picture", icon:"solidImage"},
    {class:"fas fa-images", name:"album, landscape, photo, picture", icon:"solidImages"},
    {class:"fas fa-inbox", name:"archive, desk, email, mail, message", icon:"inbox"},
    {class:"fas fa-indent", name:"align, justify, paragraph, tab", icon:"indent"},
    {class:"fas fa-industry", name:"building, factory, industrial, manufacturing, mill, warehouse", icon:"industry"},
    {class:"fas fa-infinity", name:"eternity, forever, math", icon:"infinity"},
    {class:"fas fa-info", name:"details, help, information, more, support", icon:"info"},
    {class:"fas fa-info-circle", name:"details, help, information, more, support", icon:"infoCircle"},
    {class:"fas fa-italic", name:"edit, emphasis, font, format, text, type", icon:"italic"},
    {class:"fas fa-jedi", name:"crest, force, sith, skywalker, star wars, yoda", icon:"jedi"},
    {class:"fas fa-joint", name:"blunt, cannabis, doobie, drugs, marijuana, roach, smoke, smoking, spliff", icon:"joint"},
    {class:"fas fa-journal-whills", name:"book, force, jedi, sith, star wars, yoda", icon:"journalWhills"},
    {class:"fas fa-kaaba", name:"building, cube, islam, muslim", icon:"kaaba"},
    {class:"fas fa-key", name:"lock, password, private, secret, unlock", icon:"key"},
    {class:"fas fa-keyboard", name:"accessory, edit, input, text, type, write", icon:"solidKeyboard"},
    {class:"fas fa-khanda", name:"chakkar, sikh, sikhism, sword", icon:"khanda"},
    {class:"fas fa-kiss", name:"beso, emoticon, face, love, smooch", icon:"solidKiss"},
    {class:"fas fa-kiss-beam", name:"beso, emoticon, face, love, smooch", icon:"solidKissBeam"},
    {class:"fas fa-kiss-wink-heart", name:"beso, emoticon, face, love, smooch", icon:"solidKissWinkHeart"},
    {class:"fas fa-kiwi-bird", name:"bird, fauna, new zealand", icon:"kiwiBird"},
    {class:"fas fa-landmark", name:"building, historic, memorable, monument, politics", icon:"landmark"},
    {class:"fas fa-language", name:"dialect, idiom, localize, speech, translate, vernacular", icon:"language"},
    {class:"fas fa-laptop", name:"computer, cpu, dell, demo, device, mac, macbook, machine, pc", icon:"laptop"},
    {class:"fas fa-laptop-code", name:"computer, cpu, dell, demo, develop, device, mac, macbook, machine, pc", icon:"laptopCode"},
    {class:"fas fa-laptop-house", name:"computer, covid-19, device, office, remote, work from home", icon:"laptopHouse"},
    {class:"fas fa-laptop-medical", name:"computer, device, ehr, electronic health records, history", icon:"laptopMedical"},
    {class:"fas fa-laugh", name:"LOL, emoticon, face, laugh, smile", icon:"solidLaugh"},
    {class:"fas fa-laugh-beam", name:"LOL, emoticon, face, happy, smile", icon:"solidLaughBeam"},
    {class:"fas fa-laugh-squint", name:"LOL, emoticon, face, happy, smile", icon:"solidLaughSquint"},
    {class:"fas fa-laugh-wink", name:"LOL, emoticon, face, happy, smile", icon:"solidLaughWink"},
    {class:"fas fa-layer-group", name:"arrange, develop, layers, map, stack", icon:"layerGroup"},
    {class:"fas fa-leaf", name:"eco, flora, nature, plant, vegan", icon:"leaf"},
    {class:"fas fa-lemon", name:"citrus, lemonade, lime, tart", icon:"solidLemon"},
    {class:"fas fa-less-than", name:"arithmetic, compare, math", icon:"lessThan"},
    {class:"fas fa-less-than-equal", name:"arithmetic, compare, math", icon:"lessThanEqual"},
    {class:"fas fa-level-down-alt", name:"arrow, level-down", icon:"levelDownAlt"},
    {class:"fas fa-level-up-alt", name:"arrow, level-up", icon:"levelUpAlt"},
    {class:"fas fa-life-ring", name:"coast guard, help, overboard, save, support", icon:"solidLifeRing"},
    {class:"fas fa-lightbulb", name:"energy, idea, inspiration, light", icon:"solidLightbulb"},
    {class:"fas fa-link", name:"attach, attachment, chain, connect", icon:"link"},
    {class:"fas fa-lira-sign", name:"currency, money, try, turkish", icon:"liraSign"},
    {class:"fas fa-list", name:"checklist, completed, done, finished, ol, todo, ul", icon:"list"},
    {class:"fas fa-list-alt", name:"checklist, completed, done, finished, ol, todo, ul", icon:"solidListAlt"},
    {class:"fas fa-list-ol", name:"checklist, completed, done, finished, numbers, ol, todo, ul", icon:"listOl"},
    {class:"fas fa-list-ul", name:"checklist, completed, done, finished, ol, todo, ul", icon:"listUl"},
    {class:"fas fa-location-arrow", name:"address, compass, coordinate, direction, gps, map, navigation, place", icon:"locationArrow"},
    {class:"fas fa-lock", name:"admin, lock, open, password, private, protect, security", icon:"lock"},
    {class:"fas fa-lock-open", name:"admin, lock, open, password, private, protect, security", icon:"lockOpen"},
    {class:"fas fa-long-arrow-alt-down", name:"download, long-arrow-down", icon:"longArrowAltDown"},
    {class:"fas fa-long-arrow-alt-left", name:"back, long-arrow-left, previous", icon:"longArrowAltLeft"},
    {class:"fas fa-long-arrow-alt-right", name:"forward, long-arrow-right, next", icon:"longArrowAltRight"},
    {class:"fas fa-long-arrow-alt-up", name:"long-arrow-up, upload", icon:"longArrowAltUp"},
    {class:"fas fa-low-vision", name:"blind, eye, sight", icon:"lowVision"},
    {class:"fas fa-luggage-cart", name:"bag, baggage, suitcase, travel", icon:"luggageCart"},
    {class:"fas fa-lungs", name:"air, breath, covid-19, organ, respiratory", icon:"lungs"},
    {class:"fas fa-lungs-virus", name:"breath, covid-19, respiratory, sick", icon:"lungsVirus"},
    {class:"fas fa-magic", name:"autocomplete, automatic, mage, magic, spell, wand, witch, wizard", icon:"magic"},
    {class:"fas fa-magnet", name:"Attract, lodestone, tool", icon:"magnet"},
    {class:"fas fa-mail-bulk", name:"archive, envelope, letter, post office, postal, postcard, send, stamp, usps", icon:"mailBulk"},
    {class:"fas fa-male", name:"human, man, person, profile, user", icon:"male"},
    {class:"fas fa-map", name:"address, coordinates, destination, gps, localize, location, map, navigation, paper, pin, place, point of interest, position, route, travel", icon:"solidMap"},
    {class:"fas fa-map-marked", name:"address, coordinates, destination, gps, localize, location, map, navigation, paper, pin, place, point of interest, position, route, travel", icon:"mapMarked"},
    {class:"fas fa-map-marked-alt", name:"address, coordinates, destination, gps, localize, location, map, navigation, paper, pin, place, point of interest, position, route, travel", icon:"mapMarkedAlt"},
    {class:"fas fa-map-marker", name:"address, coordinates, destination, gps, localize, location, map, navigation, paper, pin, place, point of interest, position, route, travel", icon:"mapMarker"},
    {class:"fas fa-map-marker-alt", name:"address, coordinates, destination, gps, localize, location, map, navigation, paper, pin, place, point of interest, position, route, travel", icon:"mapMarkerAlt"},
    {class:"fas fa-map-pin", name:"address, agree, coordinates, destination, gps, localize, location, map, marker, navigation, pin, place, position, travel", icon:"mapPin"},
    {class:"fas fa-map-signs", name:"directions, directory, map, signage, wayfinding", icon:"mapSigns"},
    {class:"fas fa-marker", name:"design, edit, sharpie, update, write", icon:"marker"},
    {class:"fas fa-mars", name:"male", icon:"mars"},
    {class:"fas fa-mars-double", name:"marsDouble", icon:"marsDouble"},
    {class:"fas fa-mars-stroke", name:"marsStroke", icon:"marsStroke"},
    {class:"fas fa-mars-stroke-h", name:"marsStrokeH", icon:"marsStrokeH"},
    {class:"fas fa-mars-stroke-v", name:"marsStrokeV", icon:"marsStrokeV"},
    {class:"fas fa-mask", name:"carnivale, costume, disguise, halloween, secret, super hero", icon:"mask"},
    {class:"fas fa-medal", name:"award, ribbon, star, trophy", icon:"medal"},
    {class:"fas fa-medkit", name:"first aid, firstaid, health, help, support", icon:"medkit"},
    {class:"fas fa-meh", name:"emoticon, face, neutral, rating", icon:"solidMeh"},
    {class:"fas fa-meh-blank", name:"emoticon, face, neutral, rating", icon:"solidMehBlank"},
    {class:"fas fa-meh-rolling-eyes", name:"emoticon, face, neutral, rating", icon:"solidMehRollingEyes"},
    {class:"fas fa-memory", name:"DIMM, RAM, hardware, storage, technology", icon:"memory"},
    {class:"fas fa-menorah", name:"candle, hanukkah, jewish, judaism, light", icon:"menorah"},
    {class:"fas fa-mercury", name:"transgender", icon:"mercury"},
    {class:"fas fa-meteor", name:"armageddon, asteroid, comet, shooting star, space", icon:"meteor"},
    {class:"fas fa-microchip", name:"cpu, hardware, processor, technology", icon:"microchip"},
    {class:"fas fa-microphone", name:"audio, podcast, record, sing, sound, voice", icon:"microphone"},
    {class:"fas fa-microphone-alt", name:"audio, podcast, record, sing, sound, voice", icon:"microphoneAlt"},
    {class:"fas fa-microphone-alt-slash", name:"audio, disable, mute, podcast, record, sing, sound, voice", icon:"microphoneAltSlash"},
    {class:"fas fa-microphone-slash", name:"audio, disable, mute, podcast, record, sing, sound, voice", icon:"microphoneSlash"},
    {class:"fas fa-microscope", name:"covid-19, electron, lens, optics, science, shrink", icon:"microscope"},
    {class:"fas fa-minus", name:"collapse, delete, hide, minify, negative, remove, trash", icon:"minus"},
    {class:"fas fa-minus-circle", name:"delete, hide, negative, remove, shape, trash", icon:"minusCircle"},
    {class:"fas fa-minus-square", name:"collapse, delete, hide, minify, negative, remove, shape, trash", icon:"solidMinusSquare"},
    {class:"fas fa-mitten", name:"clothing, cold, glove, hands, knitted, seasonal, warmth", icon:"mitten"},
    {class:"fas fa-mobile", name:"apple, call, cell phone, cellphone, device, iphone, number, screen, telephone", icon:"mobile"},
    {class:"fas fa-mobile-alt", name:"apple, call, cell phone, cellphone, device, iphone, number, screen, telephone", icon:"mobileAlt"},
    {class:"fas fa-money-bill", name:"buy, cash, checkout, money, payment, price, purchase", icon:"moneyBill"},
    {class:"fas fa-money-bill-alt", name:"buy, cash, checkout, money, payment, price, purchase", icon:"solidMoneyBillAlt"},
    {class:"fas fa-money-bill-wave", name:"buy, cash, checkout, money, payment, price, purchase", icon:"moneyBillWave"},
    {class:"fas fa-money-bill-wave-alt", name:"buy, cash, checkout, money, payment, price, purchase", icon:"moneyBillWaveAlt"},
    {class:"fas fa-money-check", name:"bank check, buy, checkout, cheque, money, payment, price, purchase", icon:"moneyCheck"},
    {class:"fas fa-money-check-alt", name:"bank check, buy, checkout, cheque, money, payment, price, purchase", icon:"moneyCheckAlt"},
    {class:"fas fa-monument", name:"building, historic, landmark, memorable", icon:"monument"},
    {class:"fas fa-moon", name:"contrast, crescent, dark, lunar, night", icon:"solidMoon"},
    {class:"fas fa-mortar-pestle", name:"crush, culinary, grind, medical, mix, pharmacy, prescription, spices", icon:"mortarPestle"},
    {class:"fas fa-mosque", name:"building, islam, landmark, muslim", icon:"mosque"},
    {class:"fas fa-motorcycle", name:"bike, machine, transportation, vehicle", icon:"motorcycle"},
    {class:"fas fa-mountain", name:"glacier, hiking, hill, landscape, travel, view", icon:"mountain"},
    {class:"fas fa-mouse", name:"click, computer, cursor, input, peripheral", icon:"mouse"},
    {class:"fas fa-mouse-pointer", name:"arrow, cursor, select", icon:"mousePointer"},
    {class:"fas fa-mug-hot", name:"caliente, cocoa, coffee, cup, drink, holiday, hot chocolate, steam, tea, warmth", icon:"mugHot"},
    {class:"fas fa-music", name:"lyrics, melody, note, sing, sound", icon:"music"},
    {class:"fas fa-network-wired", name:"computer, connect, ethernet, internet, intranet", icon:"networkWired"},
    {class:"fas fa-neuter", name:"neuter", icon:"neuter"},
    {class:"fas fa-newspaper", name:"article, editorial, headline, journal, journalism, news, press", icon:"solidNewspaper"},
    {class:"fas fa-not-equal", name:"arithmetic, compare, math", icon:"notEqual"},
    {class:"fas fa-notes-medical", name:"clipboard, doctor, ehr, health, history, records", icon:"notesMedical"},
    {class:"fas fa-object-group", name:"combine, copy, design, merge, select", icon:"solidObjectGroup"},
    {class:"fas fa-object-ungroup", name:"copy, design, merge, select, separate", icon:"solidObjectUngroup"},
    {class:"fas fa-oil-can", name:"auto, crude, gasoline, grease, lubricate, petroleum", icon:"oilCan"},
    {class:"fas fa-om", name:"buddhism, hinduism, jainism, mantra", icon:"om"},
    {class:"fas fa-otter", name:"animal, badger, fauna, fur, mammal, marten", icon:"otter"},
    {class:"fas fa-outdent", name:"align, justify, paragraph, tab", icon:"outdent"},
    {class:"fas fa-pager", name:"beeper, cellphone, communication", icon:"pager"},
    {class:"fas fa-paint-brush", name:"acrylic, art, brush, color, fill, paint, pigment, watercolor", icon:"paintBrush"},
    {class:"fas fa-paint-roller", name:"acrylic, art, brush, color, fill, paint, pigment, watercolor", icon:"paintRoller"},
    {class:"fas fa-palette", name:"acrylic, art, brush, color, fill, paint, pigment, watercolor", icon:"palette"},
    {class:"fas fa-pallet", name:"archive, box, inventory, shipping, warehouse", icon:"pallet"},
    {class:"fas fa-paper-plane", name:"air, float, fold, mail, paper, send", icon:"solidPaperPlane"},
    {class:"fas fa-paperclip", name:"attach, attachment, connect, link", icon:"paperclip"},
    {class:"fas fa-parachute-box", name:"aid, assistance, rescue, supplies", icon:"parachuteBox"},
    {class:"fas fa-paragraph", name:"edit, format, text, writing", icon:"paragraph"},
    {class:"fas fa-parking", name:"auto, car, garage, meter", icon:"parking"},
    {class:"fas fa-passport", name:"document, id, identification, issued, travel", icon:"passport"},
    {class:"fas fa-pastafarianism", name:"agnosticism, atheism, flying spaghetti monster, fsm", icon:"pastafarianism"},
    {class:"fas fa-paste", name:"clipboard, copy, document, paper", icon:"paste"},
    {class:"fas fa-pause", name:"hold, wait", icon:"pause"},
    {class:"fas fa-pause-circle", name:"hold, wait", icon:"solidPauseCircle"},
    {class:"fas fa-paw", name:"animal, cat, dog, pet, print", icon:"paw"},
    {class:"fas fa-peace", name:"serenity, tranquility, truce, war", icon:"peace"},
    {class:"fas fa-pen", name:"design, edit, update, write", icon:"pen"},
    {class:"fas fa-pen-alt", name:"design, edit, update, write", icon:"penAlt"},
    {class:"fas fa-pen-fancy", name:"design, edit, fountain pen, update, write", icon:"penFancy"},
    {class:"fas fa-pen-nib", name:"design, edit, fountain pen, update, write", icon:"penNib"},
    {class:"fas fa-pen-square", name:"edit, pencil-square, update, write", icon:"penSquare"},
    {class:"fas fa-pencil-alt", name:"design, edit, pencil, update, write", icon:"pencilAlt"},
    {class:"fas fa-pencil-ruler", name:"design, draft, draw, pencil", icon:"pencilRuler"},
    {class:"fas fa-people-arrows", name:"covid-19, personal space, social distance, space, spread, users", icon:"peopleArrows"},
    {class:"fas fa-people-carry", name:"box, carry, fragile, help, movers, package", icon:"peopleCarry"},
    {class:"fas fa-pepper-hot", name:"buffalo wings, capsicum, chili, chilli, habanero, jalapeno, mexican, spicy, tabasco, vegetable", icon:"pepperHot"},
    {class:"fas fa-percent", name:"discount, fraction, proportion, rate, ratio", icon:"percent"},
    {class:"fas fa-percentage", name:"discount, fraction, proportion, rate, ratio", icon:"percentage"},
    {class:"fas fa-person-booth", name:"changing, changing room, election, human, person, vote, voting", icon:"personBooth"},
    {class:"fas fa-phone", name:"call, earphone, number, support, telephone, voice", icon:"phone"},
    {class:"fas fa-phone-alt", name:"call, earphone, number, support, telephone, voice", icon:"phoneAlt"},
    {class:"fas fa-phone-slash", name:"call, cancel, earphone, mute, number, support, telephone, voice", icon:"phoneSlash"},
    {class:"fas fa-phone-square", name:"call, earphone, number, support, telephone, voice", icon:"phoneSquare"},
    {class:"fas fa-phone-square-alt", name:"call, earphone, number, support, telephone, voice", icon:"phoneSquareAlt"},
    {class:"fas fa-phone-volume", name:"call, earphone, number, sound, support, telephone, voice, volume-control-phone", icon:"phoneVolume"},
    {class:"fas fa-photo-video", name:"av, film, image, library, media", icon:"photoVideo"},
    {class:"fas fa-piggy-bank", name:"bank, save, savings", icon:"piggyBank"},
    {class:"fas fa-pills", name:"drugs, medicine, prescription, tablets", icon:"pills"},
    {class:"fas fa-pizza-slice", name:"cheese, chicago, italian, mozzarella, new york, pepperoni, pie, slice, teenage mutant ninja turtles, tomato", icon:"pizzaSlice"},
    {class:"fas fa-place-of-worship", name:"building, church, holy, mosque, synagogue", icon:"placeOfWorship"},
    {class:"fas fa-plane", name:"airplane, destination, fly, location, mode, travel, trip", icon:"plane"},
    {class:"fas fa-plane-arrival", name:"airplane, arriving, destination, fly, land, landing, location, mode, travel, trip", icon:"planeArrival"},
    {class:"fas fa-plane-departure", name:"airplane, departing, destination, fly, location, mode, take off, taking off, travel, trip", icon:"planeDeparture"},
    {class:"fas fa-plane-slash", name:"airplane mode, canceled, covid-19, delayed, grounded, travel", icon:"planeSlash"},
    {class:"fas fa-play", name:"audio, music, playing, sound, start, video", icon:"play"},
    {class:"fas fa-play-circle", name:"audio, music, playing, sound, start, video", icon:"solidPlayCircle"},
    {class:"fas fa-plug", name:"connect, electric, online, power", icon:"plug"},
    {class:"fas fa-plus", name:"add, create, expand, new, positive, shape", icon:"plus"},
    {class:"fas fa-plus-circle", name:"add, create, expand, new, positive, shape", icon:"plusCircle"},
    {class:"fas fa-plus-square", name:"add, create, expand, new, positive, shape", icon:"solidPlusSquare"},
    {class:"fas fa-podcast", name:"audio, broadcast, music, sound", icon:"podcast"},
    {class:"fas fa-poll", name:"results, survey, trend, vote, voting", icon:"poll"},
    {class:"fas fa-poll-h", name:"results, survey, trend, vote, voting", icon:"pollH"},
    {class:"fas fa-poo", name:"crap, poop, shit, smile, turd", icon:"poo"},
    {class:"fas fa-poo-storm", name:"bolt, cloud, euphemism, lightning, mess, poop, shit, turd", icon:"pooStorm"},
    {class:"fas fa-poop", name:"crap, poop, shit, smile, turd", icon:"poop"},
    {class:"fas fa-portrait", name:"id, image, photo, picture, selfie", icon:"portrait"},
    {class:"fas fa-pound-sign", name:"currency, gbp, money", icon:"poundSign"},
    {class:"fas fa-power-off", name:"cancel, computer, on, reboot, restart", icon:"powerOff"},
    {class:"fas fa-pray", name:"kneel, preach, religion, worship", icon:"pray"},
    {class:"fas fa-praying-hands", name:"kneel, preach, religion, worship", icon:"prayingHands"},
    {class:"fas fa-prescription", name:"drugs, medical, medicine, pharmacy, rx", icon:"prescription"},
    {class:"fas fa-prescription-bottle", name:"drugs, medical, medicine, pharmacy, rx", icon:"prescriptionBottle"},
    {class:"fas fa-prescription-bottle-alt", name:"drugs, medical, medicine, pharmacy, rx", icon:"prescriptionBottleAlt"},
    {class:"fas fa-print", name:"business, copy, document, office, paper", icon:"print"},
    {class:"fas fa-procedures", name:"EKG, bed, electrocardiogram, health, hospital, life, patient, vital", icon:"procedures"},
    {class:"fas fa-project-diagram", name:"chart, graph, network, pert", icon:"projectDiagram"},
    {class:"fas fa-pump-medical", name:"anti-bacterial, clean, covid-19, disinfect, hygiene, medical grade, sanitizer, soap", icon:"pumpMedical"},
    {class:"fas fa-pump-soap", name:"anti-bacterial, clean, covid-19, disinfect, hygiene, sanitizer, soap", icon:"pumpSoap"},
    {class:"fas fa-puzzle-piece", name:"add-on, addon, game, section", icon:"puzzlePiece"},
    {class:"fas fa-qrcode", name:"barcode, info, information, scan", icon:"qrcode"},
    {class:"fas fa-question", name:"help, information, support, unknown", icon:"question"},
    {class:"fas fa-question-circle", name:"help, information, support, unknown", icon:"solidQuestionCircle"},
    {class:"fas fa-quidditch", name:"ball, bludger, broom, golden snitch, harry potter, hogwarts, quaffle, sport, wizard", icon:"quidditch"},
    {class:"fas fa-quote-left", name:"mention, note, phrase, text, type", icon:"quoteLeft"},
    {class:"fas fa-quote-right", name:"mention, note, phrase, text, type", icon:"quoteRight"},
    {class:"fas fa-quran", name:"book, islam, muslim, religion", icon:"quran"},
    {class:"fas fa-radiation", name:"danger, dangerous, deadly, hazard, nuclear, radioactive, warning", icon:"radiation"},
    {class:"fas fa-radiation-alt", name:"danger, dangerous, deadly, hazard, nuclear, radioactive, warning", icon:"radiationAlt"},
    {class:"fas fa-rainbow", name:"gold, leprechaun, prism, rain, sky", icon:"rainbow"},
    {class:"fas fa-random", name:"arrows, shuffle, sort, swap, switch, transfer", icon:"random"},
    {class:"fas fa-receipt", name:"check, invoice, money, pay, table", icon:"receipt"},
    {class:"fas fa-record-vinyl", name:"LP, album, analog, music, phonograph, sound", icon:"recordVinyl"},
    {class:"fas fa-recycle", name:"Waste, compost, garbage, reuse, trash", icon:"recycle"},
    {class:"fas fa-redo", name:"forward, refresh, reload, repeat", icon:"redo"},
    {class:"fas fa-redo-alt", name:"forward, refresh, reload, repeat", icon:"redoAlt"},
    {class:"fas fa-registered", name:"copyright, mark, trademark", icon:"solidRegistered"},
    {class:"fas fa-remove-format", name:"cancel, font, format, remove, style, text", icon:"removeFormat"},
    {class:"fas fa-reply", name:"mail, message, respond", icon:"reply"},
    {class:"fas fa-reply-all", name:"mail, message, respond", icon:"replyAll"},
    {class:"fas fa-republican", name:"american, conservative, election, elephant, politics, republican party, right, right-wing, usa", icon:"republican"},
    {class:"fas fa-restroom", name:"bathroom, john, loo, potty, washroom, waste, wc", icon:"restroom"},
    {class:"fas fa-retweet", name:"refresh, reload, share, swap", icon:"retweet"},
    {class:"fas fa-ribbon", name:"badge, cause, lapel, pin", icon:"ribbon"},
    {class:"fas fa-ring", name:"Dungeons & Dragons, Gollum, band, binding, d&d, dnd, engagement, fantasy, gold, jewelry, marriage, precious", icon:"ring"},
    {class:"fas fa-road", name:"highway, map, pavement, route, street, travel", icon:"road"},
    {class:"fas fa-robot", name:"android, automate, computer, cyborg", icon:"robot"},
    {class:"fas fa-rocket", name:"aircraft, app, jet, launch, nasa, space", icon:"rocket"},
    {class:"fas fa-route", name:"directions, navigation, travel", icon:"route"},
    {class:"fas fa-rss", name:"blog, feed, journal, news, writing", icon:"rss"},
    {class:"fas fa-rss-square", name:"blog, feed, journal, news, writing", icon:"rssSquare"},
    {class:"fas fa-ruble-sign", name:"currency, money, rub", icon:"rubleSign"},
    {class:"fas fa-ruler", name:"design, draft, length, measure, planning", icon:"ruler"},
    {class:"fas fa-ruler-combined", name:"design, draft, length, measure, planning", icon:"rulerCombined"},
    {class:"fas fa-ruler-horizontal", name:"design, draft, length, measure, planning", icon:"rulerHorizontal"},
    {class:"fas fa-ruler-vertical", name:"design, draft, length, measure, planning", icon:"rulerVertical"},
    {class:"fas fa-running", name:"exercise, health, jog, person, run, sport, sprint", icon:"running"},
    {class:"fas fa-rupee-sign", name:"currency, indian, inr, money", icon:"rupeeSign"},
    {class:"fas fa-sad-cry", name:"emoticon, face, tear, tears", icon:"solidSadCry"},
    {class:"fas fa-sad-tear", name:"emoticon, face, tear, tears", icon:"solidSadTear"},
    {class:"fas fa-satellite", name:"communications, hardware, orbit, space", icon:"satellite"},
    {class:"fas fa-satellite-dish", name:"SETI, communications, hardware, receiver, saucer, signal, space", icon:"satelliteDish"},
    {class:"fas fa-save", name:"disk, download, floppy, floppy-o", icon:"solidSave"},
    {class:"fas fa-school", name:"building, education, learn, student, teacher", icon:"school"},
    {class:"fas fa-screwdriver", name:"admin, fix, mechanic, repair, settings, tool", icon:"screwdriver"},
    {class:"fas fa-scroll", name:"Dungeons & Dragons, announcement, d&d, dnd, fantasy, paper, script", icon:"scroll"},
    {class:"fas fa-sd-card", name:"image, memory, photo, save", icon:"sdCard"},
    {class:"fas fa-search", name:"bigger, enlarge, find, magnify, preview, zoom", icon:"search"},
    {class:"fas fa-search-dollar", name:"bigger, enlarge, find, magnify, money, preview, zoom", icon:"searchDollar"},
    {class:"fas fa-search-location", name:"bigger, enlarge, find, magnify, preview, zoom", icon:"searchLocation"},
    {class:"fas fa-search-minus", name:"minify, negative, smaller, zoom, zoom out", icon:"searchMinus"},
    {class:"fas fa-search-plus", name:"bigger, enlarge, magnify, positive, zoom, zoom in", icon:"searchPlus"},
    {class:"fas fa-seedling", name:"flora, grow, plant, vegan", icon:"seedling"},
    {class:"fas fa-server", name:"computer, cpu, database, hardware, network", icon:"server"},
    {class:"fas fa-shapes", name:"blocks, build, circle, square, triangle", icon:"shapes"},
    {class:"fas fa-share", name:"forward, save, send, social", icon:"share"},
    {class:"fas fa-share-alt", name:"forward, save, send, social", icon:"shareAlt"},
    {class:"fas fa-share-alt-square", name:"forward, save, send, social", icon:"shareAltSquare"},
    {class:"fas fa-share-square", name:"forward, save, send, social", icon:"solidShareSquare"},
    {class:"fas fa-shekel-sign", name:"currency, ils, money", icon:"shekelSign"},
    {class:"fas fa-shield-alt", name:"achievement, award, block, defend, security, winner", icon:"shieldAlt"},
    {class:"fas fa-shield-virus", name:"antibodies, barrier, covid-19, health, protect", icon:"shieldVirus"},
    {class:"fas fa-ship", name:"boat, sea, water", icon:"ship"},
    {class:"fas fa-shipping-fast", name:"express, fedex, mail, overnight, package, ups", icon:"shippingFast"},
    {class:"fas fa-shoe-prints", name:"feet, footprints, steps, walk", icon:"shoePrints"},
    {class:"fas fa-shopping-bag", name:"buy, checkout, grocery, payment, purchase", icon:"shoppingBag"},
    {class:"fas fa-shopping-basket", name:"buy, checkout, grocery, payment, purchase", icon:"shoppingBasket"},
    {class:"fas fa-shopping-cart", name:"buy, checkout, grocery, payment, purchase", icon:"shoppingCart"},
    {class:"fas fa-shower", name:"bath, clean, faucet, water", icon:"shower"},
    {class:"fas fa-shuttle-van", name:"airport, machine, public-transportation, transportation, travel, vehicle", icon:"shuttleVan"},
    {class:"fas fa-sign", name:"directions, real estate, signage, wayfinding", icon:"sign"},
    {class:"fas fa-sign-in-alt", name:"arrow, enter, join, log in, login, sign in, sign up, sign-in, signin, signup", icon:"signInAlt"},
    {class:"fas fa-sign-language", name:"Translate, asl, deaf, hands", icon:"signLanguage"},
    {class:"fas fa-sign-out-alt", name:"arrow, exit, leave, log out, logout, sign-out", icon:"signOutAlt"},
    {class:"fas fa-signal", name:"bars, graph, online, reception, status", icon:"signal"},
    {class:"fas fa-signature", name:"John Hancock, cursive, name, writing", icon:"signature"},
    {class:"fas fa-sim-card", name:"hard drive, hardware, portable, storage, technology, tiny", icon:"simCard"},
    {class:"fas fa-sink", name:"bathroom, covid-19, faucet, kitchen, wash", icon:"sink"},
    {class:"fas fa-sitemap", name:"directory, hierarchy, ia, information architecture, organization", icon:"sitemap"},
    {class:"fas fa-skating", name:"activity, figure skating, fitness, ice, person, winter", icon:"skating"},
    {class:"fas fa-skiing", name:"activity, downhill, fast, fitness, olympics, outdoors, person, seasonal, slalom", icon:"skiing"},
    {class:"fas fa-skiing-nordic", name:"activity, cross country, fitness, outdoors, person, seasonal", icon:"skiingNordic"},
    {class:"fas fa-skull", name:"bones, skeleton, x-ray, yorick", icon:"skull"},
    {class:"fas fa-skull-crossbones", name:"Dungeons & Dragons, alert, bones, d&d, danger, dead, deadly, death, dnd, fantasy, halloween, holiday, jolly-roger, pirate, poison, skeleton, warning", icon:"skullCrossbones"},
    {class:"fas fa-slash", name:"cancel, close, mute, off, stop, x", icon:"slash"},
    {class:"fas fa-sleigh", name:"christmas, claus, fly, holiday, santa, sled, snow, xmas", icon:"sleigh"},
    {class:"fas fa-sliders-h", name:"adjust, settings, sliders, toggle", icon:"slidersH"},
    {class:"fas fa-smile", name:"approve, emoticon, face, happy, rating, satisfied", icon:"solidSmile"},
    {class:"fas fa-smile-beam", name:"emoticon, face, happy, positive", icon:"solidSmileBeam"},
    {class:"fas fa-smile-wink", name:"emoticon, face, happy, hint, joke", icon:"solidSmileWink"},
    {class:"fas fa-smog", name:"dragon, fog, haze, pollution, smoke, weather", icon:"smog"},
    {class:"fas fa-smoking", name:"cancer, cigarette, nicotine, smoking status, tobacco", icon:"smoking"},
    {class:"fas fa-smoking-ban", name:"ban, cancel, no smoking, non-smoking", icon:"smokingBan"},
    {class:"fas fa-sms", name:"chat, conversation, message, mobile, notification, phone, sms, texting", icon:"sms"},
    {class:"fas fa-snowboarding", name:"activity, fitness, olympics, outdoors, person", icon:"snowboarding"},
    {class:"fas fa-snowflake", name:"precipitation, rain, winter", icon:"solidSnowflake"},
    {class:"fas fa-snowman", name:"decoration, frost, frosty, holiday", icon:"snowman"},
    {class:"fas fa-snowplow", name:"clean up, cold, road, storm, winter", icon:"snowplow"},
    {class:"fas fa-soap", name:"bubbles, clean, covid-19, hygiene, wash", icon:"soap"},
    {class:"fas fa-socks", name:"business socks, business time, clothing, feet, flight of the conchords, wednesday", icon:"socks"},
    {class:"fas fa-solar-panel", name:"clean, eco-friendly, energy, green, sun", icon:"solarPanel"},
    {class:"fas fa-sort", name:"filter, order", icon:"sort"},
    {class:"fas fa-sort-alpha-down", name:"alphabetical, arrange, filter, order, sort-alpha-asc", icon:"sortAlphaDown"},
    {class:"fas fa-sort-alpha-down-alt", name:"alphabetical, arrange, filter, order, sort-alpha-asc", icon:"sortAlphaDownAlt"},
    {class:"fas fa-sort-alpha-up", name:"alphabetical, arrange, filter, order, sort-alpha-desc", icon:"sortAlphaUp"},
    {class:"fas fa-sort-alpha-up-alt", name:"alphabetical, arrange, filter, order, sort-alpha-desc", icon:"sortAlphaUpAlt"},
    {class:"fas fa-sort-amount-down", name:"arrange, filter, number, order, sort-amount-asc", icon:"sortAmountDown"},
    {class:"fas fa-sort-amount-down-alt", name:"arrange, filter, order, sort-amount-asc", icon:"sortAmountDownAlt"},
    {class:"fas fa-sort-amount-up", name:"arrange, filter, order, sort-amount-desc", icon:"sortAmountUp"},
    {class:"fas fa-sort-amount-up-alt", name:"arrange, filter, order, sort-amount-desc", icon:"sortAmountUpAlt"},
    {class:"fas fa-sort-down", name:"arrow, descending, filter, order, sort-desc", icon:"sortDown"},
    {class:"fas fa-sort-numeric-down", name:"arrange, filter, numbers, order, sort-numeric-asc", icon:"sortNumericDown"},
    {class:"fas fa-sort-numeric-down-alt", name:"arrange, filter, numbers, order, sort-numeric-asc", icon:"sortNumericDownAlt"},
    {class:"fas fa-sort-numeric-up", name:"arrange, filter, numbers, order, sort-numeric-desc", icon:"sortNumericUp"},
    {class:"fas fa-sort-numeric-up-alt", name:"arrange, filter, numbers, order, sort-numeric-desc", icon:"sortNumericUpAlt"},
    {class:"fas fa-sort-up", name:"arrow, ascending, filter, order, sort-asc", icon:"sortUp"},
    {class:"fas fa-spa", name:"flora, massage, mindfulness, plant, wellness", icon:"spa"},
    {class:"fas fa-space-shuttle", name:"astronaut, machine, nasa, rocket, space, transportation", icon:"spaceShuttle"},
    {class:"fas fa-spell-check", name:"dictionary, edit, editor, grammar, text", icon:"spellCheck"},
    {class:"fas fa-spider", name:"arachnid, bug, charlotte, crawl, eight, halloween", icon:"spider"},
    {class:"fas fa-spinner", name:"circle, loading, progress", icon:"spinner"},
    {class:"fas fa-splotch", name:"Ink, blob, blotch, glob, stain", icon:"splotch"},
    {class:"fas fa-spray-can", name:"Paint, aerosol, design, graffiti, tag", icon:"sprayCan"},
    {class:"fas fa-square", name:"block, box, shape", icon:"solidSquare"},
    {class:"fas fa-square-full", name:"block, box, shape", icon:"squareFull"},
    {class:"fas fa-square-root-alt", name:"arithmetic, calculus, division, math", icon:"squareRootAlt"},
    {class:"fas fa-stamp", name:"art, certificate, imprint, rubber, seal", icon:"stamp"},
    {class:"fas fa-star", name:"achievement, award, favorite, important, night, rating, score", icon:"solidStar"},
    {class:"fas fa-star-and-crescent", name:"islam, muslim, religion", icon:"starAndCrescent"},
    {class:"fas fa-star-half", name:"achievement, award, rating, score, star-half-empty, star-half-full", icon:"solidStarHalf"},
    {class:"fas fa-star-half-alt", name:"achievement, award, rating, score, star-half-empty, star-half-full", icon:"starHalfAlt"},
    {class:"fas fa-star-of-david", name:"jewish, judaism, religion", icon:"starOfDavid"},
    {class:"fas fa-star-of-life", name:"doctor, emt, first aid, health, medical", icon:"starOfLife"},
    {class:"fas fa-step-backward", name:"beginning, first, previous, rewind, start", icon:"stepBackward"},
    {class:"fas fa-step-forward", name:"end, last, next", icon:"stepForward"},
    {class:"fas fa-stethoscope", name:"covid-19, diagnosis, doctor, general practitioner, hospital, infirmary, medicine, office, outpatient", icon:"stethoscope"},
    {class:"fas fa-sticky-note", name:"message, note, paper, reminder, sticker", icon:"solidStickyNote"},
    {class:"fas fa-stop", name:"block, box, square", icon:"stop"},
    {class:"fas fa-stop-circle", name:"block, box, circle, square", icon:"solidStopCircle"},
    {class:"fas fa-stopwatch", name:"clock, reminder, time", icon:"stopwatch"},
    {class:"fas fa-stopwatch-20", name:"ABCs, countdown, covid-19, happy birthday, i will survive, reminder, seconds, time, timer", icon:"stopwatch20"},
    {class:"fas fa-store", name:"building, buy, purchase, shopping", icon:"store"},
    {class:"fas fa-store-alt", name:"building, buy, purchase, shopping", icon:"storeAlt"},
    {class:"fas fa-store-alt-slash", name:"building, buy, closed, covid-19, purchase, shopping", icon:"storeAltSlash"},
    {class:"fas fa-store-slash", name:"building, buy, closed, covid-19, purchase, shopping", icon:"storeSlash"},
    {class:"fas fa-stream", name:"flow, list, timeline", icon:"stream"},
    {class:"fas fa-street-view", name:"directions, location, map, navigation", icon:"streetView"},
    {class:"fas fa-strikethrough", name:"cancel, edit, font, format, text, type", icon:"strikethrough"},
    {class:"fas fa-stroopwafel", name:"caramel, cookie, dessert, sweets, waffle", icon:"stroopwafel"},
    {class:"fas fa-subscript", name:"edit, font, format, text, type", icon:"subscript"},
    {class:"fas fa-subway", name:"machine, railway, train, transportation, vehicle", icon:"subway"},
    {class:"fas fa-suitcase", name:"baggage, luggage, move, suitcase, travel, trip", icon:"suitcase"},
    {class:"fas fa-suitcase-rolling", name:"baggage, luggage, move, suitcase, travel, trip", icon:"suitcaseRolling"},
    {class:"fas fa-sun", name:"brighten, contrast, day, lighter, sol, solar, star, weather", icon:"solidSun"},
    {class:"fas fa-superscript", name:"edit, exponential, font, format, text, type", icon:"superscript"},
    {class:"fas fa-surprise", name:"emoticon, face, shocked", icon:"solidSurprise"},
    {class:"fas fa-swatchbook", name:"Pantone, color, design, hue, palette", icon:"swatchbook"},
    {class:"fas fa-swimmer", name:"athlete, head, man, olympics, person, pool, water", icon:"swimmer"},
    {class:"fas fa-swimming-pool", name:"ladder, recreation, swim, water", icon:"swimmingPool"},
    {class:"fas fa-synagogue", name:"building, jewish, judaism, religion, star of david, temple", icon:"synagogue"},
    {class:"fas fa-sync", name:"exchange, refresh, reload, rotate, swap", icon:"sync"},
    {class:"fas fa-sync-alt", name:"exchange, refresh, reload, rotate, swap", icon:"syncAlt"},
    {class:"fas fa-syringe", name:"covid-19, doctor, immunizations, medical, needle", icon:"syringe"},
    {class:"fas fa-table", name:"data, excel, spreadsheet", icon:"table"},
    {class:"fas fa-table-tennis", name:"ball, paddle, ping pong", icon:"tableTennis"},
    {class:"fas fa-tablet", name:"apple, device, ipad, kindle, screen", icon:"tablet"},
    {class:"fas fa-tablet-alt", name:"apple, device, ipad, kindle, screen", icon:"tabletAlt"},
    {class:"fas fa-tablets", name:"drugs, medicine, pills, prescription", icon:"tablets"},
    {class:"fas fa-tachometer-alt", name:"dashboard, fast, odometer, speed, speedometer", icon:"tachometerAlt"},
    {class:"fas fa-tag", name:"discount, label, price, shopping", icon:"tag"},
    {class:"fas fa-tags", name:"discount, label, price, shopping", icon:"tags"},
    {class:"fas fa-tape", name:"design, package, sticky", icon:"tape"},
    {class:"fas fa-tasks", name:"checklist, downloading, downloads, loading, progress, project management, settings, to do", icon:"tasks"},
    {class:"fas fa-taxi", name:"cab, cabbie, car, car service, lyft, machine, transportation, travel, uber, vehicle", icon:"taxi"},
    {class:"fas fa-teeth", name:"bite, dental, dentist, gums, mouth, smile, tooth", icon:"teeth"},
    {class:"fas fa-teeth-open", name:"dental, dentist, gums bite, mouth, smile, tooth", icon:"teethOpen"},
    {class:"fas fa-temperature-high", name:"cook, covid-19, mercury, summer, thermometer, warm", icon:"temperatureHigh"},
    {class:"fas fa-temperature-low", name:"cold, cool, covid-19, mercury, thermometer, winter", icon:"temperatureLow"},
    {class:"fas fa-tenge", name:"currency, kazakhstan, money, price", icon:"tenge"},
    {class:"fas fa-terminal", name:"code, command, console, development, prompt", icon:"terminal"},
    {class:"fas fa-text-height", name:"edit, font, format, text, type", icon:"textHeight"},
    {class:"fas fa-text-width", name:"edit, font, format, text, type", icon:"textWidth"},
    {class:"fas fa-th", name:"blocks, boxes, grid, squares", icon:"th"},
    {class:"fas fa-th-large", name:"blocks, boxes, grid, squares", icon:"thLarge"},
    {class:"fas fa-th-list", name:"checklist, completed, done, finished, ol, todo, ul", icon:"thList"},
    {class:"fas fa-theater-masks", name:"comedy, perform, theatre, tragedy", icon:"theaterMasks"},
    {class:"fas fa-thermometer", name:"covid-19, mercury, status, temperature", icon:"thermometer"},
    {class:"fas fa-thermometer-empty", name:"cold, mercury, status, temperature", icon:"thermometerEmpty"},
    {class:"fas fa-thermometer-full", name:"fever, hot, mercury, status, temperature", icon:"thermometerFull"},
    {class:"fas fa-thermometer-half", name:"mercury, status, temperature", icon:"thermometerHalf"},
    {class:"fas fa-thermometer-quarter", name:"mercury, status, temperature", icon:"thermometerQuarter"},
    {class:"fas fa-thermometer-three-quarters", name:"mercury, status, temperature", icon:"thermometerThreeQuarters"},
    {class:"fas fa-thumbs-down", name:"disagree, disapprove, dislike, hand, social, thumbs-o-down", icon:"solidThumbsDown"},
    {class:"fas fa-thumbs-up", name:"agree, approve, favorite, hand, like, ok, okay, social, success, thumbs-o-up, yes, you got it dude", icon:"solidThumbsUp"},
    {class:"fas fa-thumbtack", name:"coordinates, location, marker, pin, thumb-tack", icon:"thumbtack"},
    {class:"fas fa-ticket-alt", name:"movie, pass, support, ticket", icon:"ticketAlt"},
    {class:"fas fa-times", name:"close, cross, error, exit, incorrect, notice, notification, notify, problem, wrong, x", icon:"times"},
    {class:"fas fa-times-circle", name:"close, cross, exit, incorrect, notice, notification, notify, problem, wrong, x", icon:"solidTimesCircle"},
    {class:"fas fa-tint", name:"color, drop, droplet, raindrop, waterdrop", icon:"tint"},
    {class:"fas fa-tint-slash", name:"color, drop, droplet, raindrop, waterdrop", icon:"tintSlash"},
    {class:"fas fa-tired", name:"angry, emoticon, face, grumpy, upset", icon:"solidTired"},
    {class:"fas fa-toggle-off", name:"switch", icon:"toggleOff"},
    {class:"fas fa-toggle-on", name:"switch", icon:"toggleOn"},
    {class:"fas fa-toilet", name:"bathroom, flush, john, loo, pee, plumbing, poop, porcelain, potty, restroom, throne, washroom, waste, wc", icon:"toilet"},
    {class:"fas fa-toilet-paper", name:"bathroom, covid-19, halloween, holiday, lavatory, prank, restroom, roll", icon:"toiletPaper"},
    {class:"fas fa-toilet-paper-slash", name:"bathroom, covid-19, halloween, holiday, lavatory, leaves, prank, restroom, roll, trouble, ut oh", icon:"toiletPaperSlash"},
    {class:"fas fa-toolbox", name:"admin, container, fix, repair, settings, tools", icon:"toolbox"},
    {class:"fas fa-tools", name:"admin, fix, repair, screwdriver, settings, tools, wrench", icon:"tools"},
    {class:"fas fa-tooth", name:"bicuspid, dental, dentist, molar, mouth, teeth", icon:"tooth"},
    {class:"fas fa-torah", name:"book, jewish, judaism, religion, scroll", icon:"torah"},
    {class:"fas fa-torii-gate", name:"building, shintoism", icon:"toriiGate"},
    {class:"fas fa-tractor", name:"agriculture, farm, vehicle", icon:"tractor"},
    {class:"fas fa-trademark", name:"copyright, register, symbol", icon:"trademark"},
    {class:"fas fa-traffic-light", name:"direction, road, signal, travel", icon:"trafficLight"},
    {class:"fas fa-trailer", name:"carry, haul, moving, travel", icon:"trailer"},
    {class:"fas fa-train", name:"bullet, commute, locomotive, railway, subway", icon:"train"},
    {class:"fas fa-tram", name:"crossing, machine, mountains, seasonal, transportation", icon:"tram"},
    {class:"fas fa-transgender", name:"intersex", icon:"transgender"},
    {class:"fas fa-transgender-alt", name:"intersex", icon:"transgenderAlt"},
    {class:"fas fa-trash", name:"delete, garbage, hide, remove", icon:"trash"},
    {class:"fas fa-trash-alt", name:"delete, garbage, hide, remove, trash-o", icon:"solidTrashAlt"},
    {class:"fas fa-trash-restore", name:"back, control z, oops, undo", icon:"trashRestore"},
    {class:"fas fa-trash-restore-alt", name:"back, control z, oops, undo", icon:"trashRestoreAlt"},
    {class:"fas fa-tree", name:"bark, fall, flora, forest, nature, plant, seasonal", icon:"tree"},
    {class:"fas fa-trophy", name:"achievement, award, cup, game, winner", icon:"trophy"},
    {class:"fas fa-truck", name:"cargo, delivery, shipping, vehicle", icon:"truck"},
    {class:"fas fa-truck-loading", name:"box, cargo, delivery, inventory, moving, rental, vehicle", icon:"truckLoading"},
    {class:"fas fa-truck-monster", name:"offroad, vehicle, wheel", icon:"truckMonster"},
    {class:"fas fa-truck-moving", name:"cargo, inventory, rental, vehicle", icon:"truckMoving"},
    {class:"fas fa-truck-pickup", name:"cargo, vehicle", icon:"truckPickup"},
    {class:"fas fa-tshirt", name:"clothing, fashion, garment, shirt", icon:"tshirt"},
    {class:"fas fa-tty", name:"communication, deaf, telephone, teletypewriter, text", icon:"tty"},
    {class:"fas fa-tv", name:"computer, display, monitor, television", icon:"tv"},
    {class:"fas fa-umbrella", name:"protection, rain, storm, wet", icon:"umbrella"},
    {class:"fas fa-umbrella-beach", name:"protection, recreation, sand, shade, summer, sun", icon:"umbrellaBeach"},
    {class:"fas fa-underline", name:"edit, emphasis, format, text, writing", icon:"underline"},
    {class:"fas fa-undo", name:"back, control z, exchange, oops, return, rotate, swap", icon:"undo"},
    {class:"fas fa-undo-alt", name:"back, control z, exchange, oops, return, swap", icon:"undoAlt"},
    {class:"fas fa-universal-access", name:"accessibility, hearing, person, seeing, visual impairment", icon:"universalAccess"},
    {class:"fas fa-university", name:"bank, building, college, higher education - students, institution", icon:"university"},
    {class:"fas fa-unlink", name:"attachment, chain, chain-broken, remove", icon:"unlink"},
    {class:"fas fa-unlock", name:"admin, lock, password, private, protect", icon:"unlock"},
    {class:"fas fa-unlock-alt", name:"admin, lock, password, private, protect", icon:"unlockAlt"},
    {class:"fas fa-upload", name:"hard drive, import, publish", icon:"upload"},
    {class:"fas fa-user", name:"account, avatar, head, human, man, person, profile", icon:"solidUser"},
    {class:"fas fa-user-alt", name:"account, avatar, head, human, man, person, profile", icon:"userAlt"},
    {class:"fas fa-user-alt-slash", name:"account, avatar, head, human, man, person, profile", icon:"userAltSlash"},
    {class:"fas fa-user-astronaut", name:"avatar, clothing, cosmonaut, nasa, space, suit", icon:"userAstronaut"},
    {class:"fas fa-user-check", name:"accept, check, person, verified", icon:"userCheck"},
    {class:"fas fa-user-circle", name:"account, avatar, head, human, man, person, profile", icon:"solidUserCircle"},
    {class:"fas fa-user-clock", name:"alert, person, remind, time", icon:"userClock"},
    {class:"fas fa-user-cog", name:"admin, cog, person, settings", icon:"userCog"},
    {class:"fas fa-user-edit", name:"edit, pen, pencil, person, update, write", icon:"userEdit"},
    {class:"fas fa-user-friends", name:"group, people, person, team, users", icon:"userFriends"},
    {class:"fas fa-user-graduate", name:"cap, clothing, commencement, gown, graduation, person, student", icon:"userGraduate"},
    {class:"fas fa-user-injured", name:"cast, injury, ouch, patient, person, sling", icon:"userInjured"},
    {class:"fas fa-user-lock", name:"admin, lock, person, private, unlock", icon:"userLock"},
    {class:"fas fa-user-md", name:"covid-19, job, medical, nurse, occupation, physician, profile, surgeon", icon:"userMd"},
    {class:"fas fa-user-minus", name:"delete, negative, remove", icon:"userMinus"},
    {class:"fas fa-user-ninja", name:"assassin, avatar, dangerous, deadly, sneaky", icon:"userNinja"},
    {class:"fas fa-user-nurse", name:"covid-19, doctor, midwife, practitioner, surgeon", icon:"userNurse"},
    {class:"fas fa-user-plus", name:"add, avatar, positive, sign up, signup, team", icon:"userPlus"},
    {class:"fas fa-user-secret", name:"clothing, coat, hat, incognito, person, privacy, spy, whisper", icon:"userSecret"},
    {class:"fas fa-user-shield", name:"admin, person, private, protect, safe", icon:"userShield"},
    {class:"fas fa-user-slash", name:"ban, delete, remove", icon:"userSlash"},
    {class:"fas fa-user-tag", name:"avatar, discount, label, person, role, special", icon:"userTag"},
    {class:"fas fa-user-tie", name:"avatar, business, clothing, formal, professional, suit", icon:"userTie"},
    {class:"fas fa-user-times", name:"archive, delete, remove, x", icon:"userTimes"},
    {class:"fas fa-users", name:"friends, group, people, persons, profiles, team", icon:"users"},
    {class:"fas fa-users-cog", name:"admin, cog, group, person, settings, team", icon:"usersCog"},
    {class:"fas fa-users-slash", name:"disband, friends, group, people, persons, profiles, separate, team, ungroup", icon:"usersSlash"},
    {class:"fas fa-utensil-spoon", name:"cutlery, dining, scoop, silverware, spoon", icon:"utensilSpoon"},
    {class:"fas fa-utensils", name:"cutlery, dining, dinner, eat, food, fork, knife, restaurant", icon:"utensils"},
    {class:"fas fa-vector-square", name:"anchors, lines, object, render, shape", icon:"vectorSquare"},
    {class:"fas fa-venus", name:"female", icon:"venus"},
    {class:"fas fa-venus-double", name:"female", icon:"venusDouble"},
    {class:"fas fa-venus-mars", name:"Gender", icon:"venusMars"},
    {class:"fas fa-vest", name:"biker, fashion, style", icon:"vest"},
    {class:"fas fa-vest-patches", name:"biker, fashion, style", icon:"vestPatches"},
    {class:"fas fa-vial", name:"experiment, lab, sample, science, test, test tube", icon:"vial"},
    {class:"fas fa-vials", name:"experiment, lab, sample, science, test, test tube", icon:"vials"},
    {class:"fas fa-video", name:"camera, film, movie, record, video-camera", icon:"video"},
    {class:"fas fa-video-slash", name:"add, create, film, new, positive, record, video", icon:"videoSlash"},
    {class:"fas fa-vihara", name:"buddhism, buddhist, building, monastery", icon:"vihara"},
    {class:"fas fa-virus", name:"bug, covid-19, flu, health, sick, viral", icon:"virus"},
    {class:"fas fa-virus-slash", name:"bug, covid-19, cure, eliminate, flu, health, sick, viral", icon:"virusSlash"},
    {class:"fas fa-viruses", name:"bugs, covid-19, flu, health, multiply, sick, spread, viral", icon:"viruses"},
    {class:"fas fa-voicemail", name:"answer, inbox, message, phone", icon:"voicemail"},
    {class:"fas fa-volleyball-ball", name:"beach, olympics, sport", icon:"volleyballBall"},
    {class:"fas fa-volume-down", name:"audio, lower, music, quieter, sound, speaker", icon:"volumeDown"},
    {class:"fas fa-volume-mute", name:"audio, music, quiet, sound, speaker", icon:"volumeMute"},
    {class:"fas fa-volume-off", name:"audio, ban, music, mute, quiet, silent, sound", icon:"volumeOff"},
    {class:"fas fa-volume-up", name:"audio, higher, louder, music, sound, speaker", icon:"volumeUp"},
    {class:"fas fa-vote-yea", name:"accept, cast, election, politics, positive, yes", icon:"voteYea"},
    {class:"fas fa-vr-cardboard", name:"3d, augment, google, reality, virtual", icon:"vrCardboard"},
    {class:"fas fa-walking", name:"exercise, health, pedometer, person, steps", icon:"walking"},
    {class:"fas fa-wallet", name:"billfold, cash, currency, money", icon:"wallet"},
    {class:"fas fa-warehouse", name:"building, capacity, garage, inventory, storage", icon:"warehouse"},
    {class:"fas fa-water", name:"lake, liquid, ocean, sea, swim, wet", icon:"water"},
    {class:"fas fa-wave-square", name:"frequency, pulse, signal", icon:"waveSquare"},
    {class:"fas fa-weight", name:"health, measurement, scale, weight", icon:"weight"},
    {class:"fas fa-weight-hanging", name:"anvil, heavy, measurement", icon:"weightHanging"},
    {class:"fas fa-wheelchair", name:"accessible, handicap, person", icon:"wheelchair"},
    {class:"fas fa-wifi", name:"connection, hotspot, internet, network, wireless", icon:"wifi"},
    {class:"fas fa-wind", name:"air, blow, breeze, fall, seasonal, weather", icon:"wind"},
    {class:"fas fa-window-close", name:"browser, cancel, computer, development", icon:"solidWindowClose"},
    {class:"fas fa-window-maximize", name:"browser, computer, development, expand", icon:"solidWindowMaximize"},
    {class:"fas fa-window-minimize", name:"browser, collapse, computer, development", icon:"solidWindowMinimize"},
    {class:"fas fa-window-restore", name:"browser, computer, development", icon:"solidWindowRestore"},
    {class:"fas fa-wine-bottle", name:"alcohol, beverage, cabernet, drink, glass, grapes, merlot, sauvignon", icon:"wineBottle"},
    {class:"fas fa-wine-glass", name:"alcohol, beverage, cabernet, drink, grapes, merlot, sauvignon", icon:"wineGlass"},
    {class:"fas fa-wine-glass-alt", name:"alcohol, beverage, cabernet, drink, grapes, merlot, sauvignon", icon:"wineGlassAlt"},
    {class:"fas fa-won-sign", name:"currency, krw, money", icon:"wonSign"},
    {class:"fas fa-wrench", name:"construction, fix, mechanic, plumbing, settings, spanner, tool, update", icon:"wrench"},
    {class:"fas fa-x-ray", name:"health, medical, radiological images, radiology, skeleton", icon:"xRay"},
    {class:"fas fa-yen-sign", name:"currency, jpy, money", icon:"yenSign"},
    {class:"fas fa-yin-yang", name:"daoism, opposites, taoism", icon:"yinYang"},
  ];

  constructor() {
    this.filteredStates = this.stateCtrl.valueChanges.pipe(
      startWith(''),
      map(state => (state ? this._filterStates(state) : this.states.slice())),
    );

  }

  select(event) {
    if (this.states.filter(s => s.icon === event)[0] != null){
      this.selected = this.states.filter(s => s.icon === event)[0].class;
    }

    this.iconChange.emit(event);
  }

  private _filterStates(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.states.filter(state => state.name.toLowerCase().includes(filterValue));
  }

  ngOnInit(): void {
  }

}

import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {GameThemeService} from "./game-theme.service";
import {AuthActionTypes} from "../../ngrx-auth/store/ngrx-auth.actions";
import {filter, map, mergeMap, withLatestFrom} from "rxjs/operators";

import {GameThemeApiActions} from "./game-theme.actions";
import {getInSyncTillMy} from "./game-theme.selectors";


@Injectable()
export class GameThemeEffectsLoadMyThemes {

  constructor(private actions$: Actions,
              private store: Store<State>,
              private gameThemeService: GameThemeService,
  ) {
  }


  getMyThemes = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.Authenticated),
      withLatestFrom(
        this.store.select(getInSyncTillMy)
      ),
      mergeMap(([action, inSyncTill]: [any, number]) => this.gameThemeService.listAllMy(inSyncTill).pipe(
        map(themeList => GameThemeApiActions.getMyThemeListSuccess({themeList}))
      ))
    )
  );


  resumeGetMyThemes = createEffect(
    () => this.actions$.pipe(
      ofType(GameThemeApiActions.getMyThemeListSuccess),
      filter((action) => !!action &&
        !!action.themeList && !!action.themeList.resumptionToken && (action.themeList.themes || []).length != 0),
      mergeMap(
        (action) => this.gameThemeService.listAll(action.themeList.from, action.themeList.resumptionToken).pipe(
          map(themeList => GameThemeApiActions.getMyThemeListSuccess({themeList}))
        ),
      )
    )
  )

  updateInSyncTill = createEffect(
    () => this.actions$.pipe(
      ofType(GameThemeApiActions.getMyThemeListSuccess),
      filter((action) => !!action &&
        !!action.themeList && (!action.themeList.resumptionToken || (action.themeList.themes || []).length == 0)),
      map(action => GameThemeApiActions.updateMyThemeListSyncTime({time: action.themeList.serverTime - 120000}))
    )
  )

}

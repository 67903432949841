import * as fromRoot from "../../core/reducers";
import {createFeatureSelector, createSelector} from "@ngrx/store";

import {RunUserState} from "./run-players.state";
import {runUserAdapter} from "./run-players.reducer";
import * as fromRootSelector from "../../core/selectors/router.selector";
import {getUser} from "../../ngrx-auth/store/ngrx-auth.selectors";

export interface State extends fromRoot.State {
  game: RunUserState;
}

export const getRunUsersFeature = createFeatureSelector<RunUserState>('run-players');
export const getRunUser = createSelector(getRunUsersFeature, (state: RunUserState) => state.players);
export const getInSyncTill = createSelector(getRunUsersFeature, (state: RunUserState) => state.lastSyncTime);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = runUserAdapter.getSelectors(getRunUser);

export const currentRunPlayersNew = createSelector(
  selectAll,
  fromRootSelector.selectRouteParam('runId'),
  (players, runId) => players
    .filter(p => (p.runId + '' === runId) && !p.deleted)
);


export const currentGamePlayers = createSelector(
  selectAll,
  fromRootSelector.selectRouteParam('gameId'),
  getUser,
  (players, gameId, user) => {
    if (user == null) {
      return [];
    }
    return players
      .filter(s => s.localId === user.uid)
      .filter(p => (p.gameId + '' === gameId) && !p.deleted)

  }
);

export const allMyPlayerGameIds = createSelector(selectAll, getUser,
  (playerStatements, user) => {
    if (user == null) {
      return [];
    }
    return playerStatements
      .filter(p => !p.deleted)
      .filter(s => s.localId === user.uid)
      .map(s => s.gameId + '');
  });



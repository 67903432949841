import {TutorialApiActions, TutorialPageActions} from './tutorial.actions';
import {initialTutorialState, TutorialState,} from './tutorial.state';
import {Action, createReducer, on} from "@ngrx/store";

const reducer = createReducer(
  initialTutorialState,
  on(TutorialApiActions.addGame,
    (state, action) => action.faq ?
      ({
        ...state,
        faqGames: [action.game, ...state.faqGames.filter(game => game.gameId !== action.game.gameId)],
        videoGames: state.videoGames,
        messages: state.messages
      }) :
      ({
        ...state,
        videoGames: [action.game, ...state.videoGames.filter(game => game.gameId !== action.game.gameId)],
        faqGames: state.faqGames,
        messages: state.messages
      })
  ),
  on(TutorialApiActions.addItems, (state, action) => ({
    ...state,
    messages: [...action.messages, ...state.messages.filter(x => !action.messages.some(y => y.id === x.id))]
  })),
  on(TutorialPageActions.clearMessages, (state, action) => ({...state, messages: []})),
  on(TutorialPageActions.selectVideoCategory, (state, action) => ({...state, selectedVideoCategory: action.category}))
);

export function tutorialReducer(
  state: TutorialState | undefined,
  action: Action
): TutorialState {
  return reducer(state, action);
}

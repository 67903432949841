import {Component, Input, OnInit} from '@angular/core';
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {GameMessagePageActions} from "../../../../store/game-message.actions";
import {Store} from "@ngrx/store";
import {State} from "../../../../../core/reducers";
import {SelectAssetComponent} from "../../../../../portal-image-management/modals/select-asset.component";
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {canWriteCurrentGame} from "../../../../../game-authors/store/game-author.selectors";

@Component({
  selector: 'app-answer-component',
  template: `
    <div>

      <mat-form-field class="mb-3 w-100">


        <input appTriggerMobileView [data]="{}" [name]="'mc'" matInput [placeholder]="'GAME.TITLE' |translate"
               [disabled]="!(iCanWrite|async)"

               [ngModel]="answer.answer"
               (ngModelChange)="answerChange($event)">
      </mat-form-field>


      <mat-slide-toggle
        class="mb-3 w-100 color-black-8a"
        [disabled]="!(iCanWrite|async)"
        [ngModel]="answer.isCorrect"
        [labelPosition]="'after'"
        (change)="isCorrectChange($event)">{{'MESSAGE.OPTION_CORRECT'|translate}}
      </mat-slide-toggle>



      <mat-form-field
        class="mb-3 w-100"
        *ngIf="feedback">

        <input appTriggerMobileView [data]="answer" [name]="'answer'" matInput placeholder="Feedback"
               [disabled]="!feedback"
               [disabled]="!(iCanWrite|async) "

               [ngModel]="answer.feedback"
               (ngModelChange)="feedbackChange($event)">
      </mat-form-field>

<!--      <div class="pos-answer-image-text font-regular-11-15-roboto color-black-8a" *ngIf="feedback">-->
<!--        {{'MESSAGE.FEEDBACK_IMAGE' |translate}}-->
<!--      </div>-->
<!--      <div class="pos-feedback-image-container gl-pos-field-full-width gl-pos-between-fields" *ngIf="feedback">-->
<!--        <div class="pos-feedback-image">-->
<!--          <app-filestore-background-image-->
<!--            *ngIf="fileRefs[answer.isCorrect?'correct':'wrong']"-->
<!--            [paths]="[fileRefs[answer.isCorrect?'correct':'wrong']]"-->
<!--            [deleteButton]="false"-->

<!--          >-->
<!--            <ng-content></ng-content>-->
<!--          </app-filestore-background-image>-->
<!--          <div-->
<!--            class="pos-feedback-image pos-answer-no-image"-->
<!--            *ngIf="!fileRefs[answer.isCorrect?'correct':'wrong']"-->
<!--          >-->
<!--&lt;!&ndash;            <div class="pos-icon-image">&ndash;&gt;-->
<!--&lt;!&ndash;              <svg xmlns="http://www.w3.org/2000/svg"&ndash;&gt;-->
<!--&lt;!&ndash;                   width="70" height="44" viewBox="0 0 144 92">&ndash;&gt;-->
<!--&lt;!&ndash;                <defs>&ndash;&gt;-->
<!--&lt;!&ndash;                  <style>.a {&ndash;&gt;-->
<!--&lt;!&ndash;                    fill: #0000008A;&ndash;&gt;-->
<!--&lt;!&ndash;                  }</style>&ndash;&gt;-->
<!--&lt;!&ndash;                </defs>&ndash;&gt;-->
<!--&lt;!&ndash;                <path class="a"&ndash;&gt;-->
<!--&lt;!&ndash;                      d="M117.675,38.717c0-.383.064-.767.064-1.15A37.1,37.1,0,0,0,47.025,21.4,19.087,19.087,0,0,0,19.511,35.458,29.082,29.082,0,0,0,28.768,92H61.714V66.444H46.221L72,39.707,97.779,66.412H82.286V91.968h35.454a26.628,26.628,0,0,0-.064-53.251Z"/>&ndash;&gt;-->
<!--&lt;!&ndash;              </svg>&ndash;&gt;-->


<!--&lt;!&ndash;            </div>&ndash;&gt;-->

<!--          </div>-->
<!--        </div>-->
<!--        <div class="pos-feedback-image-instruction font-regular-16-24-roboto color-black-de">-->
<!--          <div>{{'MESSAGE.DRAG_OR_ADD_IMAGE'|translate}}</div>-->
<!--          <button mat-stroked-button-->
<!--                  class="pos-select-image-button"-->
<!--                  color="primary"-->
<!--                  (click)="selectFeedbackImage(answer.isCorrect?'correct':'wrong')">{{'COMMON.BROWSE'|translate}}-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="pos-horizontal-line black-color gl-pos-between-fields"></div>-->

    </div>

  `
})
export class AnswerComponentComponent implements OnInit {

  @Input() index: number;
  @Input() imageAnswer = false;
  @Input() answer;
  @Input() fileRefs = {};
  @Input() feedback = false;
  public iCanWrite: Observable<boolean> = this.store.select(canWriteCurrentGame);

  constructor(
    private store: Store<State>,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
  }

  answerChange(event: any) {
    console.log("answer", event);
    this.store.dispatch(GameMessagePageActions.updateAnswer({
      delete: false,
      id: this.answer.id,
      field: "answer",
      value: event
    }));
  }

  feedbackChange(event: any) {
    this.store.dispatch(GameMessagePageActions.updateAnswer({
      delete: false,
      id: this.answer.id,
      field: "feedback",
      value: event
    }));
  }

  isCorrectChange(event: MatSlideToggleChange) {
    this.store.dispatch(GameMessagePageActions.updateAnswer({
      delete: false,
      id: this.answer.id,
      field: "isCorrect",
      value: event.checked
    }));
  }

  remove() {
    this.store.dispatch(GameMessagePageActions.updateAnswer({
      delete: true,
      id: this.answer.id
    }));

  }

  selectAnswerImage() {
    const dialogRef = this.dialog.open(SelectAssetComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("image select result", result);
      this.store.dispatch(GameMessagePageActions.updateFileRef({
        delete: false,
        key: this.answer.id,
        value: result

      }));
    });
  }


  selectFeedbackImage(key) {
    const dialogRef = this.dialog.open(SelectAssetComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(GameMessagePageActions.updateFileRef({
        delete: false,
        key: key,
        value: result

      }));
    });
  }
}


import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {RunResponse} from "../store/run-responses.state";
import {currentItemFilteredUsersResponses} from "../store/run-responses.selectors";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {selectEntities} from "../../player-management/store/player.selector";
import {getDownloadURL} from "@firebase/storage";
import {ref, Storage} from "@angular/fire/storage";
import {saveAs} from "file-saver";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-audio-gallery',
  template: `
    <div class="audio-gallery" *ngIf="responses$ |async as responses">
      <div class="audio-gallery__wrapper" *ngIf="!loading">
        <div class="audio-item" *ngFor="let response of responses" [id]="response.id">
          <div class="d-flex flex-column justify-content-between h-100">

            <div class="d-flex flex-row h-100">
            <span

            class="flex-grow-1 audio-item__title">{{"MESSAGE.RECORDING" |translate}}</span>
              <div>
                <button
                  class="cursor-pointer"
                  (click)="download(response)"
                  mat-icon-button
                  >
                  <mat-icon>file_download</mat-icon>
                </button>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center">
              <i class="fas fa-waveform audio-wave"></i>
            </div>

            <app-audio-file-player
              *ngIf="response"
              [response]="response"></app-audio-file-player>

          </div>
          <div class="user-placeholder" *ngIf="players$ | async as playersDict">
            <div class="user" *ngIf="playersDict[response.userId] as player; else loadingplayer">
              <div class="user__avatar">{{player.shortAvatar}}</div>
              <div class="user__name">{{player.name}}</div>
            </div>
            <ng-template #loadingplayer>
              <app-loading-player-for-response
                [userId]="res.userId"
              ></app-loading-player-for-response>
            </ng-template>
          </div>


        </div>
      </div>
      <div class="load-wrapper" *ngIf="loading">
        <span>{{'COMMON.LOADING'| translate}}</span>
      </div>
    </div>
  `,
})
export class AudioGalleryComponent {
  public players$: Observable<any> = this.store.select(selectEntities);
  public responses$: Observable<RunResponse[]> = this.store.select(currentItemFilteredUsersResponses);

  public loading = false;

  constructor(
    private store: Store<State>,
    private http: HttpClient,
    public storage: Storage) {
  }

  async download(response) {
    console.log('dl', response.responseValue);
    const audioFile = await getDownloadURL(ref(this.storage, response.responseValue));
    console.log('dl', audioFile);
    // const link = await  getDownloadURL(ref(this.storage, response.responseValue));
    this.http.get(audioFile, {responseType: "blob", headers: {'Accept': 'audio/mp3'}})
      .subscribe(blob => {
        saveAs(blob, 'download.mp3');
      });


    // console.log('dl', response.responseValue);
    // const audioFile = await getDownloadURL(ref(this.storage, response.responseValue));
    // console.log('dl', audioFile);
    // window.open(audioFile);
  }
}

import {
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
    ElementRef,
    OnInit,
    Attribute, OnDestroy
} from '@angular/core';
import {getUser} from "../store/ngrx-auth.selectors";
import {User} from '../store/ngrx-auth.state';
import {Store} from "@ngrx/store";

import {Subscription} from "rxjs";
import {State} from "src/app/core/reducers";

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit, OnDestroy {
    private permissions = [];
    private isHidden = true;
    private currentUser: User;
    subscription: Subscription;

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private store: Store<State>
    ) {
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngOnInit() {
        this.subscription = this.store.select(getUser).subscribe(user => {
            this.currentUser = user;
            this.updateView();
        });
    }

    @Input()
    set hasPermission(val) {
        this.permissions = val;
        this.updateView();
    }

    private updateView() {
        if (this.checkPermission()) {
            if (this.isHidden) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isHidden = false;
            }
        } else {
            this.isHidden = true;
            this.viewContainer.clear();
        }
    }

    private checkPermission() {
        let hasPermission = false;
        if (this.currentUser && this.currentUser.claims) {
            for (const checkPermission of this.permissions) {

                const permissionFound = this.currentUser.claims[checkPermission];
                if (!permissionFound) {
                    hasPermission = false;
                    break;
                }
            }
        }

        return hasPermission;
    }
}

import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {GameAuthorService} from "./game-author.service";
import {catchError, filter, map, mergeMap, withLatestFrom} from "rxjs/operators";
import {gameIdSelector} from "../../core/selectors/router.selector";
import {GameAuthorActions, GameAuthorApiActions} from "./game-author.actions";
import {GameApiActions} from "../../games-management/store/game.actions";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {of} from "rxjs";
import {ErrorActions} from "../../shared/store/shared.actions";

const translationStrings = {
  'add-author-403': 'httpexception.can-not-update-rights'
}


@Injectable()
export class GameAuthorEffects {
  constructor(
    private gameAuthorService: GameAuthorService,
    private actions$: Actions,
    private router: Router,
    private store$: Store<State>
  ) {
  }

  loadAuthorsForGame = createEffect(
    () => this.actions$.pipe(
      ofType(GameAuthorActions.loadGameAuthors, GameApiActions.createGameSuccess),
      withLatestFrom(this.store$.select(gameIdSelector)),
      filter(([_, gameId]) => !!gameId),
      mergeMap(([_, gameId]) => this.gameAuthorService.loadAuthors(gameId)),
      map(gameAccessList => GameAuthorApiActions.addGameAuthorsWithoutResumption({gameAccessList})),
    ));



  addAuthors = createEffect(
    () => this.actions$.pipe(ofType(GameAuthorActions.addGameAuthor),
      withLatestFrom(this.store$.select(getCurrentGame)),
      mergeMap(([action, game]) =>
        this.gameAuthorService.addAuthors(game.gameId, action.fullId, action.role).pipe(
          map(gameAuthor => GameAuthorApiActions.addGameAuthorCompleted({gameAuthor})),
          catchError((e) => of(
            ErrorActions.http({
              ...e.error.error,
              time: Date.now(),
              translation: translationStrings['add-author-' + (e.error.error.code)]
            }))
          ),
          // catchError((error) => of(new actions.CurrentGameErrorAction({error: error})))
        )
      )
    ));

  removeAuthorAccess = createEffect(
    () => this.actions$.pipe(
      ofType(GameAuthorActions.deleteGameAuthor),
      withLatestFrom(this.store$.select(getCurrentGame)),
      mergeMap(
        ([action, game]) => this.gameAuthorService.revokeAuthors(game.gameId, action.fullId).pipe(
          map(gameAuthor => GameAuthorApiActions.removeGameAuthorCompleted({gameAuthor}))
        )
      )
    ));

}

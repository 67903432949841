import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {State} from '../../../core/reducers';
import {MatDialog} from '@angular/material/dialog';
import {PlayerPageActions} from '../../store/player.actions';
import {FriendAddDialogComponent} from '../../components/friend-add-dialog/friend-add-dialog.component';
import {getAllPending, getFilteredPlayersSelector} from '../../store/player.selector';
import {Observable, Subscription} from "rxjs";


@Component({
  selector: 'app-player-overview',
  template: `
    <app-top-level-navbar [title]="'CONTACT.CONTACTS'">
      <div class="button-placeholder">
        <div class="button-center">
          <button color="accent" mat-fab (click)="addContact()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <app-subtabs-navbar [items]="subMenuItems"></app-subtabs-navbar>
    </app-top-level-navbar>
    <div class="full-width-container">
      <div class="maxwidth">
        <div class="bibendo-center-outer">
          <app-search-button
            [placeholder]="'CONTACT.START_TYPING_TO_SEARCH' | translate"
            [dispatchActionFunction]="dispatchActionFunction"
            [filter]="filter"
          >
          </app-search-button>

          <div class="bibendo-center">
            <div class="connection-tile pending" *ngFor="let player of (allPending|async)">

              <app-connection-tile
                [pending]="true" [player]="player"
                (removeInvitation)="removeInvitation(player)"
                (reinvite)="reinvite(player)"
                (remove)="removeConnection(player)"></app-connection-tile>
            </div>

            <div class="connection-tile" *ngFor="let player of (players|async)">
              <app-connection-tile
                [pending]="false"
                [player]="player"

                (remove)="removeConnection(player)"></app-connection-tile>

            </div>


          </div>

        </div>
      </div>
    </div>

  `
})
export class PlayerOverviewComponent implements OnInit, OnDestroy {

  public players: Observable<any> = this.store.select(getFilteredPlayersSelector);

  allPending: Observable<any> = this.store.select(getAllPending);

  public filter: string;
  public subscription = new Subscription();
  public dispatchActionFunction = (filters: string[]) => {
    this.store.dispatch(PlayerPageActions.setFilter({filters}));
  }

  subMenuItems = [
    {
      routerLink: '/connections/list',
      label: 'CONTACT.ALLCONTACTS'
    },
    {
      routerLink: '/connections/pending',
      label: 'CONTACT.PENDING'
    },
  ];

  constructor(
    private store: Store<State>,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.store.dispatch(PlayerPageActions.loadPending());

  }

  addContact() {
    const dialogRef = this.dialog.open(FriendAddDialogComponent, {
      data: {email: ''},
      panelClass: ['modal-fullscreen', "modal-dialog"]
    });

    this.subscription.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(PlayerPageActions.inviteContact(result));
      }
    }));
  }

  removeConnection(player) {
    this.store.dispatch(PlayerPageActions.removeContact({player}));
  }

  removeInvitation(invitation) {

    this.store.dispatch(PlayerPageActions.removePendingInvitation({invitationId: invitation.localId}));

  }

  reinvite(invitation) {

    this.store.dispatch(PlayerPageActions.resendInvitation({invitationId: invitation.localId}));

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

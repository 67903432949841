import {NgModule} from '@angular/core';
import {PlayerOverviewComponent} from './pages/player-overview/player-overview.component';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {StoreModule} from '@ngrx/store';
import {reducers} from './store/player.reducer';
import {EffectsModule} from '@ngrx/effects';
import {PlayerEffects} from './store/player.effects';
import {FriendAddDialogComponent} from './components/friend-add-dialog/friend-add-dialog.component';
import {PendingPlayersOverviewComponent} from './pages/pending-players-overview/pending-players-overview.component';
import {InvitationsTableComponent} from './components/invitations-table/invitations-table.component';
import {UserManagementModule} from "../user-management/user-management.module";
import {AccountEffectsLoadContacts} from "./store/account.effects.load-contacts";

@NgModule({
    imports: [
        SharedModule.forRoot(),
        StoreModule.forFeature('accounts', reducers),
        EffectsModule.forFeature([PlayerEffects, AccountEffectsLoadContacts]),
        UserManagementModule,
        // PlayerManagementRoutingModule,
        MatMenuModule,
        MatTableModule, MatPaginatorModule, MatSortModule,
        RouterModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatDialogModule,
    ],
    entryComponents: [FriendAddDialogComponent],
    exports: [PlayerOverviewComponent],
    declarations: [PlayerOverviewComponent, FriendAddDialogComponent,
        PendingPlayersOverviewComponent, InvitationsTableComponent]
})
export class PlayerManagementModule {
}

import * as fromRoot from "../../core/reducers";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {runAuthorAdapter, RunAuthorState} from "./run-author.reducer";
import * as fromRootSelector from "../../core/selectors/router.selector";
import {runIdSelector} from "../../core/selectors/router.selector";
import {getPortalUser} from "../../player-management/store/player.selector";
import {getUser} from "../../ngrx-auth/store/ngrx-auth.selectors";

export interface State extends fromRoot.State {
  game: RunAuthorState;
}

export const getRunAuthorsFeature = createFeatureSelector<RunAuthorState>('run-authors');
export const getRunAuthor = createSelector(getRunAuthorsFeature, (state: RunAuthorState) => state);
export const getInSyncTill = createSelector(getRunAuthorsFeature, (state: RunAuthorState) => state.lastSyncTime);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = runAuthorAdapter.getSelectors(getRunAuthor);

export const currentRunOwners = createSelector(selectAll, runIdSelector,
  (authorStatements,  runId) => {
  return authorStatements
    .filter(s => s.accessRights > 0)
    .filter(s => s.runId +'' === runId);
  })


export const getMyRunAccess = createSelector(
  selectEntities,
  getPortalUser,
  fromRootSelector.selectRouteParam('runId'),
  (runCollaborators, me, runId) => {
    if (!me) return undefined;
    return runCollaborators[me.fullId + ':' + runId];
  }
);


export const currentGameAccess = createSelector(
  selectAll,
  fromRootSelector.selectRouteParam('gameId'),
  getUser,
  (players, gameId, user) =>  {
    if (user == null) {
      return [];
    }
    return players
      .filter(s => s.account.indexOf(user.uid) !== -1)
      .filter(s => (s.gameId + '' === gameId) && s.accessRights > 0)
  }

);


export const allMyRunAuthorGameIds = createSelector(selectAll, getUser,
  (authorStatements, user) => {
  if (user == null) {
    return [];
  }
return authorStatements
  .filter(s => s.accessRights > 0)
  .filter(s => s.account.indexOf(user.uid) !== -1)
  .map(s => s.gameId+ '') as any as number[];
});


export const allMyOwnedRunsCurrentGame = createSelector(selectAll, getUser, fromRootSelector.selectRouteParam('gameId'),
  (authorStatements, user, gameId) => {
    if (user == null) {
      return [];
    }
    return authorStatements
      .filter(s => (s.gameId + '' === gameId) && s.accessRights == 1)
      .filter(s => s.account.indexOf(user.uid) !== -1)
      .map(s => s.runId);
  });

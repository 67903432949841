import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';

@Component({
  selector: 'app-preview-smartphone',
  template: `
    <div
      class="smartphone-new">
      <div [ngStyle]="style">
        <div #contentSizeIdentifier>
          <ng-content></ng-content>
        </div>
      </div>
    </div>

  `,
  styles: []
})
export class PreviewSmartphoneComponent implements AfterViewInit, OnChanges {
  @Input() scale = 1;

  width;
  height;
  @ViewChild('contentSizeIdentifier')
  contentSizeIdentifier: ElementRef;

  style: any = {};

  ngAfterViewInit() {
    this.width = this.contentSizeIdentifier.nativeElement.offsetWidth;
    this.height = this.contentSizeIdentifier.nativeElement.offsetHeight;

    this.style = {
      'width' : (this.width * this.scale) +"px",
      'height' : (this.height * this.scale)+"px",
    };

  }

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scale) {
      // var width = this.contentSizeIdentifier.nativeElement.offsetWidth;
      // var height = this.contentSizeIdentifier.nativeElement.offsetHeight;

      this.style = {
        'width' : (this.width * this.scale) +"px",
        'height' : (this.height * this.scale)+"px",
      };
    }
  }


}

import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as selector from 'src/app/core/selectors/router.selector';
import {runIdSelector} from 'src/app/core/selectors/router.selector';
import {GameRunsApiActions, GameRunsPageActions} from './game-runs.actions';
import {State} from 'src/app/core/reducers';
import {catchError, distinct, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {SetErrorAction} from '../../shared/store/shared.actions';
import {currentRunWithEdits} from './game-runs.selector';
import {GameRunsService} from "./game-runs.service";
import {createResetAfterAuthenticatedEffect} from "../../shared/util/effects-util";

@Injectable()
export class GameRunsEffects {
  constructor(
    private actions$: Actions,
    private gameRunsService: GameRunsService,
    private store$: Store<State>
  ) {
  }

  getRunFromRouter = createResetAfterAuthenticatedEffect(
    this.actions$,
    this.actions$.pipe(
      ofType(GameRunsPageActions.getRun),
      withLatestFrom(this.store$.select(runIdSelector)),
      distinct(([action, runId]) => action.runId || runId),
      mergeMap(([action, runId]) =>
        this.gameRunsService.get(action.runId || runId).pipe(
          map(run => (GameRunsApiActions.addRun({run}))),
          catchError((error) => {
            return of(new SetErrorAction(error.error))
          })
        )
      )
    ));

  // getRunsCursor = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(GameRunsActionTypes.GAME_RUNS_CURSOR_REQUEST),
  //     map((action: any) => action),
  //     switchMap((action: any) =>
  //       this.gameRunsService.listMyRuns(action.payload.gameId, action.payload.cursor).pipe(
  //         mergeMap(res => {
  //             if (res.resumptionToken != null) {
  //               return [
  //                 new GetGameRunsCompletedAction(
  //                   {gameId: action.payload.gameId, items: res.runs}
  //                 ),
  //                 new GetGameRunsCursorListRequestionAction({
  //                     gameId: action.payload.gameId,
  //                     cursor: res.resumptionToken
  //                   }
  //                 )
  //               ];
  //             }
  //             return [
  //               new GetGameRunsCompletedAction(
  //                 {gameId: action.payload.gameId, items: res.runs}
  //               )
  //             ];
  //           }
  //         ),
  //         catchError((error) => of(new SetErrorAction(error.error)))
  //       )
  //     )
  //   ));

  createRun = createEffect(
    () => this.actions$.pipe(
      ofType(GameRunsPageActions.createRun),
      withLatestFrom(this.store$.select(selector.selectRouteParam('gameId'))),
      mergeMap(
        ([action, gameId]) =>
          this.gameRunsService.createRun({
            ...action.run,
            gameId: Number.parseInt(gameId, 10)
          }).pipe(
            map(run => GameRunsApiActions.addRun({run})),
            catchError((error) => of(new SetErrorAction(error.error)))
          )
      )
    ));

  saveRun = createEffect(
    () => this.actions$.pipe(
      ofType(GameRunsPageActions.saveRun),
      withLatestFrom(this.store$.select(currentRunWithEdits)),
      mergeMap(
        ([_, run]) =>
          this.gameRunsService.createRun(run).pipe(
            map(run => GameRunsApiActions.addRun({run}))
          )
      )
    ));

  deleteRun = createEffect(
    () => this.actions$.pipe(
      ofType(GameRunsPageActions.deleteRun),
      mergeMap(
        (action) =>
          this.gameRunsService.deleteRun(action.run.runId).pipe(
            catchError((error) => of(new SetErrorAction(error.error)))
          )
      )
    ));


}

import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";

import {MatSelectChange} from "@angular/material/select";
import {ownsCurrentGame} from "../../../game-authors/store/game-author.selectors";

@Component({
  selector: 'app-game-detail-message-list-type',
  template: `
    <div class="w-100 mt-5">
      <mat-form-field class="w-100">
        <mat-label>{{'GAME.MESSAGE_LIST_TYPE'|translate}}</mat-label>
        <mat-select
          [disabled]="!(iAmOwner|async)"
          [(ngModel)]="messageListTypes"
          multiple
          (selectionChange)="changeBoard($event)"
        >
          <mat-option  [value]="1">{{'GAME.LISTTYPE.BOARD' | translate}}</mat-option>
          <mat-option  [value]="2">{{'GAME.LISTTYPE.MESSAGE_LIST' | translate}}</mat-option>
          <mat-option  [value]="3">{{'GAME.LISTTYPE.MAP' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
  styles: [
  ]
})
export class GameDetailMessageListTypeComponent implements OnChanges {

  messageListTypes = [2];
  @Input()  messageListTypesString = "";
  public iAmOwner: Observable<boolean> = this.store.select(ownsCurrentGame);
  @Output() changeListType = new EventEmitter<string>();


  constructor(public store: Store<State>) {
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
      this.messageListTypes = (this.messageListTypesString || "").split(',').map(s=> Number.parseInt(s,10))
  }

  changeBoard(event: MatSelectChange) {
    this.changeListType.emit(event.value.join(','))

    // this.store.dispatch(new GameUpdateAction({
    //   messageListTypes: event.value.join(',')
    // }));
  }
}

import {NgModule} from '@angular/core';
import {GamesListComponent} from './pages/games-list/games-list.component';
import {GameEffects} from './store/game.effects';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {gamesReducer} from './store/game.reducer';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {GameManagementModule} from '../game-management/game-management.module';
import {NewGameComponent} from './modal/new-game/new-game.component';
import {
  NewGamePatternTileComponent
} from './modal/new-game/components/new-game-pattern-tile/new-game-pattern-tile.component';
import {
  CreateGameWithTitleComponent
} from './modal/new-game/components/create-game-with-title/create-game-with-title.component';
import {MatMenuModule} from "@angular/material/menu";
import {
  NewGameEntryScreenComponent
} from './modal/new-game/components/new-game-entry-screen/new-game-entry-screen.component';
import {
  GamePatternsContainerComponent
} from './modal/new-game/components/game-patterns-container/game-patterns-container.component';
import {GameMessagesModule} from "../game-messages/game-messages.module";
import {NgrxAuthModule} from "../ngrx-auth/ngrx-auth.module";
import {GameEffectsLoadGames} from "./store/game.effects.load-games";
import {NewGameTileComponent} from './components/new-game-tile.component';


// export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
//   return localStorageSync({
//      // keys: [{ game: ['list'] }]
//     keys: [ 'game' ]
//   })(reducer);
// }
//
// const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
    imports: [
        SharedModule.forRoot(),
        MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatButtonModule,
        MatDialogModule,
        StoreModule.forFeature('game', gamesReducer,
          // {metaReducers}
        ),
        EffectsModule.forFeature([GameEffects, GameEffectsLoadGames]),
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        RouterModule,
        GameManagementModule,
        GameMessagesModule,
        MatMenuModule,
      NgrxAuthModule
      // FirebaseNgrxUserManagementModule,
    ],
  entryComponents: [
    NewGameComponent
  ],
  exports: [GamesListComponent],
  declarations: [GamesListComponent,
    NewGameComponent,
    NewGamePatternTileComponent, CreateGameWithTitleComponent, NewGameEntryScreenComponent,
    GamePatternsContainerComponent,
    NewGameTileComponent]
})
export class GamesManagementModule {
}

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RunResponse} from "../store/run-responses.state";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Observable} from "rxjs";
import {selectEntities} from "../../player-management/store/player.selector";
import {ref, Storage} from "@angular/fire/storage";
import {getDownloadURL} from "@firebase/storage";
import {saveAs} from 'file-saver';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-photo-player-modal',
  template: `
    <div class="player " (keydown.arrowLeft)="prevImg()" (keydown.arrowRight)="nextImg()">
      <div class="player__image flex-grow-1">
        <div storageBackground
             class="w-100 h-100"
             [targetPath]="this.data.responses[this.data.selectedIdx].responseValue"
        ></div>
      </div>
      <div class="player__content">
        <div class="player__actions">
          <div class="player__arrows">
            <button mat-icon-button (click)="prevImg()"><i class="fas fa-arrow-left"></i></button>
            <button mat-icon-button (click)="download(this.data.responses[this.data.selectedIdx].responseValue)"><i class="fas fa-download"></i></button>
            <button mat-icon-button (click)="nextImg()"><i class="fas fa-arrow-right"></i></button>
          </div>
          <div class="player__close">
            <button mat-icon-button (click)="closeModal()"><i class="fas fa-times"></i></button>
          </div>
        </div>
        <div class="player__text">
          <div *ngIf="this.data.responses[this.data.selectedIdx].text">
            {{ this.data.responses[this.data.selectedIdx].text }}
          </div>

        </div>
        <div class="player__user" *ngIf="players$ | async as playersDict">
          <div class="user" *ngIf="playersDict[this.data.responses[this.data.selectedIdx].userId] as player">
            <div class="user__avatar">{{ player.shortAvatar }}</div>
            <div class="user__name">{{ player.name }}</div>
          </div>
        </div>
<!--        <div class="player__user">-->
<!--          <div class="user" *ngIf="users[images[selectedIdx]]">-->
<!--            <div class="user__avatar">{{ users[images[selectedIdx]].avatar }}</div>-->
<!--            <div class="user__name">{{ users[images[selectedIdx]].name }}</div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  `,
  styles: []
})
export class PhotoPlayerModalComponent {
  public players$: Observable<any> = this.store.select(selectEntities);

  constructor(
    private store: Store<State>,
    public storage: Storage,
    private http: HttpClient,
    public dialogRef: MatDialogRef<PhotoPlayerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      responses: RunResponse[],
      selectedIdx: number
    }
  ) {
    console.log('data is', data);
  }


  public prevImg() {
    if (this.data.selectedIdx === 0) {
      this.data.selectedIdx = this.data.responses.length - 1;
    } else {
      this.data.selectedIdx--;
    }
  }

  public nextImg() {
    if (this.data.selectedIdx === this.data.responses.length - 1) {
      this.data.selectedIdx = 0;
    } else {
      this.data.selectedIdx++;
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }

  async download(data) {
    const link = await  getDownloadURL(ref(this.storage, data));
    this.http.get(link, {responseType: "blob", headers: {'Accept': 'image/jpg'}})
      .subscribe(blob => {
        saveAs(blob, 'download.jpg');
      });
  //     saveAs(link, 'test.jpg', {
  // autoBom: false
  //     });
  // window.location.href=link+'?param=x';

  }
}

import {Injectable} from "@angular/core";
import {RunPlayerService} from "./run-players.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {RunPlayerApiActions, RunPlayerEffectActions} from "./run-players.actions";
import {filter, map, mergeMap, withLatestFrom} from "rxjs/operators";
import {getInSyncTill} from "./run-players.selectors";
import {AuthActionTypes} from "../../ngrx-auth/store/ngrx-auth.actions";


@Injectable()
export class RunPlayersEffectsLoadPlayers {
  constructor(
    private runPlayerService: RunPlayerService,
    private actions$: Actions,
    private router: Router,
    private store$: Store<State>
  ) {
  }

  loadAllRunPlayers = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.Authenticated),
      withLatestFrom(
        this.store$.select(getInSyncTill)
      ),
      mergeMap(
        ([action, inSyncTill]: [any, number]) => this.runPlayerService.listAllPlayers(inSyncTill).pipe(
          map(runList => RunPlayerApiActions.loadAllSuccess({runList}))
        )
      ))
  );



  resumeLoadAllRunPlayers = createEffect(
    () => this.actions$.pipe(
      ofType(RunPlayerApiActions.loadAllSuccess),
      filter((action) =>
        !!action && !!action.runList.resumptionToken && (action.runList.users || []).length != 0),
      mergeMap(
        (action) => this.runPlayerService.listAllPlayers(action.runList.from, action.runList.resumptionToken).pipe(
          map(runList => RunPlayerApiActions.loadAllSuccess({runList}))
        )
      )
    ));

  updateInSyncTill = createEffect(
    () => this.actions$.pipe(
      // ofType(RunUserActionTypes.LOAD_ALL_RUN_PLAYERS_COMPLETED),
      ofType(RunPlayerApiActions.loadAllSuccess),
      filter((action) =>
        !!action && !!action.runList && (!action.runList.resumptionToken || (action.runList.users || []).length == 0)),
      map(action => RunPlayerEffectActions.updateAllSyncTime({time: action.runList.serverTime-120000}))
    ));

}

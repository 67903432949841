import {createEffect, ofType} from "@ngrx/effects";
import {AuthActionTypes} from "../../ngrx-auth/store/ngrx-auth.actions";
import {switchMap, tap, throttle} from "rxjs/operators";
import {interval} from "rxjs";


export const createResetAfterAuthenticatedEffect = (actions, pipe) =>  createEffect(
  () => actions.pipe(
    ofType(AuthActionTypes.Authenticated),
    switchMap((action) => pipe)
  ));

import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {RunAccessList} from "./run-author.service";
import {RunAuthor} from "./run-author.state";


export const RunAuthorPageActions = createActionGroup({
  source: 'Run Authors Page',
  events: {
    'Load Run Authors Current Run': emptyProps(),
    'Grant collaborator access': props<{rights: string, author: string}>(),
    'Revoke collaborator access': props<{author: string, runId: number}>(),
  },
});

export const RunAuthorApiActions = createActionGroup({
  source: 'Run Authors Api',
  events: {
    'Load All Run Authors Completed': props<{runAccessList: RunAccessList}>(),
    'Add Run Authors without resumption': props<{runAccessList: RunAccessList}>(),
    'Add Run Author': props<{runAuthor: RunAuthor}>(),
    'Update Full last sync': props<{time: number}>(),
  },
});



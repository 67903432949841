import {createFeatureSelector, createSelector} from '@ngrx/store';
import {categoryAdapter, categoryMappingAdapter} from "./game-category.reducer";
import {GameCategoryState} from "./game-category.state";

const featureState = createFeatureSelector<GameCategoryState>('gameCategories');
const getCategories = (state: GameCategoryState) => state.categories;
const getAdapter = createSelector(featureState, getCategories);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = categoryAdapter.getSelectors(getAdapter);

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {getEditMessageSelector, selectedColor} from "../../store/game-message.selector";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";

@Component({
  selector: 'app-mobile-preview-combinationlock',
  template: `
    <app-background-image-selector>

      <div class="h-100 d-flex flex-column">
        <app-preview-navbar></app-preview-navbar>

        <div class="font-regular-16-24-roboto color-white p-1 "
             [ngStyle]="{'background-color': (selectedColor$ |async) }"
        >{{(message$|async)?.text}}</div>
        <div class="darken-background flex-grow-1 p-5 d-flex flex-column justify-content-end">
          <!--                <div class="make-dark">-->
          <div class="position-relative mb-5">
            <div class="combination-outer d-flex flex-row justify-content-around mb-5">

              <app-combination-entry
                *ngFor="let letter of combination"
                [letter]="letter"></app-combination-entry>
            </div>
          </div>
          <div
            [ngStyle]="{'background-color': (selectedColor$ |async) }"
            class="mobile-button-solid d-flex flex-row justify-content-center align-items-center mb-3">

            <div *ngIf="!(message$|async)?.description">{{'RUNS.NEXT' | translate}}</div>
            <div *ngIf="(message$|async)?.description">{{(message$|async)?.description}}</div>
          </div>
        </div>

      </div>
    </app-background-image-selector>
  `
})
export class MobilePreviewCombinationlockComponent implements OnInit, OnDestroy {

  combination = ['1', '2', '3'];
  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
  selectedColor$: Observable<string> = this.store.select(selectedColor);

  subscription: Subscription;

  constructor(public store: Store<State>) {
  }

  ngOnInit(): void {
    this.subscription = this.message$.subscribe((m: any) => {

      if (m && m.answers) {
        m.answers.forEach(a => {

          if (a['isCorrect']) {
            this.combination = a['answer']
              .trim().substr(0, 5).split('');
          }

        });
      }

    });
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

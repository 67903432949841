import {Component, Input, OnInit} from '@angular/core';
import {Location} from "@angular/common";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";

import {State} from "../../core/reducers";
import {ANIMATION_MODULE_TYPE} from "@angular/platform-browser/animations";
import {environment} from "../../../environments/environment";
import {backToManageGames} from "../../shared/shared.module.menu";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {canWriteCurrentGame, writeAndAdvanced} from "../../game-authors/store/game-author.selectors";
import {Game} from "../../games-management/store/game.state";

// import { getAuthIsAvanced } from "../../auth/store/auth.selector";

@Component({
  selector: 'app-game-detail-navbar',
  template: `
    <div class="header primary-background-color ">
      <div class="maxwidth">
        <app-top-nav></app-top-nav>

        <app-account-section></app-account-section>

        <div class="title font-medium-32-43-roboto">{{(game$|async)?.title}}</div>

        <div class="back-button">
          <app-header-back-button [route]="backRouter"></app-header-back-button>
        </div>
        <div class="context-tabs" *ngIf="game$|async as game">
          <!--                    <nav mat-tab-nav-bar [backgroundColor]="'primary'" *ngIf="!(canWrite$|async)">-->
          <!--                        <a mat-tab-link-->
          <!--                           #rl_runs="routerLinkActive"-->
          <!--                           [disabled]="!game"-->
          <!--                           [active]="rl_runs.isActive"-->
          <!--                           [ngClass]="{'active-color': rl_runs.isActive}"-->
          <!--                           [routerLink]="'/game/'+game.gameId+'/runs'"-->
          <!--                           routerLinkActive="tab-selected"> {{'RUNS.PLAY'|translate}} </a>-->
          <!--                    </nav>-->
          <nav mat-tab-nav-bar [backgroundColor]="'primary'">
            <a mat-tab-link
               *ngIf="canWrite$|async"
               [disabled]="!game"
               routerLinkActive #rl_screens="routerLinkActive"
               [active]="rl_screens.isActive"
               [ngClass]="{'active-color': rl_screens.isActive}"
               [routerLink]="'/game/'+game.gameId+'/screens'"
            >{{'MESSAGE.SCREENS'|translate}}
            </a>
            <!--                      [routerLink]="'/portal/game/'+game.gameId+'/detail/screens'"-->

            <a mat-tab-link
               *ngIf="canWrite$|async"
               routerLinkActive #rl_flowchart="routerLinkActive"
               [disabled]="!game"
               [active]="rl_flowchart.isActive"
               [ngClass]="{'active-color': rl_flowchart.isActive}"
               [routerLink]="'/game/'+game.gameId+'/flowchart/-1'"
            >{{'HOME.FLOWCHART'|translate}} </a>

            <a mat-tab-link
               *ngIf="(writeAndAdvanced$|async)"
               routerLinkActive #rl_disappear="routerLinkActive"
               [disabled]="!game"
               [active]="rl_disappear.isActive"
               [ngClass]="{'active-color': rl_disappear.isActive}"
               [routerLink]="'/game/'+game.gameId+'/hidechart/-1'"
            >{{'HOME.DISAPPEARCHART'|translate}} </a>

            <a mat-tab-link
               *ngIf="(writeAndAdvanced$|async)"
               routerLinkActive #rl_disappear="routerLinkActive"
               [disabled]="!game"
               [active]="rl_disappear.isActive"
               [ngClass]="{'active-color': rl_disappear.isActive}"
               [routerLink]="'/game/'+game.gameId+'/board/-1'"
            >{{'HOME.GAMEBOARD'|translate}} </a>

            <a mat-tab-link
               *ngIf="(canWrite$|async)"
               #rl_settings="routerLinkActive"
               [disabled]="!game"
               [active]="rl_settings.isActive"
               [ngClass]="{'active-color': rl_settings.isActive}"
               [routerLink]="'/game/'+game.gameId+'/settings'"
               routerLinkActive="tab-selected"> {{'HOME.SETTINGS'|translate}} </a>

            <a mat-tab-link
               #rl_runs="routerLinkActive"
               [disabled]="!game"
               [active]="rl_runs.isActive"
               [ngClass]="{'active-color': rl_runs.isActive}"
               [routerLink]="'/game/'+game.gameId+'/runs'"
               routerLinkActive="tab-selected"> {{'RUNS.PLAY'|translate}} </a>

            <a mat-tab-link
               *ngIf="(canWrite$|async)"
               #rl_media="routerLinkActive"
               [disabled]="!game"
               [active]="rl_media.isActive"
               [ngClass]="{'active-color': rl_media.isActive}"
               [routerLink]="'/game/'+game.gameId+'/media'"
               routerLinkActive="tab-selected"> {{'MEDIA.MEDIA' |translate}} </a>

          </nav>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
    <mat-progress-bar class="progress" *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  `,
  providers: [
    {provide: ANIMATION_MODULE_TYPE, useValue: 'BrowserAnimations'},
  ],
})
export class GameDetailNavbarComponent implements OnInit {
  showLanguage: boolean = environment.showTranslate;
  public game$: Observable<Game> = this.store.select(getCurrentGame);
  // public isLoading$: Observable<boolean> = this.store.select(getLoading).pipe(map(amount => amount !== 0));
  public isLoading = false;
  public canWrite$: Observable<boolean> = this.store.select(canWriteCurrentGame);
  public writeAndAdvanced$: Observable<boolean> = this.store.select(writeAndAdvanced);

  //todo remove
  @Input() game;

  backRouter = backToManageGames;

  constructor(
    private location: Location,
    public store: Store<State>
  ) {
  }

  ngOnInit() {
  }

  back() {
    this.location.back();
  }
}

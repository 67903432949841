import {GameThemeApiActions} from './game-theme.actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {GameTheme} from './game-theme.state';
import {Action, createReducer, on} from "@ngrx/store";

export interface GameThemeState extends EntityState<GameTheme> {
  lastSyncTime: number;
  lastSyncTimeMy: number;
}

export function selectIdentifier(a: GameTheme): number {
  return a.themeId;
}

export const gameThemeAdapter = createEntityAdapter<GameTheme>(
  {selectId: selectIdentifier}
);

const initialState: GameThemeState = gameThemeAdapter.getInitialState({
  lastSyncTime: 1,
  lastSyncTimeMy: 1
});

const reducer = createReducer(
  initialState,
  on(GameThemeApiActions.createOneSuccess,
    GameThemeApiActions.addOneSuccess,
    (state, action) => gameThemeAdapter.upsertOne(action.theme, state)),
  on(GameThemeApiActions.addAllSuccess,
    (state, action) => gameThemeAdapter.upsertMany(action.themes, state)),
  on(GameThemeApiActions.getGlobalListSuccess,
    GameThemeApiActions.getMyThemeListSuccess,
    (state, action) => gameThemeAdapter.upsertMany(action.themeList.themes, state)),
  on(GameThemeApiActions.deleteThemeSuccess,
    (state, action) => gameThemeAdapter.removeOne(action.themeId, state)),
  on(GameThemeApiActions.updateGlobalListSyncTime,
    (state, action) => ({...state, lastSyncTime: action.time})),
  on(GameThemeApiActions.updateMyThemeListSyncTime,
    (state, action) => ({...state, lastSyncTimeMy: action.time}))
);

export function gameThemeReducer(
  state: GameThemeState | undefined,
  action: Action
): GameThemeState {
  return reducer(state, action);
}

//
// export function gameThemeReducerOld(
//   state: GameThemeState = initialState,
//   action: GameThemeActions | Logout,
// ): GameThemeState {
//   switch (action.type) {
//
//     case AuthActionTypes.Logout: {
//       return {...initialState}
//     }
//
//
//     case GameThemeActionTypes.UPDATE_LAST_FULL_SYNC_MINE:
//       return {
//         ...state,
//         lastSyncTimeMy: action.payload
//       };
//
//
//     case GameThemeActionTypes.UPDATE_ONE:
//       return {
//         ...state,
//         themes: gameThemeAdapter.updateOne({
//           id: action.payload.id,
//           changes: action.payload.changes,
//         }, state.themes)
//       };
//
//     case GameThemeActionTypes.DELETE_ONE:
//       return {
//         ...state,
//         themes: gameThemeAdapter.removeOne(action.payload, state.themes)
//       };
//
//     case GameThemeActionTypes.GET_GLOBAL_LIST_COMPLETED_MINE:
//     case GameThemeActionTypes.ADD_ALL:
//       return {
//         ...state,
//         themes: gameThemeAdapter.upsertMany(action.payload?.themes || [], state.themes)
//       };
//     default:
//       return state;
//   }
// }

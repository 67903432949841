import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {combineLatest, Observable} from "rxjs";
import {getCurrentMessageSelector} from "../../game-messages/store/game-messages.selector";
import {currentItemFilteredUsersResponses} from "../store/run-responses.selectors";
import {selectEntities} from "../../player-management/store/player.selector";
import {distinct, map} from "rxjs/operators";

@Component({
  selector: 'app-answers-gallery',
  template: `
    <div class="answers" *ngIf="editMessage$ | async as editMessage">
      <mat-card class="answer mat-elevation-z3" [class.selected]="answer.isCorrect"
                *ngFor="let answer of editMessage.answers">
        <div *ngIf="answer.isCorrect" class="selected-icon">
          <mat-icon class="icon">check</mat-icon>
        </div>
        <div class="border-bottom pb-3">
          <div *ngIf="isImageTest(editMessage.type); else title">
            <div storageBackground
                 class="w-100"
                 style="height: 94px"
                 [targetPath]="editMessage?.fileReferences[answer.id]"></div>

            <app-filestore-background-image
              *ngIf="!((!editMessage?.fileReferences) || (!(editMessage?.fileReferences[answer.id]))) "
              [paths]="[(editMessage?.fileReferences[answer.id])]"
              [deleteButton]="false"

            >
            </app-filestore-background-image>
          </div>

          <ng-template #title>
            <h5 class="m-0 py-1">{{ answer.answer }}</h5>
          </ng-template>
        </div>

        <ul class="user-list" *ngIf="((responsesWithPlayers$ |async)[answer.id]||[]) as users">

          <li class="user-list-item" *ngFor="let user of users">
            <div class="user-avatar"><span class="user-avatar__text">{{ user?.shortAvatar }}</span></div>
            <div class="user-name">{{ user?.name }}</div>
          </li>
        </ul>
      </mat-card>
    </div>
  `,
  styles: []
})
export class AnswersGalleryComponent implements OnInit {

  public editMessage$: Observable<any> = this.store.select(getCurrentMessageSelector);
  // public responses$: Observable<RunResponse[]> = this.store.select(currentItemFilteredUsersResponses);
  // public players$: Observable<any> = this.store.select(selectEntities);

  public responsesWithPlayers$: Observable<any> = combineLatest([
    this.store.select(currentItemFilteredUsersResponses).pipe(
      distinct(responses => responses.length)
    ),
    this.store.select(selectEntities)
  ]).pipe(
    map(([responses, usersDict]: [any, any]) => {
      const answersMap = {}
      responses.forEach(response => {
        answersMap[response.responseValue] = (answersMap[response.responseValue] || new Set<any>());
        answersMap[response.responseValue].add(usersDict[response.userId]);
      });
      Object.keys(answersMap).forEach(key => {
          answersMap[key] = Array.from(answersMap[key]);
        }
      )
      return answersMap;
    })
  )

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
  }

  isImageTest(type) {
    return type.includes('ImageTest');
  }
}

import {Observable, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {State} from 'src/app/core/reducers';
// import {getAuthToken} from 'src/app/auth/selectors/auth.selector';
import {Router} from '@angular/router';
// import {AuthService} from '../../auth/services/auth.service';
import {catchError} from 'rxjs/operators';
// import {AngularFireAuth} from '@angular/fire/auth';
import {from} from 'rxjs';
import {first} from 'rxjs/operators';
import {Auth, authState} from "@angular/fire/auth";

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  authToken = '';

  constructor(private store: Store<State>,
              private router: Router,
              private auth: Auth
              // private afAuth: AngularFireAuth
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler):
    Promise<HttpEvent<any>> {

    const user = await authState(this.auth).pipe(first()).toPromise();
    // const user = await this.afAuth.authState.first().toPromise();
    if (user == null) {
      return next.handle(request).toPromise();
    }
    const token = await user.getIdToken();
    if (token === '') {
      return next.handle(request).toPromise();
    }

    if (token == null) {
    }
    const authReq = request.clone({
      setHeaders: {Authorization: 'Bearer ' + token},
    });
    return next.handle(authReq).toPromise();
  }
}


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((response: any) => {
        if (response instanceof HttpErrorResponse && response.status === 401) {

          this.router.navigate(['/']);

        }
        return observableThrowError(response);
      })
    );

  }
}

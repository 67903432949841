import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {AccountPageActions} from "../store/portal-user.actions";

@Component({
  selector: 'app-new-password-page',
  template: `
    <div class="login-screen pos-full-canvas a200-color">
      <div class="style-image-left pos-image-left" style="">

      </div>

      <div class="pos-full-canvas-right d-flex flex-column justify-content-center align-items-center">
        <div class="outer-form d-flex flex-column align-items-center">
          <div class="primary-color font-medium-32-43-roboto mt-3 mb-4">
            Wachtwoord vergeten?
          </div>

          <div class="contrast-color-50pct font-regular-17-13-roboto mt-2 mb-3">
            {{ 'LOGIN.APP_SUB_TITLE' | translate }}
          </div>
          <form [formGroup]="form" class="w-100 mt-5">

            <mat-form-field class="w-100 font-regular-16-24-roboto ">
              <input class="contrast-color-50pct"
                     matInput placeholder="{{ 'LOGIN.EMAIL_ADDRESS' | translate }}"
                     [type]="'text'"
                     formControlName="userName">
            </mat-form-field>


            <button
              type="submit"
              (click)="forgotPassword()" class="login-btn"
              mat-flat-button color="primary">Stuur reset link
            </button>

          </form>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class NewPasswordPageComponent {

  form = new FormGroup({
    userName: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(public store: Store<State>) {
  }

  forgotPassword() {
    this.store.dispatch(AccountPageActions.requestReset({
      email: this.form.value.userName,
    }));
  }
}

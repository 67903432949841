import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {RunResponsesApiActions, RunResponsesPageActions} from './run-responses.actions';
import {filter, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import * as fromRoot from "../../core/selectors/router.selector";
import {State} from "../../core/reducers";
import {getLastResponseTime} from "./run-responses.selectors";
import {RunResponsesService} from "./run-responses.service";


@Injectable()
export class RunResponsesEffects {

  querMessageResults$ = createEffect(
    () => this.actions$.pipe(
      ofType(RunResponsesPageActions.queryMessageResponses),
      withLatestFrom(
        this.store.select(fromRoot.selectRouteParam('runId')),
        this.store.select(fromRoot.selectRouteParam('messageId')),
        this.store.select(getLastResponseTime),
      ),
      filter(([_, runId, messageId]) => !!runId && !!messageId && messageId !== 'null'),
      mergeMap(([_, runId, messageId, from]) => this.responsesService.getResponseMessageUntil(runId, messageId, from, '*')),
      map(responses => {
        if (responses.resumptionToken) {
          this.store.dispatch(RunResponsesApiActions.addAll({responses: responses.responses}));
          return RunResponsesPageActions.queryMessageResponsesWithCursor({
            fromTime: responses.from,
            cursor: responses.resumptionToken
          });
        }
        return RunResponsesApiActions.addAll({responses: responses.responses});
      })
    )
  );

  querMessageResultsWithCursor$ = createEffect(
    () => this.actions$.pipe(
      ofType(RunResponsesPageActions.queryMessageResponsesWithCursor),
      withLatestFrom(
        this.store.select(fromRoot.selectRouteParam('runId')),
        this.store.select(fromRoot.selectRouteParam('messageId')),
      ),
      mergeMap(([action, runId, messageId]) =>
        this.responsesService.getResponseMessageUntil(runId, messageId, action.fromTime, action.cursor)),
      map(responses => {
        if (responses.resumptionToken) {
          this.store.dispatch(RunResponsesApiActions.addAll({responses: responses.responses}));
          return RunResponsesPageActions.queryMessageResponsesWithCursor({
            fromTime: responses.from,
            cursor: responses.resumptionToken
          });
        }

        return RunResponsesApiActions.addAll({responses: responses.responses})
      })
    )
  );

  // //todo check if this one can go
  // load$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(RunResponsesPageActions.load),
  //     withLatestFrom(
  //       this.store.select(fromRoot.selectRouteParam('runId')),
  //       this.store.select(fromRoot.selectRouteParam('messageId')),
  //       this.store.select(getServerTime),
  //       this.store.select(getNextCursor),
  //     ),
  //     mergeMap(([, runId, messageId, from, cursor]) => {
  //       return this.responsesService.getAllResponsesForItem(runId, messageId, cursor);
  //     }),
  //     map(responses => {
  //       if (responses.resumptionToken) {
  //         this.store.dispatch(RunResponsesApiActions.addAll({responses: responses.responses}));
  //         return RunResponsesPageActions.load();
  //       }
  //       return RunResponsesApiActions.addAll({responses: responses.responses});
  //     })
  //   )
  // );

  constructor(private actions$: Actions,
              private store: Store<State>,
              private responsesService: RunResponsesService
  ) {
  }
}

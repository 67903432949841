import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-scale-preview',
  template: `
    <div class="d-flex flex-row-reverse m-4">
      <div class="zoom-form-field">
        <mat-form-field  >

          <mat-select [value]="1" (selectionChange)="change($event)">
            <mat-option  [value]="1">100%</mat-option>
            <mat-option [value]="0.75">75%</mat-option>
            <mat-option [value]="0.5">50%</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class ScalePreviewComponent {
  @Output() scale = new EventEmitter();

  constructor() { }

  change(event: MatSelectChange) {
    this.scale.emit(Number.parseFloat(event.value));
  }
}

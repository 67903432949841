import {Component, Input, OnInit} from '@angular/core';
import {GameRun, RunUser} from "../store/game-runs.state";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {selectAll} from "src/app/run-players/store/run-players.selectors";
import {BehaviorSubject, combineLatestWith} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-run-amount-of-players',
  template: `
    <div class="d-flex w-100 h-100 flex-row justify-content-center align-items-center">

      <div class="outer">
        <div class="oval">

        </div>
        <div class="text-number d-flex justify-content-center align-items-center font-medium-32-43-roboto">
          <div *ngIf="_run | async">{{players$ | async}}</div>
          <div *ngIf="!(_run |async) ">
            <mat-spinner
              [mode]="'indeterminate'"
            ></mat-spinner>
          </div>
        </div>
        <div class="text-text font-medium-16-21-roboto" *ngIf="_run | async">
          players

        </div>
      </div>
    </div>
  `,
  styles: []
})
export class RunAmountOfPlayersComponent implements OnInit {
  @Input() runId;
  @Input() set run(r: GameRun) {
    // if (!r) {
    //   this.store.dispatch(new GetRunAction(this.runId))
    // }
    this._run.next(r);
  };

  _run = new BehaviorSubject(undefined);


  players$ = this.store.select(selectAll).pipe(
    map(player => player.filter(player => player && !player.deleted)),
    combineLatestWith(this._run),
    map(([all, run]: [RunUser[], GameRun]) =>
      (all || []).filter(a => a.runId === run?.runId).length
    )
  )

  constructor(public store: Store<State>) {
  }

  ngOnInit(): void {
  }

}

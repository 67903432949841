import {Component} from '@angular/core';
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";

import {GameMessage} from "../../../../game-messages/store/game-messages.state";
import {getEditMessageSelector, selectedColor} from "../../../store/game-message.selector";
import {State} from "../../../../core/reducers";
import {GameMessagePageActions} from "../../../store/game-message.actions";
import {getCurrentGame} from "../../../../games-management/store/game.selector";
import {canWriteCurrentGame} from "../../../../game-authors/store/game-author.selectors";
import {Game} from "../../../../games-management/store/game.state";

@Component({
  selector: 'app-screen-editor-type-single-choice-test',
  template: `
    <app-mc-select-answer-template></app-mc-select-answer-template>

    <cdk-accordion class="example-accordion">
      <cdk-accordion-item
        #accordionItem="cdkAccordionItem"

        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-question'"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-question'">

        <div class="mb-3 d-flex flex-row align-items-center primary-color">
          <mat-icon
            (click)="accordionItem.toggle()">{{ accordionItem.expanded ? 'expand_more' : 'chevron_right' }}</mat-icon>
          <div
            (click)="accordionItem.toggle()"
            class="font-medium-16-21-roboto ">
            {{'MESSAGE.QUESTION_SETTINGS' | translate}}
          </div>
        </div>
        <div
          class="mt-3"
          role="region"
          [style.display]="accordionItem.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-question'"
          [attr.aria-labelledby]="'accordion-header-question'">

          <mat-form-field class="w-100">

            <input appTriggerMobileView [data]="{}" [name]="'mc'"
                   matInput
                   [placeholder]="'GAME.TITLE'|translate"
                   [disabled]="!(iCanWrite|async)"
                   [ngModel]="(message$|async)?.name"
                   (ngModelChange)="titleChange($event)">
          </mat-form-field>

          <div class="w-100 mb-3">
            <app-color-input
              [canEdit]="(iCanWrite|async)"
              [label]="'COMMON.PRIMARY_COLOR' |translate"
              [showThemeName]="true"
              [color]="primColor$ | async"
              [unselect]="true"
              (onChange)="primColorChange($event)"
            ></app-color-input>
          </div>

          <div class="w-100 mb-3">
            <app-change-background-button
            ></app-change-background-button>
          </div>
          <mat-form-field class="w-100">
            <input appTriggerMobileView [data]="{}" [name]="'mc'"
                   matInput
                   [placeholder]="'GAME.QUESTION'|translate"
                   [disabled]="!(iCanWrite|async)"
                   [ngModel]="(message$|async)?.text"
                   (ngModelChange)="textChange($event)">
          </mat-form-field>

          <mat-slide-toggle
            [ngModel]="(message$|async)?.showFeedback"
            (change)="feedbackChange($event)">{{'MESSAGE.FEEDBACK_POSSIBLE' |translate}}
          </mat-slide-toggle>

          <app-select-image-background
            *ngIf="(message$|async)?.showFeedback"
            label="MESSAGE.CORRECT_IMAGE"
            key="correct"
          >

          </app-select-image-background>

          <app-select-image-background
            label="MESSAGE.WRONG_IMAGE"
            key="wrong"
            *ngIf="(message$|async)?.showFeedback"
          >
          </app-select-image-background>

          <div class="w-100 mt-3">
            <app-font-awesome-picker
              *hasOnePermission="['advanced']"
              [disabled]="!(iCanWrite|async)"
              [message]="(message$|async)"
              selected="fas fa-th-large"
              (iconChange)="iconChange($event)">
              >
            </app-font-awesome-picker>
          </div>

          <div
            *ngIf="(game$ | async).chapters !== 0"
            class="w-100 mb-3">
            <app-create-chapter
              (chapter)="game$.chapter = $event"
              [game]="game$ | async"
              [message]="message$ | async"
            ></app-create-chapter>
          </div>

          <app-create-label
            #tooltip="matTooltip"
            [matTooltip]="'GAME.TAGS_TOOLTIP' | translate"
            matTooltipPosition="left"
            matTooltipHideDelay="500"
            class="w-100 mb-2">
            [label]="(message$|async)?.label">

          </app-create-label>
          <app-pick-location-on-map
            [locationBased]="(message$|async)?.lat"
            [lat]="(message$|async)?.lat"
            [lng]="(message$|async)?.lng"
          >

          </app-pick-location-on-map>
        </div>
      </cdk-accordion-item>

      <cdk-accordion-item
        *ngFor="let answer of (message$|async)?.answers; let i = index"
        #answerItem="cdkAccordionItem"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-answer-'+i"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-answer-'+i">

        <div class="mb-3 d-flex flex-row align-items-center primary-color">
          <mat-icon (click)="answerItem.toggle()">{{ answerItem.expanded ? 'expand_more' : 'chevron_right' }}</mat-icon>
          <div
            (click)="answerItem.toggle()"
            class="font-medium-16-21-roboto flex-grow-1">
            {{'MESSAGE.ANSWER_OPTION' | translate}} {{i+1}}
          </div>
          <div>
            <button
              (click)="removeAnswer(answer.id)"
              mat-button
              class="color-888 color-o54"
            >
              {{'MESSAGE.DELETE_ANSWER' | translate}}
              <mat-icon class="color-888 color-o54 mr-1">delete_forever</mat-icon>
            </button>
          </div>
        </div>
        <div
          class="mt-3"
          role="region"
          [style.display]="answerItem.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-answer-'+i"
          [attr.aria-labelledby]="'accordion-header-answer'+i">

                  <app-answer-component
                    [index]="i+1"
                    [answer]="answer"
                    [fileRefs]="((message$|async)?.fileReferences)"
                    [feedback]="(message$|async)?.showFeedback"
                  ></app-answer-component>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>

    <button
      (click)="addAnswer()"
      class="flex-grow-1 button-height w-100 "
      mat-stroked-button
      color="primary"
    >
      <mat-icon class="pinkish-grey mr-1">add</mat-icon>
      {{'MESSAGE.ADD_ANSWER_OPTION' | translate}}
    </button>

  `
})
export class ScreenEditorTypeSingleChoiceTestComponent {
  primColor$ = this.store.select(selectedColor);
  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
  game$: Observable<Game> = this.store.select(getCurrentGame);
  iCanWrite: Observable<boolean> = this.store.select(canWriteCurrentGame);

  constructor(private store: Store<State>) {
  }

  titleChange(event: any) {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{name: event}}));
  }

  textChange(event: any) {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{text: event}}));
  }

  feedbackChange(event: MatSlideToggleChange) {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{showFeedback: event.checked}}));
  }

  addAnswer() {
    this.store.dispatch(GameMessagePageActions.addAnswers({
      answerType: "org.celstec.arlearn2.beans.generalItem.MultipleChoiceAnswerItem"
    }));
  }

  iconChange(event: any) {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{icon: event}}));
  }

  primColorChange(color: string) {
    if (color === "default") {
      this.store.dispatch(GameMessagePageActions.clearColor());
    } else {
      this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{primaryColor: color}}));
    }
  }

  removeAnswer(answerId) {
    this.store.dispatch(GameMessagePageActions.updateAnswer({
      delete: true,
      id: answerId
    }));

  }
}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {Action} from "@ngrx/store/src/models";
import Debounce from 'debounce-decorator';
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
  selector: 'app-search-button',
  template: `

    <mat-form-field class="pos-field search-input w-100">
      <mat-label>{{'ROW_HEADERS.SEARCH' | translate}}</mat-label>
      <input

        [matAutocomplete]="search_auto"
        matInput [placeholder]="placeholder"
             [(ngModel)]="filter"
             (input)="onFilterChange($event.target.value)"
      >
      <mat-icon matPrefix>search</mat-icon>
      <mat-autocomplete #search_auto="matAutocomplete"  (optionSelected)="selected($event)">
        <mat-option *ngFor="let label of _suggestLabels " [value]="label">
          {{label}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>


  `,
  styles: [`

    .pos-field {
      max-width: 420px;
    }
  `]
})
export class SearchButtonComponent implements OnInit, OnChanges {

  @Input() placeholder;
  // @Input() dispatchAction: FilterAction & Action;
  @Input() dispatchActionFunction: (filter: string[]) => void;
  @Input() public filter: string;
  @Input() public suggestLabels: string[] = [];
  _suggestLabels: string[] = [];

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
  }

  @Debounce(300)
  onFilterChange(filter) {

    let f: string[] = [];
    if (filter !== '') {
      f = [filter];
    }
    this._suggestLabels = this.suggestLabels.filter(acl => acl.indexOf(filter) !== -1)
    this.dispatchActionFunction(f);
    // if (filter === '') {
    //   this.dispatchAction.setFilter([]);
    //   // this.store.dispatch(new SetGamesFilterAction({filters: []}));
    // } else {
    //   //  this.store.dispatch(new SetGamesFilterAction({filters: [filter]}));
    //   this.dispatchAction.setFilter([filter]);
    // }
    // this.store.dispatch(this.dispatchAction);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.suggestLabels) {
      this._suggestLabels = this.suggestLabels;
    }
  }

  selected(event: MatAutocompleteSelectedEvent) {
    this.dispatchActionFunction([event.option.viewValue]);
  }
}

// export interface FilterAction {
//   setFilter(filter: string[]);
// }

import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {GameAuthor} from "./game-author.state";
import {GameAuthorApiActions} from "./game-author.actions";
import {Action, createReducer, on} from "@ngrx/store";

export interface GameAuthorState extends EntityState<GameAuthor> {
  lastSyncrTime: number;
}

export const gameAuthorAdapter = createEntityAdapter<GameAuthor>(
  {selectId: (a: GameAuthor) => a.identifier}
);

const initialState: GameAuthorState = gameAuthorAdapter.getInitialState({
  lastSyncrTime: -1
});

export const reducer = createReducer(
  initialState,
  on(GameAuthorApiActions.addGameAuthorCompleted, (state, action) => gameAuthorAdapter.upsertOne(action.gameAuthor, state)),
  on(GameAuthorApiActions.removeGameAuthorCompleted, (state, action) => gameAuthorAdapter.upsertOne(action.gameAuthor, state)),
  on(GameAuthorApiActions.loadGameAuthorsCompleted, (state, action) => gameAuthorAdapter.upsertMany(action.gameAccessList.gameAccess || [], state)),
  on(GameAuthorApiActions.addGameAuthorsWithoutResumption, (state, action) => gameAuthorAdapter.upsertMany(action.gameAccessList.gameAccess || [], state)),
  on(GameAuthorApiActions.updateFullLastSync, (state, action) => ({...state, lastSyncrTime: action.time})),
)


export function gameAuthorReducer(
  state: GameAuthorState | undefined,
  action: Action
): GameAuthorState {
  return reducer(state, action);
}

//
// export function gameAuthorReducer_old(
//   state: GameAuthorState = initialState,
//   action: GameAuthorAction,
// ): GameAuthorState {
//   switch (action.type) {
//
//     case AuthActionTypes.Logout: {
//       return {...initialState}
//     }
//
//     // case GameAuthorActionTypes.LOAD_ALL_GAME_AUTHORS_COMPLETED:
//     // case GameAuthorActionTypes.LOAD_GAME_AUTHORS_COMPLETED: {
//     //   return {
//     //     ...state,
//     //     authors: gameAuthorAdapter.upsertMany(action.payload.gameAccess || [], state.authors)
//     //   }
//     // }
//
//     case GameAuthorActionTypes.UPDATE_LAST_FULL_SYNC: {
//       return {
//         ...state,
//         lastSyncTime: action.payload
//       }
//     }
//
//     default:
//       return state;
//   }
// }

import {Action, createActionGroup, props} from '@ngrx/store';
import {GameTheme, ThemeList} from './game-theme.state';

export const GameThemePageActions = createActionGroup({
  source: 'Game Theme Page',
  events: {
    'Query One':  props<{ themeId?: number }>(),
    'Create Theme':  props<{ theme: GameTheme }>(),
    'Update Theme':  props<{ theme: GameTheme }>(),
    'Delete Theme':  props<{ themeId: number }>(),
  },
});

export const GameThemeApiActions = createActionGroup({
  source: 'Game Theme Api',
  events: {
    'Add One Success': props<{ theme: GameTheme }>(),
    'Create One Success': props<{ theme: GameTheme }>(),
    'Delete Theme Success':  props<{ themeId: number }>(),
    'Add All Success': props<{ themes: GameTheme[] }>(),

    'Get Global List Success': props<{ themeList: ThemeList }>(),
    'Update Global List Sync Time': props<{ time: number }>(),

    'Get My Theme List Success': props<{ themeList: ThemeList }>(),
    'Update My Theme List Sync Time': props<{ time: number }>(),
  }
});

// export const GameThemeActionTypes = {
//   GET_GLOBAL_LIST_COMPLETED: '[GameTheme] Query Global Completed',
//   GET_GLOBAL_LIST_COMPLETED_MINE: '[GameTheme] Query Global Completed my themes',
//   UPDATE_LAST_FULL_SYNC: '[GameTheme] Update All Games Sync Time',
//   UPDATE_LAST_FULL_SYNC_MINE: '[GameTheme] Update All Games Sync Time My themes',
//
//   // CREATE_REQUEST_SUCCESS: '[GameTheme] Create Request Success',
//   // DELETE_ONE: '[GameTheme] Delete One',
//   ADD_ALL: '[GameTheme] Add All'
// }



//
// export class CreateRequestSuccess implements Action {
//   type = GameThemeActionTypes.CREATE_REQUEST_SUCCESS;
//
//   constructor(public payload: GameTheme) {
//   }
// }

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sub-sub-navbar',
  template: `
    <div class="subsub">
      <button
        mat-icon-button
        aria-label="back">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>


      <div class="run-tabs">
        <nav mat-tab-nav-bar class="nav-override style-upper">
          <a mat-tab-link
             *ngFor="let item of items"
             class="tab-link"
             routerLinkActive #subsubrla="routerLinkActive"
             [active]="subsubrla.isActive"
             [ngClass]="{'active-black':subsubrla.isActive}"
             [routerLink]="item.routerLink">{{item.label|translate}} </a>


        </nav>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class SubSubNavbarComponent implements OnInit {
  @Input() items: {
    routerLink: string;
    label: string;
  }[] = [
    {
      routerLink: '/admin/user/usrmgt',
      label: 'ROW_HEADERS.SEARCH'
    },
    {
      routerLink: '/admin/user/recent',
      label: 'RECENT'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}

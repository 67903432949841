import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {NewRunDialogComponent} from "./new-run-dialog/new-run-dialog.component";
import {GameRunsPageActions} from "../store/game-runs.actions";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";

@Component({
  selector: 'app-new-run-tile',
  template: `
    <div class="tile-dimensions m-2 new-button-screen">
      <div class="h-100 d-flex flex-column justify-content-center align-items-center">
        <div>
          <button color="accent" mat-fab (click)="newRun()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div class="mt-3 font-regular-16-24-roboto color-888">
          {{ 'RUNS.MAKE_RUN' | translate }}
        </div>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class NewRunTileComponent implements OnInit {

  constructor(
    private store: Store<State>,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  newRun() {
    const dialogRef = this.dialog.open(NewRunDialogComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {title: ''}
    });

    dialogRef.afterClosed().subscribe(run => {
      console.log('The dialog was closed', run);
      if (run) {
        this.store.dispatch(GameRunsPageActions.createRun({run}));
      }

    });
  }

}

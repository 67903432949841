import {Routes} from '@angular/router';
import {LoginScreenComponent} from './pages/login-screen/login-screen.component';
import {ScreenDetailComponent} from './game-message/pages/screen-detail.component';
import {LoginScreenNoSocialComponent} from "./pages/login-screen/login-screen-no-social.component";
import {environment} from "../environments/environment";
import {GameLandingPageComponent} from "./game-management/pages/game-landing-page.component";
import {tutorialRoutes} from "./tutorial/tutorial.routes";
import {gamesRoutes} from "./games-management/games-management.routes";
import {connectionRoutes} from "./player-management/player-management.routes";

import {gameMessagesRoutes} from "./game-messages/game-messages.routes";
import {userLibraryRoutes} from "./user-library/user-library.routes";
import {gameRunRoutes} from "./runs-management/runs-management.routes";
import {AuthGuard} from "src/app/ngrx-auth/guards/auth.guard";
import {AdminAuthGuard} from "src/app/ngrx-auth/guards/admin-auth.guard";
import {MessageTopNavBarComponent} from "./game-message/component/message-top-nav-bar.component";
import {ScreenDetailNewComponent} from "./game-message/pages/screen-detail-new.component";
import {loginRoutes} from "./user-management/user-management-routes";

export const APP_ROUTES: Routes = [
  {path: '', canActivate: [AuthGuard], redirectTo: 'games/list', pathMatch: 'full'},
  {path: 'landing/game/:gameId', component: GameLandingPageComponent, pathMatch: 'full'},
  {path: 'google', component: LoginScreenComponent, pathMatch: 'full'},
  ...loginRoutes,
  {
    path: 'login',
    component: environment.socialLogin ? LoginScreenComponent : LoginScreenNoSocialComponent,
    pathMatch: 'full'
  },

  {path: 'games', canActivate: [AuthGuard], children: gamesRoutes},
  {
    path: 'game/:gameId', canActivate: [AuthGuard],
    children: [
      ...gameMessagesRoutes,
      ]
  },
  {
    path: 'game', canActivate: [AuthGuard],
    component: MessageTopNavBarComponent,
    children: [
      {path: ':gameId/screens/message/:messageId/detail', component: ScreenDetailNewComponent},
      {path: ':gameId/flowchart/:messageId/fl/message/detail', component: ScreenDetailNewComponent},
      {path: ':gameId/hidechart/:messageId/hi/message/detail', component: ScreenDetailNewComponent},
      {path: ':gameId/board/:messageId/bo/message/detail', component: ScreenDetailNewComponent},
    ]
  },
  gameRunRoutes,
  {path: 'connections', canActivate: [AuthGuard], children: connectionRoutes},
  {path: 'collection', canActivate: [AuthGuard], children: userLibraryRoutes},
  {path: 'tutorial', canActivate: [AuthGuard], children: tutorialRoutes},
  {
    path: 'admin', canActivate: [AdminAuthGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {path: '**', canActivate: [AuthGuard], redirectTo: '/games/list'},
];

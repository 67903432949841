import {Component, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {PlayerPageActions} from "../../player-management/store/player.actions";

@Component({
  selector: 'app-loading-player-for-response',
  template: `

      <mat-spinner diameter="30"></mat-spinner>

  `,
  styles: []
})
export class LoadingPlayerForResponseComponent implements OnInit {

  @Input() userId;

  constructor(private store: Store<State>,) {
  }

  ngOnInit(): void {
    this.store.dispatch(PlayerPageActions.loadOnePlayer({fullId: this.userId, isConnection: false}))
  }

}

import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Organisation} from "./organisations.state";
import {OrganisationApiActions} from "./organisations.actions";
import {Action, createReducer, on} from "@ngrx/store";


export interface OrganisationsState extends EntityState<Organisation> {
    queryLoading?: boolean;
}



export const organisationsAdapter = createEntityAdapter<Organisation>(
    {selectId: (a: Organisation) => a.id}
);

const initialState: OrganisationsState = organisationsAdapter.getInitialState({
    queryLoading: false,
});


export const reducer = createReducer(
  initialState,
  on(OrganisationApiActions.addAll, (state, action) =>
    organisationsAdapter.upsertMany(action.organisationList.items || [], {...state, queryLoading: false})
  ),
  on(OrganisationApiActions.addOne, (state, action) =>
    organisationsAdapter.upsertOne(action.organisation, {...state, queryLoading: false})
  ),
  on(OrganisationApiActions.deleteOne, (state, action) =>
    organisationsAdapter.removeOne(action.organisation.id, state)
  ),
);

export function organisationReducer(
  state: OrganisationsState | undefined,
  action: Action
): OrganisationsState {
  return reducer(state, action);
}


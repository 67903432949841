import {Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {Store} from '@ngrx/store';


import {State} from "../../core/reducers";
import {MediaLibraryPageActions} from "../store/media-lib.actions";


@Directive({
  selector: '[appFileDragDrop]'
})
export class FileDragDropDirective {

  @Input() targetPath;
  @Output() isDraggingOver = new EventEmitter();
  dragState = false;


  constructor(public store: Store<State>) {
  }

  @HostBinding('class.fileDrag') get drag(): boolean {
    return this.dragState;
  }

  @HostListener('dragover', ['$event']) onDragOver(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragState = true;
    this.isDraggingOver.emit(true)
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragState = false;
    this.isDraggingOver.emit(false)
  }

  @HostListener('drop', ['$event'])
  public ondrop(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragState = false;
    this.isDraggingOver.emit(false)
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.store.dispatch(MediaLibraryPageActions.setFilesToUpload({files, customPath: this.targetPath}));
      this.store.dispatch(MediaLibraryPageActions.startUpload());
    }
  }

}

import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {getLibraryQueryGames} from "../store/user-library.selectors";
import {Subscription} from "rxjs";
import {filter, map, throttleTime} from "rxjs/operators";
import {CdkScrollable, ScrollDispatcher} from "@angular/cdk/overlay";
import {UserLibraryPageActions} from "../store/user-library.actions";

@Component({
  selector: 'app-library-games-table',
  template: `
    <div *ngIf="data$ | async as dataSource">
      <table mat-table
             #content cdkScrollable
        [dataSource]="dataSource" class="game-library-table">
        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef class="icon"></th>
          <td mat-cell *matCellDef="let element" class="icon">
            <app-game-icon
              [game]="element"
            ></app-game-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="heading-wrapper">
              <h6 class="title">{{ element.title }}</h6>
              <span *ngIf="element.featured" class="primary-color badge">
                              {{'LIBRARY.FEATURED' | translate}}
                              </span>
            </div>
            <p class="description">{{ element.description }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="date"> {{element.lastModificationDate | date}} </td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="category"> {{element.category}} </td>
        </ng-container>

        <ng-container matColumnDef="language">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="country"> {{element.language}} </td>
        </ng-container>

        <ng-container matColumnDef="views">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="country">
            <div class="d-flex flex-row-reverse w-100">{{element.amountOfPlays}} {{'GAME.SESSIONS' | translate}} </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="privateMode">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="private center">
            <mat-icon class="table-icon" *ngIf="element.privateMode">lock</mat-icon>
            <mat-icon class="table-icon" *ngIf="!element.privateMode">lock_open</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="rate">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="rate">
                            <span> <mat-icon class="table-icon" color="primary">star</mat-icon>
                              {{ element.sharing }} </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="game-row"
            [routerLink]="'/collection/game/' + row.gameId"></tr>
      </table>
    </div>
  `,
  styles: [`
    .game-library {
      padding: 3rem 0;
    }

    .game-library-table {
      width: 100%;
      background: transparent;
    }

    .game-library-table .icon {
      width: 68px;
    }

    .game-row {
      cursor: pointer;
    }

    .game-library-table tr.cdk-row {
      height: 98px;
    }

    tr.cdk-row td:first-child {
      padding-left: 0;
      padding-right: 1rem;
      border-bottom: none;
    }

    tr.cdk-row td {
      border-color: #ECECEC;
    }

    tr.cdk-row td:last-child {
      padding-right: 0;
    }

    .title {
      margin-bottom: 0.1rem;
    }

    .description {
      font-size: 12px;
      color: #1919198A;
      max-height: 3.6em;
      line-height: 1.8em;
      word-wrap: break-word;

      display: -webkit-box;
      max-width: 70%;
      margin: 0;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .date,
    .category,
    .country {
      color: #0000008A;
    }

    td.rate {
      text-align: right;
    }

    .cdk-column-title {
      max-width: 160px;
    }

    .private {
      text-align: center;
    }

    .private .mat-icon {
      color: #A0ABB5;
    }

    .table-icon {
      font-size: 16px;
      height: unset;
      width: unset;
      vertical-align: text-top;
    }


    .game-library-table .cdk-header-row {
      display: none;
    }
  `]
})
export class LibraryGamesTableComponent implements OnInit, OnDestroy {
  public data$ = this.store.select(getLibraryQueryGames);
  public displayedColumns: string[] = ['icon', 'title', 'date', 'views', 'privateMode']; //, 'rate' 'category', 'language', 'private', 'rate'

  private subscription = new Subscription();
  @ViewChild('content') elementView: ElementRef;

  constructor(public scroll: ScrollDispatcher, private store: Store<State>) {
    this.subscription.add(this.scroll
      .scrolled()
      .pipe(
        map((data: CdkScrollable) => {
            if (data) {
              const x = data.getElementRef().nativeElement.scrollTop;
              const y = (this.elementView.nativeElement.offsetHeight - window.innerHeight);
              if ((x / y) > 0.8) {
                return true;
              }
            }
            return true;

          }
        ),
        filter(x => x),
        throttleTime(1250)
      )
      .subscribe((data: any) => {
        // console.log('dispathc ....');
        this.store.dispatch(UserLibraryPageActions.resumeRecentGames());

      }));
  }

  ngOnInit(): void {
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

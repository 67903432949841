import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../../../core/reducers";
import {MatDialog} from "@angular/material/dialog";
import {SelectAssetComponent} from "../../../../portal-image-management/modals/select-asset.component";
import {GameMessagePageActions} from "../../../store/game-message.actions";
import {Observable} from "rxjs";
import {getEditMessageSelector} from "../../../store/game-message.selector";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-change-background-button',
  template: `
    <div class="w-100 d-flex flex-row flex-nowrap justify-content-between">
      <button

        (click)="selectBackground()"
        class="flex-grow-1 button-height"
        mat-stroked-button
        color="primary"
      >
        <mat-icon class="pinkish-grey mr-1">cloud_upload</mat-icon>
          {{'THEME.CHANGE_BACKGROUND' | translate}}
      </button>
      <button
        (click)="removeBackground()"
        class="ml-2"
        mat-stroked-button *ngIf="hasBackground$ |async">
        <mat-icon class="pinkish-grey mr-1">delete_forever</mat-icon>
      </button>

    </div>

  `,
  styles: []
})
export class ChangeBackgroundButtonComponent implements OnInit {
  hasBackground$: Observable<boolean> = this.store.select(getEditMessageSelector).pipe(
    map(m => (m?.fileReferences || {}) ['background'] !== undefined)
  );

  key = 'background';

  constructor(private store: Store<State>, public dialog: MatDialog,) {
  }


  ngOnInit(): void {
  }

  selectBackground() {
    const dialogRef = this.dialog.open(SelectAssetComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.attachSplash(result);
    });
  }

  attachSplash(event) {
    const objectRef = {};
    objectRef[this.key] = event;
    this.store.dispatch(GameMessagePageActions.updateFileRef({
      delete: false,
      key: this.key,
      value: event
    }));
  }

  removeBackground() {
    this.store.dispatch(GameMessagePageActions.updateFileRef({
      delete: true,
      key: this.key
    }));
  }
}

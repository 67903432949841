import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {GameDetailScreensComponent} from '../game-detail-screens/game-detail-screens.component';
import {Observable} from "rxjs";
import {getFilteredMessagesSelector, getMessagesLoading} from "../../store/game-messages.selector";
import {GameMessage} from "../../store/game-messages.state";
import {GameMessageApiActions, GameMessagePageActions,} from "../../../game-message/store/game-message.actions";
import {MatDialog} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {Router} from "@angular/router";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {messageIdSelector} from "../../../core/selectors/router.selector";
import {distinct, map} from "rxjs/operators";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {getCurrentGame} from "../../../games-management/store/game.selector";
import {GamePageActions} from "../../../games-management/store/game.actions";
import {Game} from "../../../games-management/store/game.state";

@Component({
  selector: 'app-game-detail-flowchart',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div *ngIf="game$ | async as game">
      <div class="buttons position-absolute">
        <div class="d-flex flex-row">
          <button
            *ngIf="!game.showGrid"
            (click)="setGrid(true)"
            mat-mini-fab color="accent">
            <mat-icon>grid_4x4</mat-icon>
          </button>

          <button
            *ngIf="game.showGrid"
            (click)="setGrid(false)"
            mat-mini-fab color="accent">
            <mat-icon>grid_off</mat-icon>
          </button>

          <mat-button-toggle-group
            class="ml-3"
            *ngIf="game.showGrid"
            [value]="''+game.gridSize"
            (change)="gridSizeChange($event)">
            <mat-button-toggle value="10">10</mat-button-toggle>
            <mat-button-toggle value="20">20</mat-button-toggle>
            <mat-button-toggle value="100">100</mat-button-toggle>
          </mat-button-toggle-group>

        </div>

      </div>
      <app-game-detail-navbar [game]="game"></app-game-detail-navbar>


      <div *ngIf="messages$ | async as messages">
<!--        test {{messages | json}}-->
        <lib-wireflow
          *ngIf="messages.length > 0 && !((loading$ | async))"
          [showGrid]="game.showGrid"
          [gridSize]="!game.showGrid ? 0 :game.gridSize"
          (selectMessage)="selectMessage($event)"
          [messages]="messages"
          [endsOn]="(game$ | async)?.endsOn || {}"
          [endsOnDisabled]="false"
          (messagesChange)="messagesChange($event)"
          (deselectMessage)="deselectMessage($event)"
          (noneSelected)="noneSelected(game)"
          (onEvent)="onEvent($event)"
          (endsOnChange)="onEndsOnChange($event)"
          (endsOnCoordinatesChange)="onEndsOnCoordinatesChange($event)"
          [lang]="lang"
          [noimage]="true"
        ></lib-wireflow>


      </div>

      <div *ngIf="currentMessageId$|async as messageId">
        <app-preview-pane class="pos-preview-pane"
                          [hideControls]="true"
                          [showEdit]="true"
                          editLink="./fl/message/detail"
                          [game]="game"
                          [navTo]="'fl/message/detail'"
                          *ngIf="messageId != -1">
        </app-preview-pane>
      </div>

      <!--        [navTo]="'/portal/'+ message.gameId+ '/message/'+message.id+'/detail/flowchart'"-->

    </div>
  `,
  styles: [`
    .buttons {
      z-index: 10;
      top: 160px;
      left: 30px;
    }

    .pos-preview-pane {
      position: fixed;
      right: 0px;
      top: 0px;
      background: #FAFAFA;
      width: 429px;
      height: 100vh;
      z-index: 1;
      box-shadow: 0px 9px 18px #0000002E;
    }
  `]
})
export class GameDetailFlowchartComponent extends GameDetailScreensComponent implements OnDestroy {
  currentMessageId$: Observable<string> = this.store.select(messageIdSelector);
  game$: Observable<Game> = this.store.select(getCurrentGame);
  grid = false;
  gridSize = 20;

  public messages$: Observable<GameMessage[]> = this.store.select(getFilteredMessagesSelector).pipe(
    distinct(messages => messages.length),
    map(messages => messages.reverse())
  );
  loading$ = this.store.select(getMessagesLoading);
  lang = 'en';


  constructor(
    public dialog: MatDialog,
    public store: Store<State>,
    private router: Router,
    public gaService: GoogleAnalyticsService
  ) {
    super(dialog, store, gaService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    // this.store.dispatch(new ResetAction());
  }

  messagesChange(messages: GameMessage[]) {
    (messages || []).map(gameMessage => this.store.dispatch(GameMessagePageActions.gameMessageDirectSave({gameMessage})));
  }


  selectMessage(gameMessage) {
    if (gameMessage.gameId && gameMessage.id) {
      this.router.navigate([`game/${gameMessage.gameId}/flowchart/${gameMessage.id}`]);
      this.store.dispatch(GameMessageApiActions.gameMessageEditComplete({gameMessage}));
    }

  }

  deselectMessage($event) {
    this.router.navigate([`game/${$event.gameId}/flowchart/-1`]);
    this.store.dispatch(GameMessageApiActions.gameMessageEditComplete({gameMessage: undefined}));
  }

  noneSelected(game: Game) {

    if (game && game.gameId) {
      this.router.navigate([`game/${game.gameId}/flowchart/-1`]);
    }

    this.store.dispatch(GameMessageApiActions.gameMessageEditComplete({gameMessage: undefined}));
  }

  onEvent(event) {
    if (event) {
      switch (event.type) {
        case 'newOutputAdded': {
          if (event.nodeType === 'ScanTag' || event.nodeType === 'TextQuestion') {
            this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:event.payload.outputs}));
          }

          break;
        }
        case 'FIRST_CHUNK_LOADING': {
          this.store.dispatch(GamePageActions.setLoading({loading:event.payload}));


          break;
        }
      }
    }

  }

  onEndsOnChange(dependency: any) {
    this.store.dispatch(GamePageActions.updateEndState({dependency}));
  }

  onEndsOnCoordinatesChange(coords: any) {
    console.log('FROM ENDS COORDS', coords);
  }

  gridSizeChange(event: MatButtonToggleChange) {
    if (event.value === '10') {
      this.gridSize = 10;
    } else if (event.value === '20') {
      this.gridSize = 20;
    } else {
      this.gridSize = 100;
    }
    this.store.dispatch(GamePageActions.gridUpdate({
      size: this.gridSize
    }));
  }

  setGrid(b: boolean) {
    this.store.dispatch(GamePageActions.gridUpdate({
      show: b
    }));
    this.grid = b;
  }
}


import {Injectable} from "@angular/core";
import {GameAuthorService} from "./game-author.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {GameAuthorActions, GameAuthorApiActions} from "./game-author.actions";
import {filter, map, mergeMap, withLatestFrom} from "rxjs/operators";
import {getInSyncTill} from "./game-author.selectors";
import {GameApiActions} from "../../games-management/store/game.actions";


@Injectable()
export class GameAuthorEffectsLoadAuthors {
  constructor(
    private gameAuthorService: GameAuthorService,
    private actions$: Actions,
    private router: Router,
    private store$: Store<State>
  ) {
  }


  loadAllAuthors = createEffect(
    () => this.actions$.pipe(
      ofType(GameAuthorActions.loadMyGameAuthors, GameApiActions.createGameSuccess),
      withLatestFrom(this.store$.select(getInSyncTill)),
      mergeMap(([_, inSyncTill]) => this.gameAuthorService.loadAllAuthors(inSyncTill).pipe(
        map(gameAccessList => GameAuthorApiActions.loadGameAuthorsCompleted({gameAccessList}))
      ))
    )
  );


  resumeLoadAllAuthors = createEffect(
    () => this.actions$.pipe(
      ofType(GameAuthorApiActions.loadGameAuthorsCompleted),
      filter((action) =>
        !!action && !!action.gameAccessList.resumptionToken && (action.gameAccessList.gameAccess || []).length != 0),
      mergeMap(
        (action) => this.gameAuthorService.loadAllAuthors(action.gameAccessList.from, action.gameAccessList.resumptionToken).pipe(
          map(gameAccessList => GameAuthorApiActions.loadGameAuthorsCompleted({gameAccessList}))
        )
      )
    ));

  updateInSyncTill = createEffect(
    () => this.actions$.pipe(
      ofType(GameAuthorApiActions.loadGameAuthorsCompleted),
      filter((action) =>
        !!action && !!action.gameAccessList && (!action.gameAccessList.resumptionToken || (action.gameAccessList.gameAccess || []).length == 0)),
      map(action => GameAuthorApiActions.updateFullLastSync({time: action.gameAccessList.serverTime - 120000}))
    ));

}

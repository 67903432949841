import {GameMessageApiActions, GameMessagePageActions} from './game-message.actions';
import {
  GameMessage,
  MultipleChoiceAnswerItem,
  MultipleChoiceScreen
} from "../../game-messages/store/game-messages.state";
import {GameMessagesApiActions} from "../../game-messages/store/game-messages.actions";
import {Action, createReducer, on} from "@ngrx/store";

export interface GameMessageState {
  editMessage?: GameMessage;
  previewScreen: { previewType: string; data: Partial<MultipleChoiceAnswerItem> };
}

export const gameMessageInitialState: GameMessageState = {
  editMessage: null,
  previewScreen: {previewType: 'mc', data: {}}
};


export const reducer = createReducer(
  gameMessageInitialState,
  on(GameMessagePageActions.gameMessageEdit, (state, action) => ({
    ...state,
    editMessage: undefined
  })),
  on(GameMessageApiActions.gameMessageEditComplete, (state, action) => ({
    ...state,
    editMessage: action.gameMessage
  })),
  on(GameMessagePageActions.setMessagePreview, (state, action) => ({
    ...state,
    previewScreen: {previewType: action.previewType, data: action.data}
  })),
  on(GameMessagePageActions.gameMessageUpdate, (state, action) => ({
    ...state,
    editMessage: {...state.editMessage, ...action.partialMessage}
  })),
  on(GameMessagePageActions.addAnswers, (state, action) => {
      const editMessage = <MultipleChoiceScreen>{...(state.editMessage || {})};
      if (!editMessage.answers) {
        editMessage.answers = [];
      }
      if (action.amount) {
        editMessage.answers.splice(action.amount)
        while (editMessage.answers.length < action.amount) {
          editMessage.answers.push({
            "type": action.answerType,
            "answer": "",
            "feedback": "",
            "id": makeid(5),
            "isCorrect": false
          });
        }
        return {...state, editMessage};
      } else {
        editMessage.answers.push({
          "type": action.answerType,
          "answer": "",
          "feedback": "",
          "id": makeid(5),
          "isCorrect": false
        });
        return {...state, editMessage};
      }

    }
  ),
  on(GameMessagePageActions.updateAnswer, (state, action) => {
    const editMessage: MultipleChoiceScreen = {...state.editMessage};
    if (!editMessage.answers) {
      editMessage.answers = [];
    }
    if (action.delete) {
      editMessage.answers.splice(editMessage.answers.findIndex(e => e.id === action.id), 1);
    } else {
      const selectedAnswer = editMessage.answers.filter(idFilter(action.id))[0];
      if (editMessage.type === 'org.celstec.arlearn2.beans.generalItem.SingleChoiceTest' || editMessage.type === 'org.celstec.arlearn2.beans.generalItem.SingleChoiceImageTest') {
        if (action.value === true && action.field === 'isCorrect') {
          editMessage.answers = editMessage.answers.map(entry => {
            entry.isCorrect = false;
            return entry;
          });
        }
      }
      selectedAnswer[action.field] = action.value;
    }
    return {...state, editMessage};
  }),
  on(GameMessagePageActions.updateFileRef, (state, action) => {
    if (!state.editMessage.fileReferences) {
      state.editMessage.fileReferences = {};
    }
    if (action.delete) {
      delete state.editMessage.fileReferences[action.key];
    } else {
      state.editMessage.fileReferences[action.key] = action.value;
    }
    return {
      ...state, editMessage: {
        ...state.editMessage,
        fileReferences: {...state.editMessage.fileReferences}
      }
    };
  }),
  on(GameMessagePageActions.clearFileRef, (state, action) => ({
    ...state,
    editMessage: {
      ...state.editMessage,
      fileReferences: {
        ...state.editMessage?.fileReferences,
        [action.key]: null
      }
    }
  })),
  on(GameMessagePageActions.updateAnswerDependency, (state, action) => action.delete ?
    ({
      ...state,
      editMessage: {
        ...state.editMessage,
        dependsOn: undefined
      }
    }) :
    ({
      ...state,
      editMessage: {
        ...state.editMessage,
        dependsOn: {
          type: "org.celstec.arlearn2.beans.dependencies.ActionDependency",
          action: action.action,
          generalItemId: action.id,
        }
      }
    })
  ),
  on(GameMessagePageActions.clearLocation, (state, action) => ({
    ...state,
    editMessage: {
      ...state.editMessage,
      lat: undefined,
      lng: undefined
    }
  })),
  on(GameMessagePageActions.clearColor, (state, action) => ({
    ...state,
    editMessage: {
      ...state.editMessage,
      primaryColor: undefined,
    }
  })),
  on(GameMessagesApiActions.gameMessageSaveComplete, (state, action) => ({
    ...state,
    editMessage: undefined
  })),

);

export function reducers(
  state: GameMessageState | undefined,
  action: Action
): GameMessageState {
  return reducer(state, action);
}


// export function reducersOld(
//   state = gameMessageInitialState, action: any): GameMessageState {
//   switch (action.type) {
//
//     case AuthActionTypes.Logout : {
//       return gameMessageInitialState;
//     }
//
//     default: {
//       return state;
//     }
//   }
// }

const idFilter = (id) => {
  return (answer: any) => answer.id === id;
};

const makeid = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

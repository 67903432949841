import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../../../core/reducers";
import {MatSelectChange} from "@angular/material/select";
import {ownsCurrentGame} from "../../../../game-authors/store/game-author.selectors";

@Component({
    selector: 'app-game-detail-access',
    template: `
      <div class="w-100 mt-5">
            <mat-form-field class="w-100">
                <mat-label>{{'GAME.ACCESS_WHO'|translate}}</mat-label>
                <mat-select
                        [disabled]="!(owner || (iAmOwner|async))"
                        [(ngModel)]="accessValue"
                        (selectionChange)="changeAccess($event)">
                    <mat-option  [value]="1">{{'GAME.ACCESS.PRIVATE' | translate}}</mat-option>
                    <mat-option  [value]="2">{{'GAME.ACCESS.LINK' | translate}}</mat-option>
                    <mat-option  [value]="3">{{'GAME.ACCESS.PUBLIC' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    `
})
export class GameDetailAccessComponent implements OnInit {

    @Input() accessValue = 1;
    @Input() owner = false;
    // @Input() emitOnChange = false;
    @Output() optionChange = new EventEmitter<number>();
    public iAmOwner: Observable<boolean> = this.store.select(ownsCurrentGame);


    constructor(public store: Store<State>) {
    }

    ngOnInit() {
    }

    changeAccess(event: MatSelectChange) {
      this.optionChange.emit(event.value);
      // if (this.emitOnChange) {
      //   this.optionChange.emit(event.value);
      // } else {
      //   this.store.dispatch(new GameUpdateAction({
      //     "sharing": event.value
      //   }));
      // }

    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RunOverviewComponent} from './pages/run-overview.component';
import {StoreModule} from '@ngrx/store';
import {reducers} from './store/game-runs.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SharedModule} from '../shared/shared.module';
import {GameRunsEffects} from './store/game-runs.effects';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NewRunDialogComponent} from './components/new-run-dialog/new-run-dialog.component';
import {RunsTableComponent} from './components/runs-table/runs-table.component';
import {AddPlayerDialogComponent} from './components/add-player-dialog.component';
import {MatMenuModule} from '@angular/material/menu';
import {GameMessagesModule} from "../game-messages/game-messages.module";
import {RunPlayersPageComponent} from './pages/run-players-page.component';

import {RunSearchBarComponent} from './components/run-search-bar.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {RunSettingsPageComponent} from './pages/run-settings-page.component';
import {SettingsFieldsComponent} from './components/settings-fields.component';
import {GameManagementModule} from "../game-management/game-management.module";
import {QRCodeModule} from "angular2-qrcode";
import {NgrxAuthModule} from "../ngrx-auth/ngrx-auth.module";
import {GameRunsEffectsLoadUsers} from "./store/game-runs.effects.load-users";
import {NewRunTileComponent} from './components/new-run-tile.component';
import {RunTileComponent} from './components/run-tile.component';
import {RunAmountOfPlayersComponent} from './components/run-amount-of-players.component';
import {RunTopNavComponentComponent} from './components/run-top-nav-component.component';
import {NewPlayerTileComponent} from './components/new-player-tile.component';
import {RunCollaboratorsPageComponent} from './pages/run-collaborators-page.component';
import {RunPlayerTileComponent} from './components/run-player-tile.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@NgModule({
  imports: [

    SharedModule.forRoot(),
    StoreModule.forFeature('run-management', reducers),
    EffectsModule.forFeature([GameRunsEffects, GameRunsEffectsLoadUsers]),
    MatButtonModule,
    MatIconModule,
    MatTableModule, MatPaginatorModule, MatSortModule,
    RouterModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDialogModule,
    MatSelectModule,
    MatMenuModule,
    GameMessagesModule,
    MatAutocompleteModule,
    ReactiveFormsModule, GameManagementModule, QRCodeModule,
    NgrxAuthModule, RouterModule,
    ClipboardModule, MatProgressSpinnerModule, CommonModule,


    // FirebaseNgrxUserManagementModule

  ],
    entryComponents: [
        NewRunDialogComponent, AddPlayerDialogComponent
    ],
    exports: [
        RunOverviewComponent, RunPlayersPageComponent,
         RunSettingsPageComponent, RunSearchBarComponent],
    declarations: [
        RunOverviewComponent, NewRunDialogComponent, RunsTableComponent,
        AddPlayerDialogComponent, RunPlayersPageComponent,
        RunSearchBarComponent, RunSettingsPageComponent,
        SettingsFieldsComponent,
        NewRunTileComponent,
        RunTileComponent,
        RunAmountOfPlayersComponent,
        RunTopNavComponentComponent,
        NewPlayerTileComponent,
        RunCollaboratorsPageComponent,
        RunPlayerTileComponent]
})
export class RunsManagementModule {
}

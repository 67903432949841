import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../../../core/reducers";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {GameMessagePageActions} from "../../../store/game-message.actions";
import {canWriteCurrentGame} from "../../../../game-authors/store/game-author.selectors";

@Component({
  selector: 'app-video-autoplay',
  template: `
    <div>
      <div class="gi-edit-field-label">{{'GAME.AUTO_PLAY' | translate}}</div>
      <mat-slide-toggle
        [disabled]="!(iCanWrite|async)"
        [(ngModel)]="videoAutoPlay"
        (change)="slideChange($event)">
        <div *ngIf="videoAutoPlay">
          {{'COMMON.ACTIVE' |translate}}
        </div>

        <div *ngIf="!videoAutoPlay">
          {{'COMMON.INACTIVE' |translate}}
        </div>
      </mat-slide-toggle>
    </div>
  `,
  styles: [
  ]
})
export class VideoAutoplayComponent implements OnInit {
  public iCanWrite: Observable<boolean> = this.store.select(canWriteCurrentGame);
  @Input() videoAutoPlay = false;

  constructor(public store: Store<State>) {
  }

  slideChange(changeEvent: MatSlideToggleChange) {
    if (!changeEvent.checked) {
      this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{autoPlay: false}}));
    } else {
      this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{autoPlay: true}}));
    }
  }

  ngOnInit(): void {
  }

}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-filestore-background-image',
  template: `

    <div storageBackground [targetPath]="path" class="h-100 w-100"
         (absent)="isAbsent($event)"
         (isVideo)="isVideo.emit($event)">
      <div *ngIf="deleteButton" class="deleteSplashScreen" (click)="deleteClickedEvent($event)">
        <mat-icon class="deleteIcon" matPrefix>delete</mat-icon>
      </div>

      <div *ngIf="editButton" class="deleteSplashScreen" (click)="editClickedEvent($event)">
        <mat-icon class="deleteIcon" matPrefix>create</mat-icon>
      </div>

      <ng-content></ng-content>
    </div>
  `,
  styles: [`
    .deleteSplashScreen {
      z-index: 10;
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 15px;
      width: 48px;
      height: 48px;
      background: #FAFAFA 0% 0% no-repeat padding-box;
      border: 1px solid #FFFFFF;
      border-radius: 2px;
      opacity: 1;
    }

    .deleteIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

    }

  `]
})
export class FilestoreBackgroundImageComponent implements OnInit, OnChanges {

  @Input() paths: string[];
  @Input() deleteButton = false;
  @Input() editButton = false;
  @Output() delete = new EventEmitter<boolean>();
  @Output() edit = new EventEmitter<boolean>();
  @Output() isVideo = new EventEmitter<boolean>();
  @Output() loadSuccess = new EventEmitter<boolean>();
  @Output() loadFailure = new EventEmitter<boolean>();

  url;
  path;

  ngOnInit() {
    if (this.paths.length != 0) {
      this.path = this.paths[0];
    }
  }

  deleteClickedEvent(event) {
    event.stopPropagation();
    this.delete.emit(true);
  }

  editClickedEvent(event) {
    event.stopPropagation();
    this.edit.emit(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paths) {
      if (this.paths.length != 0 && this.paths[0]) {
        setTimeout(() => {
          this.path = this.paths[0];
        }, 1);

      }
    }
  }

  isAbsent(event: boolean) {
    if (event) {
      this.loadFailure.emit(true);
    } else {
      this.loadSuccess.emit(true);
    }

  }
}

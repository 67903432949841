import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {RunList, RunUser} from "./run-players.state";


@Injectable({
  providedIn: 'root',
})
export class RunPlayerService {

  constructor(
    private http: HttpClient) {
  }

  listAllPlayers(from: number, resumptionToken?: string): Observable<RunList> {
    let postFix = '1';
    if (!!from) {
      postFix = from + '';
    }
    if (!!resumptionToken) {
      postFix += '?resumptionToken=' + resumptionToken;
    }
    return this.http
      .get<RunList>(environment.api_url + '/run/user/list/' + postFix)
      .pipe(
        map(list => ({
              ...list,
              users: (list.users || []).map(transOneGameAuthor)
            }
          )
        )
      );
  }

  listPlayersForGame(gameId: string, from: number, resumptionToken?: string): Observable<RunList> {
    let postFix = '1';
    if (!!from) {
      postFix = from + '';
    }
    if (!!resumptionToken) {
      postFix += '?resumptionToken=' + resumptionToken;
    }
    return this.http
      .get<RunList>(environment.api_url + '/run/game/' + gameId + '/users/' + postFix)
      .pipe(
        map(list => ({
              ...list,
              gameId: Number.parseInt(gameId, 10),
              users: (list.users || []).map(transOneGameAuthor)
            }
          )
        )
      );
  }

  listPlayersForRun(runId: string): Observable<RunList> {
    return this.http
      .get<RunList>(environment.api_url + '/run/' + runId + '/users')
      .pipe(
        map(list => ({
              ...list,
              games: (list.users || []).map(transOneGameAuthor)
            }
          )
        )
      );
  }


  addUser(runId: string, userId: string): Observable<RunUser> {
    return this.http
      .get<any>(environment.api_url + '/run/' + runId + '/addUser/' + userId)
      .pipe(map(res => transOneGameAuthor(res)));
  }

  deleteUser(runId: number, fullId: string) {
    return this.http
      .delete<any>(environment.api_url + '/run/' + runId + '/user/' + fullId);
  }


}

const transOneGameAuthor = (runUser: RunUser) => {
  if (runUser.gameId) {
    runUser.gameId = Number.parseInt(runUser.gameId + '', 10);
  }
  if (runUser.runId) {
    runUser.runId = Number.parseInt(runUser.runId + '', 10);
  }
  if (runUser.lastModificationDate) {
    runUser.lastModificationDate = Number.parseInt(runUser.lastModificationDate + '', 10);
  }
  return runUser;

};

export interface RunAccessList {
  runAccess: RunUser[];
  serverTime: number;
  from: number;
  resumptionToken?: string;
}

import {Component, Input} from '@angular/core';
import {Observable} from "rxjs";
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {getEditMessageSelector} from "../../store/game-message.selector";
import {GameMessagePageActions} from "../../store/game-message.actions";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {SelectAssetComponent} from "../../../portal-image-management/modals/select-asset.component";
import {MatDialog} from "@angular/material/dialog";

import {GameTheme} from "../../../game-themes/store/game-theme.state";
import {getCurrentGame} from "../../../games-management/store/game.selector";
import {canWriteCurrentGame} from "../../../game-authors/store/game-author.selectors";
import {getCurrentGameTheme} from "../../../game-themes/store/game-theme.selectors";
import {Game} from "../../../games-management/store/game.state";

@Component({
  selector: 'app-background-image-selector',
  template: `
    <app-filestore-background-image
      [paths]="[((message$|async)?.fileReferences[key]) || (selectedTheme|async)?.backgroundPath]"
      [deleteButton]="(iCanWrite$|async) && !hideControls && !((!(message$|async)?.fileReferences) || (!((message$|async)?.fileReferences[key])))"
      [editButton]="(iCanWrite$|async) && !hideControls && ((!(message$|async)?.fileReferences) || (!((message$|async)?.fileReferences[key])))"
      (delete)="deleteAsset()"
      (edit)="select()"
      (loadFailure)="onFail()"
    >
      <ng-content></ng-content>
    </app-filestore-background-image>
  `
})
export class BackgroundImageSelectorComponent {
  @Input() hideControls = true;

  @Input() key = 'background';
  public iCanWrite$: Observable<boolean> = this.store.select(canWriteCurrentGame);

  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);

  game$: Observable<Game> = this.store.select(getCurrentGame);
  selectedTheme: Observable<GameTheme> = this.store.select(getCurrentGameTheme);

  constructor(
    public store: Store<State>,
    public dialog: MatDialog
  ) {
  }

  attachSplash(event) {
    const objectRef = {};
    objectRef[this.key] = event;
    this.store.dispatch(GameMessagePageActions.updateFileRef({
      delete: false,
      key: this.key,
      value: event
      // fileReferences: objectRef
    }));
  }

  deleteAsset() {
    this.store.dispatch(GameMessagePageActions.updateFileRef({
      delete: true,
      key: this.key
    }));
  }

  select() {
    const dialogRef = this.dialog.open(SelectAssetComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.attachSplash(result);
    });
  }

  onFail() {
    this.store.dispatch(GameMessagePageActions.clearFileRef({key: this.key}));
  }
}

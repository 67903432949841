import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {getEditMessageSelector, selectedColor} from "../../store/game-message.selector";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-mobile-preview-video-question',
  template: `
    <app-background-image-selector>
      <div class="h-100 d-flex flex-column">
        <app-preview-navbar></app-preview-navbar>

        <div class="font-regular-16-24-roboto color-white p-1 "
             [ngStyle]="{'background-color': (selectedColor$ |async) }">
          {{(message$|async)?.richText}}
        </div>

        <div class="darken-background flex-grow-1 p-5 d-flex flex-column justify-content-end">

          <div
            [ngStyle]="{'background-color': (selectedColor$ |async) }"
            class="mobile-button-solid d-flex flex-row justify-content-center align-items-center mb-3">

            <div *ngIf="!(message$|async)?.description">{{'RUNS.NEXT' | translate}}</div>
            <div *ngIf="(message$|async)?.description">{{(message$|async)?.description}}</div>
          </div>

          <div class="mobile-button d-flex flex-row justify-content-center align-items-center">
            <div class="pr-2"><i class="fas fa-video"></i></div>
            <div>{{'MESSAGE.NEW_VIDEO_RECORDING' |translate}}</div>
          </div>
        </div>
      </div>
    </app-background-image-selector>
  `
})
export class MobilePreviewVideoQuestionComponent {

  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
  selectedColor$: Observable<string> = this.store.select(selectedColor);

  constructor(public store: Store<State>) {
  }

}

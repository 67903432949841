import {Component, Input, OnInit} from '@angular/core';
import {Game} from "../../../games-management/store/game.state";

@Component({
  selector: 'app-preview-pane',
  template: `
    <div class="position-relative">
      <app-preview-pane-header></app-preview-pane-header>
    </div>
    <div class="position-relative">

      <div class="d-flex flex-row-reverse align-items-center">

        <app-scale-preview
          (scale)="setScale($event)"
        ></app-scale-preview>
        <button

          *ngIf="showEdit"
          mat-stroked-button
          routerLink="fl/message/detail"
        >
          Aanpassen
          <mat-icon>edit</mat-icon>
        </button>
      </div>


      <div class="d-flex flex-row justify-content-center">
        <app-preview-smartphone [scale]="scale">
          <app-preview-content-body [scale]="scale"></app-preview-content-body>
        </app-preview-smartphone>
      </div>

    </div>
  `
})
export class PreviewPaneComponent {
  @Input() navTo: any;
  @Input() hideControls = false;
  @Input() game: Game;
  @Input() showEdit = false;
  @Input() editLink = '';
  scale = 1;

  constructor() {
  }

  setScale(newScale: any) {
    this.scale = newScale;
  }

}

import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {PlayerApiActions, PlayerPageActions,} from './player.actions';
import {State} from 'src/app/core/reducers';
import {catchError, distinct, filter, map, mergeMap} from 'rxjs/operators';
import {SetErrorAction} from '../../shared/store/shared.actions';
import {PlayerService} from "./player.service";
import {createResetAfterAuthenticatedEffect} from "../../shared/util/effects-util";


@Injectable()
export class PlayerEffects {
  constructor(
    private actions$: Actions,
    private player: PlayerService,
  ) {
  }

  // init$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(PlayerActionTypes.PLAYERS_LOAD_REQUESTED, PlayerActionTypes.PROCESS_INVITATIONID_COMPLETED),
  //     distinct(action => action.type),
  //     withLatestFrom(
  //       this.store$.select(getLoadingComplete)
  //     ),
  //     mergeMap(
  //       ([action, loadingComplete]: [any, boolean]) => {
  //         return this.player.getContacts().pipe(
  //           map(res => new PlayerLoadCompletedAction(res)),
  //           catchError((error) => {
  //             if (error) {
  //               return of(new SetErrorAction(error.error));
  //             } else {
  //               return of(new SetErrorAction({}));
  //             }
  //
  //           })
  //         );
  //       }
  //     )
  //   )
  // );


  // //todo ... check this
  // loadPlayers = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(GameAuthorActionTypes.LOAD_GAME_AUTHORS_COMPLETED),
  //     mergeMap(
  //       (action: LoadGameAuthorCompletedAction) => {
  //         if (!action.payload.gameAccess) {
  //           action.payload.gameAccess = [];
  //         }
  //         const ids: string = action.payload.gameAccess.map(access => access.account).join(";");
  //
  //         return this.player.getAccounts(ids).pipe(
  //           map(res => {
  //               res['connection'] = false;
  //               return new AllPlayersComplete(
  //                 res
  //               );
  //             }
  //           ),
  //           catchError((error) => {
  //             console.log("in catch error", error);
  //             return of(new SetErrorAction(error.error));
  //           })
  //         );
  //       }
  //     )
  //   )
  // );

  // resumeLoadingFriends = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(PlayerActionTypes.PLAYERS_LOAD_COMPLETED),
  //     filter((action: PlayerLoadCompletedAction) =>
  //       (action.payload.resumptionToken != null
  //         && action.payload.accountList != null
  //         && action.payload.accountList.length !== 0)
  //     ),
  //     mergeMap(
  //       (action: PlayerLoadCompletedAction) => {
  //         return this.player.getContactsWithResumption(action.payload.resumptionToken).pipe(
  //           map(res => {
  //
  //               const newResult = res;
  //               // newResult.localTime = action.payload.localTime;
  //               return new PlayerLoadCompletedAction(
  //                 newResult
  //               );
  //             }
  //           ),
  //           catchError((error) => {
  //             console.log("in catch error", error);
  //             return of(new SetErrorAction(error.error));
  //           })
  //         );
  //       }
  //     )
  //   )
  // );

  // resumeLoadingComplete = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(PlayerActionTypes.PLAYERS_LOAD_COMPLETED),
  //     filter((action: PlayerLoadCompletedAction) =>
  //       !(action.payload.resumptionToken != null
  //         && action.payload.accountList != null
  //         && action.payload.accountList.length !== 0)
  //     ),
  //     map(
  //       (action: PlayerLoadCompletedAction) => {
  //         return new AllPlayersResumeComplete();
  //       }
  //     )
  //   )
  // );

  pendinginviations = createEffect(
    () => this.actions$.pipe(
      ofType(PlayerPageActions.loadPending, PlayerApiActions.removePendingInvitationSuccess, PlayerApiActions.inviteContactCompleted),
      distinct(action => action.type),
      mergeMap(
        (action) =>

          this.player.getPendingContacts().pipe(
            map(accounts => PlayerApiActions.addPending({accounts})),
            catchError((error) => {
              console.log("in catch error", error);
              return of(new SetErrorAction(error.error));
            })
          )
      )
    )
  );

  pendinginviationsToMe = createEffect(
    () => this.actions$.pipe(
      ofType(PlayerPageActions.loadPendingToMe),
      mergeMap(
        (action) =>
          this.player.getPendingContactsToMe().pipe(
            map(accounts => PlayerApiActions.addPendingToMe({accounts})),
            catchError((error) => {
              return of(new SetErrorAction(error.error));
            })
          )
      )
    )
  );

  removePendinginviations = createEffect(
    () => this.actions$.pipe(
      ofType(PlayerPageActions.removePendingInvitation),
      mergeMap(
        (action) =>
          this.player.removePending(action.invitationId).pipe(
            map(res => PlayerApiActions.removePendingInvitationSuccess({invitationId: action.invitationId})),
            catchError((error) => {
              return of(new SetErrorAction(error.error));
            })
          )
      )
    )
  );

  resendPendinginviations = createEffect(
    () => this.actions$.pipe(
      ofType(PlayerPageActions.resendInvitation),
      mergeMap(
        (action) =>
          this.player.redoInvitationId(action.invitationId).pipe(
            map(_ => PlayerApiActions.resendInvitationSuccess({invitationId: action.invitationId}))
          )
      )
    )
  );

  addContact = createEffect(
    () => this.actions$.pipe(
      ofType(PlayerPageActions.inviteContact),
      mergeMap(
        (action) =>
          this.player.inviteContactWithEmailAndNote(action).pipe(
            map(_ => PlayerApiActions.inviteContactCompleted()),
            catchError((error) => of(new SetErrorAction(error.error)))
          )
      )
    )
  );

  removeContact = createEffect(
    () => this.actions$.pipe(
      ofType(PlayerPageActions.removeContact),
      mergeMap(
        (action) =>
          this.player.removeContact(action.player).pipe(
            map(player => PlayerApiActions.removeContactComplete({player})),
            catchError((error) => of(new SetErrorAction(error.error)))
          )
      )
    )
  );



  processInvitationAfterLogin = createEffect(
    () => this.actions$.pipe(
      ofType(PlayerPageActions.acceptInvitation),
      mergeMap((action) => this.player.confirmInvitationId(action.invitationId).pipe(
        map(x => action.invitationId)
      )),
      map(invitationId => PlayerApiActions.acceptInvitationSuccess({invitationId}))
    )
  );


  loadOnePlayer = createResetAfterAuthenticatedEffect(
    this.actions$,
    this.actions$.pipe(
      ofType(PlayerPageActions.loadOnePlayer),
      distinct((action) => action.fullId),
      mergeMap(action => this.player.getOnePlayer(action.fullId, action.isConnection)),
      filter(x => !!x),
      map((player: any) => PlayerApiActions.addAccount({player})),
    ))

}

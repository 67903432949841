import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../core/reducers';
import {Observable} from "rxjs";
import {getCurrentMessageSelector} from "../../game-messages/store/game-messages.selector";
import {GameMessagesPageActions} from "../../game-messages/store/game-messages.actions";

@Component({
  selector: 'app-arlearn-responses-table',
  template: `
    <div class="answers h-100" *ngIf="editMessage$ | async as editMessage">

      <ng-container *ngIf="isMediaQuestion(editMessage.type); else answers">
        <ng-container *ngIf="isPictureQuestion(editMessage.type) ">
          <app-photo-gallery class="w-100"></app-photo-gallery>
        </ng-container>
        <ng-container *ngIf="isAudioQuestion(editMessage.type) ">
          <app-audio-gallery class="w-100" ></app-audio-gallery>
        </ng-container>
        <ng-container *ngIf="isVideoQuestion(editMessage.type) ">
          <app-video-gallery class="w-100"  ></app-video-gallery>
        </ng-container>
        <ng-container *ngIf="isTextQuestion(editMessage.type)">
          <app-text-questions-gallery class="w-100"></app-text-questions-gallery>
        </ng-container>
      </ng-container>

      <ng-template #answers>
        <app-answers-gallery></app-answers-gallery>
      </ng-template>
    </div>
  `
})
export class ArlearnResponsesTableComponent implements OnInit {

  public editMessage$: Observable<any> = this.store.select(getCurrentMessageSelector);

    constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
    this.store.dispatch(GameMessagesPageActions.getMessages({}));
  }

  isImageTest(type) {
    return type.includes('ImageTest');
  }

  isMediaQuestion(type) {
    return [
      'PictureQuestion',
      'AudioQuestion',
      'VideoQuestion',
      'TextQuestion',
    ].some(messageType => type.includes(messageType));
  }

  isPictureQuestion(type) {
    return type.includes('PictureQuestion');
  }

  isAudioQuestion(type) {
    return type.includes('AudioQuestion');
  }

  isVideoQuestion(type) {
    return type.includes('VideoQuestion');
  }

  isTextQuestion(type) {
    return type.includes('TextQuestion');
  }

}

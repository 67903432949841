import {Component} from '@angular/core';

@Component({
  selector: 'app-message-search-bar',
  template: `
    <div
      class="w-100 d-flex flex-row">
      <div
        class="flex-grow-1  pr-2 ">
        <app-screen-title-filter></app-screen-title-filter>
      </div>
      <div
        class="flex-grow-1 pr-1 pl-1">
        <app-screen-type-filter></app-screen-type-filter>
      </div>

      <div
        class="flex-grow-1 pl-2">
        <app-screen-tag-filter> </app-screen-tag-filter>
      </div>
    </div>

  `,
  styles: [
  ]
})
export class MessageSearchBarComponent{

  constructor() { }


}

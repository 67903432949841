import {createActionGroup, emptyProps, props} from '@ngrx/store';

import {GamePlayerSyncTime, RunList, RunUser} from "./run-players.state";

export const RunPlayerPageActions = createActionGroup({
  source: 'Run Players Page',
  events: {
    'Get Players For Run':  emptyProps(),
    'Get Players For Game':  emptyProps(),
    'Add Player To Run': props<{ fullId: string }>(),
    'Delete Player From Run':  props<{ runUser: RunUser }>(),
  },
});

export const RunPlayerApiActions = createActionGroup({
  source: 'Run Players Api',
  events: {
    'Load All Success':  props<{ runList: RunList }>(),
    'Load Players For Run Success':  props<{ runList: RunList }>(),
    'Load Players For Game Success':  props<{ runList: RunList }>(),
    'Add Player To Run Success':  props<{ runUser: RunUser }>(),
    'Delete Player Success':  props<{ runUser: RunUser }>(),
  },
});

export const RunPlayerEffectActions = createActionGroup({
  source: 'Run Players Effect',
  events: {
    'Update All Sync Time':  props<{ time: number }>(),
    'Update Last Game Sync Time':  props<{ time: GamePlayerSyncTime }>(),
  },
});

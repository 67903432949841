import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {getEditMessageSelector, selectedColor} from "../../store/game-message.selector";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";

@Component({
    selector: 'app-mobile-preview-audio',
    template: `

        <app-background-image-selector>
            <div class="h-100">
                <app-preview-navbar></app-preview-navbar>
            </div>
        </app-background-image-selector>
    `
})
export class MobilePreviewAudioComponent  {


    message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
    selectedColor$: Observable<string> = this.store.select(selectedColor);

    constructor(public store: Store<State>) {
    }

}

import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {RunPlayerService} from "./run-players.service";
import {catchError, distinct, map, mergeMap, switchMap, withLatestFrom} from "rxjs/operators";
import * as fromRootSelector from "../../core/selectors/router.selector";
import {RunPlayerApiActions, RunPlayerPageActions} from "./run-players.actions";
import {RunList} from "./run-players.state";
import {of} from "rxjs";
import {SetErrorAction} from "../../shared/store/shared.actions";


@Injectable()
export class RunPlayersEffects {
  constructor(
    private runPlayerService: RunPlayerService,
    private actions$: Actions,
    private router: Router,
    private store$: Store<State>
  ) {
  }

  loadAllRunPlayers = createEffect(
    () => this.actions$.pipe(
      ofType(RunPlayerPageActions.getPlayersForRun),
      withLatestFrom(
        this.store$.select(fromRootSelector.selectRouteParam('runId'))
      ),
      distinct(([action, runId]) => runId),
      mergeMap(
        ([action, runId]) =>
          this.runPlayerService.listPlayersForRun(runId)
            .pipe(
              map((runList: RunList) => {
                return RunPlayerApiActions.loadPlayersForRunSuccess({runList});
              })
            )
      )
    )
  );

  addUserToRun = createEffect(
    () => this.actions$.pipe(
      ofType(RunPlayerPageActions.addPlayerToRun),
      withLatestFrom(
        this.store$.select(fromRootSelector.selectRouteParam('runId'))
      ),
      switchMap(
        ([action, runId]) =>
          this.runPlayerService.addUser(runId, action.fullId).pipe(
            map(runUser => RunPlayerApiActions.addPlayerToRunSuccess({
                runUser: {
                  ...runUser,
                  identifier: runUser.runId + ':' + runUser.fullId
                }
              })
            ),
            catchError((error) => of(new SetErrorAction(error.error)))
          )
      )
    ));

  readonly removeUserFromRun = createEffect(
    () => this.actions$.pipe(
      ofType(RunPlayerPageActions.deletePlayerFromRun),
      switchMap(
        (action) =>
          this.runPlayerService.deleteUser(action.runUser.runId, action.runUser.fullId).pipe(
            map(_ =>
              RunPlayerApiActions.deletePlayerSuccess({runUser: action.runUser})
            ),
          )
      )
    ));
}

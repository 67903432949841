import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GameLibraryUserComponent} from './pages/game-library-user.component';
import {SharedModule} from "../shared/shared.module";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {UserLibraryEffects} from "./store/user-library.effects";
import {FeaturedBannerComponent} from './components/featured-banner.component';
import {LibraryGamesTableComponent} from './components/library-games-table.component';
import {GameIconComponent} from './components/game-icon.component';
import {GameLibararyUserViewgameComponent} from './pages/game-libarary-user-viewgame.component';
import {QRCodeModule} from "angular2-qrcode";
import {MediaLibraryModule} from "../media-library/media-library.module";
import {GameCategoryModule} from "../game-category/game-category.module";
import {ViewAuthorEntryComponent} from "./components/view-author-entry.component";
import { ConnectUserButtonComponent } from './components/connect-user-button.component';
import {libraryReducer} from "./store/user-library.reducer";


@NgModule({
    declarations: [GameLibraryUserComponent, FeaturedBannerComponent, LibraryGamesTableComponent,
      GameIconComponent, GameLibararyUserViewgameComponent, ViewAuthorEntryComponent, ConnectUserButtonComponent],
    exports: [
        GameIconComponent
    ],
    imports: [
        CommonModule,
        SharedModule.forChild(),
        StoreModule.forFeature('gameLibrary', libraryReducer),
        EffectsModule.forFeature([UserLibraryEffects]),
        QRCodeModule,
        MediaLibraryModule,
        GameCategoryModule,
    ]
})
export class UserLibraryModule { }

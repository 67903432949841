import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Query} from "../store/arlearn-actions.actions";
import {GameRunsPageActions} from "../../runs-management/store/game-runs.actions";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {GamePageActions} from "../../games-management/store/game.actions";
import {Game} from "../../games-management/store/game.state";

@Component({
    selector: 'app-actions-overview',
    template: `
        <div class="full-width-container maxwidth">

            <div class="bibendo-center top70">
                <app-arlearn-actions-table></app-arlearn-actions-table>
            </div>
        </div>
    `
})
export class ActionsOverviewComponent implements OnInit {
    public game$: Observable<Game> = this.store.select(getCurrentGame);

    constructor(
        private store: Store<State>,
    ) {
    }

    ngOnInit(): void {

      this.store.dispatch(GamePageActions.getGame({}));
        // this.store.dispatch(new GetCurrentGameFromRouterRequestAction());
        this.store.dispatch(GameRunsPageActions.getRun({}));
        // this.store.dispatch(new PlayerLoadRequestAction());
        this.store.dispatch(new Query());
    }

}

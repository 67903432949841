import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from "@angular/material/table";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ResponsesOverviewComponent } from "./pages/responses-overview.component";
import { RunsManagementModule } from "../runs-management/runs-management.module";
import { GameMessagesModule } from "../game-messages/game-messages.module";
import { ArlearnResponsesTableComponent } from "./components/arlearn-responses-table.component";
import { runResponsesReducer } from "./store/run-responses.reducer";
import { RunResponsesEffects } from "./store/run-responses.effects";
import { SharedModule } from "../shared/shared.module";
import { GameMessageModule } from "../game-message/game-message.module";
import { PhotoGalleryComponent } from './components/photo-gallery.component';
import { AudioGalleryComponent } from './components/audio-gallery.component';
import { GameManagementModule } from "../game-management/game-management.module";
import { TextQuestionsGalleryComponent } from "./components/text-questions-gallery.component";
import { VideoGalleryComponent } from "./components/video-gallery.component";
import {UsersDropdownComponent} from "./components/users-dropdown.component";
import {NgxAudioPlayerModule} from "ngx-audio-player";
import { AudioFilePlayerComponent } from './components/audio-file-player.component';
import {MatSliderModule} from "@angular/material/slider";
import {MediaLibraryModule} from "../media-library/media-library.module";
import { PhotoGalleryTileComponent } from './components/photo-gallery-tile.component';
import { PhotoPlayerModalComponent } from './modals/photo-player-modal.component';
import { VideoPlayerModalComponent } from './modals/video-player-modal.component';
import { VideoGalleryTileComponent } from './components/video-gallery-tile.component';
import { ResponsesBottomToolbarComponent } from './components/responses-bottom-toolbar.component';
import { AnswersGalleryComponent } from './components/answers-gallery.component';
import { ResultsPageLoadFirstResultComponent } from './pages/results-page-load-first-result.component';
import { LoadingPlayerForResponseComponent } from './components/loading-player-for-response.component';


@NgModule({
  declarations: [
    ResponsesOverviewComponent,
    ArlearnResponsesTableComponent,
    PhotoGalleryComponent,
    AudioGalleryComponent,
    VideoGalleryComponent,
    TextQuestionsGalleryComponent,
    UsersDropdownComponent,
    AudioFilePlayerComponent,
    PhotoGalleryTileComponent,
    PhotoPlayerModalComponent,
    VideoPlayerModalComponent,
    VideoGalleryTileComponent,
    ResponsesBottomToolbarComponent,
    AnswersGalleryComponent,
    ResultsPageLoadFirstResultComponent,
    LoadingPlayerForResponseComponent,
  ],
    imports: [
        CommonModule,
        RunsManagementModule,
        GameMessagesModule,
        MatTableModule,
        StoreModule.forFeature('run-responses', runResponsesReducer),
        EffectsModule.forFeature([RunResponsesEffects]),
        SharedModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        GameMessageModule,
        GameManagementModule,
        NgxAudioPlayerModule,
        MatSliderModule,
        MediaLibraryModule,
        CommonModule
    ],
})
export class RunResponsesModule { }

import {Component, Input, OnInit} from '@angular/core';
import {AudioService} from "../store/audio.service";
import {RunResponse} from "../store/run-responses.state";
import {ref, Storage} from "@angular/fire/storage";
import {getDownloadURL} from "@firebase/storage";

@Component({
  selector: 'app-audio-file-player',
  template: `
    <div class="d-flex flex-row">

      <button mat-icon-button
              *ngIf="!state ||(state === 'pause' || state === 'ended')"
              (click)="play()"
      ><i class="fas fa-play"></i></button>

      <button mat-icon-button
              *ngIf="state === 'timeupdate' || state === 'playing'"
              (click)="pause()"
      ><i class="fas fa-pause"></i></button>

      <mat-slider min="0" [max]="duration" [value]="time"></mat-slider>
    </div>
  `,
  styles: []
})
export class AudioFilePlayerComponent implements OnInit {

  @Input() response: RunResponse;
  audioFile: string;
  state: string;

  constructor(private audioService: AudioService,
              public storage: Storage) {
  }

  time: number = 0;
  duration: number = 5;

  async ngOnInit() {
    this.audioFile = await getDownloadURL(ref(this.storage, this.response.responseValue));
  }


  play() {
    if (!this.state) {
      this.audioService.playStream(this.audioFile)
        .subscribe((events: any) => {
          // console.log("player event", events, );
          this.state = events.type
        });
    } else {
      this.audioService.play();
    }
    this.audioService.getState().subscribe(test => {
      if (test.url != this.audioFile) {
        this.state = null;
      } else {
        this.time = test.currentTime
        this.duration = test.duration
      }
    })

  }

  pause() {
    this.audioService.pause();
  }
}

import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {combineLatest, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {State} from 'src/app/core/reducers';
import {catchError, debounceTime, distinct, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import * as selector from "../../core/selectors/router.selector";
import {MediaLibraryApiActions, MediaLibraryPageActions} from "./media-lib.actions";
import {MediaLibraryService} from "../../core/services/medialibrary.service";
import {
  filesAvailableForUploading,
  getRelativePath,
  getSelectedFiles,
  nextFileForUploading
} from "./media-lib.selector";
import {FileToUpload} from "./media-lib.state";
import {Storage} from "@angular/fire/storage";

import {FullMetadata} from "@firebase/storage";
import {createResetAfterAuthenticatedEffect} from "../../shared/util/effects-util";

@Injectable()
export class MediaLibraryEffects {
  constructor(
    private mediaStore: MediaLibraryService,
    public afStorage: Storage,
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private store$: Store<State>
  ) {
  }


  loadFolders$ = createEffect(
    () => this.actions$.pipe(
      ofType(MediaLibraryPageActions.getFolders, MediaLibraryPageActions.setRelativePath),
      withLatestFrom(
        this.store$.select(selector.selectRouteParam('gameId')),
        this.store$.select(getRelativePath)
      ),
      filter(([, gameId]) => !!gameId),
      switchMap(([action, gameId, path]) => this.mediaStore.getGameFiles(gameId, path)),
      map(foldersAndFiles => MediaLibraryApiActions.addFoldersAndFiles(foldersAndFiles))
    ));

  deleteFiles = createEffect(
    () => this.actions$.pipe(
      ofType(MediaLibraryPageActions.deleteSelectedFiles),
      withLatestFrom(
        this.store$.select(selector.selectRouteParam('gameId')),
        this.store$.select(getSelectedFiles)
      ),
      mergeMap(([_, gameId, files]) => this.mediaStore.deleteGameFiles(gameId, files)),
      map(res => MediaLibraryPageActions.getFolders())
    ));


  startUpload = createEffect(
    () => this.actions$.pipe(
      ofType(MediaLibraryPageActions.startUpload, MediaLibraryApiActions.setUploadProgress),
      withLatestFrom(
        this.store$.select(filesAvailableForUploading)
      ),
      debounceTime(200),
      filter(x => x[1]),
      withLatestFrom(
        this.store$.select(nextFileForUploading)
      ),
      map(([[action, filesavailableForUp], ftu]: [[any, boolean], FileToUpload]) =>
        (ftu.customPath) ?
          this.mediaStore.upload(ftu.file, ftu.pathPrefix) :
          this.mediaStore.upload(ftu.file, ftu.pathPrefix + '/' + ftu.file.name)
      ),
      withLatestFrom(
        this.store$.select(nextFileForUploading)
      ),
      map(([_, nextFile]) => MediaLibraryApiActions.uploadCompleted({nextFile})),
    ));

  downloadMetadata$ =
    createResetAfterAuthenticatedEffect(
      this.actions$,
      this.actions$.pipe(
        ofType(MediaLibraryPageActions.downloadMetadata),
        distinct(a => a.path),
        mergeMap(action =>
          combineLatest([
            this.mediaStore.queryMetadata(action.path),
            this.mediaStore.getDownloadUrl(action.path)

          ]).pipe(
            catchError((error) => {
              this.store.dispatch(MediaLibraryApiActions.cacheUrl({
                fileMetadata: {
                  fullPath: action.path,
                  absent: true
                }
              }))
              // this.store.dispatch(new CacheUrlAction({
              //   fullPath: action.path,
              //   absent: true
              // }));
              return of([]);
            })
          ),
        ),
        filter((x: any[]) => x.length !== 0),

        map(([metadata, url]: [FullMetadata, string]) => MediaLibraryApiActions.addMedia({
          fileMetadata: {
            contentType: metadata.contentType,
            absent: false,
            fullPath: '/' + metadata.fullPath,
            name: metadata.name,
            url: url
          }
        })),
      )
    );


}



import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {reducers} from './core/reducers/';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ComponentPageTitle} from './pages/page-title/page-title';
import {LoginScreenComponent} from './pages/login-screen/login-screen.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AgmCoreModule} from '@agm/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {getAuth, provideAuth} from "@angular/fire/auth";
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getStorage, provideStorage} from '@angular/fire/storage';
import {HttpTokenInterceptor} from './core/services/http.token.interceptor';
import {TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SharedModule} from './shared/shared.module';
import {GamesManagementModule} from './games-management/games-management.module';
import {GameMessagesModule} from './game-messages/game-messages.module';
import {RunsManagementModule} from './runs-management/runs-management.module';
import {EffectsModule} from '@ngrx/effects';
import {GameMessageModule} from './game-message/game-message.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {LoginScreenNoSocialComponent} from './pages/login-screen/login-screen-no-social.component';
import {APP_ROUTES} from './routes';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatCardModule} from "@angular/material/card";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatSelectModule} from "@angular/material/select";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatSliderModule} from "@angular/material/slider";
import {MatNativeDateModule} from "@angular/material/core";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {RunActionsModule} from "./run-actions/run-actions.module";
import {RunResponsesModule} from "./run-responses/run-responses.module";
import {TutorialModule} from "./tutorial/tutorial.module";
import {GameThemesModule} from "./game-themes/game-themes.module";
import {UserLibraryModule} from "./user-library/user-library.module";
import {NgxGoogleAnalyticsModule} from "ngx-google-analytics";
import {GameCategoryModule} from "./game-category/game-category.module";
import {PlayerManagementModule} from "./player-management/player-management.module";
import {PortalImageManagementModule} from "./portal-image-management/portal-image-management.module";
import {NgrxAuthModule} from "./ngrx-auth/ngrx-auth.module";
import {AuthGuard} from "./ngrx-auth/guards/auth.guard";

import {localStorageSync} from 'ngrx-store-localstorage';
import {ServiceWorkerModule} from '@angular/service-worker';
import {GameAuthorsModule} from "./game-authors/game-authors.module";
import {RunAuthorsModule} from "./run-authors/run-authors.module";
import {RunPlayersModule} from "./run-players/run-players.module";
import {AuthActionTypes} from "./ngrx-auth/store/ngrx-auth.actions";


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

// const reducersLocal: ActionReducerMap<IState> = {todos, visibilityFilter};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      {'game': ['games', 'participateGames', 'lastSyncTime', 'lastSyncTimeParticipate']},
      {'accounts': ['ids', 'entities', 'lastSyncTime']},
      {'game-authors': ['ids', 'entities', 'lastSyncrTime']},
      {'game-theme': ['ids', 'entities', 'lastSyncTime', 'lastSyncTimeMy']},
      {'run-players': ['players', 'lastSyncTime', 'gameSyncTimes']},
      {'run-authors': ['ids', 'entities', 'lastSyncTime']},
      {'run-management': ['ids', 'entities']}
    ],
    rehydrate: true
  })(reducer);
}
export function clearStateOnLogout(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === AuthActionTypes.Logout) {
      state = undefined;
    }
    if (state && state['game-theme'] && state['game-theme']['themes']) {
      console.log('state was reset');
      state = undefined
    }
    if (state && state['run-management'] && state['run-management']['runs']) {
      console.log('state was reset');
      state = undefined
    }
    if (state && state['accounts'] && state['accounts']['allAccounts']) {
      console.log('state was reset');
      state = undefined
    }
    if (state && state['game-authors'] && state['game-authors']['authors']) {
      console.log('state was reset');
      state = undefined
    }
    if (state && state['run-authors'] && (state['run-authors']['authors'] || !state['run-authors'].ids) ) {
      console.log('run state was reset');
      state = undefined
    }
      return reducer(state, action);
  };
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, clearStateOnLogout];

@NgModule({
  declarations: [
    AppComponent, LoginScreenComponent,
    LoginScreenNoSocialComponent],
  exports: [
    TranslateModule
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    FormsModule,
    MatSidenavModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatDatepickerModule,
    FormsModule,
    MatSidenavModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatTabsModule,
    MatTooltipModule,
    RouterModule.forRoot(APP_ROUTES, {useHash: true}),
    HttpClientModule,
    StoreModule.forRoot(
      reducers,
      {metaReducers}
    ),
    EffectsModule.forRoot([]),
    CoreModule.forRoot(),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router' // name of reducer key
    }),
    StoreDevtoolsModule.instrument({
      name: 'Youplay dev tools',
      logOnly: environment.production,

    }),
    MatCheckboxModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      return auth;
    }),
    provideStorage(() => {
      const storage = getStorage();
      // if (environment.useEmulators) {
      //   connectStorageEmulator(storage, 'localhost', 9199);
      // }
      return storage;
    }),
    NgrxAuthModule,

    RunsManagementModule,
    GamesManagementModule,
    GameMessagesModule,
    GameMessageModule,
    GameThemesModule,
    GameAuthorsModule,
    RunAuthorsModule,
    RunPlayersModule,
    PlayerManagementModule,
    FontAwesomeModule,
    RunActionsModule,
    RunResponsesModule,
    TutorialModule,
    UserLibraryModule,

    NgxGoogleAnalyticsModule.forRoot(environment.gatracking),
    GameCategoryModule,
    PortalImageManagementModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    ComponentPageTitle,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    HttpClient,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

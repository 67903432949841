import {RunResponsesApiActions} from './run-responses.actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {RunResponse} from './run-responses.state';
import {Action, createReducer, on} from "@ngrx/store";


export interface RunResponseState extends EntityState<RunResponse> {
  // serverTime: number;
  // nextCursor: string;
  selectedUsers: { [fullId: string]: boolean };
}

export function selectIdentifier(a: RunResponse): string {
  return a.responseId;
}

export const arlearnActionsAdapter = createEntityAdapter<RunResponse>(
  {selectId: selectIdentifier}
);

const initialState: RunResponseState = arlearnActionsAdapter.getInitialState({
  // serverTime: 1,
  // nextCursor: undefined,
  selectedUsers: {}
});

const reducer = createReducer(
  initialState,
  on(RunResponsesApiActions.addOne,
    (state, action) => arlearnActionsAdapter.addOne(action.response, state)),
  on(RunResponsesApiActions.clear,
    (state, action) => arlearnActionsAdapter.removeAll({...state
      // , serverTime: 1
    })),
  on(RunResponsesApiActions.addAll,
    (state, action) => arlearnActionsAdapter.upsertMany(action.responses || [], {
      ...state,
      // serverTime: action.serverTime,
      // nextCursor: action.resumptionToken,
    })),
  on(RunResponsesApiActions.toggleSelectedUser,
    (state, action) => ({...state,
      selectedUsers: {...state.selectedUsers, [action.fullId]: !state.selectedUsers[action.fullId]}
    })),
);

export function runResponsesReducer(
  state: RunResponseState | undefined,
  action: Action
): RunResponseState {
  return reducer(state, action);
}

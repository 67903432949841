import {createFeatureSelector, createSelector,} from '@ngrx/store';

import * as fromRoot from 'src/app/core/reducers';
import {MediaLibraryState} from "./media-lib.reducer";

export interface State extends fromRoot.State {
    mediaLibrary: MediaLibraryState;
}

export const getMediaLibFeature = createFeatureSelector<MediaLibraryState>('mediaLibrary');

export const getFolders = createSelector(getMediaLibFeature, (state: MediaLibraryState) => state.folders);
export const getItems = createSelector(getMediaLibFeature, (state: MediaLibraryState) => state.items);
export const getUploadModus = createSelector(getMediaLibFeature, (state: MediaLibraryState) => state.uploadModus);
export const getFilesToUpload = createSelector(getMediaLibFeature, (state: MediaLibraryState) => state.filesToUpload);
export const getRelativePath = createSelector(getMediaLibFeature, (state: MediaLibraryState) => state.relativePath);
export const getAbsolutePath = createSelector(getMediaLibFeature, (state: MediaLibraryState) => state.absolutePath);
export const getSelectedFiles = createSelector(getMediaLibFeature,  (state: MediaLibraryState) => state.selectedFiles);

export const filesAvailableForUploading = createSelector(getFilesToUpload, (files) => {
    if (files.filter((f) => f.uploading).length >= 2) {
        return false;
    }
    if (files.filter((f) => !f.uploading).length > 0) {
        return true;
    }
    return false;
});
export const nextFileForUploading = createSelector(getFilesToUpload, (files) => {
    const notUploaded = files.filter((f) => !f.uploading);
    if (notUploaded.length === 0) {
        return null;
    }
    return notUploaded[0];
});
export const relativePathWithTrailingSlash = createSelector(getRelativePath,
    (path) => {
        if (path.endsWith("/")) {
            return path;
        }
        return path + "/";
    });

export const getSelectedFilesFullPath = createSelector(getSelectedFiles, getAbsolutePath,
    (files,  abs) => {
        return files.map(f => abs +  f);
    });


import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {topLevelMenu} from "../../shared.module.menu";

@Component({
  selector: 'app-top-nav',
  template: `
    <div class="root-tabs">
      <nav mat-tab-nav-bar [backgroundColor]="'primary'">

          <a mat-tab-link
             *ngFor="let item of items | permissionFilter | async "
             routerLinkActive="active"
             #trla="routerLinkActive"
             [ngClass]="{'active-color': trla.isActive}"
             [routerLink]="item.routerLink">{{ item.label |translate}}</a>
<!--</div>-->

        <!--        <a mat-tab-link-->

        <!--           routerLinkActive="active"-->
        <!--           [ngClass]="{'active-color': rla1.isActive}"-->
        <!--           #rla1="routerLinkActive"-->
        <!--           [routerLink]="'/portal/root/connections'"-->
        <!--        > {{'CONTACT.CONTACTS' |translate}}</a>-->
        <!--        <a mat-tab-link-->

        <!--           routerLinkActive="active"-->
        <!--           #rla2="routerLinkActive"-->
        <!--           [ngClass]="{'active-color': rla2.isActive}"-->
        <!--           [routerLink]="'/portal/root/library'"-->
        <!--        > {{'LIBRARY.LABEL' |translate}}</a>-->
        <!--        <a mat-tab-link-->
        <!--           *hasOnePermission="['admin']"-->

        <!--           routerLinkActive="active"-->
        <!--           #rla3="routerLinkActive"-->
        <!--           [ngClass]="{'active-color': rla3.isActive}"-->
        <!--           [routerLink]="'/portal/root/portal'"-->
        <!--        > {{'PORTAL_MANAGEMENT.LABEL' |translate}}</a>-->
        <!--        <a mat-tab-link-->

        <!--            routerLinkActive="active"-->
        <!--            #tut="routerLinkActive"-->
        <!--           [ngClass]="{'active-color': tut.isActive}"-->
        <!--            [routerLink]="'/tutorial/video'">{{'HELP.TITLE'|translate}}</a>-->

      </nav>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class TopNavComponent implements OnInit {

  @Input() items: {
    routerLink: string;
    label: string;
  }[] = topLevelMenu;

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AccountApiActions, AccountPageActions} from "../store/portal-user.actions";
import {PortalAccount} from "../store/portal-user.state";
import {getMyAccount} from "../store/portal-user.selector";

@Component({
  selector: 'app-reset-password',
  template: `
    <div class="login-screen pos-full-canvas a200-color">
      <div class="style-image-left pos-image-left" style="">

      </div>

      <div class="pos-full-canvas-right d-flex flex-column justify-content-center align-items-center">
        <div class="outer-form d-flex flex-column align-items-center" *ngIf="myAccount|async as me">
          <div class="primary-color font-medium-32-43-roboto mt-3 mb-4 " >
              Wachtwoord veranderen
            </div>
            <p *ngIf="errorAsync">{{errorAsync}}</p>

          <div class="contrast-color-50pct font-regular-17-13-roboto mt-2 mb-3">
            {{me.email}}
          </div>


            <form [formGroup]="displayNameForm"
                  class="w-100 mt-5"
                  *ngIf="me.email == me.displayName">

              <mat-form-field class="w-100 font-regular-16-24-roboto ">
                <input class="contrast-color-50pct"
                       matInput placeholder="{{ 'LOGIN.NAME' | translate }}"
                       [type]="'text'"
                       formControlName="displayName">
              </mat-form-field>

<!--              <div class="subtitle">Naam</div>-->
<!--              <mat-form-field-->
<!--                appearance="outline" class="login-field mat-form-field-appearance-outline">-->
<!--                <input type="text" matInput formControlName="displayName">-->

<!--              </mat-form-field>-->
              <div *ngIf="displayName.invalid && ( displayName.touched)"
                   class="alert alert-danger">
                <div *ngIf="displayName.errors?.['required']">
                  Naam is verplicht
                </div>
              </div>
            </form>

            <form [formGroup]="form" class="w-100 mt-5">

              <mat-form-field class="w-100 font-regular-16-24-roboto ">
                <input class="contrast-color-50pct"
                       matInput placeholder="{{ 'LOGIN.PASSWORD' | translate }}"
                       [type]="hide1 ? 'password' : 'text'"
                       formControlName="wachtwoord1">
                <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide1">
                  <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>

              <div *ngIf="wachtwoord1.invalid && (wachtwoord1.dirty || wachtwoord1.touched)"
                   class="alert alert-danger">

                <div *ngIf="wachtwoord1.errors?.['required']">
                  Wachtwoord is verplicht
                </div>
                <div *ngIf="wachtwoord1.errors?.['minlength']">
                  Wachtwoord moet minstens 6 karakters lang zijn.
                </div>
              </div>

              <mat-form-field class="w-100 font-regular-16-24-roboto ">
                <input class="contrast-color-50pct"
                       matInput placeholder="Type wachtwoord opnieuw"
                       [type]="hide1 ? 'password' : 'text'"
                       formControlName="wachtwoord2">
                <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide2">
                  <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>

              <div *ngIf="wachtwoord2.invalid && (wachtwoord2.dirty || wachtwoord2.touched)"
                   class="alert alert-danger">

                <div *ngIf="wachtwoord2.errors?.['required']">
                  Wachtwoord is verplicht
                </div>
                <div *ngIf="wachtwoord2.errors?.['minlength']">
                  Wachtwoord moet minstens 6 karakters lang zijn.
                </div>
              </div>
            </form>

            <button
              (click)="save()"
              [disabled]="(form.value.wachtwoord1 !== form.value.wachtwoord2 || !form.valid) || (validateName && !displayNameForm.valid)"
              class="login-btn"
              mat-flat-button color="primary">
              Opslaan
            </button>
          </div>
        </div>
      </div>

  `,
  styles: [
  ]
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  // error: Observable<string> = this.store.select(getError);
  errorAsync: string;
  validateName = false;
  public myAccount: Observable<PortalAccount> = this.store.select(getMyAccount);

  hide1 = true;
  hide2 = true;
  form = new FormGroup({
    wachtwoord1: new FormControl('', [Validators.required, Validators.minLength(6)]),
    wachtwoord2: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });

  displayNameForm = new FormGroup({
    displayName: new FormControl('', [Validators.required]),
  });

  private subscription = new Subscription();

  get wachtwoord1() { return this.form.get('wachtwoord1'); }
  get wachtwoord2() { return this.form.get('wachtwoord2'); }

  get displayName() { return this.displayNameForm.get('displayName'); }

  constructor(public store: Store<State>,) {
    // this.subscription.add(this.error.subscribe((errMessage)=>{
    //   if (errMessage) {
    //     this.errorAsync = errMessage;
    //     // this.store.dispatch(new ResetError());
    //   }
    // }));
    this.subscription.add(this.myAccount.subscribe((account)=>{
      if (account) {
        if (account.email == account.name) {
          this.validateName = true;
        }
        // this.store.dispatch(new ResetError());
      }
    }))
  }

  ngOnInit(): void {
    this.store.dispatch(AccountPageActions.retrievePasswordResetAccountInfo())
  }

  save() {
    this.store.dispatch(AccountPageActions.setInitialPassword({password:this.form.value.wachtwoord1}))
    // if (this.validateName) {
    //   this.store.dispatch(new SetInitialPassword(this.form.value.wachtwoord1, this.displayNameForm.value.displayName))
    // } else {
    //   this.store.dispatch(new SetInitialPassword(this.form.value.wachtwoord1))
    // }

  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {ActionsSubject, Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {currentGameOwners, ownsCurrentGame} from "../../game-authors/store/game-author.selectors";
import {GamePageActions} from "../../games-management/store/game.actions";
import {GameAuthorActions} from "../../game-authors/store/game-author.actions";
import {ofType} from "@ngrx/effects";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-game-settings-fields',
  template: `
    <div class="screen-editor-center">
      <div class="fields-container">
        <div class="title font-regular-24-24-roboto primary-color">{{'USER.GENERIC' | translate}}</div>
        <mat-form-field class="game-form-title">
          <mat-label>{{'GAME.NAME_FIELD'|translate}}</mat-label>
          <input matInput [placeholder]="'GAME.TITLE'|translate"
                 [disabled]="!(iAmOwner|async)"
                 [ngModel]="localgame?.title"
                 (ngModelChange)="gameTitleChange($event)">

        </mat-form-field>
        <mat-form-field class="game-form-title">
          <mat-label>{{'GAME.ICON_ABR'|translate}}</mat-label>
          <input matInput
                 [disabled]="!(iAmOwner|async)"
                 [ngModel]="localgame?.iconAbbreviation"
                 (ngModelChange)="gameIconAbbrChange($event)">

        </mat-form-field>
        <mat-form-field class="game-form-title">
          <mat-label>{{'GAME.DESCRIPTION_FIELD'|translate}}</mat-label>
          <textarea matInput [placeholder]="'GAME.DESCRIPTION'|translate"
                    [disabled]="!(iAmOwner|async)"
                    [ngModel]="localgame?.description"
                    (ngModelChange)="gameDescriptionChange($event)"></textarea>
        </mat-form-field>

        <mat-form-field class="game-form-title">
          <mat-label>{{'GAME.START_BUTTON'|translate}}</mat-label>
          <input matInput
                 [disabled]="!(iAmOwner|async)"
                 [ngModel]="localgame?.startButton"
                 (ngModelChange)="gameStartButtonChange($event)">

        </mat-form-field>

        <mat-form-field class="game-form-title">
          <mat-label>{{'GAME.GAME_OVER_HEADING'|translate}}</mat-label>
          <input matInput
                 [disabled]="!(iAmOwner|async)"
                 [ngModel]="localgame?.gameOverHeading"
                 (ngModelChange)="localgame.gameOverHeading = $event">

        </mat-form-field>

        <mat-form-field class="game-form-title">
          <mat-label>{{'GAME.GAME_OVER_DESCRIPTION'|translate}}</mat-label>
          <textarea matInput
                    [disabled]="!(iAmOwner|async)"
                    [ngModel]="localgame?.gameOverDescription"
                    (ngModelChange)="localgame.gameOverDescription = $event"></textarea>
        </mat-form-field>


        <mat-form-field class="game-form-title">
          <mat-label>{{'GAME.GAME_OVER_BUTTON'|translate}}</mat-label>
          <input matInput
                 [disabled]="!(iAmOwner|async)"
                 [ngModel]="localgame?.gameOverButton"
                 (ngModelChange)="localgame.gameOverButton = $event">

        </mat-form-field>

        <app-game-detail-message-list-type
          [messageListTypesString]="(game$|async)?.messageListTypes"
          (changeListType)="localgame.messageListTypes = $event"
        >

        </app-game-detail-message-list-type>
        <app-game-detail-chapter
        [localgame]="localgame"
        (changeChapters)="chaptersChange($event)"
        ></app-game-detail-chapter>

        <app-game-detail-collaborators [gameAuthors]="gameAuthors$ | async"
                                       (onNewAuthor)="onNewAuthor($event)"
                                       (onRoleChange)="onRoleChange($event)"
                                       (onDelete)="onAuthorDelete($event)"></app-game-detail-collaborators>
        <app-game-detail-access
          [accessValue]="(game$|async)?.sharing"
          (optionChange)="localgame.sharing = $event"
        ></app-game-detail-access>

        <app-game-detail-creative-commons
          (licenseChange)="localgame.licenseCode = $event"
          *ngIf="(localgame.sharing && localgame.sharing === 3) ||(!localgame.sharing && game$|async)?.sharing == 3"></app-game-detail-creative-commons>

        <div class="account-private">{{'GAME.LOGIN_REQUIRED' | translate}}</div>
        <mat-slide-toggle
          [disabled]="!(iAmOwner|async) &&(game$|async)?.sharing == 3"
          [ngModel]="!localgame?.privateMode"
          (change)="gamePrivateChange($event)">
          <div *ngIf="localgame?.privateMode">{{'GAME.PLAY_WITHOUT_ACCOUNT' | translate}}</div>
          <div *ngIf="!localgame?.privateMode">{{'GAME.ACCOUNT_NECESS' | translate}}</div>
        </mat-slide-toggle>

        <div class="account-private">{{'GAME.WEB_BASED' | translate}}</div>
        <mat-slide-toggle
          [disabled]="!(iAmOwner|async) &&(game$|async)?.sharing == 3"
          [ngModel]="localgame?.webEnabled"
          (change)="gameWebEnabledChange($event)">
          <div *ngIf="localgame?.webEnabled">{{'GAME.MOBILE_AND_WEB' | translate}}</div>
          <div *ngIf="!localgame?.webEnabled">{{'GAME.MOBILE_ONLY' | translate}}</div>
        </mat-slide-toggle>


        <app-game-detail-location
          (mapAvailable)="mapAvailable($event)"
          (locationUpdate)="locationUpdate($event)"
        ></app-game-detail-location>

        <button *ngIf="iAmOwner|async"
                routerLink="../screens"
                class="gl-pos-button-right pos-button gl-style-stroke-button gl-style-large-button"
                mat-stroked-button color="primary" (click)="submit()">{{'ACTIONS.SAVE' |translate}}</button>
      </div>
    </div>
  `,
  styles: [`
    .account-private {
      margin-top: 17px;
      margin-bottom: 15px;
      text-align: left;
      font: 100 12px/16px Roboto;
      letter-spacing: 0;
      color: #0000008A;
      opacity: 1;
    }

    .title {
      margin-top: 74px;
      width: 100%;
      text-align: left;

      letter-spacing: 0;
      opacity: 1;
    }

    .game-form-title {
      margin-top: 22px;
      width: 100%;
    }

    .screen-editor-center {
      min-width: 538px;
      max-width: 937px;
    }

    .fields-container {

      top: 74px;
      width: 488px;
    }

    .pos-button {
      margin-top: 40px;
      width: 131px;
    }

  `]
})
export class GameSettingsFieldsComponent implements OnInit, OnDestroy {
  public gameAuthors$: Observable<any[]> = this.store.select(currentGameOwners);

  public game$: Observable<Game> = this.store.select(getCurrentGame);
  public iAmOwner: Observable<boolean> = this.store.select(ownsCurrentGame);

  public localgame: Game;
  private subscription = new Subscription();

  constructor(public store: Store<State>, private actionsSubject$: ActionsSubject,) {
  }


  ngOnInit(): void {

    this.subscription.add(this.actionsSubject$.pipe(
      ofType(GamePageActions.selectTheme),
    ).subscribe((action) => {
      console.log("theme is now", action.theme.themeId);
      this.localgame.theme = action.theme.themeId;
    }));

    this.subscription.add(this.game$.subscribe((game) => {
      this.localgame = {...game};
    }));

    // this.store.dispatch(new PlayerLoadRequestAction());
  }

  gameTitleChange(event: any) {
    this.localgame.title = event;
  }

  gameIconAbbrChange(event: any) {
    this.localgame.iconAbbreviation = event;
  }

  gameDescriptionChange(event: any) {
    this.localgame.description = event;
  }

  gameStartButtonChange(event: any) {
    this.localgame.startButton = event;
  }

  mapAvailable(changeEvent: MatSlideToggleChange) {
    if (!this.localgame.config) {
      this.localgame.config = {}
    }
    this.localgame.config.mapAvailable = changeEvent.checked

  }

  submit() {

    this.store.dispatch(GamePageActions.saveGame({game: this.localgame}));

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  gamePrivateChange($event: MatSlideToggleChange) {
    this.localgame.privateMode = !$event.checked;

  }

  gameWebEnabledChange($event: MatSlideToggleChange) {
    this.localgame.webEnabled = $event.checked;
  }

  chaptersChange(event: number) {
    this.localgame.chapters = event;
  }

  onAuthorDelete(fullId) {
    this.store.dispatch(GameAuthorActions.deleteGameAuthor({fullId}));
  }

  onRoleChange(data: { fullId: string; role: string }) {
    this.store.dispatch(GameAuthorActions.addGameAuthor(data));
  }

  onNewAuthor(data: { fullId: string; role: string }) {
    this.store.dispatch(GameAuthorActions.addGameAuthor(data));
  }


  locationUpdate(event: { lat: number; lng: number }) {

    this.localgame = {...this.localgame, ...event};

  }
}

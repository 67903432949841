import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {AccountList, Player} from './player.state';

export const PlayerPageActions = createActionGroup({
  source: 'Accounts Page',
  events: {
    'Load One Player': props<{ fullId: string, isConnection: boolean }>(),
    'Set Filter' : props<{filters: string[] }>(),
    'Load Pending' : emptyProps(),
    'Load Pending To Me' : emptyProps(),
    'Remove contact': props<{ player: Player }>(),
    'Accept Invitation': props<{ invitationId: string }>(),
    'Resend Invitation': props<{ invitationId: string }>(),
    'Remove Pending Invitation': props<{ invitationId: string }>(),
    'Invite Contact': props<{ email: string, note?: string, addpers: boolean }>(),
  },
});

export const PlayerApiActions = createActionGroup({
  source: 'Accounts Api',
  events: {
    'Add accounts': props<{ accounts: AccountList }>(),
    'Add account': props<{ player: Player }>(),
    'Update full sync time': props<{ time: number }>(),
    'Add Pending': props<{ accounts: AccountList }>(),
    'Add Pending To Me': props<{ accounts: AccountList }>(),
    'Remove contact complete': props<{ player: Player }>(),
    'Accept Invitation Success': props<{ invitationId: string }>(),
    'Resend Invitation Success': props<{ invitationId: string }>(),
    'Remove Pending Invitation Success': props<{ invitationId: string }>(),
    'Invite Contact Completed': emptyProps(),
  },
});



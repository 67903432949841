import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {filter, map} from "rxjs/operators";
import {GameRun, RunUser} from "./game-runs.state";


@Injectable({
  providedIn: 'root',
})
export class GameRunsService {

  constructor(
    private http: HttpClient) {
  }

  get(runId: string): Observable<GameRun> {
    return this.http
      .get<any>(environment.api_url + '/run/' + runId)
      .pipe(
        filter(run => !!run),
        map(addOptionalData)
      );
  }


  listMyRuns(gameId: string, cursor: string): Observable<RunList> {
    let path = '/runs/' + gameId + '/myList';
    if (cursor) {
      path += '?resumptionToken=' + cursor;
    }
    return this.http
      .get<any>(environment.api_url + path)
      .pipe(map(runTrans));
  }


  createRun(run: any): Observable<GameRun> {
    delete run.game;
    return this.http
      .post(environment.api_url + '/run/create', run)
      .pipe(map(addOptionalData));
  }

  deleteRun(runId: number) {
    return this.http
      .delete<any>(environment.api_url + '/run/delete/' + runId);
  }

}

export interface RunList {
  runs?: RunUser[];
  serverTime: number;
  from: number;
  resumptionToken: string
}


const runTrans = (res: any) => {
  if (!res.runs) {
    res.runs = [];
  }
  res.runs = res.runs.map(addOptionalData);
  return res;
};

const transOneRunUser = (game: RunUser) => {
  if (game.lastModificationDate) {
    game.lastModificationDate = Number.parseInt(game.lastModificationDate + '', 10);
  }
  if (game.gameId) {
    game.gameId = Number.parseInt(game.gameId + '', 10);
  }
  if (game.runId) {
    game.runId = Number.parseInt(game.runId + '', 10);
  }
  return game;

};


const addOptionalData = (run: GameRun) => {
  run.runId = Number.parseInt(run.runId + "", 10);
  run.gameId = Number.parseInt(run.gameId + "", 10);
  run.lastModificationDate = Number.parseInt(run.lastModificationDate + "", 10);
  run.serverCreationTime = Number.parseInt(run.serverCreationTime + "", 10);
  if (!run.runConfig) {
    run.runConfig = {};
  }
  if (!run.runConfig.selfRegistration) {
    run.runConfig.selfRegistration = false;
  }
  return run;
};

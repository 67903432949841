import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "src/app/core/reducers";
import {GameCategoryPageActions} from "../store/game-category.actions";
import {getCurrentGameCategoryMappings} from "../store/game-category.selectors.mappings";
import {Observable} from "rxjs";

@Component({
  selector: 'app-category-view-labels',
  template: `
    <div class="mb-5">
      <div class="form-group">
        <label class="form-label">{{'PORTAL_MANAGEMENT.GAMES.CATEGORY' | translate}}</label>
        <p class="form-description font-medium-16-24-roboto">

        <span
          *ngFor="let category of categories | async; let last = last; ">
          {{category.title}}
          <span *ngIf="!last">,&nbsp;</span>
        </span>
        </p>
      </div>
    </div>
  `,
  styles: []
})
export class CategoryViewLabelsComponent implements OnInit, OnChanges {

  categories: Observable<any> = this.store.select(getCurrentGameCategoryMappings);
  @Input() gameId: any;

  constructor(
    private store: Store<State>
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(GameCategoryPageActions.query());
  }

  remove(categoryId: any) {
    this.store.dispatch(GameCategoryPageActions.removeMapping({mappingId: categoryId + ":" + this.gameId}));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gameId) {
      this.store.dispatch(GameCategoryPageActions.queryMappings({gameId: this.gameId}));
    }
  }


}

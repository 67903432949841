import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {MediaLibraryPageActions} from "../store/media-lib.actions";
import {selectEntities} from "../store/media-lib.selector.cache";
import {State} from "../../core/reducers";

@Directive({
  selector: '[storageBackground]'
})
export class ImageBackgroundDirective implements OnInit, OnDestroy, OnChanges {

  @Input() targetPath;
  @Input() initColor = '#F5F5F7';
  @Output() absent = new EventEmitter();
  @Output() isVideo = new EventEmitter<boolean>();
  url: string;
  subscription = new Subscription();

  constructor(
    private store: Store<State>,
    private elRef: ElementRef,
    private renderer: Renderer2) {
    this.renderer.setStyle(
      this.elRef.nativeElement,
      'background-color',
      this.initColor);
    this.renderer.setStyle(
      this.elRef.nativeElement,
      'background-size', 'cover');
  }

  ngOnInit(): void {
    if (this.targetPath) {
      let path = this.targetPath;
      path = path.replace('//', '/');
      if (!path.startsWith('/')) {
        path = '/' +path
      }
      this.subscription.add(this.store.select(selectEntities).subscribe(_entities => {

        if (_entities[path]) {
          if (_entities[path].isVideo || ((_entities[path]?.contentType || '').indexOf('video') !== -1) ) {
            this.isVideo.emit(true);
          } else {
            this.isVideo.emit(false);
          }
          if (_entities[path].absent) {
            this.absent.emit(true);
          }
          if (_entities[path].url) {
            this.renderer.setStyle(
              this.elRef.nativeElement,
              'background-image',
              `url("${_entities[path].url})"`);
          }

        }
        // else {
        //   console.log("path not found");
        //   this.absent.emit(true);
        // }
      }))
    }

  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.targetPath && this.targetPath) {
      setTimeout(()=>{
        this.store.dispatch(MediaLibraryPageActions.downloadMetadata({path: this.targetPath, ignoreDistinct: false}));
      }, 500);

      this.ngOnInit();
    }
  }


}

import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {getEditMessageSelector, selectedColor} from "../../store/game-message.selector";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {GameMessagePageActions} from "../../store/game-message.actions";

@Component({
    selector: 'app-mobile-preview-multiple-choice-image',
    template: `
            <app-background-image-selector>
              <div class="h-100 d-flex flex-column justify-content-between">
                <app-preview-navbar></app-preview-navbar>
                <div class="text-box-preview d-flex flex-column justify-content-end m-3 p-2">
                  <div
style="overflow-y: scroll"
                    class="p-2 d-flex flex-column justify-content-between">

                  <div
                                [ngStyle]="{'color': (selectedColor$ |async) }"
                                class="screen-preview-card-title font-bold-20px-24px-gentium-basic "
                        > {{(message$|async)?.text}}
                        </div>
                        <mat-grid-list class="pos-grid-list" cols="2" rowHeight="4:4">
                            <mat-grid-tile
                                    [colspan]="(((i==0) && (((message$|async)?.answers.length %2) === 1 )) || ((message$|async)?.answers.length == 2))?2:1"
                                    *ngFor="let answer of (message$|async)?.answers; let i = index">

                              <app-filestore-background-image
                                class="h-100 w-100 mt-2"
                                        *ngIf="!((!(message$|async)?.fileReferences) || (!((message$|async)?.fileReferences[answer.id]))) "
                                        [paths]="[((message$|async)?.fileReferences[answer.id])]"

                                        (delete)="deleteAsset(answer.id)"

                                >
                                    <ng-content></ng-content>
                                </app-filestore-background-image>
                            </mat-grid-tile>
                        </mat-grid-list>
                    <div
                      [ngStyle]="{'background-color': (selectedColor$ |async) }"
                      class="mt-3 mobile-button-solid d-flex flex-row justify-content-center align-items-center mb-3">

                      <div *ngIf="!(message$|async)?.description">{{'RUNS.NEXT' | translate}}</div>
                      <div *ngIf="(message$|async)?.description">{{(message$|async)?.description}}</div>
                    </div>
                  </div>
                    </div>

                </div>
            </app-background-image-selector>

    `
})
export class MobilePreviewMultipleChoiceImageComponent implements OnInit {

    message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
    selectedColor$: Observable<string> = this.store.select(selectedColor);

    constructor(public store: Store<State>) {
    }

    ngOnInit() {
    }

    deleteAsset(key) {
        this.store.dispatch(GameMessagePageActions.updateFileRef({
            delete: true,
            key: key
        }));
    }

}

import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {Category, PortalGame} from './portal-games.state';


export const PortalGamesPageActions = createActionGroup({
  source: 'Portal Games Page',
  events: {
    'Get Portal Games': emptyProps(),
    'Get Portal Game': props<{gameId?: number}>(),
    'Get Categories': emptyProps(),
    'Query Portal Games': props<{query: string}>(),
    'Set Featured': props<{gameId: number, value: boolean}>(),
    'Delete Featured Game Image': props<{targetPath: string}>(),
    'Save Portal Game': emptyProps(),
  },
});

export const PortalGamesApiActions = createActionGroup({
  source: 'Portal Games Api',
  events: {
    'Add Portal Game': props<{game: PortalGame}>(),
    'Add Search Portal Games': props<{games: PortalGame[]}>(),
    'Set Categories':  props<{categories: Category[]}>(),
    'Set Featured Response':  props<{gameId: string, featured: boolean}>(),
    'Delete Featured Game Image Response':  props<{path: string}>(),
  },
});

import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as selector from 'src/app/core/selectors/router.selector';
import {GameMessagesApiActions, GameMessagesPageActions} from './game-messages.actions';
import {State} from 'src/app/core/reducers';
import {delay, distinct, filter, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import * as fromRoot from "../../core/selectors/router.selector";
import {GameMessagesServiceNew} from "./game-messages.service";


@Injectable()
export class GameMessagesEffects {
  constructor(
    private actions$: Actions,
    private gameMessageServiceNew: GameMessagesServiceNew,
    private store$: Store<State>
  ) {
  }

  init = createEffect(
    () => this.actions$.pipe(
      ofType(GameMessagesPageActions.getMessages),
      delay(200),
      withLatestFrom(this.store$.select(selector.selectRouteParam('gameId'))),
      distinct(([action, gameId]) => gameId),
      mergeMap(
        ([action, gameId]) => {
          this.store$.dispatch(GameMessagesPageActions.setLoading({loading: true}));
          return this.gameMessageServiceNew.listMessagesWithCursor(gameId || action.gameId, '*').pipe(
            map(res => GameMessagesApiActions.getMessagesComplete({
                gameId, items: res.generalItems || [], cursor: res.resumptionToken == null ? 'stop' : res.resumptionToken
              })
            )
          )
        }
      )
    )
  );


  resume = createEffect(
    () => this.actions$.pipe(
      ofType(GameMessagesApiActions.getMessagesComplete),
      delay(200),
      withLatestFrom(this.store$.select(selector.selectRouteParam('gameId')),),
      filter(([action, gameId]) => action.cursor !== 'stop'),
      mergeMap(
        ([action, gameId]) => {
          this.store$.dispatch(GameMessagesPageActions.setLoading({loading:true}));
          return this.gameMessageServiceNew.listMessagesWithCursor(gameId || action.gameId, action?.cursor || '*').pipe(
            map(res =>
              GameMessagesApiActions.getMessagesComplete({
                gameId,
                items: res.generalItems || [],
                cursor: res.resumptionToken == null ? 'stop' : res.resumptionToken
              })
            )
          )
        }
      )
    )
  );

  getMessage$ = createEffect(
    () => this.actions$.pipe(
      ofType(GameMessagesPageActions.getMessage),
      withLatestFrom(this.store$.select(fromRoot.selectRouteParam('messageId'))),
      filter(([_, messageId]) => messageId != '-1'),
      distinct(([action, messageId]) => messageId),
      mergeMap(([action, messageId]) => this.gameMessageServiceNew.getMessage(messageId)),
      map(gameMessage => GameMessagesApiActions.addMessage({gameMessage}))
    )
  );

  delete = createEffect(
    () => this.actions$.pipe(
      ofType(GameMessagesPageActions.deleteMessage),
      withLatestFrom(this.store$.select(selector.selectRouteParam('gameId'))),
      mergeMap(([action, gameId]) => this.gameMessageServiceNew.deleteMessage(gameId, action.messageId)),
      map(gameMessage => GameMessagesApiActions.removeMessage({gameMessage}))
    ));

  new = createEffect(
    () => this.actions$.pipe(
      ofType(GameMessagesPageActions.newMessage),
      withLatestFrom(this.store$.select(selector.selectRouteParam('gameId'))),
      mergeMap(([action, gameId]) => this.gameMessageServiceNew.postMessage({
        ...action.gameMessage,
        gameId: Number.parseInt(gameId, 10)
      })),
      map(gameMessage => GameMessagesApiActions.addMessage({gameMessage}))
    ));
}

import {Component, EventEmitter, Output} from '@angular/core';
import {Observable} from "rxjs";
import {RunResponse} from "../store/run-responses.state";
import {currentItemFilteredUsersResponses} from "../store/run-responses.selectors";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {selectEntities} from "../../player-management/store/player.selector";

@Component({
  selector: 'app-text-questions-gallery',
  template: `
    <div class="text-questions-gallery" *ngIf="responses$ |async as responses">
      <div *ngIf="players$ | async as playersDict" class="wrapper">

        <div class="answer" *ngFor="let res of responses">
          <div *ngIf="playersDict[res.userId] as player; else loadingplayer" class="d-flex flex-row h-100">

            <div class="icon-row">
              <div class="user__avatar">{{player.shortAvatar}}</div>

            </div>
            <div
              class="flex-grow-1 text-details-card"
            >
              <div class=" font-regular-14-24-roboto">{{ player?.name }} &bull; {{ res.timestamp | date }}</div>
              <div class="details-content font-regular-12-20-roboto color-black-de">
                {{ res.responseValue }}
              </div>
            </div>

          </div>


          <ng-template #loadingplayer>
            <app-loading-player-for-response
            [userId]="res.userId"
            ></app-loading-player-for-response>
          </ng-template>

        </div>

      </div>
      <div class="load-wrapper" *ngIf="loading">
        <span>{{'COMMON.LOADING'| translate}}</span>
      </div>
    </div>
  `
})
export class TextQuestionsGalleryComponent {
  public responses$: Observable<RunResponse[]> = this.store.select(currentItemFilteredUsersResponses);
  public players$: Observable<any> = this.store.select(selectEntities);

  public loading = false;
  @Output() public onLoad: EventEmitter<any> = new EventEmitter<any>();

  constructor(private store: Store<State>,) {
  }

}

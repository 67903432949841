import {Component, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Subscription} from "rxjs";
import {PlayerPageActions} from "../../player-management/store/player.actions";
import {selectAll} from 'src/app/player-management/store/player.selector';
import {map} from "rxjs/operators";
import {FriendAddDialogComponent} from "../../player-management/components/friend-add-dialog/friend-add-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-view-author-entry',
  template: `
    <div *ngIf="accountEntities | async as account">
      <b>{{account.name}}</b>

      <button mat-mini-fab
              class="ml-2"
              *ngIf="!account.isConnection"
              (click)="addAsContact(account.email)"
      > <mat-icon>person_add</mat-icon>
      </button>
    </div>
  `,
  styles: [`

    .mat-form-field-appearance-legacy .mat-form-field-underline {
      height: 0px;
    }


  `]
})
export class ViewAuthorEntryComponent implements OnInit {
  public accountEntities = this.store.select(selectAll).pipe(
    map(players => players.filter(p => p.fullId == this.author.account)),
    map(players => players[0])
  );

  @Input() author: any;
  public subscription = new Subscription();

  constructor(public store: Store<State>, public dialog: MatDialog) {
  }

  ngOnInit() {
    if (!this.author.name) {
      this.store.dispatch(PlayerPageActions.loadOnePlayer({fullId: this.author.account, isConnection: false}))
    }
  }

  addAsContact(email: string) {
    const dialogRef = this.dialog.open(FriendAddDialogComponent, {
      data: {email: email},
      panelClass: ['modal-fullscreen', "modal-dialog"]
    });

    this.subscription.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(PlayerPageActions.inviteContact(result));
      }
    }));
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {State} from 'src/app/core/reducers';
import {TutorialApiActions, TutorialPageActions} from './tutorial.actions';
import {filter, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {currentVideoGame, getMessages} from "./tutorial.selector";
import * as fromRootSelector from "../../core/selectors/router.selector";
import {GameMessagesServiceNew} from "../../game-messages/store/game-messages.service";
import {GameServiceNew} from "../../games-management/store/game.service";

@Injectable()
export class TutorialEffects {


  getPortalGames = createEffect(
    () => this.actions$.pipe(
      ofType(TutorialPageActions.getFaqGames),
      mergeMap((action) =>
        this.portalGamesService.getGame(action.gameId).pipe(
          map(game => ({game, faq: action.faq}))
        )),
      map((result: { game: any, faq: boolean }) => TutorialApiActions.addGame({game: result.game, faq: result.faq})
        //   new GetTutorialGameSuccessAction({
        //   game: result.game,
        //   faq: result.faq
        // })
      )
    ));


  getGame = createEffect(
    () => this.actions$.pipe(
      ofType(TutorialPageActions.getGame),
      withLatestFrom(
        this.store.select(getMessages),
        this.store.select(currentVideoGame),
        this.store.select(fromRootSelector.selectRouteParam('gameId')),
      ),
      filter(([action, games, current, gameId]) => {
        if (current == 'all' && !action.faq) {
          return games.length === 0;
        }
        return games.every(x => x.gameId.toString() !== action.gameId.toString());
      }),
      mergeMap(([action, games, current, gameId]) =>
        this.messagesService.listMessagesWithCursor((action.gameId || gameId).toString(), '*')),
      map((games) => TutorialApiActions.addItems({messages: games.generalItems}))
    ));


  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private portalGamesService: GameServiceNew,
    private messagesService: GameMessagesServiceNew,
  ) {
  }
}

import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {UserEffects} from './store/portal-user.effects';
import {accountReducer} from './store/portal-user.reducer';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ResetPasswordComponent } from './pages/reset-password.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import { NewPasswordPageComponent } from './pages/new-password-page.component';

@NgModule({
  imports: [
    SharedModule.forRoot(),
    StoreModule.forFeature('user', accountReducer),
    EffectsModule.forFeature([UserEffects]),
    MatCardModule,
    MatMenuModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [],
  declarations: [
    ResetPasswordComponent,
    NewPasswordPageComponent
  ]
})
export class UserManagementModule { }

import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";

import {VideoTutorialComponent} from './pages/video-tutorial.component';
import {FaqTutorialComponent} from './pages/faq-tutorial.component';
import {SharedModule} from "../shared/shared.module";
import {tutorialReducer} from "./store/tutorial.reducer";
import {TutorialEffects} from "./store/tutorial.effects";
import {FaqListQuestionsComponent} from './components/faq-list-questions.component';
import {QuestionComponent} from "./components/question.component";
import {VideoCardsComponent} from "./components/video-cards.component";
import {VideosTutorialComponent} from "./pages/videos-tutorial.component";
import {VideoCardComponent} from './components/video-card.component';
import {MediaLibraryModule} from "../media-library/media-library.module";


@NgModule({
  declarations: [
    VideoTutorialComponent,
    VideosTutorialComponent,
    VideoCardsComponent,
    FaqTutorialComponent,
    FaqListQuestionsComponent,
    QuestionComponent,
    VideoCardComponent,
  ],
    imports: [
        CommonModule,
        SharedModule.forChild(),
        StoreModule.forFeature('tutorial-games', tutorialReducer),
        EffectsModule.forFeature([TutorialEffects]),
        MediaLibraryModule,
    ]
})
export class TutorialModule { }

import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {catchError, delay, filter, map, mergeMap, tap, withLatestFrom} from "rxjs/operators";
import {forkJoin, of} from "rxjs";

import {State} from "../../core/reducers";
import {
  CreateFolder,
  CreateImage,
  DeleteFolder,
  DeleteFolderResponse,
  DeleteSelectedFiles,
  DeleteSelectedFilesResponse,
  GoBackTo,
  GoBackToResponse,
  PortalImagesActionTypes,
  Query,
  QueryResponse,
  Search,
  SearchResponse,
  SelectFolder,
  SelectFolderResponse
} from "./portal-images.actions";
import {PortalImagesService} from "../../core/services/portal-images.service";
import {MediaLibraryService} from "../../core/services/medialibrary.service";
import {getFiles, getSearchResults, getSelectedFiles, getSelectedFolder} from "./portal-images.selectors";
import {MediaGalleryItem} from "./portal-images.state";


@Injectable()
export class PortalImagesEffects {

  query$ = createEffect(() => this.actions$.pipe(
    ofType<Query>(PortalImagesActionTypes.QUERY),
    mergeMap((action) =>
      forkJoin([
        this.mediaLibraryService.getFiles(action.payload && action.payload.path.replace('mediaLibrary/', '')),
          of(action)])
    ),
    map(([response, action]: any) => {
      if (action.isGoBack) {
        this.store.dispatch(new GoBackToResponse(action.payload));
      } else {
        this.store.dispatch(new SelectFolderResponse(action.payload));
      }

      return new QueryResponse({
        folders: response.folder,
        files: response.items,
        selectedFolder: action.payload,
      });
    }),
  ));


  search$ = createEffect(
    () => this.actions$.pipe(
      ofType<Search>(PortalImagesActionTypes.SEARCH),
      filter(action => !!action.payload && action.payload.trim() !== ''),
      mergeMap((action) => this.portalImagesService.search(action.payload)),
      map(response => new SearchResponse(response as any))
    ));


  selectFolder$ = createEffect(
    () => this.actions$.pipe(
      ofType<SelectFolder>(PortalImagesActionTypes.SELECT_FOLDER),
      map((action) => new Query(action.payload, false)),
    ));

  goBackTo$ = createEffect(
    () => this.actions$.pipe(
    ofType<GoBackTo>(PortalImagesActionTypes.GO_BACK_TO),
    map((action) => new Query(action.payload, true)),
  ));


  create$ = createEffect(
    () => this.actions$.pipe(
      ofType<CreateImage>(PortalImagesActionTypes.CREATE),
      mergeMap((action: CreateImage) => this.portalImagesService.create(action.payload)),
      tap(response => this.mediaLibraryService.updateFileMetadata({
        path: `mediaLibrary/${response.path}/${response.name}.png`,
        assetId: response.assetId
      })),
      withLatestFrom(this.store.select(getSelectedFolder)),
      map(([, folder]) => new Query(folder, false))
    ));


  deleteSelectedFiles$ = createEffect(
    () => this.actions$.pipe(
      ofType<DeleteSelectedFiles>(PortalImagesActionTypes.DELETE_SELECTED_FILES),
      withLatestFrom(this.store.select(getSelectedFiles), this.store.select(getFiles), this.store.select(getSearchResults)),
      mergeMap(([action, selectedFiles, files, search]) => {
        const stream$ = this.mediaLibraryService.deleteFiles(selectedFiles).pipe(catchError(() => of(true)));

        const streamsSearchFile$ = search.filter(file => file.assetId && selectedFiles.includes(`mediaLibrary${file.path.startsWith('/') ? '' : '/'}${file.path}${file.path.endsWith('/') ? '' : '/'}${file.name}.png`)).map(
          file => this.portalImagesService.delete(file.assetId)
        );

        const streamsFile$ = files.filter(file => file.assetId && selectedFiles.includes(file.path)).map(
          file => this.portalImagesService.delete(file.assetId)
        );

        return forkJoin([...streamsFile$, ...streamsSearchFile$, stream$]).pipe(catchError(() => of(true)));
      }),
      map(() => new DeleteSelectedFilesResponse())
    ));


  createFolder$ = createEffect(
    () => this.actions$.pipe(
      ofType<CreateFolder>(PortalImagesActionTypes.CREATE_FOLDER),
      withLatestFrom(this.store.select(getSelectedFolder)),
      mergeMap(([action, folder]: [CreateFolder, MediaGalleryItem]) => {
        const path = folder ? folder.path + '/' + action.payload : 'mediaLibrary/' + action.payload;
        this.mediaLibraryService.createFolder(path);

        return of({name: action.payload, path});
        // return forkJoin([
        //
        //     of({ name: action.payload, path }
        //     )])
      }),
      delay(300),
      map((folder: any) => new SelectFolder(folder))
      //   map(([task, folder]: [any, any]) => new SelectFolder(folder))
    ));


  deleteFolder$ = createEffect(
    () => this.actions$.pipe(
      ofType<DeleteFolder>(PortalImagesActionTypes.DELETE_FOLDER),
      mergeMap((action: DeleteFolder) => forkJoin([this.mediaLibraryService.deleteFolder(action.payload), of(action)])),
      withLatestFrom(this.store.select(getSelectedFolder)),
      map(([[, action], folder]) => {
        this.store.dispatch(new DeleteFolderResponse(action.payload));

        return new Query(folder, false);
      })
    ));

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private portalImagesService: PortalImagesService,
    private mediaLibraryService: MediaLibraryService,
  ) {
  }
}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Observable} from "rxjs";
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {aggregatePreviewSelector, getEditMessageSelector} from "../../store/game-message.selector";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";

@Component({
  selector: 'app-preview-content-body',
  template: `
    <div [ngStyle]="style">
      <div class="preview-content-body">
        <div [ngSwitch]="(message$|async)?.type" class="h-100">
          <app-mobile-preview-openurl
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.OpenUrl'">
          </app-mobile-preview-openurl>

          <app-mobile-preview-narrator
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.NarratorItem'">
          </app-mobile-preview-narrator>

          <app-mobile-preview-picture-question
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.PictureQuestion'">
          </app-mobile-preview-picture-question>

          <app-mobile-preview-audio-question
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.AudioQuestion'">
          </app-mobile-preview-audio-question>

          <app-mobile-preview-text-question
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.TextQuestion'">
          </app-mobile-preview-text-question>

          <app-mobile-preview-video-question
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.VideoQuestion'">
          </app-mobile-preview-video-question>

          <app-mobile-preview-audio
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.AudioObject'">
          </app-mobile-preview-audio>

          <app-mobile-preview-scan-tag
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.ScanTag'">
          </app-mobile-preview-scan-tag>


          <app-mobile-preview-video
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.VideoObject'">
          </app-mobile-preview-video>

          <div
            class="h-100"
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.SingleChoiceTest'">
            <app-mobile-preview-answer-feedback
              [answer]="(aggregatePreviewSelector$|async).data"
              *ngIf="(aggregatePreviewSelector$|async)?.previewType === 'answer'"
            ></app-mobile-preview-answer-feedback>
            <app-mobile-preview-single-choice
              *ngIf="(aggregatePreviewSelector$|async)?.previewType !== 'answer'"
            >
            </app-mobile-preview-single-choice>
          </div>


          <div
            class="h-100"
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.MultipleChoiceTest'">
            <app-mobile-preview-answer-feedback
              [answer]="(aggregatePreviewSelector$|async).data"
              *ngIf="(aggregatePreviewSelector$|async)?.previewType === 'answer'"
            ></app-mobile-preview-answer-feedback>
            <app-mobile-preview-multiple-choice
              *ngIf="(aggregatePreviewSelector$|async)?.previewType !== 'answer'"
            >
            </app-mobile-preview-multiple-choice>
          </div>



          <div
            class="h-100"
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.MultipleChoiceImageTest'">
            <app-mobile-preview-answer-feedback
              [answer]="(aggregatePreviewSelector$|async).data"
              *ngIf="(aggregatePreviewSelector$|async)?.previewType === 'answer'"
            ></app-mobile-preview-answer-feedback>
            <app-mobile-preview-multiple-choice-image
              *ngIf="(aggregatePreviewSelector$|async)?.previewType !== 'answer'">
            </app-mobile-preview-multiple-choice-image>
          </div>
          <div
            class="h-100"
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.SingleChoiceImageTest'">
            <app-mobile-preview-answer-feedback
              [answer]="(aggregatePreviewSelector$|async).data"
              *ngIf="(aggregatePreviewSelector$|async)?.previewType === 'answer'"
            ></app-mobile-preview-answer-feedback>
            <app-mobile-preview-multiple-choice-image
              *ngIf="(aggregatePreviewSelector$|async)?.previewType !== 'answer'">
            </app-mobile-preview-multiple-choice-image>
          </div>

          <div
            class="h-100"
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.CombinationLock'">
            <app-mobile-preview-answer-feedback
              [answer]="(aggregatePreviewSelector$|async).data"
              *ngIf="(aggregatePreviewSelector$|async)?.previewType === 'answer'"
            ></app-mobile-preview-answer-feedback>
            <app-mobile-preview-combinationlock
              *ngIf="(aggregatePreviewSelector$|async)?.previewType !== 'answer'">
            </app-mobile-preview-combinationlock>
          </div>

          <div
            class="h-100"
            *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.CodeWord'">
            <app-mobile-preview-answer-feedback
              [answer]="(aggregatePreviewSelector$|async).data"
              *ngIf="(aggregatePreviewSelector$|async)?.previewType === 'answer'"
            ></app-mobile-preview-answer-feedback>
            <app-mobile-preview-codeword
              *ngIf="(aggregatePreviewSelector$|async)?.previewType !== 'answer'">
            </app-mobile-preview-codeword>
          </div>
        </div>


      </div>
    </div>
  `,
  styles: []
})
export class PreviewContentBodyComponent implements OnInit, OnChanges {
  @Input() scale = 1;

  style = {'transform' : 'scale(0.5) translate( 0, -50%)'}

  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
  aggregatePreviewSelector$: Observable<any> = this.store.select(aggregatePreviewSelector);

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scale) {
      const trans = (100 - (100 * this.scale)) / 2 / this.scale;
      this.style.transform = `scale(${this.scale}) translate( -${trans}%, -${trans}%)`
    }
  }

}

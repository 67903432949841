import {Player} from '../../player-management/store/player.state';

// export interface GameRunsState {
//   selectedRun: number;
//   editRun: GameRun;
//   runs: RunState;
// }

export interface GameRun {
  runId?: number;
  gameId: number;
  title: string;
  deleted?: boolean;
  startTime?: number;
  serverCreationTime?: number;
  lastModificationDate?: number;
  runConfig: RunConfig;
}

export interface RunConfig {
  selfRegistration?: boolean;
}

export enum RunAccessRight {
  OWNER = 1,
  EDITOR = 2,
  VIEWER = 3
}

export interface RunUser extends Player {
  identifier: string;
  runId: number;
  deleted: boolean;
  teamId: string;
  gameId: number;
  lastModificationDateGame: number
}

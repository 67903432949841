import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoryEditLabelsComponent} from './components/category-edit-labels.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {StoreModule} from "@ngrx/store";

import {EffectsModule} from "@ngrx/effects";
import {reducers} from "./store/game-category.reducer";
import {GameCategoryEffects} from "./store/game-category.effects";
import {CategoryViewLabelsComponent} from "./components/category-view-labels.component";
import {SharedModule} from "../shared/shared.module";
import { OrganisationEditLabelsComponent } from './components/organisation-edit-labels.component';


@NgModule({
    declarations: [
        CategoryEditLabelsComponent,
      CategoryViewLabelsComponent,
      OrganisationEditLabelsComponent
    ],
  exports: [
    CategoryEditLabelsComponent,
    CategoryViewLabelsComponent,
    OrganisationEditLabelsComponent
  ],
    imports: [
        CommonModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        StoreModule.forFeature('gameCategories', reducers),
        EffectsModule.forFeature([GameCategoryEffects]),
        SharedModule,
    ]
})
export class GameCategoryModule { }

import {Injectable} from "@angular/core";
import {RunAuthorService} from "./run-author.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {RunAuthorApiActions} from "./run-author.actions";
import {filter, map, mergeMap, withLatestFrom} from "rxjs/operators";
import {getInSyncTill} from "./run-author.selectors";
import {AuthActionTypes} from "../../ngrx-auth/store/ngrx-auth.actions";
import {GameRunsApiActions} from "../../runs-management/store/game-runs.actions";


@Injectable()
export class RunAuthorEffectsLoadAuthors {
  constructor(
    private runAuthorService: RunAuthorService,
    private actions$: Actions,
    private router: Router,
    private store$: Store<State>
  ) {
  }

  loadAllAuthors = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.Authenticated, GameRunsApiActions.addRun),
      withLatestFrom(this.store$.select(getInSyncTill)),
      mergeMap(([action, inSyncTill]: [any, number]) => this.runAuthorService.loadAllAuthors(inSyncTill)),
      map(runAccessList => RunAuthorApiActions.loadAllRunAuthorsCompleted({runAccessList}))
    ));


  resumeLoadAllAuthors = createEffect(
    () => this.actions$.pipe(
      ofType(RunAuthorApiActions.loadAllRunAuthorsCompleted),
      filter((action) =>
        !!action && !!action.runAccessList && !!action.runAccessList.resumptionToken && (action.runAccessList.runAccess || []).length != 0),
      mergeMap((action) => this.runAuthorService.loadAllAuthors(action.runAccessList.from, action.runAccessList.resumptionToken)),
      map(runAccessList => RunAuthorApiActions.loadAllRunAuthorsCompleted({runAccessList}))
    ));

  updateInSyncTill = createEffect(
    () => this.actions$.pipe(
      ofType(RunAuthorApiActions.loadAllRunAuthorsCompleted),
      filter((action) =>
        !!action && !!action.runAccessList && (!action.runAccessList.resumptionToken || (action.runAccessList.runAccess || []).length == 0)),
      map(action => RunAuthorApiActions.updateFullLastSync({time:action.runAccessList.serverTime - 120000}))
    ));

}

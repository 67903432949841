import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Observable} from "rxjs";
import {GameRunsPageActions} from "../store/game-runs.actions";
import {GameRun} from "../store/game-runs.state";
import {environment} from "../../../environments/environment";
import {currentRunWithEdits} from "../store/game-runs.selector";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {GamePageActions} from "../../games-management/store/game.actions";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-run-settings-page',
  template: `
    <div class="full-width-container maxwidth">

      <div class="run-settings-pane d-flex flex-row justify-content-between">
        <div class="run-settings-form">
          <app-settings-fields></app-settings-fields>
        </div>
        <div *ngIf="(run$|async)?.runConfig?.selfRegistration">
        <div class="run-settings-qr d-flex flex-column justify-content-around align-items-center">
          <qr-code [value]="getQrCode((run$|async)?.runId)" [size]="100"></qr-code>
          <div class="font-medium-16-21-roboto">{{'RUNS.SCAN_AND_SHARE' | translate}}</div>
          <div class="font-regular-12-20-roboto color-888 text-align-center">
            {{'RUNS.SCAN_AND_SHARE_DESC' |translate}}
          </div>
        </div>
        </div>
      </div>

    </div>
  `,
})
export class RunSettingsPageComponent implements OnInit {
  public game$: Observable<Game> = this.store.select(getCurrentGame);
  run$: Observable<GameRun> = this.store.select(currentRunWithEdits);

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
    this.store.dispatch(GamePageActions.getGame({}));
    this.store.dispatch(GameRunsPageActions.resetEdits());
    this.store.dispatch(GameRunsPageActions.getRun({}));

    // this.store.dispatch(new PlayerLoadRequestAction());
  }

  getUrl(runId: number) {
    const url = environment.deep_link + 'run/' + runId;
    return 'https://qrfree.kaywa.com/?l=1&d=' + url;
  }

  getQrCode(runId: number) {
    return environment.deep_link + 'run/' + runId;
  }



}

import {Action, createActionGroup, emptyProps, props} from '@ngrx/store';
import {Organisation, OrganisationList} from "./organisations.state";

export const OrganisationPageActions = createActionGroup({
  source: 'Organisation Page',
  events: {
    'Query' : emptyProps(),
    'Query One' : emptyProps(),

    'Create organisation': props<{ organisation: Organisation }>(),
    'Delete organisation': props<{ organisationId: string }>(),
    'update organisation expiration': props<{ organisationId: string, expirationDate: number }>(),
  },
});

export const OrganisationApiActions = createActionGroup({
  source: 'Organisation Page',
  events: {
    'Add all' : props<{ organisationList: OrganisationList }>(),
    'Add One' : props<{ organisation: Organisation }>(),
    'Delete One' : props<{ organisation: Organisation }>(),
  },
});

import {createSelector} from "@ngrx/store";
import {RunAccessRight} from "../../runs-management/store/game-runs.state";
import {getMyRunAccess} from "./run-author.selectors";


export const ownsRun = createSelector(getMyRunAccess, (access) => {
  if (!access) {
    return false;
  }
  return (access.accessRights === RunAccessRight.OWNER);
});

export const canViewRun = createSelector(getMyRunAccess, (access) => {
  if (!access) {
    return false;
  }
  return true;
});

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Observable} from "rxjs";
import {
  getSelectedFiles as getSelectedLocalFiles,
  getSelectedFilesFullPath
} from "../../media-library/store/media-lib.selector";
import {getSelectedFiles as getSelectedGlobalFiles} from "../store/portal-images.selectors";
import {first, map} from "rxjs/operators";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {Game} from "../../games-management/store/game.state";

@Component({
    selector: 'app-select-asset',
    template: `
        <div class="maxwidth pos-top">
            <app-modal-esc-button
                    class="gl-pos-modal-esc-button"
                    [type]="'esc'" (buttonClick)="onNoClick()"></app-modal-esc-button>
            <app-modal-esc-button
                    class="gl-pos-modal-back-button"
                    [type]="'back'" (buttonClick)="onNoClick()"></app-modal-esc-button>
        </div>
        <div class="maxwidth">
            <div class="pos-title primary-color font-medium-32-43-roboto">{{ 'MESSAGE.SELECT_ASSET' | translate }}</div>
        </div>

        <div class="maxwidth">
            <mat-tab-group (selectedIndexChange)="globalFilesOpened = $event === 1" class="wrap">
                <mat-tab [label]="'MEDIA.GAME_LIB' | translate">
                    <app-media-lib-container
                            [multiSelect]="false"
                            [upload]="false"
                            (doubleClick)="saveMessage()"
                            [gameId]="(game$|async)?.gameId"
                    ></app-media-lib-container>
                </mat-tab>
                <mat-tab [label]="'MEDIA.BIBENDO_LIB' | translate">
                    <app-media-gallery-container
                            [multiSelect]="false"
                            [assessmentSelect]="true"
                    ></app-media-gallery-container>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="maxwidth pos-media-select">
            <div class="toolbar-wrapper">
                <div class="d-flex flex-row maxwidth justify-content-center align-items-center h-100">
                    <button class="button-fixed-width button-height"
                            *ngIf="!globalFilesOpened"
                            [disabled]="(selectedLocalFileNames$|async).length === 0"
                            mat-flat-button (click)="saveMessage()"
                            color="primary"> {{'MEDIA.SELECT' | translate }}
                    </button>

                    <button class="button-fixed-width button-height"
                            *ngIf="globalFilesOpened"
                            [disabled]="(selectedGlobalFileNames$|async).length === 0"
                            mat-flat-button (click)="saveMessage()"
                            color="primary">  {{'MEDIA.SELECT' | translate}}
                    </button>
                </div>
            </div>

        </div>

    `,
    styles: [`
        .pos-media-select {
            position: relative;
            margin-top: 59px;
            margin-bottom: 50px;
        }

        .buttons > * {
            margin-right: 10px;
        }

        .wrap {
            margin-bottom: 68px;
        }

    `]
})
export class SelectAssetComponent implements OnInit {
    globalFilesOpened = false;

    public game$: Observable<Game> = this.store.select(getCurrentGame);
    public selectedLocalFileNames$: Observable<string[]> = this.store.select(getSelectedLocalFiles);
    public selectedGlobalFileNames$: Observable<string[]> = this.store.select(getSelectedGlobalFiles);

    constructor(public dialogRef: MatDialogRef<SelectAssetComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public store: Store<State>) {
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    saveMessage() {

        let stream$;

        if (this.globalFilesOpened) {
            stream$ = this.store.select(getSelectedGlobalFiles).pipe(
              first(),
              map(([url]) => ['/' + url]));
        } else {
            stream$ = this.store.select(getSelectedFilesFullPath).pipe(first());
        }

        stream$.subscribe(x => {
            if (x.length === 0) {
                this.dialogRef.close();
            } else {
                this.dialogRef.close(x[0]);
            }
        });

    }
}

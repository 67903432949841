import { Component, OnInit } from '@angular/core';
import {NewGameComponent} from "../modal/new-game/new-game.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-new-game-tile',
  template: `
    <div class="tile-dimensions m-2 new-button-screen">
      <div class="h-100 d-flex flex-column justify-content-center align-items-center">
        <div>
          <button color="accent" mat-fab (click)="newGame()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div class="mt-3 font-regular-16-24-roboto color-888">
          {{ 'GAME.NEW_GAME_TITLE' | translate }}
        </div>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class NewGameTileComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }


  newGame() {

    const dialogRef = this.dialog.open(NewGameComponent, {
      width: '99%',
      data: {},
      panelClass: ['modal-fullscreen', "modal-dialog"]

    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


}

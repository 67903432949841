import {createFeatureSelector, createSelector} from '@ngrx/store';
import {categoryAdapter, categoryMappingAdapter, organisatinoMappingAdapter} from "./game-category.reducer";
import {GameCategoryState, GameOrganisationMapping} from "./game-category.state";
import * as fromOrganisation from "../../organisations-management/store/organisations.selectors";

const featureState = createFeatureSelector<GameCategoryState>('gameCategories');
const getCategories = (state: GameCategoryState) => state.gameOrganisationMappings;
const getAdapter = createSelector(featureState, getCategories);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = organisatinoMappingAdapter.getSelectors(getAdapter);

export const getCurrentGameOrganisationMappings = createSelector(selectAll, fromOrganisation.selectEntities,
  (all, organisationDict)=>{
  return all.map(mapping => organisationDict[mapping.organisationId] || {name: '', id: ''+mapping.organisationId});
})

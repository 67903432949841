import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {FileMetadata, FileToUpload, UploadProgress} from "./media-lib.state";

export const MediaLibraryPageActions = createActionGroup({
  source: 'Media Library Page',
  events: {
    'Download Metadata': props<{ path: string, ignoreDistinct: boolean }>(),
    'Un Cache Url': props<{ path: string}>(),
    'Get Folders': emptyProps(),
    'select file':  props<{ file: string, multiSelect: boolean}>(),
    'delete selected files': emptyProps(),
    'set files to upload':  props<{ files: FileList, customPath?: string}>(),
    'Start Upload': emptyProps(),
    'set upload modus':  props<{ modus: boolean}>(),
    'create folder':  props<{ name: string}>(),
    'set absolute path':  props<{ gameId: number}>(),
    'set relative path':  props<{ relativePath: string}>(),
  },
});

export const MediaLibraryApiActions = createActionGroup({
  source: 'Media Library Api',
  events: {
    'Cache Url': props<{ fileMetadata: FileMetadata }>(),
    'Add Media': props<{ fileMetadata: FileMetadata }>(),
    'Add Folders and files': props<{ folders: string[], files: string[] }>(),
    'Upload Completed':  props<{ nextFile?: FileToUpload}>(),
    'set upload progress': props<UploadProgress>(),
  },
});

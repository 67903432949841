import {Component, OnInit} from '@angular/core';
import {GameMessagePageActions} from "../../store/game-message.actions";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";

@Component({
  selector: 'app-mc-select-answer-template',
  template: `
    <mat-form-field class="w-100">

      <mat-label>{{'MESSAGE.SCREEN_TEMPLATE' | translate}}</mat-label>
      <mat-select
        (valueChange)="change($event)">
        <mat-option *ngFor="let templ of templates" [value]="templ.value">
          {{templ.viewValue | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  `,
  styles: []
})
export class McSelectAnswerTemplateComponent implements OnInit {
  templates: any[] = [
    {value: '0', viewValue: 'MESSAGE.SCREEN_TEMPLATE_NONE'},
    {value: '2', viewValue: 'MESSAGE.SCREEN_TEMPLATE_2'},
    {value: '4', viewValue: 'MESSAGE.SCREEN_TEMPLATE_4'},
    {value: '6', viewValue: 'MESSAGE.SCREEN_TEMPLATE_6'}
  ];

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
  }

  change(amount: number) {
    this.store.dispatch(GameMessagePageActions.addAnswers({
      answerType: "org.celstec.arlearn2.beans.generalItem.MultipleChoiceAnswerItem", amount
    }));
  }

}

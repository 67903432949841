import {Action, createActionGroup, props} from '@ngrx/store';
import {HttpError} from "./shared.state";

export const ErrorActions = createActionGroup({
  source: 'Error',
  events: {
    'Http': props<{ code: number, message: string, time: number, route?: string, translation?: string }>(),
  },
});

export const SharedActionTypes = {

  SET_ERROR: '[SharedActions]  Set Error',
  SET_HTTP_ERROR: '[SharedActions]  Set Http Error',
  SHARED_ERROR: '[SharedActions]  Error'
};

export class SetErrorAction implements Action {
  type = SharedActionTypes.SET_ERROR;

  constructor(public payload: any) {
    console.log("setting error1");
  }
}

export class SetHttpError implements Action {
  type = SharedActionTypes.SET_HTTP_ERROR;

  constructor(public payload: HttpError) {
  }
}

export class SharedErrorAction implements Action {
  type = SharedActionTypes.SHARED_ERROR;

  constructor(public payload: any) {
    console.log("setting error2");
  }
}


export type SharedAction
  = SetErrorAction
  | SharedErrorAction;

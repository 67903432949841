import {MediaLibraryApiActions, MediaLibraryPageActions} from './media-lib.actions';
import {FileMetadata, FileToUpload} from './media-lib.state';
// import {AuthActionTypes} from "../../auth/actions/auth.actions";
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";

export interface MediaLibraryState extends EntityState<FileMetadata> {
  filesToUpload: FileToUpload[];
  folders: string[];
  items: string[];
  uploadModus: boolean;
  absolutePath: string;
  relativePath: string;


  selectedFiles: string[];
}


export const fileMetadataAdapter = createEntityAdapter<FileMetadata>(
  {selectId: (a: FileMetadata) => a.fullPath}
);


export const mediaLibraryInitialState: MediaLibraryState = fileMetadataAdapter.getInitialState({
  folders: [],
  items: [],
  uploadModus: false,
  absolutePath: '',
  relativePath: '/',
  selectedFiles: [],
  filesToUpload: []
});


export const reducer = createReducer(
  mediaLibraryInitialState,
  on(MediaLibraryApiActions.cacheUrl, (state, action) => fileMetadataAdapter.upsertOne(action.fileMetadata, state)),
  on(MediaLibraryApiActions.addMedia, (state, action) => fileMetadataAdapter.upsertOne(action.fileMetadata, state)),
  on(MediaLibraryPageActions.unCacheUrl, (state, action) => (state.entities[action.path] == null) ? state :
    fileMetadataAdapter.upsertOne({...(state.entities[action.path]), absent: true,}, state)),
  on(MediaLibraryPageActions.getFolders, (state, _) => ({...state, selectedFiles: []})),
  on(MediaLibraryApiActions.addFoldersAndFiles, (state, action) => ({
    ...state,
    folders: Array.from(new Set([...state.folders, ...action.folders])),
    items: [...action.files]
  })),
  on(MediaLibraryPageActions.selectFile, (state, action) => action.multiSelect ?
    ({...state, selectedFiles: [action.file]}) :
    ({
      ...state, selectedFiles: (state.selectedFiles.indexOf(action.file) === -1) ?
        ([...state.selectedFiles, action.file]) :
        (state.selectedFiles || []).filter(element => element !== action.file)
    })
  ),
  on(MediaLibraryPageActions.setFilesToUpload, (state, action) => (!!action.customPath) ?
    ({
      ...state
      , filesToUpload: [{
        uploading: false,
        completed: false,
        customPath: true,
        progress: 0,
        pathPrefix: action.customPath,
        file: Array.from(action.files)[0] as File
      }]
    }) :
    ({
      ...state, filesToUpload: Array.from(action.files).map((file: File) => ({
        uploading: false,
        completed: false,
        customPath: false,
        progress: 0,
        pathPrefix: state.absolutePath + state.relativePath,
        file
      }))
    })
  ),
  on(MediaLibraryApiActions.setUploadProgress, (state, action) =>
    (state.filesToUpload.filter(f => f.file.name === action.filename).length === 0) ? state :
      ({
        ...state,
        filesToUpload: !action.completed ? state.filesToUpload : state.filesToUpload.filter(f => f.file.name !== action.filename)
      })),
  on(MediaLibraryPageActions.setUploadModus, (state, action) => ({...state, uploadModus: action.modus})),
  on(MediaLibraryPageActions.createFolder, (state, action) =>
    (state.folders.indexOf(action.name) !== -1) ? state : ({...state, folders: [...state.folders, action.name]})),
  on(MediaLibraryPageActions.setAbsolutePath, (state, action) => ({...state, absolutePath: `/game/${action.gameId}`})),
  on(MediaLibraryPageActions.setRelativePath, (state, action) => ({...state, relativePath: action.relativePath}))
);

export function reducers(
  state: MediaLibraryState | undefined,
  action: Action
): MediaLibraryState {
  return reducer(state, action);
}
//
// export function reducersOld(
//   state = mediaLibraryInitialState,
//   action: actions.MediaLibraryAction): MediaLibraryState {
//   switch (action.type) {
//
//
//
//     // case actions.MediaLibraryActionTypes.SET_FILES_TO_UPLOAD: {
//     //   if (action.payload.customPath) {
//     //     const fl: FileList = action.payload.files;
//     //     return Object.assign({}, state, {
//     //       filesToUpload: [{
//     //         uploading: false,
//     //         completed: false,
//     //         customPath: true,
//     //         pathPrefix: action.payload.customPath,
//     //         file: Array.from(fl)[0]
//     //       }]
//     //     });
//     //   }
//     //   const fl: FileList = action.payload.files;
//     //   const ar = Array.from(fl).map((file: File) => {
//     //     return {
//     //       uploading: false,
//     //       completed: false,
//     //       customPath: false,
//     //       pathPrefix: state.absolutePath + state.relativePath,
//     //       file: file
//     //     };
//     //   });
//     //   return Object.assign({}, state, {filesToUpload: ar});
//     // }
//     //todo check other way of dealing with this
//     // case CurrentGameActionTypes.SET_CURRENT_GAME_COMPLETED : {
//     //   return {...state, folders: [], items:[]}
//     // }
//
//
//     //todo check if effects of new game on media-lib
//     // case CurrentGameActionTypes.GET_CURRENT_GAME_FROM_ROUTER_REQUESTED: {
//     //   return Object.assign({}, state, {selectedFiles: []});
//     // }
//
//
//
//     default: {
//       return state;
//     }
//   }
// }

import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {GamePlayerSyncTime, RunUser, RunUserState} from "./run-players.state";
import {RunPlayerApiActions, RunPlayerEffectActions} from "./run-players.actions";
import {Action, createReducer, on} from "@ngrx/store";

export interface RunUserEntityState extends EntityState<RunUser> {
}

export interface GamePlayersSyncTimeEntityState extends EntityState<GamePlayerSyncTime> {
}

export const runUserAdapter = createEntityAdapter<RunUser>(
  {selectId: (a: RunUser) => a.identifier}
);

export const gamePlayersSyncTimesAdapter = createEntityAdapter<GamePlayerSyncTime>(
  {selectId: (gameSyncTime: GamePlayerSyncTime) => gameSyncTime.gameId}
);

const initialState: RunUserState = {
  players: runUserAdapter.getInitialState(),
  gameSyncTimes: gamePlayersSyncTimesAdapter.getInitialState(),
  lastSyncTime: -1
};

export const reducer = createReducer(
  initialState,
  on(RunPlayerApiActions.loadPlayersForGameSuccess,
    RunPlayerApiActions.loadPlayersForRunSuccess,
    RunPlayerApiActions.loadAllSuccess,
    (state, action) => ({
    ...state,
    players: runUserAdapter.upsertMany(action.runList.users || [], state.players)
  })),
  on(RunPlayerApiActions.addPlayerToRunSuccess,
    (state, action) => ({
      ...state,
      players: runUserAdapter.upsertOne(action.runUser, state.players)
    })),
  on(RunPlayerApiActions.deletePlayerSuccess,
    (state, action) => ({
      ...state,
      players: runUserAdapter.removeOne(action.runUser.identifier, state.players)
    })),

  on (RunPlayerEffectActions.updateAllSyncTime, (state, action) => ({
    ...state,
    lastSyncTime: action.time
  })),
  on (RunPlayerEffectActions.updateLastGameSyncTime, (state, action) => ({
    ...state,
    gameSyncTimes: gamePlayersSyncTimesAdapter.upsertOne(action.time, state.gameSyncTimes)
  })),
  //
)

export function runPlayerReducer(
  state: RunUserState | undefined,
  action: Action
): RunUserState {
  return reducer(state, action);
}


import {Component, OnInit} from '@angular/core';

import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {RunAuthorPageActions} from "../../run-authors/store/run-author.actions";
import {currentRunOwners} from "../../run-authors/store/run-author.selectors";
import {Observable} from "rxjs";
import {RunAuthor} from "../../run-authors/store/run-author.state";

@Component({
  selector: 'app-run-collaborators-page',
  template: `
    <div class="full-width-container maxwidth">

      <div class="run-settings-pane d-flex flex-row justify-content-between">
        <div class="run-settings-form">

          <app-game-detail-collaborators
            [gameAuthors]="players$ | async"
            (onNewAuthor)="onNewAuthor($event)"
            (onRoleChange)="onRoleChange($event)"
            (onDelete)="onAuthorDelete($event)">
          </app-game-detail-collaborators>

      </div>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class RunCollaboratorsPageComponent implements OnInit {
  players$: Observable<RunAuthor[]> = this.store.select(currentRunOwners);

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
  }

  onNewAuthor(event) {
    this.store.dispatch(RunAuthorPageActions.grantCollaboratorAccess({
      rights: event.role,
      author: event.fullId
    }));
  }
  onRoleChange(event) {
    this.store.dispatch(RunAuthorPageActions.grantCollaboratorAccess({
      rights: event.role,
      author: event.fullId
    }));
    //
  }

  onAuthorDelete(event) {
    console.log('DELETE', event);

    this.store.dispatch(RunAuthorPageActions.revokeCollaboratorAccess({
      author: event.account,
      runId: event.runId
    }));
  }
}

import {GameDetailScreensComponent} from "./pages/game-detail-screens/game-detail-screens.component";
import {GameDetailFlowchartComponent} from "./pages/game-detail-flowchart/game-detail-flowchart.component";
import {GameDisappearFlowchartComponent} from "./pages/game.disappear.flowchart";
import {GameMessagesListPictureComponent} from "./pages/game-messages-list-picture.component";
import {GameDetailSettingsComponent} from "../game-management/pages/game-detail-settings.component";
import {RunOverviewComponent} from "../runs-management/pages/run-overview.component";
import {MediaLibraryAllFilesComponent} from "./pages/media-library-all-files/media-library-all-files.component";


export const gameMessagesRoutes = [
  {path: 'screens', component: GameDetailScreensComponent},
  {path: 'flowchart/:messageId',  component: GameDetailFlowchartComponent},
  {path: 'hidechart/:messageId',  component: GameDisappearFlowchartComponent},
  {path: 'board/:messageId', component: GameMessagesListPictureComponent},
  {path: 'settings',  component: GameDetailSettingsComponent},
  {path: 'runs', component: RunOverviewComponent},
  {path: 'media',  component: MediaLibraryAllFilesComponent},
];

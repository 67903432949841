import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Observable} from "rxjs";
import {MatDialog} from "@angular/material/dialog";

import {getCurrentGame} from "../../games-management/store/game.selector";
import {currentRunPlayersNew} from "../../run-players/store/run-players.selectors";
import {RunUser} from "../../run-players/store/run-players.state";
import {RunPlayerPageActions} from "../../run-players/store/run-players.actions";
import {RunAuthorPageActions} from "../../run-authors/store/run-author.actions";
import {ownsRun} from "../../run-authors/store/run-author.selectors.ownership";
import {Game} from "../../games-management/store/game.state";

@Component({
    selector: 'app-run-players-page',
    template: `
        <div class="full-width-container maxwidth">
<!--            <app-run-tab-select></app-run-tab-select>-->
            <div
                    class="bibendo-center top70">
              <app-new-player-tile></app-new-player-tile>
              <div
                        class="connection-tile connection-tile-dimensions" *ngFor="let player of (currentPlayers$|async)">
                  <div *ngIf="(ownsRun$|async); then thenBlock else elseBlock"></div>
                    <ng-template #thenBlock>
                        <app-run-player-tile class="gameTile"
                                             [hasAction]="true"
                                             [actions]="['MAKE_OWNER','MAKE_EDITOR','MAKE_VIEWER']"
                                             [actionIcon]="{'MAKE_OWNER': ['fas', 'fa-user-tie'], 'MAKE_EDITOR': ['fas', 'fa-edit'], 'MAKE_VIEWER' : ['fas', 'fa-eye'] }"
                                             [player]="player"
                                             (remove)="remove(player)"
                                             (actionsClick)="actionsClick($event, player)"
                        ></app-run-player-tile>
                    </ng-template>
                    <ng-template #elseBlock>
                        <app-connection-tile class="gameTile"
                                             [hasAction]="false"
                                             [player]="player"
                                             [canRemove]="false"
                        ></app-connection-tile>
                    </ng-template>


                </div>
            </div>
        </div>
    `
})
export class RunPlayersPageComponent implements OnInit {
    public game$: Observable<Game> = this.store.select(getCurrentGame);

    currentPlayers$: Observable<RunUser[]> = this.store.select(currentRunPlayersNew);
    ownsRun$: Observable<any> = this.store.select(ownsRun);

    constructor(
        private store: Store<State>,
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
        // this.store.dispatch(new GetGameRunsRequestAction());

        // this.store.dispatch(new GetGameRequestAction());
        // this.store.dispatch(new GetCurrentGameFromRouterRequestAction());
        // this.store.dispatch(new GetRunAction());
        // this.store.dispatch(new PlayerLoadRequestAction());

    }

    addPlayer() {
        // const dialogRef = this.dialog.open(AddPlayerDialogComponent, {
        //     panelClass: ['modal-fullscreen', "modal-dialog"],
        //     data: {fullId: ""}
        // });
        //
        // dialogRef.afterClosed().subscribe(result => {
        //     if (result && result.fullId) {
        //         this.store.dispatch(new AddPlayerToRunRequestAction(result.fullId));
        //     }
        //
        // });
    }

    remove(runUser: RunUser) {
        this.store.dispatch(RunPlayerPageActions.deletePlayerFromRun({runUser}));
    }

    actionsClick($event: any, player) {
        if ($event === 'MAKE_OWNER') {
            this.store.dispatch(RunAuthorPageActions.grantCollaboratorAccess({
                rights: '1',
                author: player.fullId
            }));
        } else if ($event === 'MAKE_EDITOR') {
          this.store.dispatch(RunAuthorPageActions.grantCollaboratorAccess({
                rights: '2',
                author: player.fullId
            }));
        } else {
          this.store.dispatch(RunAuthorPageActions.grantCollaboratorAccess({
                rights: '3',
                author: player.fullId
            }));
        }
    }
}

import {arlearnActionsAdapter, RunResponseState} from './run-responses.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {currentMessageFromRouter} from "../../game-messages/store/game-messages.selector";

export const getArlearnActionsState = createFeatureSelector<RunResponseState>('run-responses');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = arlearnActionsAdapter.getSelectors(getArlearnActionsState);
//
// export const getServerTime = createSelector(getArlearnActionsState, (state) => {
//   return state.serverTime;
// });

export const getSelectedUsers = createSelector(getArlearnActionsState, (state) => {
  return state.selectedUsers;
});


// export const getNextCursor = createSelector(getArlearnActionsState, (state) => {
//   return state.nextCursor;
// });


export const currentItemResponses = createSelector(selectAll, currentMessageFromRouter,
  (all, messageId) => {
    const result = all.filter(m => m.generalItemId == messageId);
    result.sort((a, b) => b.timestamp - a.timestamp);
    return result;
  });

export const currentItemFilteredUsersResponses = createSelector(currentItemResponses, getSelectedUsers,
  (all, users) => {
    const keyList = Object.keys(users).filter(k => users[k]);
    if (keyList.length === 0) {
      return all;
    }
    return all.filter(m => keyList.indexOf(m.userId) !== -1);
  });

export const getLastResponseTime = createSelector(getArlearnActionsState, selectAll, currentMessageFromRouter, (state, all, messageId) => {
  let filtered = all.filter(m => m.generalItemId == messageId);
  if (filtered.length < 1) {
    return 1;
  }
  if (filtered.length < 2) {
    return filtered[0].timestamp + 1;
  }
  return filtered.reduce((a, b) => (a.timestamp > b.timestamp ? a : b)).timestamp + 1;
});

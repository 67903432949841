import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {filter, map} from "rxjs/operators";
import {GameTheme, ThemeList} from "./game-theme.state";
import {CATEGORY_CUSTOM_THEMES} from "./game-theme.selectors";

@Injectable({
  providedIn: 'root',
})
export class GameThemeService {

  constructor(private http: HttpClient) {
  }

  getTheme(id: number): Observable<GameTheme> {
    return this.http.get<any>(environment.api_url + `/game/theme/` + id).pipe(
      filter(x => x != null)
    );

  }

  createTheme(theme: GameTheme): Observable<GameTheme> {
    return this.http
      .post<any>(environment.api_url + '/game/theme/create', theme)
      .pipe(map(theme => {
        if (!theme.global) {
          theme.category = CATEGORY_CUSTOM_THEMES;
        }

        return theme;
      }));
  }

  deleteTheme(themeId: number): Observable<any> {
    return this.http
      .delete<any>(environment.api_url + '/game/theme/' + themeId);
  }

  listAll(from: number, resumptionToken?: string): Observable<ThemeList> {
    let postFix = '1';
    if (!!from) {
      postFix = from +'';
    }
    if (!!resumptionToken) {
      postFix += '?resumptionToken='+resumptionToken;
    }
    return this.http
      .get<ThemeList>(environment.api_url + '/game/theme/list/'+postFix).pipe(
        map(list => ({
              ...list,
              themes: (list.themes||[]).map(gameThemeTransform)}
          )
        )
      );
  }

  listAllMy(from: number, resumptionToken?: string): Observable<ThemeList> {
    let postFix = '1';
    if (!!from) {
      postFix = from +'';
    }
    if (!!resumptionToken) {
      postFix += '?resumptionToken='+resumptionToken;
    }
    return this.http
      .get<ThemeList>(environment.api_url + '/game/theme/listMy/'+postFix).pipe(
        map(list => ({
              ...list,
              themes: (list.themes||[]).map(gameThemeTransform)}
          )
        )
      );
  }
}


const gameThemeTransform = (theme: any) => {
  if (theme.themeId) {
    theme.themeId = Number.parseInt(theme.themeId, 10);
  }
  return theme;
};


import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {ActivatedRoute, Router} from "@angular/router";
import {getMultipleMessagesSelector} from "../../game-messages/store/game-messages.selector";
import {filter, map, tap} from "rxjs/operators";

@Component({
  selector: 'app-results-page-load-first-result',
  template: `
    <div *ngIf="!(messageId$ | async)">
      <div *ngIf="trying">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>

      </div>

      <div
        *ngIf="!trying"
        class="full-width-container maxwidth">

        Geen schermen beschikbaar
      </div>
    </div>

  `,
  styles: [
  ]
})
export class ResultsPageLoadFirstResultComponent implements OnInit {
  trying = true;

  messageId$ = this.store.select(getMultipleMessagesSelector)
    .pipe(
      map(messages => messages[0] && messages[0].id),
      filter(x => !!x),
      tap(x=> this.router.navigate(["./"+x], {relativeTo: this.route}))
    );

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    setTimeout(()=> {
      this.trying = false;
    }, 5000)
  }

}

import {Component, Input} from '@angular/core';
import {GameMessage} from "../../game-messages/store/game-messages.state";

@Component({
  selector: 'app-video-card',
  template: `
    <mat-card class="video-card" [routerLink]="'/tutorial/video/'+video.gameId+'/'+video.id">
      <div class="question-card-image w-100" mat-card-image
           storageBackground
           [targetPath]="this.video.fileReferences['background']"
           alt="Bibendo uitleg video"></div>
      <mat-card-content>
        <h4 class="question-card-title">
          {{video.name}}
        </h4>
      </mat-card-content>
    </mat-card>
  `,
  styles: [`
    .video-card {
      height: 100%;
    }

    .question-card-image {
      height: 180px;
      object-fit: cover;
    }

    .question-card-title {
      padding-top: 10px;
      font-size: 14px;
    }
  `]
})
export class VideoCardComponent  {

  @Input() video: GameMessage;

  constructor() {
  }
}

//invoked by deploy-nbd-prod.sh
import {fa_icons} from "./font-awsome-states";

export const environment = {
  production: true,
  api_url: '/api',
  apiKey : "AIzaSyAr9YojBPkhDREBtYqbRubIqi3nqrYkDzE",
  // api_url: 'https://serious-gaming-platform.appspot.com/api',
  deep_link: 'https://serious-gaming-platform.appspot.com/',
  gatracking: 'G-GQ61DEF0VQ',
  firebase: {
    apiKey: "AIzaSyBj3K9zGxeSPdu2gYmtmyvEwgXJViPnMzA",
    authDomain: "serious-gaming-platform.firebaseapp.com",
    databaseURL: "https://serious-gaming-platform.firebaseio.com",
    projectId: "serious-gaming-platform",
    storageBucket: "serious-gaming-platform.appspot.com",
    messagingSenderId: "801681056035",
    appId: "1:801681056035:web:5e3238babc98ac4406ac96"
  },



  messageType: [
    'NarratorItem',
    'ScanTag',
    'VideoObject',
    'AudioObject',
    'MultipleChoiceImageTest',
    'SingleChoiceImageTest',
    'SingleChoiceTest',
    'MultipleChoiceTest',
    'PictureQuestion',
    'AudioQuestion',
    'TextQuestion',
    'VideoQuestion',
    'CombinationLock',
    'CodeWord',
    'OpenUrl'
  ],
  messageTypeWeb: [
    'NarratorItem',
    'VideoObject',
    'AudioObject',
    'MultipleChoiceImageTest',
    'SingleChoiceImageTest',
    'SingleChoiceTest',
    'MultipleChoiceTest',
    'TextQuestion',
    'CombinationLock',
    'CodeWord',
  ],
  homeLocation: {
    loc: "zoetermeer",
    latitude: 52.051255,
    longitude: 4.530731
  },
  messageTypeToIcon: {
    'NarratorItem': ['fas', 'fa-comment-alt-lines'],
    'ScanTag': ['fas', 'fa-qrcode'],
    'VideoObject': ['fas', 'fa-film'],
    'MultipleChoiceImageTest': ['fas', 'fa-question-circle'],
    'SingleChoiceImageTest': ['fas', 'fa-question-circle'],
    'SingleChoiceTest': ['fas', 'fa-clipboard-list-check'],
    'MultipleChoiceTest': ['fas', 'fa-clipboard-list-check'],
    'PictureQuestion': ['fas', 'fa-question-circle'],
    'AudioQuestion': ['fas', 'fa-question-circle'],
    'TextQuestion': ['fas', 'fa-question-circle'],
    'VideoQuestion': ['fas', 'fa-video-plus'],
    'AudioObject': ['fas', 'fa-microphone-alt'],
    'CombinationLock': ['fas', 'fa-lock-open'],
  },
  icons : fa_icons,
  tutorial: {
    defaultFaq: 4794892807045120,
    faqTopicsOld: [6213425403265024, 5667914023698432],
    faqTopics: [4794892807045120, 6253276131491840, 6247388402417664, 4861258910138368,
      4912952868077568, 6325682703433728,6282835941916672,6214059456200704
    ],
    videoTopics: [6292962937929728],
  },
  homepage: './homepage.nbd.html',
  login: './login-screen.component.html',
  socialLogin: false,
  showTranslate: false
};

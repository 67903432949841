import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {GameCategory, GameCategoryMapping, GameCategoryState, GameOrganisationMapping} from "./game-category.state";
import {GameCategoryApiActions} from "./game-category.actions";
import {Action, createReducer, on} from "@ngrx/store";

export interface CategoryState extends EntityState<GameCategory> {
}

export interface CategoryMappingState extends EntityState<GameCategoryMapping> {
}

export interface OrganisationMappingState extends EntityState<GameOrganisationMapping> {
}

export const categoryAdapter = createEntityAdapter<GameCategory>(
  {selectId: (a: GameCategory) => a.categoryId}
);

export const categoryMappingAdapter = createEntityAdapter<GameCategoryMapping>(
  {selectId: (a: GameCategoryMapping) => a.id}
);

export const organisatinoMappingAdapter = createEntityAdapter<GameOrganisationMapping>(
  {selectId: (a: GameOrganisationMapping)=> a.id}
);

const categoryInitialState: GameCategoryState = {
  gameCategoryMappings: categoryMappingAdapter.getInitialState(),
  categories: categoryAdapter.getInitialState(),
  gameOrganisationMappings: organisatinoMappingAdapter.getInitialState(),
}

export const reducer = createReducer(
  categoryInitialState,
  on(GameCategoryApiActions.addAll,
    (state, action) => ({...state, categories: categoryAdapter.upsertMany(action.categories, state.categories)})),
  on(GameCategoryApiActions.addCategoryMapping,
    (state, action) => ({
      ...state,
      gameCategoryMappings: categoryMappingAdapter.upsertOne(action.categoryMapping, state.gameCategoryMappings)
    })),
  on(GameCategoryApiActions.addCategoryMappings,
    (state, action) => ({
      ...state,
      gameCategoryMappings: categoryMappingAdapter.upsertMany(action.categoryMappings, state.gameCategoryMappings)
    })),
  on(GameCategoryApiActions.removeCategoryMapping,
    (state, action) => ({
      ...state,
      gameCategoryMappings: categoryMappingAdapter.removeOne(action.mappingId, state.gameCategoryMappings)
    })),
  on(GameCategoryApiActions.addOrganisationMapping,
    (state, action) => ({
      ...state,
      gameOrganisationMappings: organisatinoMappingAdapter.upsertOne(action.organisationMapping, state.gameOrganisationMappings)
    })),
  on(GameCategoryApiActions.addOrganisationMappings,
    (state, action) => ({
      ...state,
      gameOrganisationMappings: organisatinoMappingAdapter.upsertMany(action.organisationMappings, state.gameOrganisationMappings)
    })),
  on(GameCategoryApiActions.removeOrganisationMapping,
    (state, action) => ({
      ...state,
      gameOrganisationMappings: organisatinoMappingAdapter.removeOne(action.mappingId, state.gameOrganisationMappings)
    })),
)

export function reducers(
  state: GameCategoryState | undefined,
  action: Action
): GameCategoryState {
  return reducer(state, action);
}


import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {State} from 'src/app/core/reducers';
import {distinct, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {PortalGamesService} from 'src/app/core/services/portal-games.service';
import {UserLibraryApiActions, UserLibraryPageActions} from "./user-library.actions";
import * as fromRootSelector from "../../core/selectors/router.selector";
import {PortalGame} from "../../portal-management/store/portal-games.state";
import {getCursor} from "./user-library.selectors";

@Injectable()
export class UserLibraryEffects {


  searchPortalGames = createEffect(
    () => this.actions$.pipe(
      ofType(UserLibraryPageActions.searchGames),
      switchMap((action) => this.portalGamesService.search(action.query)),
      tap((games: PortalGame[]) => {
        if (games != null) {
          games.forEach(game => this.store.dispatch(UserLibraryPageActions.getLibraryGame({gameId: game.gameId + ''})));
        }
      }),
      map((games) => UserLibraryApiActions.addLibraryGamesSuccess({games}))
    ));


  featuredGames = createEffect(
    () => this.actions$.pipe(
      ofType(UserLibraryPageActions.getFeaturedGames),
      switchMap((action) => this.portalGamesService.getFeatured('nl')),
      map((games) => UserLibraryApiActions.getFeaturedSuccess({games}))
    ));

  getRecentGames = createEffect(
    () => this.actions$.pipe(
      ofType(UserLibraryPageActions.getRecentGames),
      distinct(action => action.type),
      mergeMap((_) => this.portalGamesService.list()),
      map((gameList) => UserLibraryApiActions.setRecentGames({gameList}))
    ));

  resumeRecentGames = createEffect(
    () => this.actions$.pipe(
      ofType(UserLibraryPageActions.resumeRecentGames),
      withLatestFrom(
        this.store.select(getCursor)
      ),
      distinct(([action, cursor]) => cursor),
      mergeMap(([action, cursor])=> this.portalGamesService.list(cursor)),
      map((gameList) => UserLibraryApiActions.setRecentGames({gameList}))
    ));


  getGame = createEffect(
    () => this.actions$.pipe(
      ofType(UserLibraryPageActions.getLibraryGame),
      withLatestFrom(
        this.store.select(fromRootSelector.selectRouteParam('gameId'))
      ),
      mergeMap(([action, gameId]: [any, string]) => this.portalGamesService.getGame(action.gameId || gameId)),
      map((game) => UserLibraryApiActions.addLibraryGameSuccess({game}))
    ));

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private portalGamesService: PortalGamesService,
  ) {
  }
}

import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RunResponse} from "../store/run-responses.state";
import {ref, Storage} from "@angular/fire/storage";
import {Observable} from "rxjs";
import {selectEntities} from "../../player-management/store/player.selector";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {getDownloadURL} from "@firebase/storage";
import {saveAs} from "file-saver";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-video-player-modal',
  template: `
    <div class="player">
      <div class="player__video">
        <video controls autoplay #video (loadedmetadata)="video.muted = true">
          <source [src]="selectedVideo" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="player__content">
        <div class="player__actions">
          <div class="player__arrows">
            <button mat-icon-button (click)="prevVideo(video)"><i class="fas fa-arrow-left"></i></button>
            <button mat-icon-button (click)="download()"><i class="fas fa-download"></i></button>
            <button mat-icon-button (click)="nextVideo(video)"><i class="fas fa-arrow-right"></i></button>
          </div>
          <div class="player__close">
            <button mat-icon-button (click)="close()"><i class="fas fa-times"></i></button>
          </div>
        </div>
        <div class="player__text">
          description
        </div>
        <div class="player__user" *ngIf="players$ | async as playersDict">
          <div class="user" *ngIf="playersDict[this.data.responses[this.data.selectedIdx].userId] as player">
            <div class="user__avatar">{{ player.shortAvatar }}</div>
            <div class="user__name">{{ player.name }}</div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class VideoPlayerModalComponent  {
  public players$: Observable<any> = this.store.select(selectEntities);
  public selectedVideo: any;
  @ViewChild('video') videoPlayer;

  constructor(
    private store: Store<State>,
    public dialogRef: MatDialogRef<VideoPlayerModalComponent>,
    public storage: Storage,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: {
      responses: RunResponse[],
      selectedIdx: number
    }
  ) {
    this.start();
  }

  public close() {
    this.dialogRef.close();
  }

  async start() {
    this.selectedVideo = await getDownloadURL(ref(this.storage, this.data.responses[this.data.selectedIdx].responseValue));
    this.playVideo(this.videoPlayer.nativeElement);
  }

  public async prevVideo(videoRef) {
    if (this.data.selectedIdx === 0) {
      this.data.selectedIdx = this.data.responses.length - 1;
    } else {
      this.data.selectedIdx--;
    }
    this.selectedVideo = await getDownloadURL(ref(this.storage, this.data.responses[this.data.selectedIdx].responseValue));
    this.playVideo(videoRef);
  }

  public async nextVideo(videoRef: HTMLVideoElement) {
    if (this.data.selectedIdx === this.data.responses.length - 1) {
      this.data.selectedIdx = 0;
    } else {
      this.data.selectedIdx++;
    }
    this.selectedVideo = await getDownloadURL(ref(this.storage, this.data.responses[this.data.selectedIdx].responseValue));
    this.playVideo(videoRef);
  }

  playVideo(videoRef: HTMLVideoElement) {
    videoRef.src = this.selectedVideo;
    videoRef.load();
    videoRef.play();
  }

  async download() {

    this.http.get(this.selectedVideo, {responseType: "blob", headers: {'Accept': 'video/mp4'}})
      .subscribe(blob => {
        saveAs(blob, 'download.mp4');
      });

  }

}

import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromRoot from 'src/app/core/reducers';
import {UserState} from "../../user-management/store/portal-user.state";
import {GameRunsState, runAdapter} from "./game-runs.reducer";
import * as fromRootSelector from "../../core/selectors/router.selector";


export interface State extends fromRoot.State {
  'run-management': GameRunsState;
  user: UserState;
}

export const getGameRunsFeature = createFeatureSelector<GameRunsState>('run-management');
export const getEditRunSelector = createSelector(getGameRunsFeature, (state: GameRunsState) => state.editRun);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = runAdapter.getSelectors(getGameRunsFeature);


export const currentGameRuns = createSelector(
  selectAll,
  fromRootSelector.selectRouteParam('gameId'),
  (all, currentGameId) =>
     (all || [])
      .filter(ra => !ra.deleted && (ra.gameId + '') === currentGameId)
);

export const currentRunWithEdits = createSelector(
  selectEntities,
  fromRootSelector.selectRouteParam('runId'),
  getEditRunSelector,
  (runDict, runId, editRun) =>
    ({...runDict[Number.parseInt(runId, 10)], ...editRun})
)

import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {GameMessage} from "../../game-messages/store/game-messages.state";
import {Game} from "../../games-management/store/game.state";


export const TutorialPageActions = createActionGroup({
  source: 'Tutorial Page',
  events: {
    'Get FAQ Games':   props<{ gameId: number, faq: boolean }>(),
    'Get Game':   props<{ gameId?: number, faq?: boolean }>(),
    'Clear Messages':   emptyProps(),
    'Select Video Category':   props<{ category: string }>(),
  },
});

export const TutorialApiActions = createActionGroup({
  source: 'Tutorial Api',
  events: {
    'Add Game':   props<{ game: Game, faq: boolean }>(),
    'Add Items':   props<{ messages: GameMessage[] }>(),
  },
});

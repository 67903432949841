import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScreenDetailComponent} from './pages/screen-detail.component';
import {SharedModule} from '../shared/shared.module';
import {PreviewPaneComponent} from './component/preview-pane/preview-pane.component';
import {PreviewPaneHeaderComponent} from './component/preview-pane-header/preview-pane-header.component';
import {ScreenEditorNavbarComponent} from './component/screen-editor-navbar/screen-editor-navbar.component';
import {ScreenEditorComponent} from './component/screen-editor/screen-editor.component';
import {ScreenEditorTypeSelectComponent} from './component/screen-editor-type-select/screen-editor-type-select.component';
import {ScreenEditorTypeNarratorComponent} from './component/screen-editor-type-select/screen-editor-type-narrator/screen-editor-type-narrator.component';
import {ScreenEditorTypeSingleChoiceTestComponent} from './component/screen-editor-type-select/screen-editor-type-single-choice-test/screen-editor-type-single-choice-test.component';
import {ScreenEditorTypeMultipleChoiceTestComponent} from './component/screen-editor-type-select/screen-editor-type-multiple-choice-test/screen-editor-type-multiple-choice-test.component';
import {StoreModule} from '@ngrx/store';
import {reducers} from './store/game-message.reducer';
import {EffectsModule} from '@ngrx/effects';
import {GameMessageEffects} from './store/game-message.effects';
import {MobilePreviewNarratorComponent} from './component/preview-pane-mobile-view/mobile-preview-narrator.component';
import {PickLocationOnMapComponent} from './component/screen-editor-type-select/component/pick-location-on-map/pick-location-on-map.component';
import {CreateLabelComponent} from './component/screen-editor-type-select/component/create-label/create-label.component';
import {ScreenEditorTypeVideoObjectComponent} from './component/screen-editor-type-select/screen-editor-type-video-object/screen-editor-type-video-object.component';
import {ScreenEditorTypeScanTagComponent} from './component/screen-editor-type-select/screen-editor-type-scan-tag/screen-editor-type-scan-tag.component';
import {ScreenEditorTypeSingleChoiceImageComponent} from './component/screen-editor-type-select/screen-editor-type-single-choice-image/screen-editor-type-single-choice-image.component';
import {ScreenEditorTypeMultipleChoiceImageComponent} from './component/screen-editor-type-select/screen-editor-type-multiple-choice-image/screen-editor-type-multiple-choice-image.component';
import {AnswerComponentComponent} from './component/screen-editor-type-select/component/answer-component/answer-component.component';
import {DependencyReadTempComponent} from './component/screen-editor-type-select/component/dependency-read-temp/dependency-read-temp.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {MobilePreviewVideoComponent} from './component/preview-pane-mobile-view/mobile-preview-video.component';
import {MobilePreviewMultipleChoiceComponent} from './component/preview-pane-mobile-view/mobile-preview-multiple-choice.component';
import {MediaLibraryModule} from "../media-library/media-library.module";
import {BackgroundImageSelectorComponent} from './component/background-image-selector/background-image-selector.component';
import {MobilePreviewSingleChoiceComponent} from './component/preview-pane-mobile-view/mobile-preview-single-choice.component';
import {ChangeScreenPreviewDirective} from "./directives/change-screen-preview.directive";
import {MobilePreviewAnswerFeedbackComponent} from './component/preview-pane-mobile-view/mobile-preview-answer-feedback.component';
import {MobilePreviewMultipleChoiceImageComponent} from './component/preview-pane-mobile-view/mobile-preview-multiple-choice-image.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {PreviewNavbarComponent} from './component/preview-pane-mobile-view/components/preview-navbar/preview-navbar.component';
import {MobilePreviewScanTageComponent} from './component/preview-pane-mobile-view/mobile-preview-scan-tage.component';
import {McImagePreviewHeaderComponent} from './component/preview-pane-header/mc-image-preview-header.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {QRCodeModule} from 'angular2-qrcode';
import {ScreenEditorTypeAudioObjectComponent} from './component/screen-editor-type-select/screen-editor-type-audio-object.component';
import {MobilePreviewAudioComponent} from './component/preview-pane-mobile-view/mobile-preview-audio.component';
import {MobilePreviewCombinationlockComponent} from './component/preview-pane-mobile-view/mobile-preview-combinationlock.component';
import {ScreenEditorTypeCombinationlockComponent} from './component/screen-editor-type-select/screen-editor-type-combinationlock.component';
import {CombinationEntryComponent} from './component/preview-pane-mobile-view/components/combination-entry.component';
import {ScreenEditorTypeOpenurlComponent} from './component/screen-editor-type-select/screen-editor-type-openurl.component';
import {ScreenEditorTypeAudioQuestionComponent} from './component/screen-editor-type-select/screen-editor-type-audio-question.component';
import {MobilePreviewAudioQuestionComponent} from './component/preview-pane-mobile-view/mobile-preview-audio-question.component';
import {MobilePreviewPictureQuestionComponent} from './component/preview-pane-mobile-view/mobile-preview-picture-question.component';
import {ScreenEditorTypePictureQuestionComponent} from './component/screen-editor-type-select/screen-editor-type-picture-question.component';
import {MobilePreviewTextQuestionComponent} from './component/preview-pane-mobile-view/mobile-preview-text-question.component';
import {MobilePreviewVideoQuestionComponent} from './component/preview-pane-mobile-view/mobile-preview-video-question.component';
// import {FirebaseNgrxUserManagementModule} from "../auth/firebase-ngrx-user-management.module";
import {ScreenEditorTypeCodewordComponent} from './component/screen-editor-type-select/screen-editor-type-codeword.component';
import {MobilePreviewCodewordComponent} from "./component/preview-pane-mobile-view/mobile-preview-codeword.component";
import {CodeWordCombinatieEntryComponent} from "./component/preview-pane-mobile-view/components/code-word-combinatie-entry.component";
import {MessageOverlayComponent} from './component/util/message-overlay.component';
import {FontAwesomePickerComponent} from './component/form-edit-components/font-awesome-picker.component';
import {MobilePreviewOpenurlComponent} from './component/preview-pane-mobile-view/mobile-preview-openurl.component';
import {ScalePreviewComponent} from './component/util/scale-preview.component';
import {VideoAutoplayComponent} from './component/screen-editor-type-select/component/video-autoplay.component';
import {NgrxAuthModule} from "../ngrx-auth/ngrx-auth.module";
import {MessageTopNavBarComponent} from './component/message-top-nav-bar.component';
import {ScreenDetailNewComponent} from './pages/screen-detail-new.component';
// import {ChangeMessageTypeComponent} from './components/screen-editor/change-message-type.component';
import {ChangeBackgroundButtonComponent} from './component/screen-editor-type-select/component/change-background-button.component';
import {MessageTypeIconComponent} from './component/util/message-type-icon.component';
import {McSelectAnswerTemplateComponent} from './component/form-edit-components/mc-select-answer-template.component';
import {CdkAccordionModule} from "@angular/cdk/accordion";
import {ImageAnswerComponentComponent} from './component/form-edit-components/answers/image-answer-component.component';
import {PreviewContentBodyComponent} from './component/preview-pane-mobile-view/preview-content-body.component';
import {PreviewSmartphoneComponent} from './component/preview-pane-mobile-view/preview-smartphone.component';
import { SelectImageBackgroundComponent } from './component/form-edit-components/select-image-background.component';
import { CreateChapterComponent } from './component/screen-editor-type-select/component/create-chapter.component';


@NgModule({
  declarations: [
    ScreenDetailComponent,
    PreviewPaneComponent,
    PreviewPaneHeaderComponent,
    // PreviewPaneMobileViewComponent,
    ScreenEditorNavbarComponent,
    ScreenEditorComponent,
    ScreenEditorTypeSelectComponent,
    ScreenEditorTypeNarratorComponent,
    ChangeScreenPreviewDirective,
    ScreenEditorTypeSingleChoiceTestComponent,
    ScreenEditorTypeMultipleChoiceTestComponent,
    MobilePreviewNarratorComponent,
    PickLocationOnMapComponent,
    CreateLabelComponent,
    ScreenEditorTypeVideoObjectComponent,
    ScreenEditorTypeScanTagComponent,
    ScreenEditorTypeSingleChoiceImageComponent,
    ScreenEditorTypeMultipleChoiceImageComponent,
    AnswerComponentComponent,
    DependencyReadTempComponent,
    MobilePreviewVideoComponent,
    MobilePreviewMultipleChoiceComponent,

    BackgroundImageSelectorComponent,
    MobilePreviewSingleChoiceComponent,
    MobilePreviewAnswerFeedbackComponent,
    MobilePreviewMultipleChoiceImageComponent,
    PreviewNavbarComponent,
    MobilePreviewScanTageComponent,
    McImagePreviewHeaderComponent,
    ScreenEditorTypeAudioObjectComponent,
    MobilePreviewAudioComponent,
    MobilePreviewCombinationlockComponent,
    ScreenEditorTypeCombinationlockComponent,
    CombinationEntryComponent,
    ScreenEditorTypeOpenurlComponent,
    ScreenEditorTypeAudioQuestionComponent,
    MobilePreviewAudioQuestionComponent,
    MobilePreviewPictureQuestionComponent,
    ScreenEditorTypePictureQuestionComponent,
    MobilePreviewTextQuestionComponent,
    MobilePreviewVideoQuestionComponent,
    ScreenEditorTypeCodewordComponent,
    MobilePreviewCodewordComponent,
    CodeWordCombinatieEntryComponent,
    MessageOverlayComponent,
    FontAwesomePickerComponent,
    MobilePreviewOpenurlComponent,
    ScalePreviewComponent,
    VideoAutoplayComponent,
    MessageTopNavBarComponent,
    ScreenDetailNewComponent,
    // ChangeMessageTypeComponent,
    ChangeBackgroundButtonComponent,
    MessageTypeIconComponent,
    McSelectAnswerTemplateComponent,
    ImageAnswerComponentComponent,
    PreviewContentBodyComponent,
    PreviewSmartphoneComponent,
    SelectImageBackgroundComponent,
    CreateChapterComponent
  ],
  exports: [ScreenDetailComponent, PreviewPaneComponent, ScreenEditorNavbarComponent, PreviewContentBodyComponent, PreviewSmartphoneComponent],
    imports: [
        CommonModule,
        SharedModule.forRoot(),
        StoreModule.forFeature('gameMessage', reducers),
        EffectsModule.forFeature([GameMessageEffects]),
        MediaLibraryModule,
        MatAutocompleteModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatGridListModule,
        MatTooltipModule,
        ClipboardModule,
        QRCodeModule,
        // FirebaseNgrxUserManagementModule,
        NgrxAuthModule,
        CommonModule,
        CommonModule,
        CdkAccordionModule,
        CommonModule,
        CommonModule
    ]
})
export class GameMessageModule {
}



import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import Debounce from 'debounce-decorator';
import {GameMessagesPageActions} from "../store/game-messages.actions";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Subscription} from "rxjs";
import {amountOfFilters} from "../store/game-messages.selector";

@Component({
  selector: 'app-screen-title-filter',
  template: `

    <mat-form-field class="pos-field search-input w-100">
      <mat-label>{{'ROW_HEADERS.SEARCH' | translate}}</mat-label>
      <input
        #screenInput
        matInput [placeholder]="'Screen name'"
        (input)="onFilterChange($event.target.value)"
      >
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
  `,
  styles: [
  ]
})
export class ScreenTitleFilterComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  constructor(public store: Store<State>,) { }
  @ViewChild('screenInput') screenInput: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    this.subscription.add(this.store.select(amountOfFilters).subscribe((amount)=>{
      if (amount === 0 && this.screenInput) {
        this.screenInput.nativeElement.value = '';
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @Debounce(300)
  onFilterChange(value: any) {
    this.store.dispatch(GameMessagesPageActions.setFilter({filters: value.trim().split(' ').filter(val => val !== '')}));
  }


}

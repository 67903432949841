import {Component, OnInit} from '@angular/core';
import {AddPlayerDialogComponent} from "./add-player-dialog.component";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {MatDialog} from "@angular/material/dialog";
import {RunPlayerPageActions} from "../../run-players/store/run-players.actions";

@Component({
  selector: 'app-new-player-tile',
  template: `
    <div class="connection-tile-dimensions m-2 new-button-screen">
      <div class="h-100 d-flex flex-column justify-content-center align-items-center">
        <div>
          <button color="accent" mat-fab (click)="newPlayer()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div class="mt-3 font-regular-16-24-roboto color-888 text-align-center">
          {{ 'CONTACT.ADD_PLAYER' | translate }}
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class NewPlayerTileComponent implements OnInit {

  constructor(private store: Store<State>,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  newPlayer() {
    const dialogRef = this.dialog.open(AddPlayerDialogComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {fullId: ""}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.fullId) {
        this.store.dispatch(RunPlayerPageActions.addPlayerToRun({fullId: result.fullId}))
      }

    });
  }
}

import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {GameRun} from './game-runs.state';

export const GameRunsPageActions = createActionGroup({
  source: 'Game Runs Page',
  events: {
    'Get Run': props<{ runId?: string }>(),
    'Create Run': props<{ run: Partial<GameRun> }>(),
    'Reset Edits': emptyProps(),
    'Update Run': props<{ run: Partial<GameRun> }>(),
    'Save run': emptyProps(),
    'Delete Run': props<{ run: GameRun }>(),
  },
});

export const GameRunsApiActions = createActionGroup({
  source: 'Game Runs Page',
  events: {
    'Add Run': props<{ run: GameRun }>(),
  },
});

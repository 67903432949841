import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {canWriteCurrentGame} from "../../../../game-authors/store/game-author.selectors";
import {Store} from "@ngrx/store";
import {State} from "../../../../core/reducers";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {GameMessagePageActions} from "../../../store/game-message.actions";
import {SelectAssetComponent} from "../../../../portal-image-management/modals/select-asset.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-image-answer-component',
  template: `
    <div>
      <button

        (click)="selectBackground()"
        class="flex-grow-1 button-height w-100 mb-3"
        mat-stroked-button
        color="primary"
      >
        <mat-icon class="pinkish-grey mr-1">cloud_upload</mat-icon>
          {{'MESSAGE.CHANGE_IMAGE' | translate}}
      </button>
      <mat-slide-toggle
        class="mb-3 w-100 color-black-8a"
        [disabled]="!(iCanWrite|async)"
        [ngModel]="answer.isCorrect"
        [labelPosition]="'after'"
        (change)="isCorrectChange($event)">{{'MESSAGE.OPTION_CORRECT'|translate}}
      </mat-slide-toggle>

      <mat-form-field class="mb-3 w-100" *ngIf="feedback">

        <input appTriggerMobileView [data]="answer" [name]="'answer'" matInput placeholder="Feedback" [disabled]="!feedback"
               [disabled]="!(iCanWrite|async) "

               [ngModel]="answer.feedback"
               (ngModelChange)="feedbackChange($event)">
      </mat-form-field>
    </div>
  `,
  styles: [
  ]
})
export class ImageAnswerComponentComponent implements OnInit {
  @Input() answer;
  @Input() feedback = false;
  public iCanWrite: Observable<boolean> = this.store.select(canWriteCurrentGame);

  constructor(
    private store: Store<State>,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  isCorrectChange(event: MatSlideToggleChange) {
    this.store.dispatch(GameMessagePageActions.updateAnswer({
      delete: false,
      id: this.answer.id,
      field: "isCorrect",
      value: event.checked
    }));
  }

  selectBackground() {
    const dialogRef = this.dialog.open(SelectAssetComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(GameMessagePageActions.updateFileRef({
        delete: false,
        key: this.answer.id,
        value: result

      }));
    });
  }

  feedbackChange(event: any) {
    this.store.dispatch(GameMessagePageActions.updateAnswer({
      delete: false,
      id: this.answer.id,
      field: "feedback",
      value: event
    }));
  }
}

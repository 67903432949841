import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RunUser} from "../../run-players/store/run-players.state";
import {BehaviorSubject, combineLatestWith, Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import * as fromAccounts from "src/app/player-management/store/player.selector"
import * as fromAuthors from "src/app/run-authors/store/run-author.selectors"
import {map} from "rxjs/operators";
import {Account} from "../../player-management/store/player.state";
import {RunAuthor} from "../../run-authors/store/run-author.state";
import * as fromRootSelector from "../../core/selectors/router.selector";
import {PlayerPageActions} from "../../player-management/store/player.actions";

@Component({
  selector: 'app-run-player-tile',
  template: `
    <div *ngIf="_playerSubject | async as player">
      <mat-icon *ngIf="hasAction"
                class="style-icon"
                [matMenuTriggerFor]="menu"
      >more_vert
      </mat-icon>
      <mat-menu #menu="matMenu">
        <button
          *ngFor="let action of actions"
          mat-menu-item (click)="actionsClick.emit(action)">
          <i
            *ngIf="!!actionIcon[action]"
            [ngClass]="actionIcon[action]"
            class=" style-fa-icon"></i>
          <span class="ml-2">{{ 'ACTIONS.' + action | translate }} </span>
        </button>

      </mat-menu>
      <div *ngIf="!player.picture"
           class="avatar-circle"></div>
      <div *ngIf="player.picture"
           [ngStyle]="{'background': 'transparent url('+picture+') 0% 0% no-repeat padding-box'}"
           class="avatar-circle">

      </div>
      <div>
        <div *ngIf="!player.picture"
             [ngStyle]="{'background': 'transparent  0% 0% no-repeat padding-box' }"
             class="avatar-text font-medium-24-32-roboto"
        >
          {{(_accountPlayer$ | async)?.shortAvatar}}
        </div>
        <div
          class="avatar-br-annotation"
          *ngIf="_accountAuthor$ | async as author">
          <i
            *ngIf="(''+author.accessRights) === '1'"
            class="fas fa-user-tie"></i>
          <i
            *ngIf="(''+author.accessRights) === '2'"
            class="fas fa-edit"></i>
          <i
            *ngIf="(''+author.accessRights) === '3'"
            class="fas fa-eye"></i>
        </div>
      </div>

      <div
        *ngIf="player.accountType !== 8"
        class="name-section font-regular-11-15-roboto">
        {{player.name}}
      </div>
      <div
        *ngIf="player.accountType == 8"
        class="name-section font-regular-11-15-roboto">
        Anoniem
      </div>

      <div class="remove-button "
           *ngIf="canRemove"
      >
        <button mat-button color="primary"
                class="mat-small"
                (click)="buttonclick()"
        >
          <div
            class="font-medium-10-13-roboto button-text">{{'ACTIONS.DELETE' | translate}}</div>
        </button>
      </div>
    </div>
  `,
  styles: []
})
export class RunPlayerTileComponent implements OnInit {
  @Input() set player(p: RunUser) {
    this._playerSubject.next(p);
    this.store.dispatch(PlayerPageActions.loadOnePlayer({fullId: p.fullId, isConnection: false}))

  };

  _playerSubject = new BehaviorSubject<RunUser>(null);
  _accountPlayer$: Observable<Account> = this.store.select(fromAccounts.selectEntities).pipe(
    combineLatestWith(this._playerSubject),
    map(([dict, player]) => dict[player.fullId])
  )
  _accountAuthor$: Observable<RunAuthor> = this.store.select(fromAuthors.selectEntities).pipe(
    combineLatestWith(
      this._playerSubject,
      this.store.select(fromRootSelector.selectRouteParam('runId'))),
    map(([dict, player, runId]) => dict[player.fullId + ':' + runId])
  )
  @Input() hasAction = false;
  @Input() canRemove = true;
  @Output() remove = new EventEmitter();
  @Input() actionIcon: any = {};
  @Input() actions = [];
  @Output() actionsClick = new EventEmitter();

  picture: string;
  initial: string;

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
  }

  buttonclick() {
    this.remove.emit();
  }
}

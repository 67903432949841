import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {getEditMessageSelector} from "../../store/game-message.selector";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {GameMessagePageActions} from "../../store/game-message.actions";

@Component({
  selector: 'app-mobile-preview-video',
  template: `
    <div class="h-100 d-flex flex-column">
      <app-preview-navbar></app-preview-navbar>
      <div class="flex-grow-1 position-relative">
        <app-filestore-background-video
          *ngIf="!(!(message$|async)?.fileReferences || !((message$|async)?.fileReferences['video']))"
          [paths]="[((message$|async)?.fileReferences['video'])]"
        >

        </app-filestore-background-video>

        <div class="d-flex h-100">
          <div
            *ngIf="!(message$|async)?.fileReferences || !((message$|async)?.fileReferences['video'])"
            style="background-color: white"
            class="flex-grow-1 d-flex align-items-center justify-content-center"
          >
            <i class="fas fa-film" style="font-size: 100px"></i>
          </div>
        </div>

      </div>
    </div>
  `
})
export class MobilePreviewVideoComponent implements OnInit {

  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);

  constructor(private store: Store<State>) {
  }

  ngOnInit() {
  }

  attachSplash(event) {
    const objectRef = {};
    objectRef['video'] = event;
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage: {
      fileReferences: objectRef
    }}));
  }
}

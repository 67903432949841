import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Observable} from "rxjs";

import {UserLibraryPageActions} from "../store/user-library.actions";
import {Router} from "@angular/router";

import {environment} from "../../../environments/environment";
import {NewRunDialogComponent} from "../../runs-management/components/new-run-dialog/new-run-dialog.component";
import {GameRunsPageActions} from "../../runs-management/store/game-runs.actions";
import {MatDialog} from "@angular/material/dialog";
import {currentGameOwners} from "../../game-authors/store/game-author.selectors";
import {GameAuthorActions} from "../../game-authors/store/game-author.actions";
import {getCurrentGame} from "../store/user-library.selectors";
import {GamePageActions} from "../../games-management/store/game.actions";
import {GameAuthor} from "../../game-authors/store/game-author.state";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-game-libarary-user-viewgame',
  template: `
    <app-top-level-navbar [title]="(game$ | async)?.title">

    </app-top-level-navbar>
    <div class="game maxwidth d-flex" *ngIf="game$ | async as game">
      <div class="game-icon">
        <app-game-icon
          [game]="game"
        ></app-game-icon>
      </div>
      <div class="d-flex flex-column">
        <div class="game-details d-flex">

          <div class="form-info">
            <div class="form-group">
              <label class="form-label">{{'GAME.TITLE' | translate}}</label>
              <h4 class="form-heading">{{ game.title }}</h4>
            </div>

            <div class="form-group">
              <label class="form-label">{{'GAME.DESCRIPTION_FIELD' | translate}}</label>
              <p class="form-description font-medium-16-24-roboto">
                {{ game.description }}
              </p>

            </div>

            <div class="form-group">
              <label class="form-label" *ngIf="(gameOwners |async)?.length >1 ">{{'CONTACT.PERSONS' | translate}}</label>
              <label class="form-label" *ngIf="(gameOwners |async)?.length <=1 ">{{'CONTACT.PERSON' | translate}}</label>

              <app-view-author-entry
                [author]="author"
                *ngFor="let author of (gameOwners |async)"
              ></app-view-author-entry>
            </div>

            <app-category-view-labels
              [gameId]="game.gameId"
            ></app-category-view-labels>
          </div>

          <div class="remix-outer">
            <div class="button-outer">
              <div class="pos-qr-code-container">
                <qr-code [value]="getQrCode(game.gameId)" [size]="100"></qr-code>
              </div>

              <div class="remix-btn-wrapper">
                <button mat-flat-button color="primary"
                        [disabled]="clicked"
                        (click)="addRun(game.gameId)"
                        class="remix-btn">{{'RUNS.NEW_RUN' | translate}}

                </button>

                <button mat-flat-button color="primary"
                        [disabled]="clicked"
                        class="remix-btn" (click)="remix(game)">{{'RUNS.REMIX' | translate}}
                  <mat-icon class="remix-icon" svgIcon="remix"></mat-icon>
                </button>
              </div>
            </div>


            <div class="image-container">
              <div class="image-class">
                <app-filestore-background-image
                  [paths]="['/featuredGames/backgrounds/'+game.gameId+'.png']"
                  (isVideo)="false"
                >
                </app-filestore-background-image>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5"></div>
        <div class="d-flex flex-row mt-5 justify-content-between">

          <div
            class="back-ground-screenshot"
            *ngFor="let index of [1,2,3]"
          >
            <div class="back-ground-screenshot"
                 storageBackground [targetPath]="'featuredGames/screenshots/'+game.gameId+'/screenshot-'+index+'.png'">
            </div>
          </div>

        </div>
      </div>

    </div>

  `,

  styles: [`
    .back-ground-screenshot {

      width:236px;
      height: 418px;
      border-radius: 5px;
    }
    .pos-qr-code-container {
      width: 120px;
      height: 120px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 10px #0000001A;
      border-radius: 2px;
      padding: 10px;
    }

    .remix-icon {
      height: 15px;
      width: 18px;
    }

    mat-icon.remix-icon {
      display: inline-flex;
      height: 15px;
      width: 18px;
    }

    mat-icon.remix-icon > svg {
      vertical-align: top;
    }

    .remix-outer {
      display: flex;
      flex-direction: column;
      align-content: flex-end;
      align-items: flex-end;
      width: 418px;
    }

    .button-outer {
      width: 405px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .remix-btn {
      text-transform: uppercase;
      width: 168px;
    }

    .remix-btn-wrapper {
      /*padding-top: 2rem;*/
      margin-bottom: 2rem;
      border-top: 2px solid #f1f1f1;
      width: 168px;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }


    .image-container {
      width: 405px;
      position: relative;
    }

    .image-class {
      position: absolute;
      width: 405px;
      height: 200px;

    }

    .game {
      padding: 3rem 0;
    }

    .game-details,
    .form-drag-drop {
      flex: 1;
      justify-content: space-between;
    }

    .game-icon {
      width: 68px;
      margin-right: 1rem;
    }


    .game-icon img {
      width: 68px;
    }

    .form-group {
      margin-bottom: 2rem;
    }

    .form-label {
      color: #0000008A;
      font-size: 14px;
      margin: 0 0 0.5rem;
    }

    .form-heading {
      margin: 0;
      font-weight: 400;
    }

    .form-description {
      font-size: 16px;
      color: #000000;
      width: 405px;
    }

    .form-info {
      max-width: 410px;
    }

    .form-group mat-form-field {
      width: 100%;
    }

    .form-drag-drop {
      margin-left: 5rem;
    }

  `],
  encapsulation: ViewEncapsulation.None
})
export class GameLibararyUserViewgameComponent implements OnInit {
  clicked = false;
  gameOwners : Observable<GameAuthor[]> = this.store.select(currentGameOwners);

  game$: Observable<any> = this.store.select(getCurrentGame);

  getQrCode(gameId: number) {
    // return "https://play.bibendo.nl/game/" + gameId;
    return environment.deep_link + 'game/' + gameId;
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private store: Store<State>) {
  }

  ngOnInit(): void {
    this.store.dispatch(UserLibraryPageActions.getLibraryGame({}));
    // this.store.dispatch(new PlayerLoadRequestAction());
    this.store.dispatch(GameAuthorActions.loadGameAuthors());
    // this.store.dispatch(new LoadGameAuthorRequestAction());

  }

  remix(game: Game): void {
    this.clicked = true;
    this.store.dispatch(GamePageActions.cloneGame({game}));
    this.router.navigate(['/games/list']);
  }

  addRun(gameId) {
    const dialogRef = this.dialog.open(NewRunDialogComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {title: ''}
    });

    dialogRef.afterClosed().subscribe(run => {
      if (run) {
        this.store.dispatch(GameRunsPageActions.createRun({run}));
        // this.store.dispatch(new CreateRunRequestAction(result));
      }
      this.router.navigate(['/game/' + gameId + '/runs']);

    });
  }
}

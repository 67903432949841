import * as fromRoot from "../../core/reducers";
import {createFeatureSelector, createSelector} from "@ngrx/store";

import {gamesAdapter} from "../../portal-management/store/portal-games.reducer";
import {FeaturedGameState} from "./user-library.reducer";
import {selectRouteParam} from "../../core/selectors/router.selector";


export interface State extends fromRoot.State {
  gameLibrary: FeaturedGameState;
}

export const getGameLibraryFeature = createFeatureSelector<FeaturedGameState>('gameLibrary');
export const getCursor = createSelector(getGameLibraryFeature, state => state.cursor);
export const getFeaturedGames = createSelector(getGameLibraryFeature, (state) => state);
export const getQueryResult = createSelector(getGameLibraryFeature, (state) => state.currentQuery);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = gamesAdapter.getSelectors(getFeaturedGames);


export const featuredGames = createSelector(getGameLibraryFeature, (state) => state.featuredGames);

export const getLibraryQueryGames = createSelector(getQueryResult, selectAll, (query, games) => {
  if (!query || query.trim() == '') {
    return games;
  }
  return games.filter(game => game.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
});



export const getCurrentGame = createSelector(selectEntities, selectRouteParam('gameId'),
    (entities, gameId) => entities[gameId]);

import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {MediaLibraryPageActions} from "../store/media-lib.actions";
import {selectEntities} from "../store/media-lib.selector.cache";
import {State} from "../../core/reducers";


@Directive({
  selector: '[videoSrcDirective]'
})
export class VideoSourceDirective implements OnInit, OnDestroy, OnChanges {

  @Input() targetPath;
  @Input() initColor = '#F5F5F7';
  @Output() absent = new EventEmitter();
  url: string;
  subscription = new Subscription();

  constructor(
    private store: Store<State>,
    private elRef: ElementRef,
    private renderer: Renderer2) {
  }

  ngOnInit(): void {
    if (this.targetPath) {
      let path = this.targetPath;
      path = path.replace('//', '/');
      if (!path.startsWith('/')) {
        path = '/' + path
      }
      this.subscription.add(this.store.select(selectEntities).subscribe(_entities => {
        if (_entities[path]) {
          if (_entities[path].absent) {
            this.absent.emit(true);
          }
          this.renderer.setAttribute(
            this.elRef.nativeElement,
            'src',
            `${_entities[path].url}`);

        }
      }))
    }

  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.targetPath && this.targetPath) {
      this.store.dispatch(MediaLibraryPageActions.downloadMetadata({path: this.targetPath, ignoreDistinct: false}));
      this.ngOnInit();
    }
  }


}

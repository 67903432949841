import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {GameMessage} from "../../game-messages/store/types";
import {getEditMessageSelector} from "../store/game-message.selector";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {GameMessagePageActions} from "../store/game-message.actions";
import {GameMessagesPageActions} from "../../game-messages/store/game-messages.actions";
import {Router} from "@angular/router";
import {isUserLogged} from "../../ngrx-auth/store/ngrx-auth.selectors";

@Component({
  selector: 'app-message-top-nav-bar',
  template: `
    <app-screen-editor-navbar>
      <div class="ext-navbar-left">
        <div class="back-button" *ngIf="message$ |async as message">
          <app-header-back-button
            *ngIf="message.gameId"
            (onClicked)="resetEditMessage()"
          >
          </app-header-back-button>
        </div>
      </div>
    </app-screen-editor-navbar>

    <router-outlet *ngIf="isUserLogged$ | async"></router-outlet>
  `,
  styles: [
  ]
})
export class MessageTopNavBarComponent {
  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
  isUserLogged$ = this.store.select(isUserLogged);

  constructor(
    private store: Store<State>,
    private router:Router,
  ) {
    this.store.dispatch(GameMessagesPageActions.getMessages({}));
    this.store.dispatch(GameMessagePageActions.gameMessageEdit());
  }

  resetEditMessage() {
    let newPath = this.router.url.substring(0, this.router.url.lastIndexOf('/'));
    newPath = newPath.substring(0, newPath.lastIndexOf('/'));
    newPath = newPath.substring(0, newPath.lastIndexOf('/'));
    this.router.navigate([newPath]);
    // this.store.dispatch(new ResetGameMessageEditAction());
  }
}

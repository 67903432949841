import {createActionGroup, emptyProps, props} from "@ngrx/store";
import {GameAccessList} from "./game-author.service";
import {GameAuthor} from "./game-author.state";

export const GameAuthorActions = createActionGroup({
  source: 'Game Authors Page',
  events: {
    'Load Game Authors': emptyProps(),
    'Load My Game Authors': emptyProps(),
    'Add Game Author': props<{ fullId: string; role: string}>(),
    'Delete Game Author': props<{ fullId: string}>(),
  },
});

export const GameAuthorApiActions = createActionGroup({
  source: 'Game Authors Api',
  events: {
    'Add Game Author Completed': props<{gameAuthor: GameAuthor}>(),
    'Remove Game Author Completed': props<{gameAuthor: GameAuthor}>(),
    'Load Game Authors Completed': props<{gameAccessList: GameAccessList}>(),
    'Add Game Authors without resumption': props<{gameAccessList: GameAccessList}>(),
    'Update Full last sync': props<{time: number}>(),

  },
});

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {GameCategoryPageActions} from "../store/game-category.actions";
import {getCurrentGameCategoryMappings} from "../store/game-category.selectors.mappings";
import {Observable} from "rxjs";

@Component({
  selector: 'app-category-edit-labels',
  template: `
    <div class="mb-5">
      <mat-chip-list #chipList aria-label="Category selection">
        <mat-chip
          *ngFor="let category of categories | async "
          [selectable]="true"
          [removable]="true"
          (removed)="remove(category.categoryId)">
          {{category.title}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  `,
  styles: []
})
export class CategoryEditLabelsComponent implements OnInit, OnChanges {

  categories: Observable<any> = this.store.select(getCurrentGameCategoryMappings);
  @Input() gameId: any;

  constructor(
    private store: Store<State>
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(GameCategoryPageActions.query());
  }

  remove(categoryId: any) {
    this.store.dispatch(GameCategoryPageActions.removeMapping({mappingId: categoryId + ":" + this.gameId}));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gameId) {
      this.store.dispatch(GameCategoryPageActions.queryMappings({gameId: this.gameId}));
    }
  }


}

import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatestWith, Observable, Subscription} from "rxjs";
import {amountOfFilters, currentGameLabels} from "../store/game-messages.selector";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from "@angular/forms";
import {map} from "rxjs/operators";
import {GameMessagesPageActions} from "../store/game-messages.actions";

@Component({
  selector: 'app-screen-tag-filter',
  template: `
    <mat-form-field class="w-100">
      <mat-label>{{'ROW_HEADERS.SCREEN_TAGS' | translate}}</mat-label>
      <mat-chip-list #chipList aria-label="Fruit selection">
        <mat-chip
          *ngFor="let tag of (labels | async)"
          (removed)="remove(tag)">
          {{tag}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>

        <input
          placeholder="Suggest tag..."
          #tagInput
          [formControl]="tagCtrl"
          [matAutocomplete]="search_auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <!--      <input-->

      <!--        [matAutocomplete]="search_auto"-->
      <!--        matInput [placeholder]="placeholder"-->
      <!--        [(ngModel)]="filter"-->
      <!--        (input)="onFilterChange($event.target.value)"-->
      <!--      >-->
      <mat-icon matPrefix>filter_list</mat-icon>
      <mat-autocomplete #search_auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let label of (allMessageLabels |async ) " [value]="label">
          {{label}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  `,
  styles: []
})
export class ScreenTagFilterComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  @Input() placeholder;

  @Input()
  public set filter(existingFilter: string[]) {
    if (existingFilter) {
      this.labels.next(existingFilter);
    }

  } ;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  labels = new BehaviorSubject([]);
  subscription = new Subscription();

  allMessageLabels: Observable<string[]> = this.store.select(currentGameLabels).pipe(
    combineLatestWith(this.labels),
    map(([messageLabels, selectedLabels]: [string[], string[]])=> {
      const labelsSet = new Set(messageLabels);
      selectedLabels.forEach(l => labelsSet.delete(l))
      return Array.from(labelsSet);
    })
  );

  _suggestLabels: string[] = [];

  constructor(public store: Store<State>,) {
  }

  ngOnInit(): void {
    this.subscription.add(this.store.select(amountOfFilters).subscribe((amount)=>{
      if (amount === 0 && this.tagInput) {
        this.labels.next([]);
        this.tagInput.nativeElement.value = '';
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      // this.fruits.push(value);
      const tags = [...this.labels.value, value];
      console.log('adding to labels', tags)
      this.labels.next(tags)
      this.store.dispatch(GameMessagesPageActions.setFilter({tags}));
    }

    // Clear the input value
    event.chipInput!.clear();

    this.tagCtrl.setValue(null);
  }


  remove(fruit: string): void {
    const index = this.labels.value.indexOf(fruit);


    if (index >= 0) {
      const array = [...this.labels.value];
      array.splice(index, 1);
      this.labels.next(array);
      this.store.dispatch(GameMessagesPageActions.setFilter({tags: array}));
    }
  }

  // @Debounce(300)
  // onFilterChange(filter) {
  //   console.log(filter)
  // }

  selected(event: MatAutocompleteSelectedEvent) {
    // this.fruits.push(event.option.viewValue);
    const tags = [...this.labels.value, event.option.viewValue]
    this.labels.next(tags)
    this.store.dispatch(GameMessagesPageActions.setFilter({tags}));
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue([]);
  }
}

import * as fromRoot from "../../core/reducers";
import {createFeatureSelector, createSelector} from "@ngrx/store";

import {RunUserState} from "./run-players.state";
import {gamePlayersSyncTimesAdapter} from "./run-players.reducer";
import * as fromRootSelector from "../../core/selectors/router.selector";

export interface State extends fromRoot.State {
  game: RunUserState;
}

export const getRunUsersFeature = createFeatureSelector<RunUserState>('run-players');
export const getGameSyncTimes = createSelector(getRunUsersFeature, (state: RunUserState) => state.gameSyncTimes);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = gamePlayersSyncTimesAdapter.getSelectors(getGameSyncTimes);


export const currentGameLastSyncTime = createSelector(
  selectEntities,
  fromRootSelector.selectRouteParam('gameId'),
  (syncTimes, gameId) =>  syncTimes[Number.parseInt(gameId, 10)]?.syncTime || 1
);

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../../../core/reducers";
import {Observable} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {AddGameCollaboratorComponent} from "../../../../game-messages/modal/add-game-collaborator.component";
import {canWriteCurrentGame, ownsCurrentGame} from "../../../../game-authors/store/game-author.selectors";

@Component({
    selector: 'app-game-detail-collaborators',
    template: `
        <div class="collaborators-container">

            <div class="d-flex flex-row justify-content-between">
                <div class="collaborators color-600 font-regular-24-24-roboto">
                    {{'GAME.COLLABORATOR'|translate}}
                </div>
                <div
                        *ngIf="iAmOwner|async"
                        class="add-collaborator-button color-700 font-regular-11-15-roboto">
                    <button mat-button (click)="addCollaborator()">{{'GAME.ADD_COLLABORATOR'|translate}}</button>
                </div>
            </div>

            <div class="collaborators-small font-regular-11-15-roboto mt-3">
                {{'CONTACT.COLLABORATORS' | translate}}
            </div>
            <app-game-detail-collaborator-entry *ngFor="let game_author of gameAuthors"
                                                [author]="game_author"
                                                [isAbleToDelete]="gameAuthors.length > 1"
                                                (roleChange)="onRoleChange.emit($event)"
                                                (onDelete)="onDelete.emit($event)"
            ></app-game-detail-collaborator-entry>

        </div>
    `,
})
export class GameDetailCollaboratorsComponent implements OnInit {
    public iCanWrite: Observable<boolean> = this.store.select(canWriteCurrentGame);
    public iAmOwner: Observable<boolean> = this.store.select(ownsCurrentGame);

    @Input() gameAuthors: any[];
    @Output() onRoleChange = new EventEmitter();
    @Output() onDelete = new EventEmitter();
    @Output() onNewAuthor = new EventEmitter();

    constructor(
        private store: Store<State>,
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
    }

    addCollaborator() {

        const dialogRef = this.dialog.open(AddGameCollaboratorComponent, {
            panelClass: ['modal-fullscreen', "modal-dialog"],
            data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.fullId) {
                this.onNewAuthor.emit({
                    fullId: result.fullId.fullId,
                    role: result.role
                });

                // this.store.dispatch(new AddUserToRunRequestAction(result.fullId));
            }

        });
    }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-codeword-entry',
  template: `
    <div class="combination-outer d-flex flex-column-reverse">
      <div class="codeWordPlaceHolder mb-5 " [ngClass]="{first:first}">

      </div>
<!--      <div>{{letter}}</div>-->

    </div>
  `,
  styles: [`
    .codeWordPlaceHolder {
      width: 30px;
      border-radius: 3px;
      border: 3px solid rgba(255, 255, 255, 0.4);
    }

    .first {
      border: 3px solid #FFFFFF;
    }
  `]
})
export class CodeWordCombinatieEntryComponent implements OnInit {

  @Input() letter;
  @Input() first;

  constructor() { }

  ngOnInit(): void {
  }

}

import {PlayerApiActions, PlayerPageActions} from './player.actions';
import {PendingInvitation, Player} from './player.state';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";

export interface PlayerState extends EntityState<Player> {
  selectedPlayer: string;
  lastSyncTime: number;
  pendingInvitations: (PendingInvitation | Player)[];
  pendingInvitationsToMe: PendingInvitation[];
  // invitation: Invitation;
  loadingComplete: boolean;
  filter: string[];
}

export const allAccountsAdapter = createEntityAdapter<Player>(
  {selectId: (a: Player) => (a.accountType + ':' + a.localId)}
);

export const playersInitialState: PlayerState = allAccountsAdapter.getInitialState({
  selectedPlayer: null,
  lastSyncTime: -1,
  filter: [],
  pendingInvitations: [],
  pendingInvitationsToMe: [],
  // invitation: null,
  loadingComplete: false,
});


export const reducer = createReducer(
  playersInitialState,
  on(PlayerApiActions.addAccounts, (state, action) =>
    allAccountsAdapter.upsertMany(
      (action.accounts.accountList || []).map(account => ({...account})) //, isConnection: account.connection
      , state)
  ),
  on(PlayerApiActions.updateFullSyncTime, (state, action) => ({...state,
    lastSyncTime: action.time
  })),
  on(PlayerApiActions.addAccount, (state, action) => allAccountsAdapter.upsertOne(action.player, state)),
  on(PlayerPageActions.setFilter, (state, action) => ({...state, filter: action.filters})),
  on(PlayerApiActions.addPending, (state, action) => ({...state,
    pendingInvitations: action.accounts.accountList || []
  })),
  on(PlayerApiActions.addPendingToMe, (state, action) => ({...state,
    pendingInvitationsToMe: action.accounts.invitationList
  })),
  on(PlayerApiActions.removeContactComplete, (state, action) =>  allAccountsAdapter.removeOne(action.player.fullId, state)),
  on(PlayerApiActions.acceptInvitationSuccess, (state, action) => ({...state,
    pendingInvitationsToMe: [
      ...state.pendingInvitationsToMe.filter((inv: PendingInvitation) => inv.invitationId !== action.invitationId)
    ]
  })),

  on(PlayerApiActions.removePendingInvitationSuccess, (state, action) => ({...state,
    pendingInvitations: [
      ...(state.pendingInvitations || []).filter((inv: PendingInvitation) => inv.invitationId !== action.invitationId
      )
    ]
  })),

);

export function reducers(
  state: PlayerState | undefined,
  action: Action
): PlayerState {
  return reducer(state, action);
}
//
// export function reducersOld(
//   state = playersInitialState, action: actions.PlayerAction): PlayerState {
//   switch (action.type) {
//
//     case AuthActionTypes.Logout: {
//       return {...playersInitialState};
//     }
//
//
//
//     default: {
//       return state;
//     }
//   }
// }

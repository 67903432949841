import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {GameAuthor} from "./game-author.state";


@Injectable({
  providedIn: 'root',
})
export class GameAuthorService {

  constructor(
    private http: HttpClient) {
  }

  loadAuthors(gameId: any): Observable<GameAccessList> {
    return this.http
      .get<any>(environment.api_url + '/game/access/' + gameId);
  }


  loadAllAuthors(from: number, resumptionToken?: string): Observable<GameAccessList> {
    let postFix = '1';
    if (!!from) {
      postFix = from + '';
    }
    if (!!resumptionToken) {
      postFix += '?resumptionToken=' + resumptionToken;
    }
    return this.http
      .get<GameAccessList>(environment.api_url + '/game/access/user/' + postFix)
      .pipe(
        map(list => ({
              ...list,
            gameAccess: (list.gameAccess || []).map(transOneGameAuthor)
            }
          )
        )
      );
  }

  addAuthors(gameId: number, fullId: string, role: string): Observable<GameAuthor> {
    return this.http
      .post<GameAuthor>(environment.api_url + '/game/access/' + gameId + '/' + fullId + '/' + role, {})
      .pipe(map(transOneGameAuthor));
  }

  revokeAuthors(gameId: number, fullId: string): Observable<GameAuthor> {
    return this.http
      .delete <GameAuthor>(environment.api_url + '/game/access/revoke/' + gameId + '/' + fullId).pipe(map(transOneGameAuthor));
  }

}

const transOneGameAuthor = (game: GameAuthor) => {
  if (game.gameId) {
    game.gameId = Number.parseInt(game.gameId + '', 10);
  }
  if (game.timestamp) {
    game.timestamp = Number.parseInt(game.timestamp + '', 10);
  }
  return game;

};

export interface GameAccessList {
  gameAccess: GameAuthor[];
  loginAccount?: string;
  serverTime: number;
  from: number;
  resumptionToken?: string;
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, combineLatestWith, Observable} from "rxjs";
import {selectEntities} from "../../game-themes/store/game-theme.selectors";
import {Dictionary} from "@ngrx/entity";
import {GameTheme} from "../../game-themes/store/game-theme.state";
import {map} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";

@Component({
  selector: 'app-game-theme-selector-light',
  template: `
    <ng-container *ngIf="theme$ | async as selectedTheme">
      <div class="game-theme-selector box">
        <div
          class="d-flex flex-row justify-content-between w-100 h-100">

          <div
            class="h-100"
          >
            <div class="theme-icon ">
              <div class="font-regular-12-20-roboto color-888">
                <label>{{'COMMON.ICON_IMAGE'|translate}}</label>
              </div>
              <div class="theme-icon__img"
                   storageBackground
                   [targetPath]="selectedTheme.iconPath"
              >
              </div>
            </div>

            <div class="primary-color mt-5">
              <app-color-input
                [label]="'COMMON.PRIMARY_COLOR'|translate"
                [color]="selectedTheme?.primaryColor"
                [canEdit]="false"
                (onChange)="primColorChange($event)"
              ></app-color-input>
            </div>


          </div>

          <div class="theme-background"
               storageBackground
               [targetPath]="selectedTheme.backgroundPath"
          >
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .game-theme-selector {
        /*background-color: #FAFAFA;*/
        text-align: center;
        width: 306px;
        height: 246px;
      }

      .game-theme-selector.full {
        max-width: 85%;
        margin: auto;
      }

      .theme-btn {
        text-transform: uppercase;
      }

      .theme-background {
        width: 127px;
        height: 246px;
      }


      .theme-background img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .theme-icon {
        text-align: left;
      }

      .theme-icon label {
        color: #0000008A;
      }

      .theme-icon__img {
        height: 54px;
        width: 54px;
      }

      .theme-icon__img img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }
    `
  ]
})
export class GameThemeSelectorLightComponent implements OnInit {

  @Output() themeChange = new EventEmitter();

  @Input() set themeId(id) {
    this.themeId$.next(id)
  };

  public themeId$ = new BehaviorSubject(0)
  public theme$ = this.store.select(selectEntities)
    .pipe(
      combineLatestWith(this.themeId$),
      map(([themeEntities, themeId]: [Dictionary<GameTheme>, number]) => themeEntities[themeId]),
    )

  emitOnChange = true;

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
  }


  primColorChange(event) {
  }

  openSelectModal(theme = null) {
    console.log("in openselectmodal")
  }
}

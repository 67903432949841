import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Router} from "@angular/router";
import {currentMessageFromRouter, getMultipleMessagesSelector} from "../../game-messages/store/game-messages.selector";
import {getCurrentGame} from "../../games-management/store/game.selector";
import * as fromRootSelector from "../../core/selectors/router.selector";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-responses-bottom-toolbar',
  template: `
    <mat-toolbar class="toolbar-white mat-elevation-z2" *ngIf="messages$ | async as messages">
      <div class="maxwidth" *ngIf="game$ | async as game">
        <div class="count-wrapper">
          <span class="font-medium-14-20-roboto count-label">{{'MESSAGE.SCREENS' | translate}}</span>
          <mat-chip-list>
            <mat-chip class="count" color="primary" disableRipple selected>{{ messages.length }}</mat-chip>
          </mat-chip-list>
        </div>
        <div class="screens horizontal-scroll-wrapper" (wheel)="onScrollChips($event)">
          <ng-container *ngIf="runId$ | async as runId">
            <mat-chip-list *ngIf="selectedMessageId$| async as selectedScreen">
              <mat-chip
                *ngFor="let item of messages"
                color="primary"
                class="light-chip"
                [routerLink]="'../'+item.id"
                [selected]="selectedScreen == item.id"
              >{{ item.name }}</mat-chip>
            </mat-chip-list>
          </ng-container>
        </div>
      </div>
    </mat-toolbar>
  `,
  styles: [
  ]
})
export class ResponsesBottomToolbarComponent implements OnInit {
  public game$: Observable<Game> = this.store.select(getCurrentGame);
  public runId$: Observable<any> = this.store.select(fromRootSelector.selectRouteParam('runId'));
  public messages$: Observable<any> = this.store.select(getMultipleMessagesSelector);
  public selectedMessageId$ : Observable<number> = this.store.select(currentMessageFromRouter);

  constructor(
    private store: Store<State>,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  onSelect(url) {
    this.router.navigate([url]).then(() => {
      // console.log('navigation change', url);
      // this.store.dispatch(new SelectMessage());
      // this.store.dispatch(new Load());
    })
  }

  onScrollChips(event) {
    document.querySelector('.screens').scrollLeft += event.deltaY;
    event.preventDefault();
  }
}

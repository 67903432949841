import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import * as fromRootSelector from "../../core/selectors/router.selector";
import {Router} from "@angular/router";
import {RunPlayerPageActions} from "../../run-players/store/run-players.actions";
import {GameRun} from "../store/game-runs.state";
import {currentRunWithEdits} from "../store/game-runs.selector";
import {RunAuthorPageActions} from "../../run-authors/store/run-author.actions";
import {canViewRun, ownsRun} from "../../run-authors/store/run-author.selectors.ownership";
import {getMultipleMessagesSelector} from "../../game-messages/store/game-messages.selector";
import {map} from "rxjs/operators";
import {GameMessagesPageActions} from "../../game-messages/store/game-messages.actions";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-run-top-nav-component',
  template: `
    <div class="header primary-background-color ">
      <div class="maxwidth" *ngIf="game$|async as game">
        <app-account-section></app-account-section>

        <div *ngIf="run$ | async as run" class="title font-medium-32-43-roboto">{{run.title}}</div>

        <div class="back-button" *ngIf="(game$ |async) as game">
          <app-header-back-button [route]="'/game/'+game.gameId+ '/runs'"></app-header-back-button>
        </div>
        <div class="context-tabs">

          <nav mat-tab-nav-bar [backgroundColor]="'primary'" *ngIf="runId$ |async as runId">
            <a mat-tab-link
               class="tab-link"
               *ngIf="canViewRun$ |async"
               routerLinkActive #runtab1="routerLinkActive"
               [disabled]="getDisabled(game, runId)"
               [active]="runtab1.isActive"
               [ngClass]="{'active-black':runtab1.isActive}"
               [routerLink]="'/game/'+game.gameId+'/detail/runs/'+runId+'/players'">
              {{'RUNS.PLAYERS'|translate}}
            </a>
            <a mat-tab-link
               *ngIf="messageId$ | async as messageId"
               class="tab-link"
               routerLinkActive #runtab2="routerLinkActive"
               [routerLinkActiveOptions]="{exact: true}"
               [disabled]="getDisabled(game, runId)"
               [active]="runtab2.isActive || isActiveResults()"
               [ngClass]="{'active-black':runtab2.isActive}"
               [routerLink]="'/game/'+game.gameId+'/detail/runs/'+runId+'/results/' + messageId">
              {{'RUNS.RESULTS'|translate}}
            </a>
            <a mat-tab-link
               class="tab-link"
               routerLinkActive #runtabc="routerLinkActive"
               [routerLinkActiveOptions]="{exact: true}"
               [disabled]="getDisabled(game, runId)"
               [active]="runtabc.isActive"
               [ngClass]="{'active-black':runtabc.isActive}"
               [routerLink]="'/game/'+game.gameId+'/detail/runs/'+runId+'/collaborators'">
              {{'CONTACT.COLLABORATORS'|translate}}
            </a>

            <a mat-tab-link
               class="tab-link"
               routerLinkActive #runtab3="routerLinkActive"
               *ngIf="ownsRun$ |async"
               [disabled]="getDisabled(game, runId)"
               [ngClass]="{'active-black':runtab3.isActive}"
               [active]="runtab3.isActive"
               [routerLink]="'/game/'+game.gameId+'/detail/runs/'+runId+'/settings'">{{'HOME.SETTINGS'|translate}}</a>
            <a mat-tab-link
               class="tab-link"
               *hasOnePermission="['admin']"
               routerLinkActive #runtabactions="routerLinkActive"
               [disabled]="getDisabled(game, runId)"
               [ngClass]="{'active-black':runtabactions.isActive}"
               [active]="runtabactions.isActive"
               [routerLink]="'/game/'+game.gameId+'/detail/runs/'+runId+'/actions'">
              ACTIONS
            </a>

          </nav>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
    <router-outlet></router-outlet>

  `,
  styles: []
})
export class RunTopNavComponentComponent implements OnInit {
  public game$: Observable<Game> = this.store.select(getCurrentGame);
  runId$: Observable<any> = this.store.select(fromRootSelector.selectRouteParam('runId'));
  run$: Observable<GameRun> = this.store.select(currentRunWithEdits);

  canViewRun$: Observable<any> = this.store.select(canViewRun);
  ownsRun$: Observable<any> = this.store.select(ownsRun);

  messageId$ = this.store.select(getMultipleMessagesSelector)
    .pipe(
      map(messages => messages[0] && messages[0].id),
    );

  // backRouter = backToManageGames;

  constructor(
    private store: Store<State>,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(RunPlayerPageActions.getPlayersForRun());

    this.store.dispatch(RunAuthorPageActions.loadRunAuthorsCurrentRun());
    this.store.dispatch(GameMessagesPageActions.getMessages({}));
  }

  getDisabled(gameId, runId) {
    return !gameId || !runId;
  }

  isActiveResults() {
    return this.router.url.includes('/results/');
  }


}

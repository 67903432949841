import {GameRunsApiActions, GameRunsPageActions} from './game-runs.actions';
import {GameRun} from './game-runs.state';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";

export interface GameRunsState extends EntityState<GameRun> {
  selectedRun: number;
  editRun: GameRun;
}

export const runAdapter = createEntityAdapter<GameRun>({
  selectId: (gr: GameRun) => gr.runId
});

export const gameRunsInitialState: GameRunsState = runAdapter.getInitialState({
  selectedRun: 0,
  editRun: null,
});


const reducer = createReducer(
  gameRunsInitialState,
  on(GameRunsApiActions.addRun,
    (state, action) => runAdapter.upsertOne(action.run, state)),
  on(GameRunsPageActions.resetEdits, (s, a) => ({...s, editRun: null})),
  on(GameRunsPageActions.deleteRun, (s, a) => runAdapter.removeOne(a.run.runId, s)),
  on(GameRunsPageActions.updateRun, (s, a) => ({
    ...s,
    editRun: {
      ...s.editRun,
      ...a.run
    }
  }))
);

export function reducers(
  state: GameRunsState | undefined,
  action: Action
): GameRunsState {
  return reducer(state, action);
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActionsSubject, Store} from "@ngrx/store";
import {BehaviorSubject, combineLatestWith, Observable, Subscription} from "rxjs";
import {filter, map} from 'rxjs/operators';

import {State} from "../../core/reducers";
import {SelectThemeComponent} from "../../game-themes/modal/select-theme.component";
import {GameThemePageActions} from "../../game-themes/store/game-theme.actions";
import {selectAll, selectEntities} from 'src/app/game-themes/store/game-theme.selectors';
import {CreateThemeNameModalComponent} from "../../game-themes/modal/create-theme/create-theme-name-modal.component";
import {CreateThemeSettingsComponent} from "../../game-themes/modal/create-theme/create-theme-settings.component";
import {DeleteThemeComponent} from "../../game-themes/modal/create-theme/delete-theme.component";
import {getUser} from "../../ngrx-auth/store/ngrx-auth.selectors";
import {canWriteCurrentGame} from "../../game-authors/store/game-author.selectors";
import {GameTheme} from "../../game-themes/store/game-theme.state";
import {Dictionary} from "@ngrx/entity";
import {SelectThemeModalComponent} from "../../game-themes/modal/select-theme-modal.component";
import {ofType} from "@ngrx/effects";
import {GamePageActions} from "../../games-management/store/game.actions";


@Component({
  selector: 'app-game-theme-selector',
  template: `
    <ng-container *ngIf="theme$ | async as selectedTheme">
      <div class="game-theme-selector box">
        <div
          class="d-flex flex-row justify-content-between w-100 h-100">
          <div
            class="h-100"
          >
            <div class="theme-icon ">
              <div>
                <label>{{'COMMON.ICON_IMAGE'|translate}}</label>
              </div>
              <div class="theme-icon__img"
                   storageBackground
                   [targetPath]="selectedTheme.iconPath"
              >
              </div>
            </div>

            <div class="primary-color mt-5">
              <app-color-input
                [label]="'COMMON.PRIMARY_COLOR'|translate"
                [color]="selectedTheme?.primaryColor"
                [canEdit]="false"
                (onChange)="primColorChange($event)"
              ></app-color-input>
            </div>
          </div>
          <div class="theme-background"
               storageBackground
               [targetPath]="selectedTheme.backgroundPath"
          >
          </div>
        </div>
        <div class="theme-btn-wrapper" *ngIf="(iCanWrite$|async)">
          <button mat-flat-button color="primary" class="theme-btn"
                  (click)="openSelectModalNew(selectedTheme)">{{'GAME.SWITCH_THEME'|translate}}</button>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .game-theme-selector {
        /*background-color: #FAFAFA;*/
        text-align: center;
        width: 306px;
        height: 366px;
      }

      .game-theme-selector.full {
        max-width: 85%;
        margin: auto;
      }

      .theme-btn {
        text-transform: uppercase;
      }

      .theme-background {
        width: 127px;
        height: 246px;
      }


      .theme-background img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .theme-btn-wrapper {
        padding-top: 2rem;
        margin-top: 2rem;
        border-top: 2px solid #f1f1f1;
      }

      .theme-icon {
        text-align: left;
      }

      .theme-icon label {
        color: #0000008A;
      }

      .theme-icon__img {
        height: 54px;
        width: 54px;
      }

      .theme-icon__img img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }
    `
  ]
})
export class GameThemeSelectorComponent implements OnInit, OnDestroy {
  public iCanWrite$: Observable<boolean> = this.store.select(canWriteCurrentGame);
  @Input() emitOnChange = false;
  @Output() themeChange = new EventEmitter();

  @Input() set themeId(id) {
    this.themeId$.next(id)
  };

  public themeId$ = new BehaviorSubject(0)
  public themes$ = this.store.select(selectAll) as Observable<GameTheme[]>;
  public themesEnt$ = this.store.select(selectEntities) as Observable<Dictionary<GameTheme>>;
  public theme$ = this.themesEnt$.pipe(
    combineLatestWith(this.themeId$),
    map(([themeEntities, themeId]: [ Dictionary<GameTheme>,number]) => themeEntities[themeId]),
  )

  private user$: Observable<any> = this.store.select(getUser);

  private subscription: Subscription = new Subscription();
  private currentUserId;
  private dialogNameThemeRef: any;

  constructor(
    private store: Store<State>,
    public dialog: MatDialog,
    private actionsSubject$: ActionsSubject
  ) {
  }


  ngOnInit(): void {
    this.subscription.add(this.user$
      .pipe(filter(u => !!u))
      .subscribe((user) => this.currentUserId = user.uid));
    this.subscription.add(this.actionsSubject$.pipe(
      ofType(GamePageActions.selectTheme),
    ).subscribe((action) => {
      this.themeId$.next(action.theme.themeId);
    }));
  }
  openSelectModalNew(theme = null) {
    const dialogRef = this.dialog.open(SelectThemeModalComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });

    dialogRef.afterClosed().subscribe(theme => {
      if (theme) {
        console.log('result* is ', theme);
        this.store.dispatch(GamePageActions.selectTheme({theme}))
        //this.store.dispatch(new SetSelectedThemeAction(theme));
      }

    });
  }

  openSelectModal(theme = null) {
    console.log("in openselectmodal")


    const dialogRef = this.dialog.open(SelectThemeComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });

    dialogRef.componentInstance.selectedTheme = theme;

    this.subscription.add(dialogRef.componentInstance.submit
      .subscribe((theme) => {
        console.log("theme from modal", theme);
        this.themeId$.next(theme.themeId);
        if (this.emitOnChange) {
          this.themeChange.emit(theme.themeId);
        } else {
          //this.store.dispatch(new SetSelectedThemeAction(theme));
          this.store.dispatch(GamePageActions.selectTheme({theme}))
        }

        dialogRef.close();
      }));

    this.subscription.add(
      dialogRef.componentInstance.onCreateTheme.subscribe(() => {
        this.openCreateThemeModal();
      })
    );

    this.subscription.add(
      dialogRef.componentInstance.onDeleteTheme.subscribe((toDeleteTheme) => {
        this.deleteThemeModal(toDeleteTheme);
      })
    );

    this.subscription.add(
      dialogRef.componentInstance.onUpdateTheme.subscribe((toEditTheme) => {
        this.openThemeSettingsModal(toEditTheme);
      })
    );
  }

  openCreateThemeModal() {
    this.dialogNameThemeRef = this.dialog.open(CreateThemeNameModalComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });

    this.subscription.add(
      this.dialogNameThemeRef.componentInstance.submit.subscribe(theme => {
        this.store.dispatch(GameThemePageActions.createTheme({theme}));
      })
    );
  }

  openThemeSettingsModal(theme) {
    const dialogRef = this.dialog.open(CreateThemeSettingsComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });

    dialogRef.componentInstance.theme = {
      ...dialogRef.componentInstance.theme, ...theme,
      fullAccount: this.currentUserId
    };

    // this.subscription.add(
    //   dialogRef.componentInstance.submit.subscribe((theme) => {
    //     this.store.dispatch(GameThemePageActions.updateTheme({theme}));
    //     dialogRef.close();
    //   })
    // );
  }

  deleteThemeModal(theme) {
    const dialogRef = this.dialog.open(DeleteThemeComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });

    dialogRef.componentInstance.theme = {
      ...dialogRef.componentInstance.theme, ...theme,
      fullAccount: this.currentUserId
    };

    // this.subscription.add(
    //   dialogRef.componentInstance.submit.subscribe((payload) => {
    //     this.store.dispatch(GameThemePageActions.deleteTheme({themeId: payload.themeId}));
    //     dialogRef.close();
    //   })
    // );
  }

  primColorChange(event) {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // getDownloadUrl(path: string) {
  //     if (typeof path !== 'string') {
  //         return path;
  //     }
  //     return this.afStorage.ref(path).getDownloadURL().toPromise();
  // }
}

import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {getEditMessageSelector} from "../../../game-message/store/game-message.selector";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {GameMessagePageActions} from "../../store/game-message.actions";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {environment} from "../../../../environments/environment";
import {canWriteCurrentGame} from "../../../game-authors/store/game-author.selectors";

@Component({
  selector: 'app-screen-editor',
  template: `
    <div class="editor-container">
      <div [ngSwitch]="(message$|async)?.type" class="pos-message">
        <app-screen-editor-type-openurl
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.OpenUrl'">
        </app-screen-editor-type-openurl>

        <app-screen-editor-type-narrator
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.NarratorItem'">
        </app-screen-editor-type-narrator>

        <app-screen-editor-type-picture-question
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.PictureQuestion'">
        </app-screen-editor-type-picture-question>

        <app-screen-editor-type-audio-question
          selected="fas fa-microphone-alt"
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.AudioQuestion'">
        </app-screen-editor-type-audio-question>

        <app-screen-editor-type-audio-question
          selected="fas fa-edit"
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.TextQuestion'">
        </app-screen-editor-type-audio-question>

        <app-screen-editor-type-audio-question
          selected="fas fa-video"
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.VideoQuestion'">
        </app-screen-editor-type-audio-question>

        <app-screen-editor-type-audio-object
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.AudioObject'">
        </app-screen-editor-type-audio-object>

        <app-screen-editor-type-scan-tag
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.ScanTag'">
        </app-screen-editor-type-scan-tag>

        <app-screen-editor-type-video-object
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.VideoObject'">
        </app-screen-editor-type-video-object>

        <app-screen-editor-type-multiple-choice-image
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.MultipleChoiceImageTest'">
        </app-screen-editor-type-multiple-choice-image>

        <app-screen-editor-type-single-choice-image
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.SingleChoiceImageTest'">
        </app-screen-editor-type-single-choice-image>

        <app-screen-editor-type-single-choice-test
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.SingleChoiceTest'">
        </app-screen-editor-type-single-choice-test>

        <app-screen-editor-type-multiple-choice-test
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.MultipleChoiceTest'">
        </app-screen-editor-type-multiple-choice-test>

        <app-screen-editor-type-codeword
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.CodeWord'">
        </app-screen-editor-type-codeword>




        <app-screen-editor-type-combinationlock
          *ngSwitchCase="'org.celstec.arlearn2.beans.generalItem.CombinationLock'">
        </app-screen-editor-type-combinationlock>





      </div>

<!--      <button mat-stroked-button-->
<!--              *ngIf="iCanWrite|async"-->
<!--              class="gl-pos-button-right pos-button gl-style-stroke-button gl-style-large-button"-->
<!--              routerLink="../../.."-->
<!--              color="primary" (click)="save()">{{'ACTIONS.SAVE' |translate}}</button>-->
<!--      <div class="pos-spacer"></div>-->

      <div
        *ngIf="iCanWrite|async"
        class="game-theme-selector width-only">
        <div class="mt-4 w-100">
          <button
            (click)="save()"
            class="w-100 button-height"
            mat-flat-button
            color="primary"
            routerLink="../../.."
          >{{'ACTIONS.SAVE' |translate}}
          </button>
        </div>
      </div>

    </div>
  `,
  styles: [`
    /*.editor-container {*/
    /*  position: relative;*/
    /*  margin-top: 100px;*/
    /*  width: 403px;*/
    /*  margin-left: auto;*/
    /*  margin-right: auto;*/
    /*}*/

    /*.save-button {*/
    /*    !*position: relative;*!*/
    /*    !*right: 0px;*!*/
    /*    margin-bottom: 300px;*/
    /*}*/

    .pos-message {
      position: relative;
    }

    .pos-spacer {
      position: relative;
      height: 300px;
    }

    .pos-button {
      width: 131px;
      text-transform: uppercase;
    }
  `]
})
export class ScreenEditorComponent {
  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
  public iCanWrite: Observable<boolean> = this.store.select(canWriteCurrentGame);

  constructor(private store: Store<State>,
              public gaService: GoogleAnalyticsService) {
  }

  save() {
    if (environment.gatracking !== '') {
      this.gaService.event('SAVE_SCREEN', 'SCREEN');
    }
    this.store.dispatch(GameMessagePageActions.gameMessageSave());
  }
}

import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../core/reducers';
import {GameMessagesPageActions} from '../../game-messages/store/game-messages.actions';
import {GameMessagePageActions} from '../store/game-message.actions';
import {Observable} from "rxjs";
import {GameMessage} from "../../game-messages/store/types";
import {getEditMessageSelector} from "../store/game-message.selector";

import {GoogleAnalyticsService} from "ngx-google-analytics";
import {environment} from "../../../environments/environment";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {GamePageActions} from "../../games-management/store/game.actions";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-screen-detail',
  template: `
    <div class="detail-container">
      <div *ngIf="game$ | async as game">
        <app-preview-pane
          [game]="game"
          class="pos-preview-pane">
        </app-preview-pane>
      </div>
      <!--            <app-screen-editor-navbar>-->
      <!--                <div class="ext-navbar-left">-->
      <!--                    <div class="back-button" *ngIf="message$ |async as message">-->
      <!--                        <app-header-back-button-->
      <!--                                (onClicked)="resetEditMessage()"-->
      <!--                                routerLink="../../.."-->
      <!--                        >-->
      <!--                        </app-header-back-button>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </app-screen-editor-navbar>-->

      <div class="proper-max-width ">
        <app-screen-editor
          class="screen-editor-center"></app-screen-editor>
      </div>
    </div>
  `,
  styles: [`
    .detail-container {
      position: relative;

    }

    @media (max-width: 992px) {
      .proper-max-width {
        margin-left: 0px;
        margin-right: 429px;
        position: relative;
        /*background: #9fd1ee;*/
      }
    }

    @media (min-width: 992px) and (max-width: 1850px) {
      .proper-max-width {
        max-width: 992px;
        margin-left: calc((100% - 992px) / 2);
        margin-right: 429px;
        position: relative;
        /*background: red;*/
      }
    }

    @media (min-width: 1850px) {
      .proper-max-width {
        max-width: 992px;
        margin: auto;
        position: relative;
        /*background: green;*/
      }
    }

    .pos-preview-pane {
      position: fixed;
      right: 0px;
      top: 0px;
      background: #FAFAFA;
      width: 429px;
      height: 100vh;
      z-index: 5;
      box-shadow: 0px 9px 18px #0000002E;
    }

    .back-button {
      position: absolute;
      top: 64px;
      right: 19px;

      /*background: red;*/
    }

    .ext-navbar-left {

      width: 1000px;
      position: absolute;
      left: -1000px;
      height: 144px
    }

  `]
})
export class ScreenDetailComponent implements OnInit {

  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
  game$: Observable<Game> = this.store.select(getCurrentGame);

  constructor(
    private store: Store<State>,
    public gaService: GoogleAnalyticsService
  ) {
  }

  ngOnInit() {
    if (environment.gatracking !== '') {
      this.gaService.event('OPEN_SCREEN', 'SCREEN');
    }

    this.store.dispatch(GameMessagesPageActions.getMessages({}));
    this.store.dispatch(GamePageActions.getGame({}));
    this.store.dispatch(GameMessagePageActions.gameMessageEdit());
  }

  resetEditMessage() {
    // this.store.dispatch(new ResetGameMessageEditAction());

  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from "rxjs/operators";
import {Player} from "../../player-management/store/player.state";
import {Organisation, OrganisationList} from "../../organisations-management/store/organisations.state";
import {PortalAccount, UserState} from "../../user-management/store/portal-user.state";
import {addUsersData} from "../../player-management/store/player.service";


@Injectable()
export class AccountService {

  constructor(private http: HttpClient) {
  }

  get(): Observable<PortalAccount> {

    return this.http
      .get<any>(environment.api_url + '/account/accountDetails').pipe(
        map(user => {
          if (user.expirationDate) {
            user.expirationDate = parseInt(user.expirationDate, 10);
          }
          return addUsersData(user);
        })
      );
  }

  recreate(): Observable<any> {
    return this.http
      .get<any>(environment.api_url + '/account/create');
  }

  searchByOrganisation(organisationId: string): Observable<Player[]> {
    return this.http
      .get<any>(environment.api_url + '/account/organisation/' + organisationId + "/list")
      .pipe(
        map(result => result.accountList),
        map(accounts => accounts && accounts.map(account => this.populateAccount(account)))
      );
  }

  updateExpiration(fullId: string, expiration: number, action: any): Observable<any[]> {
    return this.http
      .post<any>(environment.api_url + `/account/${fullId}/expiration/${expiration}`, null);
  }

  updateOrganisation(fullId: string, organisation: string): Observable<any[]> {
    return this.http
      .post<any>(environment.api_url + `/account/${fullId}/organisation/${organisation}`, null);
  }

  updateAccount(account: Player): Observable<Player> {
    delete (account as any).claims;
    return this.http
      .post<Player>(environment.api_url + '/account/update', account)
      .pipe(map(acc => this.populateAccount(acc)));
  }

  createAccount(account: Player): Observable<Player> {

    return this.http
      .post<Player>(environment.api_url + '/account/create', account)
      .pipe(map(account => this.populateAccount(account)));
  }


  private populateAccount(account) {
    return addUsersData(account && {
      ...account,
      labels: account.label && account.label.split(';'),
    });
  }

  deleteAccount(fullId: string): Observable<Player> {
    return this.http
      .delete<Player>(environment.api_url + '/account/' + fullId);
  }

  suspendAccount(fullId: string, suspend: boolean): Observable<Player> {
    if (!suspend) {
      return this.http
        .post<Player>(environment.api_url + '/account/unsuspend/' + fullId, {});
    }
    return this.http
      .post<Player>(environment.api_url + '/account/suspend/' + fullId, {});
  }

  setRole(fullId: string, role: string, isInRole: boolean): Observable<Player> {
    return this.http
      .post<Player>(environment.api_url + '/account/' + fullId + '/' + role + '/' + isInRole, {});
  }

  setPortalRole(fullId: string, role: string, isInRole: boolean): Observable<Player> {
    return this.http
      .post<Player>(environment.api_url + '/account/role/' + fullId + '/' + role + '/' + isInRole, {});
  }

  createOrganisation(organisation: Organisation): Observable<Organisation> {
    return this.http
      .post<Organisation>(environment.api_url + '/organization/create', organisation);
  }

  listOrganizations(): Observable<OrganisationList> {
    return this.http
      .get<any>(environment.api_url + '/organization/list');
  }

  deleteOrganisation(id: string): Observable<Organisation> {
    return this.http
      .delete<Organisation>(environment.api_url + '/organization/' + id);
  }

  getOrganization(organisationId: string): Observable<Organisation> {
    return this.http
      .get<Organisation>(environment.api_url + '/organization/' + organisationId);
  }

  updateOrganisationExpiration(organisationId: string, date: number): Observable<Organisation> {
    return this.http
      .post<Organisation>(environment.api_url + '/organization/' + organisationId + '/exp/' + date, {});
  }

  getAccountViaToken(resetPwToken: string)  : Observable<PortalAccount> {
    return this.http
      .get<PortalAccount>(environment.api_url + '/account/resetPwToken/'+resetPwToken);
  }


  setInitialPw(password : string, token: string, displayName: string) {
    return this.http
      .post<any>(environment.api_url + '/account/setPw/'+token, {password, displayName: displayName});
  }

  requestNewPassword(email: string ) : Observable<any> {
    return this.http
      .post<any>(environment.api_url + '/account/resetpw/'+email, {});
  }
}


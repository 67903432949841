import { ResetPasswordComponent } from "./pages/reset-password.component";
import {NewPasswordPageComponent} from "./pages/new-password-page.component";


export const loginRoutes = [
  // {
  //   path: 'login', component: LoginPageComponent, pathMatch: 'full',
  // },
  {
    path: 'forgot-password', component: NewPasswordPageComponent, pathMatch: 'full',
  },
  // {
  //   path: 'login/demo/create', component: AccountCreateDemoComponent, pathMatch: 'full',
  // },
  // {
  //   path: 'registreer/pnp', component: AccountSuspendedComponent, pathMatch: 'full',
  // },
  // {
  //   path: 'wachtwoord/:token', component: CreateInitialPasswordComponent, pathMatch: 'full',
  // },
  {
    path: 'reset/wachtwoord/:token', component: ResetPasswordComponent, pathMatch: 'full',
  }
];

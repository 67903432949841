import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionsSubject, Store} from '@ngrx/store';

import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable, Subscription} from 'rxjs';
// import {getAuthErrorPipe} from '../../auth/store/auth.selector';
import {State} from '../../core/reducers';
import {environment} from "../../../environments/environment";
import {GoogleAnalyticsService} from "ngx-google-analytics";

import {
  AuthActionTypes,
  AuthError,
  CredentialsLogin,
  ResetPasswordRequest
} from 'src/app/ngrx-auth/store/ngrx-auth.actions';
// import {AuthActionTypes, CredentialsLogin, GoogleLogin, AuthError} from "../../auth/actions/auth.actions";
import {filter} from "rxjs/operators";
import {Router} from "@angular/router";
// import {ResetPasswordRequest} from "../../auth/actions/password-management.actions";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-login-screen',
  // templateUrl: environment.login,
  // templateUrl : 'login-screen.component.html',
  template: 'todo',
  styles: [`

    .pos-full-canvas {
      position: absolute;
      width: 100vw;
      height: 100vh;
    }

    .pos-image-left {
      position: absolute;
      width: 50%;
      height: 100vh;
      left: 0px;
    }

    .pos-full-canvas-right {
      position: absolute;
      width: 50%;
      height: 100vh;
      right: 0px;
    }

    .pos-login-container {
      position: absolute;
      top: 50%;
      left: 0;
      width: 640px;
      height: 454px;
      transform: translate(0%, -50%);
    }

    .pos-app-title {
      position: relative;
      top: 0px;
      left: 0px;
      width: 640px;
      height: 44px;
      text-align: center;
    }

    .pos-subtitle {
      position: relative;
      margin-top: 12px;
      width: 640px;
      height: 23px;
      text-align: center;
    }

    .pos-email-field {
      position: relative;
      margin-top: 50px;
      top: 299 px;
      left: 98px;
      width: 444px;
      height: 30px;
      opacity: 1;

    }


    .pos-password-field {
      position: relative;
      margin-top: 35px;
      left: 98px;
      width: 444px;
      height: 30px;
    }

    .pos-option-buttons {
      position: relative;
      margin-top: 35px;
      left: 98px;
      width: 444px;
      height: 21px;
    }


    .pos-remember-me {
      position: absolute;
      top: 3px;
      left: 0px;
      width: 78px;
      height: 21px;
      text-align: left;

    }

    .pos-forgot-pw {
      position: absolute;
      top: 0px;
      right: -16px;
      text-align: right;
    }

    .pos-login-button {
      position: relative;
      margin-top: 35px;
      left: 98px;
      width: 444px;
      height: 44px;
    }

    .pos-seperator {
      position: relative;
      margin-top: 35px;
      left: 98px;
      width: 444px;
      height: 19px;
    }


    .pos-stroke-left {
      position: absolute;
      top: 10px;
      left: 0px;
      width: 190px;

    }

    .pos-stroke-right {
      position: absolute;
      top: 10px;
      right: 0px;
      width: 190px;
    }

    .pos-divider-of {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translate(-50%, 0%);
      height: 19px;
    }

    .pos-social-login-buttons {
      position: relative;
      margin-top: 35px;
      left: 98px;
      width: 444px;
      height: 44px;
    }


    .pos-google-button {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 205px;
      height: 44px;
    }

    .pos-facebook-button {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 205px;
      height: 44px;
    }


    .pos-login-button-text {
      position: absolute;
      right: 0px;
      top: 11px;
      width: 160px;
      text-align: center;
    }

    .pos-icon-in-button {
      position: absolute;
      left: 25px;
      top: 3px;
      opacity: 1;
      width: 18px;
      height: 18px;
    }

    .style-image-left {
      background: transparent url("/assets/img/login/login-image.png") 0% 0% no-repeat padding-box;
      background-size: cover;
      border-width: 1px;
    }

    .style-stroke {
      height: 1px;
      background: #000000 0% 0%;
      opacity: 0.12;
    }

  `]
})
export class LoginScreenComponent implements OnInit, OnDestroy {

  form = new FormGroup({
    userName: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    rememberMe: new FormControl(true, Validators.required),
  });

  passwordError : string;
  usernameError : string;

  public get username():any { return this.form.get('userName'); }
  public get wachtwoord():any { return this.form.get('password'); }

  private subscription = new Subscription();



  email = '';
  pw = '';
  rememberMe = true;
  error$: Observable<string>;

  constructor(
    public store: Store<State>,
    public _snackBar: MatSnackBar,
    public gaService: GoogleAnalyticsService,
    public actionsSubject$: ActionsSubject,
    public router: Router
  ) {
  }

  ngOnInit() {
    this.subscription.add(this.actionsSubject$.pipe(
      filter((action) => action.type === AuthActionTypes.Authenticated)
    ).subscribe(() => {
      this.router.navigate(['/games']);

    }));
    this.subscription.add(this.actionsSubject$.pipe(
      filter((action) => action.type === AuthActionTypes.AuthError)
    ).subscribe((action:AuthError) => {
      this.usernameError = undefined;
      this.passwordError = undefined;
      if (action.payload.code === 'auth/wrong-password') {
        this.passwordError = "Wachtwoord fout";

      }
      if (action.payload.code === 'auth/user-not-found') {
        this.usernameError = "Gebruiker niet gevonden";

      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  googleLogin() {
    if (environment.gatracking !== '') {
      this.gaService.event('LOGIN');
    }

    //todo re-enable google login
    // this.store.dispatch(
    //   new GoogleLogin()
    // );

  }

  facebookLogin(){}


  forgotPassword() {
    this.store.dispatch(new ResetPasswordRequest({
      email: this.email,
      redirectUrl: environment.deep_link + '#/login'
    }))
    this.store.dispatch(new CredentialsLogin(this.email, this.pw, this.rememberMe));
  }

  credentialsLogin() {
    this.passwordError = undefined;
    this.usernameError = undefined;
    this.store.dispatch(new CredentialsLogin(
      this.form.value.userName,
      this.form.value.password,
      this.form.value.rememberMe));
  }

}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromRoot from 'src/app/core/reducers';
import {GameMessage} from "../../game-messages/store/game-messages.state";
import * as fromGameThemes from 'src/app/game-themes/store/game-theme.selectors';
import {getCurrentMessageSelector} from "../../game-messages/store/game-messages.selector";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {Game} from "../../games-management/store/game.state";
import {GameMessageState} from "./game-message.reducer";

export interface State extends fromRoot.State {
    gameMessage: GameMessageState;

}

export const getGameMessageFeature = createFeatureSelector<GameMessageState>('gameMessage');

export const getEditMessageSelector = createSelector(getGameMessageFeature, getCurrentMessageSelector,
  (state: GameMessageState, message) => state.editMessage || message);

export const getPreviewSelector = createSelector(getGameMessageFeature, (state: GameMessageState) => state.previewScreen);

export const aggregatePreviewSelector = createSelector(
    getPreviewSelector,
    getEditMessageSelector,
    (state: any, message: GameMessage) => {
        if (!message) {
            return state;
        }
        return {...state, messageType: message.type};
    }
);

export const selectedColor = createSelector(
    getEditMessageSelector,
  getCurrentGame,
  fromGameThemes.selectEntities,
    (message: GameMessage, game: Game, ent) => {

        if (message && message.primaryColor) {
            return message.primaryColor;
        }
        if (game) {
            const theme = ent[game.theme];
            return theme && theme.primaryColor;
        }
        return null;
    }
);

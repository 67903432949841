import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {MatDialog} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {NewMessageComponent} from "../modal/new-message/new-message.component";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-new-screen-tile',
  template: `
    <div class="tile-dimensions m-2 new-button-screen">
      <div class="h-100 d-flex flex-column justify-content-center align-items-center">
        <div *ngIf="game$|async as game">
          <button color="accent" mat-fab (click)="newMessage(game)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div class="mt-3 font-regular-16-24-roboto color-888">
          {{ 'MESSAGE.CREATE_TITLE' | translate }}
        </div>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class NewScreenTileComponent implements OnInit {
  public game$: Observable<Game> = this.store.select(getCurrentGame);
  constructor(public dialog: MatDialog,
              public store: Store<State>,) { }

  ngOnInit(): void {
  }

  newMessage(game: Game) {
    const dialogRef = this.dialog.open(NewMessageComponent, {
      width: '99%',
      data: {game},
      panelClass: ['modal-fullscreen', "modal-dialog"]
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.animal = result;
    });
  }
}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GameRun} from "../store/game-runs.state";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {allMyOwnedRunsCurrentGame} from "../../run-authors/store/run-author.selectors";
import {GameRunsPageActions} from "../store/game-runs.actions";

@Component({
  selector: 'app-run-tile',
  template: `
    <div class="tile tile-dimensions position-relative tile-style" *ngIf="myOwnedRuns | async as ownedRuns">
      <app-hover-overlay
        [clickText]="ownedRuns.indexOf(run.runId+'') !== -1 ? 'COMMON.EDIT' : 'RUNS.RESULTS'"
        [actionText]="ownedRuns.indexOf(run.runId+'') !== -1 ? ['ACTIONS.DELETE_RUN']: []"
        [actionIcon]="{'ACTIONS.DELETE_RUN': 'delete_forever' }"
        (actionClicked)="actionClickedMethod($event, run)"
        [navTo]="navTo"
        (clickCenter)="actionClickedCenter($event, run, ownedRuns.indexOf(run.runId+'') !== -1)"

      >
        <app-run-amount-of-players
            [runId]="run.runId"
          [run]="run"
        ></app-run-amount-of-players>
      </app-hover-overlay>
      <div class="position-icon pos-icon-type icon-style" *ngIf="icon">
        <i [ngClass]="iconClass"></i>
      </div>
      <div class="position-footer footer-style">
        <div class="position-footer-title footer-title-style font-regular-16-20-roboto">{{run.title}}</div>
        <div class="position-footer-subtitle footer-subtitle-style font-regular-12-20-roboto">
          {{run.lastModificationDate | date:'mediumDate'}}</div>
      </div>
    </div>
  `
})
export class RunTileComponent implements OnInit, OnChanges {


  @Input() run: GameRun;
  @Input() icon: any;
   navTo:string ;

  myOwnedRuns = this.store.select(allMyOwnedRunsCurrentGame)

  constructor(
    private store: Store<State>,
    private router: Router) {
  }

  ngOnInit(): void {
  }

  actionClickedCenter(event, run, owns) {
    console.log('clicked ... ', owns)
    // if (accessRights === RunAccessRight.EDITOR) {


if (owns) {
  this.router.navigate([`game/${run.gameId}/detail/runs/${run.runId}/players`]);
} else {
  this.router.navigate([`game/${run.gameId}/detail/runs/${run.runId}/results`]);
}




    // } else if (accessRights === RunAccessRight.OWNER) {
    //   console.log("in click ow", accessRights);
    //   this.router.navigate([`game/${this.run.gameId}/detail/runs/${this.run.runId}/settings`]);
    // } else {
    //   console.log("in click no", accessRights);
    //   this.router.navigate([`game/${this.run.gameId}/detail/runs/${this.run.runId}/results`]);
    // }

  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.navTo = `/game/${this.run.gameId}/detail/runs/${this.run.runId}/players`;
  }

  actionClickedMethod(event, run) {
    if (event === 'ACTIONS.DELETE_RUN') {
      console.log("clicked on delete", run);
      this.store.dispatch(GameRunsPageActions.deleteRun({run}))
    }
  }
}

import {VideosTutorialComponent} from "./pages/videos-tutorial.component";
import {VideoTutorialComponent} from "./pages/video-tutorial.component";
import {FaqTutorialComponent} from "./pages/faq-tutorial.component";


export const tutorialRoutes = [
    {path: '', redirectTo: 'overview', pathMatch: 'full'},
    {path: 'video', component: VideosTutorialComponent, pathMatch: 'full'},
    {path: 'video/:gameId', component: VideosTutorialComponent, pathMatch: 'full'},
    {path: 'video/:gameId/:videoId', component: VideoTutorialComponent, pathMatch: 'full'},
    {path: 'faq', component: FaqTutorialComponent, pathMatch: 'full'},
    {path: 'faq/:gameId', component: FaqTutorialComponent, pathMatch: 'full'},
    {path: '**', redirectTo: 'overview'},
  ];

import {Component, Input} from '@angular/core';
import {RunResponse} from "../store/run-responses.state";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {selectEntities} from "../../player-management/store/player.selector";

@Component({
  selector: 'app-photo-gallery-tile',
  template: `
  <div class="w-100 h-100">
    <div storageBackground
         class="w-100 h-100"
         [targetPath]="response.responseValue"
    ></div>
    <div  class="user-placeholder" *ngIf="players$ | async as playersDict">
      <div class="user" *ngIf="playersDict[response.userId] as player; else loadingplayer">
                <div class="user__avatar">{{player.shortAvatar}}</div>
                <div class="user__name">{{player.name}}</div>
      </div>
      <ng-template #loadingplayer>
        <app-loading-player-for-response
          [userId]="res.userId"
        ></app-loading-player-for-response>
      </ng-template>
    </div>
  </div>
  `,
  styles: [
  ]
})
export class PhotoGalleryTileComponent  {
  @Input() response: RunResponse;
  public players$: Observable<any> = this.store.select(selectEntities);

  constructor(private store: Store<State>) { }

}

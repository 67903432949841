import {Component, Input, OnInit} from '@angular/core';
import {RunResponse} from "../store/run-responses.state";
import {ref, Storage} from "@angular/fire/storage";
import {getDownloadURL} from "@firebase/storage";

@Component({
  selector: 'app-video-gallery-tile',
  template: `
    <div *ngIf="url">
      <video class="video-el" [src]="url"></video>
    </div>
  `
})
export class VideoGalleryTileComponent implements OnInit {

  url;
  @Input() response: RunResponse;

  constructor(public storage: Storage) {
  }

  async ngOnInit() {
    this.url = await getDownloadURL(ref(this.storage, this.response.responseValue));
  }

}

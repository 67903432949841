import {GameMessage} from "../../game-messages/store/types";
import {Game} from "../../games-management/store/game.state";

export interface TutorialState {
    faqGames: Game[];
    videoGames: Game[];
    messages: GameMessage[];

    selectedVideoCategory?: string;
}

export const initialTutorialState: TutorialState = {
    faqGames: [],
    videoGames: [],
    messages: [],

    selectedVideoCategory: 'all',
};

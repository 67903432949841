import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GameThemeApiActions, GameThemePageActions} from './game-theme.actions';
import {distinct, map, mergeMap, withLatestFrom,} from 'rxjs/operators';
import {State} from "../../core/reducers";
import * as fromRootSelector from "../../core/selectors/router.selector";
import {createResetAfterAuthenticatedEffect} from "../../shared/util/effects-util";
import {GameThemeService} from "./game-theme.service";

@Injectable()
export class GameThemeEffects {

  constructor(private actions$: Actions,
              private store: Store<State>,
              private gameThemeService: GameThemeService,
  ) {
  }

  queryOne = createResetAfterAuthenticatedEffect(
    this.actions$,
    this.actions$.pipe(
      ofType(GameThemePageActions.queryOne),
      withLatestFrom(
        this.store.select(fromRootSelector.selectRouteParam('themeId')).pipe(
          map((themeId) => Number.parseInt(themeId, 10))
        )
      ),
      distinct(([action, themeId]) => action.themeId || themeId),
      mergeMap(([action, themeId]) => this.gameThemeService.getTheme(action.themeId || themeId)),
      map(theme => GameThemeApiActions.addOneSuccess({theme}))
    )
  );

  create$ = createEffect(
    () => this.actions$.pipe(
      ofType(GameThemePageActions.createTheme),
      mergeMap((action) => this.gameThemeService.createTheme(action.theme)),
      map(theme => GameThemeApiActions.createOneSuccess({theme}))
    ));

  update$ = createEffect(
    () => this.actions$.pipe(
      ofType(GameThemePageActions.updateTheme),
      mergeMap((action) => this.gameThemeService.createTheme(action.theme)),
      map(theme => GameThemeApiActions.addOneSuccess({theme}))
    ));

  delete$ = createEffect(
    () => this.actions$.pipe(
      ofType(GameThemePageActions.deleteTheme),
      mergeMap((action) => this.gameThemeService.deleteTheme(action.themeId)),
      map(response => GameThemeApiActions.deleteThemeSuccess({themeId: response.themeId}))
    ));


}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../../core/reducers';
import {Observable, Subscription} from 'rxjs';
import {GamePageActions} from '../../store/game.actions';
import * as fromGame from '../../store/game.selector';
import * as fromTempGame from '../../store/game.selector.temp';
import {MatDialog} from '@angular/material/dialog';
import {filter, map, take} from "rxjs/operators";
import {selectEntities} from 'src/app/game-themes/store/game-theme.selectors';
import {getUser} from "src/app/ngrx-auth/store/ngrx-auth.selectors";
import {canAuthorGame} from "../../../game-authors/store/game-author.selectors";
import {Dictionary} from "@ngrx/entity";
import {GameAuthorActions} from "../../../game-authors/store/game-author.actions";
import {Game} from "../../store/game.state";

@Component({
  selector: 'app-games-list',
  template: `
    <app-top-level-navbar [title]="'GAME.MYGAMES'">
    </app-top-level-navbar>
    <div class="full-width-container maxwidth">
      <div class="bibendo-center-outer">
        <app-search-button
          placeholder="{{ 'MESSAGE.START_TYPING_TO_SEARCH' |translate}}"
          [dispatchActionFunction]="dispatchActionFunction"
          [filter]="filter"
        >
        </app-search-button>
        <div *ngIf="canAuthorGame$ | async as canAuthor">
          <div class="bibendo-center">

            <div *hasOnePermission="['admin', 'make']">


              <app-new-game-tile
              ></app-new-game-tile>
            </div>
            <div class="tile-dimensions m-2" *ngFor="let game of (tempGames$|async)">
              <app-screen-tile [title]="game.title"
                               [subtitle]="game.lastModificationDate | date:'mediumDate'"
                               [imagePath]=" themes[game.theme]?.backgroundPath"
                               [themeId]="game.theme"
                               [actionText]="[]"
                               [loading]="true"

              ></app-screen-tile>
            </div>

            <div class="tile-dimensions m-2" *ngFor="let game of (gameList$|async)">
              <ng-template [ngIf]="canAuthor[game.gameId] === 1 || canAuthor[game.gameId] === 2" [ngIfElse]="playOnly">
                <app-screen-tile
                  [title]="game.title"
                  [subtitle]="game.lastModificationDate | date:'mediumDate'"
                  [themeId]="game.theme"
                  [actionText]="['GAME.DELETEGAME', 'GAME.CLONEGAME']"
                  [actionIcon]="{'GAME.CLONEGAME': 'content_copy'}"
                  [clickText]="'GAME.EDITGAME'"
                  [navTo]="'/game/' +game.gameId+ '/screens'"
                  (actionClicked)="deleteGame(game, $event)"
                ></app-screen-tile>
              </ng-template>

              <ng-template #playOnly>

                <app-screen-tile [title]="game.title"
                                 [subtitle]="game.lastModificationDate | date:'mediumDate'"
                                 [imagePath]=" themes[game.theme]?.backgroundPath"
                                 [themeId]="game.theme"
                                 [actionText]="[ 'GAME.CLONEGAME']"
                                 [actionIcon]="{'GAME.CLONEGAME': 'content_copy'}"
                                 [clickText]="'GAME.PLAYGAME'"
                                 [navTo]="'/game/' +game.gameId+ '/runs'"
                                 (actionClicked)="deleteGame(game, $event)"
                ></app-screen-tile>
              </ng-template>


            </div>
          </div>

        </div>

      </div>

    </div>


  `
})
export class GamesListComponent implements OnInit, OnDestroy {


  hasMakePermission: Observable<boolean> = this.store.select(getUser).pipe(
    map(u => !!(u.claims['make'] || u.claims['admin']))
  );

  gameList$: Observable<Game[]> = this.store.select(fromGame.getFilteredGamesSelector);
  tempGames$: Observable<Game[]> = this.store.select(fromTempGame.selectAll);
  canAuthorGame$: Observable<Dictionary<number>> = this.store.select(canAuthorGame);
  public currentFilter$: Observable<string[]> = this.store.select(fromGame.getGamesFilters).pipe(
    take(1),
  );

  public themes = {};

  private filterSubscription: Subscription;
  public filter: string;
  public dispatchAction = GamePageActions.setFilter({filters:[]});
  public dispatchActionFunction = (filters: string[]) => {
    this.store.dispatch(GamePageActions.setFilter({filters}));
  }

  constructor(
    public dialog: MatDialog,
    private store: Store<State>
  ) {
  }

  ngOnInit() {
    this.store.dispatch(GameAuthorActions.loadMyGameAuthors());

    this.filterSubscription = this.currentFilter$
      .pipe(
        filter(f => !!f)
      )
      .subscribe((f) => this.filter = f[0]);

    this.store.select(selectEntities)
      .subscribe(themes => this.themes = themes);
  }

  // newGame() {
  //
  //   const dialogRef = this.dialog.open(NewGameComponent, {
  //     width: '99%',
  //     data: {},
  //     panelClass: ['modal-fullscreen', "modal-dialog"]
  //
  //   });
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //   });
  // }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  deleteGame(game, action) {
    if (action === "GAME.DELETEGAME") {
      this.store.dispatch(GamePageActions.deleteGame({gameId: game.gameId}))
      // this.store.dispatch(new DeleteGameRequestAction({gameId: game.gameId}));
    } else if (action === "GAME.CLONEGAME") {
      this.store.dispatch(GamePageActions.cloneGame({game: game}))
      // this.store.dispatch(new CloneGameRequestAction({gameId: game.gameId}));
      // this.store.dispatch(new WaitForAsyncGameCreation({timeout: 1000}));
    }
  }
}

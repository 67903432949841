import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {GameMessage} from "../../../../game-messages/store/game-messages.state";
import {getEditMessageSelector, selectedColor} from "../../../store/game-message.selector";
import {Store} from "@ngrx/store";
import {State} from "../../../../core/reducers";
import {GameMessagePageActions} from "../../../store/game-message.actions";
import {getCurrentGame} from "../../../../games-management/store/game.selector";
import {canWriteCurrentGame} from "../../../../game-authors/store/game-author.selectors";
import {SelectAssetComponent} from "../../../../portal-image-management/modals/select-asset.component";
import {MatDialog} from "@angular/material/dialog";
import {Game} from "../../../../games-management/store/game.state";

@Component({
  selector: 'app-screen-editor-type-video-object',
  template: `
    <mat-form-field
      class="w-100 mb-3">
      <input matInput [placeholder]="'GAME.TITLE'|translate"
             [disabled]="!(iCanWrite|async)"
             [ngModel]="(message$|async)?.name"
             (ngModelChange)="titleChange($event)">
    </mat-form-field>

    <div class="w-100 mb-3">
      <app-color-input
        [canEdit]="(iCanWrite|async)"
        [label]="'COMMON.PRIMARY_COLOR' |translate"
        [showThemeName]="true"
        [color]="primColor$ | async"
        [unselect]="true"
        (onChange)="primColorChange($event)"
      ></app-color-input>
    </div>

    <div class="w-100 mb-3">
      <app-change-background-button
      ></app-change-background-button>
    </div>

    <div class="w-100 d-flex flex-row flex-nowrap justify-content-between">
      <button
        class="flex-grow-1 button-height"
        mat-stroked-button
        color="primary"
        (click)="selectVideo()"
      >
        <mat-icon class="pinkish-grey mr-1">videocam</mat-icon>
        {{'MESSAGE.SELECT_VIDEO' | translate}}
      </button>
    </div>


    <mat-form-field
      class="w-100 mb-3">
      <input matInput [placeholder]="'MESSAGE.BUTTON_TEXT'|translate"
             [disabled]="!(iCanWrite|async)"
             [ngModel]="(message$|async)?.description"
             (ngModelChange)="descriptionChange($event)">
    </mat-form-field>

    <app-font-awesome-picker
      *hasOnePermission="['advanced']"
      [disabled]="!(iCanWrite|async)"
      [message]="(message$|async)"
      selected="fas fa-film"
      (iconChange)="iconChange($event)">
      >
    </app-font-awesome-picker>

    <div *ngIf="game$ | async as game">
      <div *ngIf="!game.webEnabled">
        <app-video-autoplay
          class="w-100 mb-3"
          [videoAutoPlay]="(message$|async)?.autoPlay"
        ></app-video-autoplay>
      </div>
    </div>


    <div class="w-100 mb-3">
      <app-pick-location-on-map
        class="gl-pos-between-fields"
        [locationBased]="(message$|async)?.lat"
        [showInList]="(message$|async)?.showInList"
        [lat]="(message$|async)?.lat"
        [lng]="(message$|async)?.lng"
      >
      </app-pick-location-on-map>
    </div>

    <div
      *ngIf="(game$ | async).chapters !== 0"
      class="w-100 mb-3">
      <app-create-chapter
        (chapter)="game$.chapter = $event"
        [game]="game$ | async"
        [message]="message$ | async"
      ></app-create-chapter>
    </div>

    <app-create-label
      #tooltip="matTooltip"
      [matTooltip]="'GAME.TAGS_TOOLTIP' | translate"
      matTooltipPosition="left"
      matTooltipHideDelay="500"
      class="w-100 mb-2"
      [label]="(message$|async)?.label">

    </app-create-label>
  `
})
export class ScreenEditorTypeVideoObjectComponent {
  primColor$ = this.store.select(selectedColor);
  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
  game$: Observable<Game> = this.store.select(getCurrentGame);
  public iCanWrite: Observable<boolean> = this.store.select(canWriteCurrentGame);

  constructor(private store: Store<State>,
              public dialog: MatDialog) {
  }

  titleChange(event: any) {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{name: event}}));
  }

  descriptionChange(event: any) {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{description: event}}));
  }

  iconChange(event: any) {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{icon: event}}));
  }

  primColorChange(color: string) {
    if (color === "default") {
      this.store.dispatch(GameMessagePageActions.clearColor());
    } else {
      this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{primaryColor: color}}));
    }

  }

  selectVideo() {
    const dialogRef = this.dialog.open(SelectAssetComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(GameMessagePageActions.updateFileRef({
        delete: false,
        key: 'video',
        value: result

      }));
    });
  }
}

import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {amountOfFilters} from "../store/game-messages.selector";
import {GameMessagesPageActions} from "../store/game-messages.actions";

@Component({
  selector: 'app-display-filter',
  template: `
    <div style="height: 36px">
    <div
      class="d-flex flex-row justify-content-start align-items-end"
      *ngIf="hasActiveFilter$| async as filters"
      >

      <div
        *ngIf="filters == 1"
        class="mb-2 font-medium-16-21-roboto"
        >
          {{'MESSAGE.ACTIVE_FILTER' | translate}}
      </div>

      <div
        *ngIf="filters > 1"
        class="mb-2 font-medium-16-21-roboto"
      >
          {{filters}} {{'MESSAGE.ACTIVE_FILTERS' | translate}}
      </div>
      <div *ngIf="filters !== 0">
        <button
          (click)="resetFilters()"
          mat-button color="accent">{{'MESSAGE.CLEAR_FILTER' | translate}}</button>
      </div>
    </div>
    </div>
  `
})
export class DisplayFilterComponent implements OnInit {

  public hasActiveFilter$ = this.store.select(amountOfFilters)

  constructor(public store: Store<State>,) { }

  ngOnInit(): void {
  }

  resetFilters() {
    this.store.dispatch(GameMessagesPageActions.setFilter({
      filters: [],
      tags:[],
      messageTypes:[]

    }));
  }
}

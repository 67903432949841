import {Component} from '@angular/core';

@Component({
    selector: 'app-runs-table',
    template: `
<!--        <div *ngIf="runAccess$|async as ra">-->
<!--            <table mat-table [dataSource]="dataSource" matSort *ngIf="me |async as myFullId">-->

<!--              <ng-container matColumnDef="icon">-->
<!--                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>-->
<!--                <td mat-cell *matCellDef="let row" class="pos-icon">-->
<!--                  <i-->
<!--                    *ngIf="(ra[myFullId+'_'+row.runId])?.accessRights === 1"-->
<!--                    class="fas fa-user-tie"></i>-->
<!--                  <i-->
<!--                    *ngIf="(ra[myFullId+'_'+row.runId])?.accessRights === 2"-->
<!--                    class="fas fa-edit"></i>-->
<!--                  <i-->
<!--                    *ngIf="(ra[myFullId+'_'+row.runId])?.accessRights === 3"-->
<!--                    class="fas fa-eye"></i>-->
<!--                </td>-->
<!--              </ng-container>-->

<!--                <ng-container matColumnDef="title">-->
<!--                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ROW_HEADERS.TITLE' | translate }}</th>-->
<!--                    <td mat-cell *matCellDef="let row" (click)="click(row, ra[myFullId+'_'+row.runId]?.accessRights)">{{row.title}}</td>-->
<!--                </ng-container>-->

<!--                <ng-container matColumnDef="lastModificationDate">-->
<!--                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ROW_HEADERS.DATE' | translate }}</th>-->
<!--                    <td mat-cell *matCellDef="let row"-->
<!--                        (click)="click(row, ra[myFullId+'_'+row.runId]?.accessRights)">{{row.lastModificationDate |  date:'medium'}}</td>-->
<!--                </ng-container>-->


<!--                <ng-container matColumnDef="controls" stickyEnd>-->
<!--                    <th mat-header-cell *matHeaderCellDef></th>-->
<!--                    <td mat-cell *matCellDef="let row" class="cell-right">-->
<!--&lt;!&ndash;                        <button&ndash;&gt;-->
<!--&lt;!&ndash;                                *ngIf="((runAccess$|async)[myFullId+'_'+row.runId])?.accessRights == 1"&ndash;&gt;-->
<!--&lt;!&ndash;                                mat-icon-button [matMenuTriggerFor]="menu">&ndash;&gt;-->
<!--&lt;!&ndash;                            <mat-icon>more_vert</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                        </button>&ndash;&gt;-->
<!--                        <mat-menu #menu="matMenu">-->
<!--                            <button mat-menu-item (click)="deleteRun(row)">-->
<!--                                <mat-icon>delete_forever</mat-icon>-->
<!--                                <span>{{ 'ACTIONS.DELETE_RUN' | translate }}</span>-->
<!--                            </button>-->

<!--                        </mat-menu>-->
<!--                    </td>-->
<!--                </ng-container>-->


<!--                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
<!--                <tr class="bin-element-row" mat-row-->
<!--                    *matRowDef="let row; columns: displayedColumns;"-->

<!--                ></tr>-->
<!--            </table>-->
<!--        </div>-->

    `,
    styles: [`

      table .pos-icon {
        width: 20px;
        padding-left: 10px;
      }

        table.mat-table {
            background-color: transparent;
        }

        table {
            width: 100%;
        }

        .cell-right {
            text-align: right;

        }

        tr.bin-element-row:hover {
            background: #f5f5f5;
        }

        tr.bin-element-row {
            cursor: pointer;
        }

        tr.example-element-row:active {
            background: #efefef;
        }

        @media only screen and (max-width: 900px) {
            .hide900 {
                display: none;
            }
        }

        @media only screen and (max-width: 500px) {
            .hide500 {
                display: none;
            }
        }

    `]
})
export class RunsTableComponent { //implements OnInit, OnDestroy {
    //
    // @ViewChild(MatPaginator) paginator: MatPaginator;
    // @ViewChild(MatSort) sort: MatSort;
    // displayedColumns = ['icon', 'title', 'lastModificationDate', 'controls'];
    // public runs$: Observable<any> = this.store.select(currentGameRuns);
    // public runAccess$: Observable<Dictionary<RunAccess>> = this.store.select(fromRunAccess.selectEntities);
    // public me: Observable<any> = this.store.select(getPortalUser).pipe(map(pu => pu.fullId));
    // dataSource: MatTableDataSource<GameRun>;
    //
    // public subscription: Subscription;
    //
    // constructor(
    //     private store: Store<State>,
    //     private router: Router
    // ) {
    //
    // }
    //
    // ngOnInit() {
    //     this.subscription = this.runs$.subscribe((runList) => {
    //         this.dataSource = new MatTableDataSource(runList);
    //         const sortState: Sort = {active: 'lastModificationDate', direction: 'desc'};
    //         if (this.sort) {
    //             this.sort.active = sortState.active;
    //             this.sort.direction = sortState.direction;
    //             this.sort.sortChange.emit(sortState);
    //
    //         }
    //
    //         this.dataSource.sort = this.sort;
    //         this.dataSource.paginator = this.paginator;
    //     });
    //
    // }
    //
    // click(gameRun: GameRun, accessRights: number) {
    //     if (accessRights === RunAccessRight.EDITOR) {
    //         this.router.navigate([`game/${gameRun.gameId}/detail/runs/${gameRun.runId}/players`]);
    //     } else if (accessRights === RunAccessRight.OWNER) {
    //         console.log("in click ow", accessRights);
    //         this.router.navigate([`game/${gameRun.gameId}/detail/runs/${gameRun.runId}/settings`]);
    //     } else {
    //         console.log("in click no", accessRights);
    //         this.router.navigate([`game/${gameRun.gameId}/detail/runs/${gameRun.runId}/results`]);
    //     }
    //
    // }
    //
    //
    // deleteRun(toDeleteRun: GameRun) {
    //     this.store.dispatch(new DeleteRunRequestAction(toDeleteRun));
    //
    // }
    //
    // ngOnDestroy(): void {
    //     if (this.subscription) {
    //         this.subscription.unsubscribe();
    //     }
    // }

}

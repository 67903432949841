import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {environment} from "../../../environments/environment";
import {filter, map} from "rxjs/operators";
import {AccountList, PendingPlayer, Player} from "../../player-management/store/player.state";

@Injectable({
  providedIn: 'root',
})
export class PlayerService {

  constructor(private http: HttpClient) {
  }

  listAllAccounts(from: number, resumptionToken?: string): Observable<AccountList> {
    let postFix = '1';
    if (!!from) {
      postFix = from +'';
    }
    if (!!resumptionToken) {
      postFix += '?resumptionToken='+resumptionToken;
    }
    return this.http
      .get<AccountList>(environment.api_url + '/account/myContacts/list/'+postFix).pipe(
        map(list => ({
              ...list,
            accountList: (list.accountList||[]).map(addUsersData)}
          )
        )
      );
  }

  getContacts(): Observable<{accountList: Player[]; resumptionToken?: string, serverTime?: number}> {
    return this.http
      .get<any>(environment.api_url + '/player/myContacts' ).pipe(
        map(players => ({...players,
        accountList : (players.accountList || []).map(p=> ({...p, isConnection: true})) || []
        }))
      );
  }

  getContactsWithResumption(resumptionToken: string): Observable<any> {
    const time = null;
    return this.http
      .get<any>(environment.api_url + '/player/myContacts/cursor/' + resumptionToken ).pipe(
        map(players => ({...players,
          accountList : (players.accountList || []).map(p=> ({...p, isConnection: true})) || []
        }))
      );
  }

  getAccounts(ids: string): Observable<any> {
    if (!ids || ids.length < 2) {
      return of([]);
    }
    return this.http
      .get<any>(environment.api_url + '/accounts/' + ids).pipe(
        map(x=> ({...x, accountList:(x.accountList||[])
            .filter(acc => !!acc)
            .map(account => addUsersData(account))
        })),
      );
  }



  getPendingContacts(): Observable<AccountList> {
    return this.http
      .get<any>(environment.api_url + '/player/pendingInvitations');
  }

  getPendingContactsToMe(): Observable<AccountList> {
    return this.http
      .get<any>(environment.api_url + '/player/invitationsToMe');
  }

  removePending(id: string) {
    return this.http
      .delete<any>(environment.api_url + '/player/pending/' + id);
  }

  redoInvitationId(invitationId: string) {
    return this.http
      .get<any>(environment.api_url + '/player/reinvite/' + invitationId);
  }

  inviteContactWithEmailAndNote(body: any) {
    return this.http
      .post<any>(environment.api_url + '/player/add', body);
  }

  removeContact(player: PendingPlayer): Observable<Player> {
    return this.http
      .delete<any>(environment.api_url + '/player/delete/' + player.accountType + "/" + player.localId);
  }

  getAccountForInvitation(id: string) {
    return this.http
      .get<any>(environment.api_url + '/player/invitation/' + id);
  }

  confirmInvitationId(id: string) {
    return this.http
      .get<any>(environment.api_url + '/player/confirmInvitation/' + id);
  }

  getOnePlayer(fullId: string, isConnection: boolean) :Observable<Player> {
    return this.http
      .get<any>(environment.api_url + '/player/contact/' + fullId).pipe(
        filter(account => !!account),
        map(account => ({...account, isConnection})),
        map(account => addUsersData(account)),

      );
  }
}


export const  addUsersData = (player: any) => {
  if (!player.name) {
    player.shortAvatar = '-';
  } else {
    const names = player.name.split(' ').filter(n => n !== '');

    if (player.connection) {
      player.isConnection = true;
    }
    if (names.length === 1) {
      player.shortAvatar = names[0][0].toUpperCase();
    } else {
      player.shortAvatar = names[0][0].toUpperCase() + names[1][0].toUpperCase();
    }
  }
  return player;
};

import {Directive, HostListener, Input} from "@angular/core";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {GameMessagePageActions} from "../store/game-message.actions";
import {MultipleChoiceAnswerItem} from "../../game-messages/store/game-messages.state";

@Directive({selector: '[appTriggerMobileView]'})
export class ChangeScreenPreviewDirective {
    @Input() name: string;
    @Input() data: Partial<MultipleChoiceAnswerItem>;

    constructor(public store: Store<State>) {
    }

    @HostListener('click', ['$event.target'])
    onClick(btn) {
        this.store.dispatch(GameMessagePageActions.setMessagePreview({
            previewType: this.name,
            data: this.data
        }));
    }
}

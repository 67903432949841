import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {AuthActionTypes} from "../../ngrx-auth/store/ngrx-auth.actions";
import {catchError, filter, map, mergeMap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
import {SetErrorAction} from "../../shared/store/shared.actions";
import {PlayerService} from "./player.service";
import {getInSyncTill} from "./player.selector";
import {PlayerApiActions} from "./player.actions";


@Injectable()
export class AccountEffectsLoadContacts {
  constructor(
    private player: PlayerService,
    private actions$: Actions,
    private store$: Store<State>
  ) {
  }

  getAccountList$ = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.Authenticated),
      withLatestFrom(this.store$.select(getInSyncTill)),
      mergeMap(([action, inSyncTill]: [any, number]) =>
        this.player.listAllAccounts(inSyncTill).pipe(
          map(accounts => PlayerApiActions.addAccounts({accounts})),
          catchError((error) => of(new SetErrorAction(error.error)))
        ),
      )
    )
  );


  resumeGetAccountsList = createEffect(
    () => this.actions$.pipe(
      ofType(PlayerApiActions.addAccounts),
      filter((action) => !!action && !!action.accounts && !!action.accounts.resumptionToken && (action.accounts.accountList || []).length != 0),
      mergeMap(
        (action) => this.player.listAllAccounts(action.accounts.from, action.accounts.resumptionToken).pipe(
          map(accounts => PlayerApiActions.addAccounts({accounts})),
          catchError((error) => of(new SetErrorAction(error.error)))
        ),
      )
    )
  )

  updateInSyncTill = createEffect(
    () => this.actions$.pipe(
      ofType(PlayerApiActions.addAccounts),
      filter((action) => !!action &&
        !!action.accounts && (!action.accounts.resumptionToken || (action.accounts.accountList || []).length == 0)),
      map(action => PlayerApiActions.updateFullSyncTime({time: action.accounts.serverTime - 120000}))
    )
  )

}

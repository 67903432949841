import {createFeatureSelector, createSelector,} from '@ngrx/store';

import * as fromRoot from 'src/app/core/reducers';
import {GameMessage, MessageFilter} from './game-messages.state';

import {GameMessagesState, messagesAdapter} from "./game-messages.reducer";
import * as fromRootSelector from "../../core/selectors/router.selector";
import {messageIdSelector} from "../../core/selectors/router.selector";


export interface State extends fromRoot.State {
  gameMessages: GameMessagesState;
}

export const getGameMessagesFeature = createFeatureSelector<GameMessagesState>('gameMessages');
export const getMessagesSelector = createSelector(getGameMessagesFeature, (state: GameMessagesState) => state);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = messagesAdapter.getSelectors(getMessagesSelector);

const _targetMessageProjector = (state: GameMessagesState) => state.targetMessage;
const _editModeProjector = (state: GameMessagesState) => state.editMode;
const _filtersProjector = (state: GameMessagesState) => state.filter;

export const currentMessageFromRouter = createSelector(fromRootSelector.selectRouteParam('messageId'),
  (mId) => {
    return Number.parseInt(mId, 10);
  })


export const getEditModeSelector = createSelector(getGameMessagesFeature, _editModeProjector);
export const getFiltersSelector = createSelector(getGameMessagesFeature, _filtersProjector);
export const getTargetMessageIdSelector = createSelector(getGameMessagesFeature, _targetMessageProjector);
export const getCurrentGameMessages = createSelector(
  selectAll,
  fromRootSelector.selectRouteParam('gameId'),
  (allMessages, currentGameId) => allMessages.filter(m => (m.gameId + '') === currentGameId));

export const getMessagesLoading = createSelector(getGameMessagesFeature, state => state.loading);


function getAllDependenciesByCondition(dependency, cb, result = []) {
  if (cb(dependency)) {
    result.push(dependency);
  }

  if (Array.isArray(dependency.dependencies) && dependency.dependencies.length > 0) {
    dependency.dependencies.forEach(x => {
      getAllDependenciesByCondition(x, cb, result);
    });
  }

  if (dependency.offset) {
    getAllDependenciesByCondition(dependency.offset, cb, result);
  }
  return result;
}

export const getQrCodesSelector = createSelector(messageIdSelector, getCurrentGameMessages, (state, messages) => {
  const deps = [];
  if (!state || state === '') {
    return deps;
  }

  messages.forEach(x =>
    x.dependsOn &&
    getAllDependenciesByCondition(
      x.dependsOn,
      dependency => dependency.action && dependency.generalItemId.toString() === state,
      deps
    )
  );

  return deps;
});

export const amountOfFilters = createSelector(getFiltersSelector,
  (messageFilter: MessageFilter)  => messageFilter.filters.length + messageFilter.tags.length + messageFilter.messageTypes.length
)

export const getFilteredMessagesSelector = createSelector(getCurrentGameMessages, getFiltersSelector,
  (messages: GameMessage[], messageFilter: MessageFilter) => {
    const fMessages = messages.filter(message => {
      if (!messageFilter ) {
        return true;
      }
      if (messageFilter.filters && messageFilter.filters.length !== 0 ) {
        for (let i = 0; i < messageFilter.filters.length; i++) {
          if ((message.name.toUpperCase().indexOf(messageFilter.filters[i].toUpperCase()) === -1)
            &&
            ((message.label || '').indexOf(messageFilter.filters[0]) === -1)) {
            return false;
          }
        }
      }
      if (messageFilter.messageTypes && messageFilter.messageTypes.length !== 0 ) {
        let messageMatches = false;
        for (let i = 0; i < messageFilter.messageTypes.length; i++) {
          if ((message.type.indexOf(messageFilter.messageTypes[i]) !== -1)) {
            messageMatches = true
          }
        }
        if (!messageMatches) {
          return false;
        }
      }

      if (messageFilter.tags && messageFilter.tags.length !== 0 ) {
        let messageMatches = false;
        for (let i = 0; i < messageFilter.tags.length; i++) {
          if (message.label && (message.label.indexOf(messageFilter.tags[i]) !== -1)) {
            messageMatches = true
          }
        }
        if (!messageMatches) {
          return false;
        }
      }

      return true;

    });
    fMessages.sort((a, b) => b.lastModificationDate - a.lastModificationDate)
    return fMessages;
  });

export const getCurrentMessageSelector = createSelector(currentMessageFromRouter, getCurrentGameMessages, (messageId, messages) => {
  return messages.filter(message => {
    return message.id == messageId;
  })[0];
});


//getCurrentGameMessages,
export const getMultipleMessagesSelector = createSelector(getFilteredMessagesSelector,
  (messages: GameMessage[]) => {
    return messages.filter(message => {
      const entry = message.type.toLowerCase();
      return entry.includes('single') || entry.includes('multiple') || entry.includes('question');
    });
  });

export const currentGameLabels = createSelector(getCurrentGameMessages,
  (messages) => [...new Set(messages.filter(m => m.label).map(m => m.label).join(',').split(','))]
   );

import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap, throttleTime,} from 'rxjs/operators';
import {State} from "../../core/reducers";
import {GameCategoryApiActions, GameCategoryPageActions} from "./game-category.actions";
import {GameCategoryService} from "./game-category.service";
import {PortalGamesService} from "../../core/services/portal-games.service";


@Injectable()
export class GameCategoryEffects {


  queryGlobal$ = createEffect(
    () => this.actions$.pipe(
      ofType(GameCategoryPageActions.query),
      throttleTime(100000),
      mergeMap((action) => this.categoryService.allCategories()),
      map(categories => GameCategoryApiActions.addAll({categories}))
    ));

  queryGameCategories$ = createEffect(
    () => this.actions$.pipe(
      ofType(GameCategoryPageActions.queryMappings),
      mergeMap((action) => this.categoryService.getCategoriesForGame(action.gameId)),
      map(categoryMappings => GameCategoryApiActions.addCategoryMappings({categoryMappings}))
    ));

  deleteMapping$ = createEffect(
    () => this.actions$.pipe(
      ofType(GameCategoryPageActions.removeMapping),
      mergeMap((action) => this.categoryService.deleteMappingId(action.mappingId)),
      map(mapping => GameCategoryApiActions.removeCategoryMapping({mappingId: mapping.id}))
    ));


  setPortalGameCategory = createEffect(
    () => this.actions$.pipe(
      ofType(GameCategoryPageActions.saveGameCategory),
      mergeMap((action) => this.portalGamesService.setPortalGameCategory(action.gameId, action.categoryId)),
      map((categoryMapping) => GameCategoryApiActions.addCategoryMapping({categoryMapping}))
    ));

  setPortalGameOrganisation = createEffect(
    () => this.actions$.pipe(
      ofType(GameCategoryPageActions.saveGameOrganisation),
      mergeMap((action) => this.portalGamesService.setPortalGameOrganisation(action.gameId, action.organisationId)),
      map((organisationMapping) => GameCategoryApiActions.addOrganisationMapping({organisationMapping}))
    ));

  queryGameOrganisations$ = createEffect(
    () => this.actions$.pipe(
      ofType(GameCategoryPageActions.queryOrganisationMappings),
      mergeMap((action) => this.portalGamesService.getOrganisationsForGame(action.gameId)),
      map(organisationMappings => GameCategoryApiActions.addOrganisationMappings({organisationMappings}))
    ));


  deleteOrganisationMapping$ = createEffect(
    () => this.actions$.pipe(
      ofType(GameCategoryPageActions.removeOrganisationMapping),
      mergeMap((action) => this.portalGamesService.deleteOrganisationMappingId(action.mappingId)),
      map(mapping => GameCategoryApiActions.removeOrganisationMapping({mappingId: mapping.id}))
    ));

  constructor(private actions$: Actions,
              private store: Store<State>,
              private portalGamesService: PortalGamesService,
              private categoryService: GameCategoryService,
  ) {
  }
}

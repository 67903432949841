import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromRouter from '@ngrx/router-store';
import {selectRouter} from '../reducers/index';

export interface State {
  router: fromRouter.RouterReducerState;
}

export const getRouter = createFeatureSelector<fromRouter.RouterReducerState>('router');
export const getRouterState = (state: State) => state.router;

export const getCurrentUrl = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState) => state.state.url
);

export const invitationId = createSelector(
  getRouter,
  router => {
    // console.log(router.state)
    if (router == null
      || router.state.root.firstChild == null
    ) {

      return null;
    }

    return router.state.root.firstChild.params.id;
  }
);


export const {
    selectCurrentRoute,   // select the current route
    selectQueryParams,    // select the current route query params
    selectQueryParam,     // factory function to select a query param
    selectRouteParams,    // select the current route params
    selectRouteParam,     // factory function to select a route param
    selectRouteData,      // select the current route data
    selectUrl,            // select the current url
} = fromRouter.getSelectors(selectRouter);

export const gameIdSelector = selectRouteParam('gameId');
export const runIdSelector = selectRouteParam('runId');
export const messageIdSelector = selectRouteParam('messageId');

export const organisationIdSelector = selectRouteParam('organisationId');

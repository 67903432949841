import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {RunAuthor} from "./run-author.state";
import {RunAuthorApiActions, RunAuthorPageActions} from "./run-author.actions";
import {Action, createReducer, on} from "@ngrx/store";

export interface RunAuthorState extends EntityState<RunAuthor> {
  lastSyncTime: number;
}

export const runAuthorAdapter = createEntityAdapter<RunAuthor>(
  {selectId: (a: RunAuthor) => a.identifier}
);

const initialState: RunAuthorState = runAuthorAdapter.getInitialState({lastSyncTime: 1652090806000});

export const reducer = createReducer(
  initialState,
  on(RunAuthorApiActions.addRunAuthor,
    (state, action) => runAuthorAdapter.upsertOne(action.runAuthor, state)),
  on(RunAuthorPageActions.revokeCollaboratorAccess,
    (state, action) => runAuthorAdapter.removeOne(action.author + ':' + action.runId, state)),
  on(RunAuthorApiActions.loadAllRunAuthorsCompleted,
    (state, action) => runAuthorAdapter.upsertMany(action.runAccessList.runAccess || [], state)),
  on(RunAuthorApiActions.addRunAuthorsWithoutResumption,
    (state, action) => runAuthorAdapter.upsertMany(action.runAccessList.runAccess || [], state)),
  on(RunAuthorApiActions.updateFullLastSync, (state, action) => ({...state, lastSyncTime: action.time})),
)

export function runAuthorReducer(
  state: RunAuthorState | undefined,
  action: Action
): RunAuthorState {
  return reducer(state, action);
}


import {GameMessagesApiActions, GameMessagesPageActions} from './game-messages.actions';
import {GameMessage, MessageFilter,} from './game-messages.state';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";

export interface GameMessagesState extends EntityState<GameMessage> {
  filter: MessageFilter;
  targetMessage?: number;
  editMode: number;
  loading: boolean;
}

export const messagesAdapter = createEntityAdapter<GameMessage>(
  {selectId: (a: GameMessage) => a.id}
);

export const gameMessagesInitialState: GameMessagesState = messagesAdapter.getInitialState({
  filter: {filters: [], messageTypes: [], tags: []},
  editMode: 1,
  loading: false,
});


export const reducer = createReducer(
  gameMessagesInitialState,
  on(GameMessagesApiActions.gameMessageSaveComplete, (state, action) =>
        messagesAdapter.upsertOne(action.gameMessage, state)
  ),
  on(GameMessagesApiActions.addMessage, (state, action) =>
    messagesAdapter.upsertOne(action.gameMessage, {...state, loading: false})
  ),
  on(GameMessagesApiActions.getMessagesComplete, (state, action) =>
    messagesAdapter.upsertMany(action.items, {
      ...state,
      loading: action.items.length !== 0 && action.cursor !== 'stop',
    })
  ),
  on(GameMessagesApiActions.removeMessage, (state, action) =>
    messagesAdapter.removeOne(action.gameMessage.id, state)
  ),
  on(GameMessagesPageActions.setFilter, (state, action) =>
    ({...state, filter: {...state.filter, ...action}})
  ),
  on(GameMessagesPageActions.setLoading, (state, action) =>
    ({...state, loading: action.loading})
  )
);

export function reducers(
  state: GameMessagesState | undefined,
  action: Action
): GameMessagesState {
  return reducer(state, action);
}


import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {getEditMessageSelector} from "../../store/game-message.selector";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {map, tap} from "rxjs/operators";

@Component({
  selector: 'app-message-type-icon',
  template: ``,
})
export class MessageTypeIconComponent implements OnDestroy {
  message$: Observable<string> = this.store.select(getEditMessageSelector).pipe(
    map(m => (m && m.type) || '. '),
    map(m => m.substr(m.lastIndexOf('.') + 1))
  );
  subscription = new Subscription();

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private store: Store<State>) {
    this.subscription.add(this.message$.subscribe(mType => {
        if (mType.length > 2) {
          this.renderer.setStyle(
            this.elRef.nativeElement,
            'background-image',
            'url(/assets/images/schermen/' + mType + '.png)');

        }
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

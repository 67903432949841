import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {GameDetailScreensComponent} from './game-detail-screens/game-detail-screens.component';
import {Observable} from "rxjs";
import {getFilteredMessagesSelector, getMessagesLoading} from "../store/game-messages.selector";
import {GameMessage} from "../store/game-messages.state";
import {GameMessageApiActions, GameMessagePageActions,} from "../../game-message/store/game-message.actions";
import {MatDialog} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Router} from "@angular/router";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {messageIdSelector} from "../../core/selectors/router.selector";
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {GamePageActions} from "../../games-management/store/game.actions";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-game-disappear-flowchart',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div *ngIf="game$ | async as game">
      <div class="buttons position-absolute">
        <div class="d-flex flex-row">
          <button
            *ngIf="!grid"
            (click)="grid = !grid"
            mat-mini-fab color="accent" aria-label="Example icon button with a plus one icon">
            <mat-icon>grid_4x4</mat-icon>
          </button>

          <button
            *ngIf="grid"
            (click)="grid = !grid"
            mat-mini-fab color="accent" aria-label="Example icon button with a plus one icon">
            <mat-icon>grid_off</mat-icon>
          </button>

          <mat-button-toggle-group
            class="ml-3"
            *ngIf="grid"
            (change)="gridSizeChange($event)" >
            <mat-button-toggle value="s10">10</mat-button-toggle>
            <mat-button-toggle value="s20">20</mat-button-toggle>
            <mat-button-toggle value="s30">100</mat-button-toggle>
          </mat-button-toggle-group>

        </div>

      </div>
      <app-game-detail-navbar [game]="game$|async"></app-game-detail-navbar>

      <div *ngIf="messages$ | async as messages">

        <lib-wireflow
          *ngIf="messages.length > 0 && !(loading$ | async)"
          [showGrid]="grid"
          [gridSize]="!grid ? 0 :gridSize"
          (selectMessage)="selectMessage($event)"
          [messages]="messages"
          [endsOnDisabled]="true"
          (messagesChange)="messagesChange($event)"
          (deselectMessage)="deselectMessage($event)"
          (noneSelected)="noneSelected(game)"
          (onEvent)="onEvent($event)"
          [lang]="lang"
          [selector]="'disappearOn'"
          [noimage]="true"
        ></lib-wireflow>
      </div>

      <div *ngIf="currentMessageId$|async as messageId">
        <app-preview-pane class="pos-preview-pane"
                          [hideControls]="true"
                          [game]="game"
                          [navTo]="'hi/message/detail'"
                          *ngIf="messageId != -1">
        </app-preview-pane>
      </div>
    </div>
    <!--        [navTo]="'/portal/'+ message.gameId+ '/message/'+message.id+'/detail/disappear'"-->

  `,
  styles: [`
    .buttons {
      z-index: 10;
      top: 160px;
      left:30px;
    }

    .pos-preview-pane {
      position: fixed;
      right: 0px;
      top: 0px;
      background: #FAFAFA;
      width: 429px;
      height: 100vh;
      z-index: 1;
      box-shadow: 0px 9px 18px #0000002E;
    }
  `]
})
export class GameDisappearFlowchartComponent extends GameDetailScreensComponent implements OnInit, OnDestroy {
  currentMessageId$: Observable<string> = this.store.select(messageIdSelector);
  game$: Observable<Game> = this.store.select(getCurrentGame);
  grid= false;
  gridSize= 20;


  public messages$: Observable<GameMessage[]> = this.store.select(getFilteredMessagesSelector);
  lang = 'en';
  loading$ = this.store.select(getMessagesLoading);


  constructor(
    public dialog: MatDialog,
    public store: Store<State>,
    private router: Router,
    public gaService: GoogleAnalyticsService
  ) {
    super(dialog, store, gaService);
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
    // this.store.dispatch(new ResetAction());
  }

  messagesChange(messages: GameMessage[]) {
    messages.map(gameMessage => this.store.dispatch(GameMessagePageActions.gameMessageDirectSave({gameMessage})));
  }


  selectMessage(gameMessage) {
    this.router.navigate([`game/${gameMessage.gameId}/hidechart/${gameMessage.id}`]);
    this.store.dispatch(GameMessageApiActions.gameMessageEditComplete({gameMessage}));
    // this.store.dispatch(new SetPreviewMessageAction($event.id));
  }

  deselectMessage($event) {
    this.router.navigate([`game/${$event.gameId}/hidechart/-1`]);
    this.store.dispatch(GameMessageApiActions.gameMessageEditComplete({gameMessage: undefined}));
    // this.store.dispatch(new SetPreviewMessageAction(null));
  }

  noneSelected(game: Game) {
    if (game && game.gameId) {
      this.router.navigate([`game/${game.gameId}/hidechart/-1`]);
    }
    this.store.dispatch(GameMessageApiActions.gameMessageEditComplete({gameMessage: undefined}));
  }

  onEvent(event) {
    switch (event.type) {
      case 'newOutputAdded': {
        if (event.nodeType === 'ScanTag') {
          this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:event.payload.outputs}));
        }

        break;
      }
      case 'FIRST_CHUNK_LOADING': {
        this.store.dispatch(GamePageActions.setLoading({loading:event.payload}));
        break;
      }
    }
  }


  gridSizeChange(event: MatButtonToggleChange) {
    if (event.value === 's10') {
      this.gridSize = 10;
    } else if (event.value === 's20') {
      this.gridSize = 20;
    } else {
      this.gridSize = 100;
    }
    console.log("size change ", event)
  }
}


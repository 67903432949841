import {Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {getUser} from "src/app/ngrx-auth/store/ngrx-auth.selectors";
import {User} from '../store/ngrx-auth.state';
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {State} from "src/app/core/reducers";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[hasOnePermission]'
})
export class HasPermissionOrDirective implements OnInit, OnDestroy {
  private permissions = [];
  private isHidden = true;
  private currentUser: User;
  subscription: Subscription;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<State>
  ) {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.subscription = this.store.select(getUser).subscribe(user => {
      this.currentUser = user;
      this.updateView();
    });
  }

  @Input()
  set hasOnePermission(val) {
    this.permissions = val;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    if ((this.permissions || []).length === 0) {
      return true;
    }
    if (this.currentUser && this.currentUser.claims) {
      for (const checkPermission of this.permissions) {
        if (this.currentUser.claims[checkPermission]) {
          return true;
        }
      }
    }
    return false;
  }
}

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {CATEGORY_CUSTOM_THEMES, selectAll, selectThemeCategories} from "../store/game-theme.selectors";
import {BehaviorSubject, combineLatest, Subscription} from "rxjs";
import {distinct, map} from "rxjs/operators";
import {GameTheme} from "../store/game-theme.state";
import {DeleteThemeComponent} from "./create-theme/delete-theme.component";
import {GameThemeApiActions, GameThemePageActions} from "../store/game-theme.actions";
import {CreateThemeNameModalComponent} from "./create-theme/create-theme-name-modal.component";
import {Actions, ofType} from "@ngrx/effects";
import {CreateThemeSettingsComponent} from "./create-theme/create-theme-settings.component";

@Component({
  selector: 'app-select-theme-modal',
  template: `
    <div class="maxwidth pos-top">
      <app-modal-esc-button
        class="gl-pos-modal-esc-button"
        [type]="'esc'" (buttonClick)="onNoClick()"></app-modal-esc-button>
      <app-modal-esc-button
        class="gl-pos-modal-back-button"
        [type]="'back'" (buttonClick)="onNoClick()"></app-modal-esc-button>
    </div>
    <div class="maxwidth">
      <div class="pos-title primary-color font-medium-32-43-roboto">{{'THEME.CHOOSE' |translate}}</div>
      <div class="theme-panel">
        <div class="sidebar">
          <div *ngFor="let category of (categories$ | async)">
            <button class="menu-item" [class.selected]="selectedCategory == category"
                    (click)="selectedCategory !== category && selectCategory(category)">{{ category }}</button>
          </div>
          <div class="add-btn" *ngIf="!data.hideOwn">
            <button mat-button color="primary" (click)="createTheme()">
              <mat-icon>add</mat-icon>
              {{'THEME.OWN' |translate}}
            </button>
          </div>
        </div>

        <div class="theme-items" *ngIf="themes$ | async as themes">
          <div class="theme-item" *ngFor="let theme of themes"
               [class.theme-item--selected]="selectedTheme?.themeId == theme.themeId"
               (click)="select(theme)">
            <span class="selected-icon" *ngIf="selectedTheme?.themeId == theme.themeId"><mat-icon>done</mat-icon></span>
            <div class="theme-img"
                 storageBackground
                 [targetPath]="theme.backgroundPath"
            >
            </div>
            <div class="theme-name">
              {{ theme.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-toolbar  class="toolbar" *ngIf="selectedTheme">

      <div class="maxwidth d-flex align-items-center justify-content-between w-100">
        <div class="theme-info d-flex align-items-center">
          <div class="image"
               *ngIf="selectedTheme.backgroundPath"
               storageBackground
               [targetPath]="selectedTheme.backgroundPath"
          >
          </div>
          <h4>{{ selectedTheme.name }}</h4>
        </div>

        <div>
          <button mat-button color="primary" *ngIf="isThemeCustom(selectedTheme)"
                  (click)="editTheme()">{{'COMMON.EDIT' | translate}}</button>
          <button mat-button color="primary" *ngIf="isThemeCustom(selectedTheme)"
                  (click)="deleteTheme()">{{'MEDIA.DELETE' | translate}}</button>
          <button mat-flat-button color="primary" [disabled]="!isThemeComplete(selectedTheme)"
                  (click)="onSubmit()">{{'COMMON.SELECT' | translate}}
          </button>
        </div>
      </div>
    </mat-toolbar>
  `,
  styles: [`
    .pos-top {
      height: 1px;
    }

    .pos-title {
      position: relative;
      margin-top: 83px;
      height: 38px;
      text-align: center;
    }

    .theme-panel {
      display: flex;
      align-items: flex-start;
      margin: 3rem 0 8rem;
    }

    .theme-items {
      display: flex;
      flex-wrap: wrap;
    }

    .theme-item {
      margin-right: 20px;
      margin-bottom: 20px;
      width: 200px;
    }

    .theme-img {
      height: 260px;
    }

    .theme-img img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .theme-name {
      background-color: #ffffff;
      padding: 0.75rem;
    }

    .theme-item--selected {
      position: relative;
    }

    .theme-item--selected .selected-icon {
      position: absolute;
      top: 0;
      right: 0;
      color: #ffffff;
      height: 24px;
      width: 24px;
      border-bottom-left-radius: 4px;
      background-color: #3EA3DC;
    }

    .selected-icon mat-icon {
      font-size: 16px;
      text-align: center;
      line-height: 24px;
    }

    .sidebar {
      border-right: 1px solid #DDDDDD;
      min-width: 250px;
      padding-right: 20px;
      margin-right: 60px;
    }

    .menu-item {
      background: none;
      outline: none;
      border: none;
      padding: 0.5rem 1rem;
      margin-bottom: 0.15rem;
      cursor: pointer;
    }

    .menu-item.selected {
      font-weight: 700;
      background-color: #ffffff;
    }

    .menu-item:hover {
      background-color: #ffffff;
    }

    .add-btn {
      margin-top: 1rem;
    }

    .theme-toolbar-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

  `
  ]
})
export class SelectThemeModalComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  public selectCategory$ = new BehaviorSubject(null);
  public categories$ = this.store.select(selectThemeCategories);
  public selectedCategory: any;
  public selectedTheme: any;
  public themes$ = combineLatest([
    this.store.select(selectAll),
    this.selectCategory$
  ]).pipe(
    map(([themes, category]) => {
      return (themes||[]).filter(theme => category == null || theme.category == category)
        .map(theme => {
          return theme;
        });
    })
  )

  constructor(public dialogRef: MatDialogRef<SelectThemeModalComponent>,
              public dialog: MatDialog,
              private actionSubj: Actions,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public store: Store<State>) {
  }

  ngOnInit(): void {
  }


  onNoClick() {
    this.dialogRef.close();
  }

  select(theme) {
    this.selectedTheme = theme;
  }

  selectCategory(category) {
    if (this.selectedCategory != category) {
      this.selectedCategory = category;
      this.selectCategory$.next(category);
    } else {
      this.selectedCategory = null;
      this.selectCategory$.next(null);
    }
  }

  onSubmit() {
    this.dialogRef.close(this.selectedTheme);
  }

  deleteTheme() {
    const dialogRef = this.dialog.open(DeleteThemeComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {theme: this.selectedTheme}
    });
    this.subscription.add(dialogRef.afterClosed().subscribe(deleteResult => {
      if (deleteResult) {
        this.store.dispatch(GameThemePageActions.deleteTheme({themeId: deleteResult.themeId}));
      }
    }));
  }

  createTheme() {
    const dialogRef = this.dialog.open(CreateThemeNameModalComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });

    this.subscription.add(dialogRef.afterClosed().subscribe(createResult => {
      if (createResult) {
        console.log('createResult result ', createResult);
        this.store.dispatch(GameThemePageActions.createTheme({theme: createResult}));
        this.subscription.add(this.actionSubj.pipe(
          ofType(GameThemeApiActions.createOneSuccess),
          distinct(action => action.type)
        ).subscribe((action) => {
          console.log('in edit')
          this.selectedTheme = action.theme;
          this.editTheme();
        }));
      }
    }));

  }

  editTheme() {
    const dialogRef = this.dialog.open(CreateThemeSettingsComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {theme:this.selectedTheme}
    });
    this.subscription.add(dialogRef.afterClosed().subscribe(createResult => {
      if (createResult) {
        this.store.dispatch(GameThemePageActions.updateTheme({theme:createResult.theme}));
      }
    }));
  }

  isThemeCustom(theme: GameTheme) {
    return theme.category === CATEGORY_CUSTOM_THEMES;
  }

  isThemeComplete(theme: GameTheme) {
    return theme.iconPath && theme.backgroundPath && theme.correctPath && theme.wrongPath;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {RunAuthor} from "./run-author.state";


@Injectable({
  providedIn: 'root',
})
export class RunAuthorService {

  constructor(
    private http: HttpClient) {
  }

  // loadAuthors(gameId: any): Observable<RunAccessList> {
  //   return this.http
  //     .get<any>(environment.api_url + '/run/access/' + gameId);
  // }


  loadAllAuthors(from: number, resumptionToken?: string): Observable<RunAccessList> {
    let postFix = '1';
    if (!!from) {
      postFix = from + '';
    }
    if (!!resumptionToken) {
      postFix += '?resumptionToken=' + resumptionToken;
    }
    return this.http
      .get<RunAccessList>(environment.api_url + '/run/access/user/' + postFix)
      .pipe(
        map(list => ({
              ...list,
            runAccess: (list.runAccess || []).map(transOneGameAuthor)
            }
          )
        )
      );
  }

  getAuthorsForRun(runId: string): Observable<RunAccessList> {
    return this.http
      .get<any>(environment.api_url + '/run/access/' + runId + '/list').pipe(
        map(list => ({
              ...list,
              runAccess: (list.runAccess || []).map(transOneGameAuthor)
            }
          )
        )
      );
  }

  grantCollaboratorAccess(runId: string, fullId: string, rights: string): Observable<RunAuthor> {
    return this.http
      .post<any>(environment.api_url + '/run/access/' + runId + '/' + fullId + '/' + rights, {}).pipe(
        map(addOptionalRunAccessData)
      );
  }

  revokeCollaboratorAccess(runId: string, fullId: string) {
    return this.http
      .delete<any>(environment.api_url + '/run/access/revoke/' + runId + '/' + fullId);
  }

}

const transOneGameAuthor = (game: RunAuthor) => {
  if (game.gameId) {
    game.gameId = Number.parseInt(game.gameId + '', 10);
  }
  if (game.timestamp) {
    game.timestamp = Number.parseInt(game.timestamp + '', 10);
  }
  return game;

};

export interface RunAccessList {
  runAccess: RunAuthor[];
  serverTime: number;
  from: number;
  resumptionToken?: string;
}


const addOptionalRunAccessData = (ra: RunAuthor) => {
  ra.timestamp = Number.parseInt(ra.timestamp + "", 10);
  ra.runId = Number.parseInt(ra.runId + "", 10);
  ra.gameId = Number.parseInt(ra.gameId + "", 10);
  return ra;
};

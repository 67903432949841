import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {GameCategory, GameCategoryMapping, GameOrganisationMapping} from "./game-category.state";

export const GameCategoryPageActions = createActionGroup({
  source: 'Game Category Page',
  events: {
    'Query': emptyProps(),
    'Query Mappings': props<{gameId: number}>(),
    'Remove Mapping': props<{mappingId: string}>(),
    'Save Game Category': props<{gameId: number, categoryId: number}>(),
    'Query Organisation Mappings': props<{gameId: number}>(),
    'Remove Organisation Mapping': props<{mappingId: string}>(),
    'Save Game Organisation': props<{gameId: number, organisationId: number}>(),
    // 'Revoke collaborator access': props<{author: string, runId: number}>(),
  },
});


export const GameCategoryApiActions = createActionGroup({
  source: 'Game Category Api',
  events: {
    'Add all': props<{categories: GameCategory[]}>(),

    'Add Category Mapping': props<{categoryMapping: GameCategoryMapping}>(),
    'Add Category Mappings': props<{categoryMappings: GameCategoryMapping[]}>(),
    'Remove Category Mapping': props<{mappingId: string}>(),
    'Add Organisation Mapping': props<{organisationMapping: GameOrganisationMapping}>(),
    'Add Organisation Mappings': props<{organisationMappings: GameOrganisationMapping[]}>(),
    'Remove Organisation Mapping': props<{mappingId: string}>(),
  },
});


import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {environment} from "../../../environments/environment";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {Game} from "../../games-management/store/game.state";

@Component({
    selector: 'app-game-settings-preview',
    template: `
        <div class="selector-full-width">
            <div  *ngIf="game$ |async as game"
              class="selector-aligned d-flex flex-column justify-content-start align-items-center h-100">
              <div class="mt-5">
              <app-game-theme-selector
                [themeId]="game.theme || 0"
                ></app-game-theme-selector>
              </div>
                <div class="pos-qr-code-container m-5" >
                    <div class="pos-scan-text font-medium-16-21-roboto">
                        {{'GAME.SCAN_QR' | translate}}
                    </div>
                    <div class="pos-code">
                        <qr-code [value]="getQrCode(game?.gameId)" [size]="100"></qr-code>
                    </div>

                </div>

            </div>
        </div>
    `,
    styles: [`
        :host {
            background-color: red;
        }


        .selector-full-width {
            position: relative;
            height: 100%;
        }

        /*.selector-aligned {*/
        /*    height: 100%;*/
        /*    display: flex;*/
        /*    justify-content: center;*/
        /*    align-items: center;*/
        /*}*/

        .pos-qr-code-container {

            width: 186px;
            height: 226px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 10px #0000001A;
            border-radius: 2px;
        }

        .pos-scan-text {
            position: relative;
            top: 22px;
            left: 18px

        }

        .pos-code {
            position: relative;
            top: 67px;
            left: 40px;
            width: 105px;
            height: 105px;
        }



    `]
})
export class GameSettingsPreviewComponent implements OnInit {

    public game$: Observable<Game> = this.store.select(getCurrentGame);

    constructor(public store: Store<State>) {
    }

    ngOnInit(): void {
    }

    getQrCode(gameId: number) {
        return environment.deep_link + 'game/' + gameId;
    }
}

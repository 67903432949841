import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {RunResponsesApiActions, RunResponsesPageActions} from "../store/run-responses.actions";
import {GameRunsPageActions} from "../../runs-management/store/game-runs.actions";
import {getEditMessageSelector} from "../../game-message/store/game-message.selector";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {GamePageActions} from "../../games-management/store/game.actions";
import {GameMessage} from "../../game-messages/store/game-messages.state";
import * as fromRootSelector from "../../core/selectors/router.selector";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-responses-overview',
  template: `
    <ng-container *ngIf="game$ | async as game">
      <div class="full-width-container maxwidth">

        <app-run-search-bar>
          <app-users-dropdown *ngIf="isMediaQuestion((editMessage$ | async)?.type || '')"
          ></app-users-dropdown>
        </app-run-search-bar>
        <div class="bibendo-center top70 flex-nowrap">
          <div class="photo-container">
            <div class="photo">
              <div class="d-flex flex-row">
                <app-preview-smartphone [scale]="0.8">
                  <app-preview-content-body [scale]="0.8"></app-preview-content-body>
                </app-preview-smartphone>
              </div>

              <!--              <app-preview-pane-mobile-view-->
              <!--                [game]="game$ | async"-->
              <!--                hideControls="true"></app-preview-pane-mobile-view>-->
            </div>
          </div>
          <div class="answers-wrapper">
            <app-arlearn-responses-table></app-arlearn-responses-table>
          </div>
        </div>
      </div>

      <app-responses-bottom-toolbar></app-responses-bottom-toolbar>

    </ng-container>
  `
})
export class ResponsesOverviewComponent implements OnInit, OnDestroy {
  public game$: Observable<Game> = this.store.select(getCurrentGame);
  public editMessage$: Observable<GameMessage> = this.store.select(getEditMessageSelector);

  subscription = new Subscription();

  constructor(
    private store: Store<State>,
  ) {
  }

  ngOnInit(): void {

    this.subscription.add(this.store.select(fromRootSelector.selectRouteParam('messageId'))
     .subscribe((message) => {
      this.store.dispatch(RunResponsesPageActions.queryMessageResponses());
    }))
    this.store.dispatch(GamePageActions.getGame({}));
    // this.store.dispatch(new GetCurrentGameFromRouterRequestAction());
    this.store.dispatch(GameRunsPageActions.getRun({}));
    // this.store.dispatch(new PlayerLoadRequestAction());


    setInterval(() => {
      this.store.dispatch(RunResponsesPageActions.queryMessageResponses());
    }, 30000)
  }

  isMediaQuestion(type) {
    return [
      'PictureQuestion',
      'AudioQuestion',
      'VideoQuestion',
      'TextQuestion',
    ].some(messageType => type.includes(messageType));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.store.dispatch(RunResponsesApiActions.clear());
  }

}

import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {RunAuthorService} from "./run-author.service";
import * as fromRootSelector from "../../core/selectors/router.selector";
import * as fromRoot from "../../core/selectors/router.selector";
import {catchError, filter, map, mergeMap, switchMap, withLatestFrom} from "rxjs/operators";
import {RunAuthorApiActions, RunAuthorPageActions} from "./run-author.actions";
import {of} from "rxjs";
import {SetErrorAction} from "../../shared/store/shared.actions";


@Injectable()
export class RunAuthorEffects {
  constructor(
    private runAuthorService: RunAuthorService,
    private actions$: Actions,
    private router: Router,
    private store$: Store<State>
  ) {
  }

  loadAuthorsForGame = createEffect(
    () => this.actions$.pipe(
      ofType(RunAuthorPageActions.loadRunAuthorsCurrentRun),
      withLatestFrom(this.store$.select(fromRootSelector.selectRouteParam('runId'))),
      filter(([_, runId]) => !!runId),
      mergeMap(([_, runId]) => this.runAuthorService.getAuthorsForRun(runId)),
      map(runAccessList => RunAuthorApiActions.addRunAuthorsWithoutResumption({runAccessList})),
    )
  );

  grantCollaboratorAccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(RunAuthorPageActions.grantCollaboratorAccess),
      withLatestFrom(this.store$.select(fromRoot.selectRouteParam('runId'))),
      switchMap(
        ([action, runId]) => this.runAuthorService.grantCollaboratorAccess(runId, action.author, action.rights).pipe(
          map(runAuthor => RunAuthorApiActions.addRunAuthor({runAuthor})),
          catchError((error) => of(new SetErrorAction(error.error)))
        )
      )
    ));

  revokeCollaboratorAccess = createEffect(
    () => this.actions$.pipe(
      ofType(RunAuthorPageActions.revokeCollaboratorAccess),
      withLatestFrom(this.store$.select(fromRoot.selectRouteParam('runId'))),
      switchMap(
        ([action, runId]: [any, string]) => this.runAuthorService.revokeCollaboratorAccess(runId, action.payload.author).pipe(
          catchError((error) => of(new SetErrorAction(error.error)))
        )
      )
    ), {dispatch: false});

}

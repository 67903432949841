import {createFeatureSelector, createSelector} from "@ngrx/store";
import {GameState} from "./game.state";
import {gameAdapter} from "./game.reducer";

export const getGameFeature = createFeatureSelector<GameState>('game');
const getTempGames = createSelector(getGameFeature, (state: GameState) => state.tempGames || gameAdapter.getInitialState());


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = gameAdapter.getSelectors(getTempGames);

import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {Store} from '@ngrx/store';
import {State} from './core/reducers';
import {NavigationEnd, Router} from "@angular/router";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {environment} from "../environments/environment";
import {GetUser} from "./ngrx-auth/store/ngrx-auth.actions";
// import {GetUser} from "./auth/actions/auth.actions";

@Component({
    selector: 'app-arlearn',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    constructor(private store: Store<State>,
                // private authService: AuthService,
                public gaService: GoogleAnalyticsService,
                private router: Router) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (environment.gatracking !== '') {
                    gaService.pageView(event.urlAfterRedirects);
                }
                // (<any>window).ga('set', 'page', event.urlAfterRedirects);
                // (<any>window).ga('send', 'pageview');
            }
        });
    }


    ngOnInit() {
        // this.authService.setPersistence();
        // this.store.dispatch(new ReLoginRequestedAction());
      this.store.dispatch(new GetUser());
    }

}

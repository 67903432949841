import {Component, Input, OnInit} from '@angular/core';
import {Player} from "../../player-management/store/player.state";

@Component({
  selector: 'app-connect-user-button',
  template: `
    <p>
      {{account.isConnection}}
      connect-user-button works!
      {{account |json}}
    </p>
  `,
  styles: [
  ]
})
export class ConnectUserButtonComponent implements OnInit {

  @Input() account : Player;
  constructor() { }

  ngOnInit(): void {
  }

}

import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Injectable} from "@angular/core";
import * as userActions from './ngrx-auth.actions';
import {catchError, exhaustMap, from, of, switchMap, take, tap, zip} from "rxjs";
import {map} from "rxjs/operators";
import {
  Auth,
  authState,
  signInWithCredential,
  signInAnonymously,
  signOut,
  GoogleAuthProvider,
  signInWithPopup, sendPasswordResetEmail
} from '@angular/fire/auth';
import {signInWithEmailAndPassword} from "@firebase/auth";
import {Router} from "@angular/router";
import {Credentials, User} from "./ngrx-auth.state";

@Injectable()
export class NgrxAuthEffects {


  getUser$ = createEffect(
    () => this.actions$.pipe(
      ofType<userActions.GetUser>(userActions.AuthActionTypes.GetUser),
      map((action: userActions.GetUser) => action.payload),
      exhaustMap(payload => authState(this.auth).pipe(
          take(1),
          switchMap(authData => {
            if (authData) {
              return zip(from(authData.getIdTokenResult(true))).pipe(
                switchMap(res => {
                  const user = new User(authData.uid, authData.displayName ?? '-', authData.email ?? '-',
                    authData.phoneNumber ?? '-', authData.photoURL ?? '-',
                    authData.emailVerified, res[0].claims); //iot extension
                  return from([new userActions.Authenticated({user})]);
                })
              );
            } else {
              return of(new userActions.NotAuthenticated());
            }
          })
        )
      )
    )
  );

  signUpWithCredentials$ = createEffect(
    () => this.actions$.pipe(
      ofType(userActions.AuthActionTypes.CredentialsLogin),
      map((action: userActions.CredentialsLogin) => {
        return {
          email: action.email,
          password: action.password,
          remember: (action.remember) ? action.remember : false

        };
      }),
      exhaustMap((credentials: Credentials) => {
          return from(signInWithEmailAndPassword(this.auth, credentials.email, credentials.password))
            .pipe(
              map(_ => new userActions.GetUser()),
              tap(x => this.router.navigate(['/'])),
              catchError(error => of(new userActions.AuthError(error)))
            );
        }
      )
    )
  );


  logout$ = createEffect(
    () => this.actions$.pipe(
      ofType(userActions.AuthActionTypes.Logout),
      map((action: userActions.Logout) => action.payload),
      exhaustMap(payload => {
        return from(signOut(this.auth));
      }),
      tap(x => this.router.navigate(['/login'])),
      map(authData => {
        return new userActions.NotAuthenticated();
      })
    )
  );

  passwordForgotten$ = createEffect(
    () => this.actions$.pipe(
      ofType(userActions.AuthActionTypes.ResetPasswordRequest),
      map((action: userActions.ResetPasswordRequest) => action.payload),
      exhaustMap(payload => {
        return from(sendPasswordResetEmail(this.auth, payload.email, {url: payload.redirectUrl})).pipe(
          map(authData => {
            return new userActions.ResetPasswordRequestSuccess();
          }),
          catchError(err => of(new userActions.ResetPasswordRequestError(err)))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private auth: Auth
  ) {
  }
}

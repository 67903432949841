import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Observable} from "rxjs";
import {GameMessagesPageActions} from "../../game-messages/store/game-messages.actions";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {GamePageActions} from "../../games-management/store/game.actions";
import {Game} from "../../games-management/store/game.state";
import {isUserLogged} from "../../ngrx-auth/store/ngrx-auth.selectors";

@Component({
  selector: 'app-game-detail-settings',
  template: `
    <div class="box">
      <app-game-detail-navbar class="row mx-0 header" [game]="game$|async"></app-game-detail-navbar>
      <div class="content h-100">
        <div class="maxwidth settings-wrapper">
          <div class="parent">
            <app-game-settings-fields
              *ngIf="isUserLogged$ | async"
              class="editor"></app-game-settings-fields>

          </div>
        </div>
        <app-game-settings-preview class="preview-pane"></app-game-settings-preview>
      </div>
    </div>
  `,
  styles: [`

        .parent {
            display: flex;
            height: 100%;
            min-width: 917px;
        }

        .editor {
            flex: 1;
            width: 937px;
        }

        .preview-pane {
            flex: 0 0 429px;
            background: #FAFAFA 0% 0% no-repeat padding-box;
            opacity: 1;

            position: absolute;
            right: 0;
            bottom: 0;
            top: 144px;
            width: 500px;
            height: calc(100vmax - 144px);
        }
        .space-below-button {
            height: 50px;
        }

        .settings-wrapper {
            position: unset;
        }
    `]
})
export class GameDetailSettingsComponent implements OnInit {

  public game$: Observable<Game> = this.store.select(getCurrentGame);
  isUserLogged$ = this.store.select(isUserLogged);

  constructor(public store: Store<State>) {
  }


  ngOnInit(): void {
    this.store.dispatch(GameMessagesPageActions.getMessages({}));
    this.store.dispatch(GamePageActions.getGame({}));
    // this.store.dispatch(new GetCurrentGameFromRouterRequestAction());

  }

}


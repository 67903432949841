import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {GameCategoryPageActions} from "../store/game-category.actions";
import {getCurrentGameOrganisationMappings} from "../store/game-category.selectors.organisations";
import {Organisation} from 'src/app/organisations-management/store/organisations.state';

@Component({
  selector: 'app-organisation-edit-labels',
  template: `
    <div class="mb-5">
      <mat-chip-list #chipList aria-label="Organisation selection">
        <mat-chip
          *ngFor="let organisation of organisations | async "
          [selectable]="true"
          [removable]="true"
          (removed)="remove(organisation.id)">
          {{organisation.name}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  `,
  styles: []
})
export class OrganisationEditLabelsComponent implements OnInit, OnChanges {

  organisations: Observable<Organisation[]> = this.store.select(getCurrentGameOrganisationMappings);
  @Input() gameId: any;

  constructor(
    private store: Store<State>
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(GameCategoryPageActions.query());
  }

  remove(organisationId: any) {
    this.store.dispatch(GameCategoryPageActions.removeOrganisationMapping({mappingId: organisationId + ":" + this.gameId}));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gameId) {
      this.store.dispatch(GameCategoryPageActions.queryOrganisationMappings({gameId: this.gameId}));
    }
  }


}

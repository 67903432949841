import {createEntityAdapter, EntityState} from "@ngrx/entity";

import {UserLibraryApiActions, UserLibraryPageActions} from "./user-library.actions";
import {Action, createReducer, on} from "@ngrx/store";
import {Game} from "../../games-management/store/game.state";

export interface FeaturedGameState extends EntityState<Game> {
  currentQuery: string;
  featuredGames: Game[];
  cursor : string;
}

export function selectIdentifier(a: Game): number {
  return a.gameId;
}

export const gamesAdapter = createEntityAdapter<Game>(
  {selectId: selectIdentifier}
);

const initialState: FeaturedGameState = gamesAdapter.getInitialState({
  currentQuery: '',
  featuredGames: [],
  cursor: ''
});


const reducer = createReducer(
  initialState,
  on(UserLibraryPageActions.searchGames,
    (state, action) => ({...state, currentQuery: action.query})),
  on(UserLibraryPageActions.resetQuery,
    (state, action) => ({...state, currentQuery: ''})),
  on(UserLibraryApiActions.addLibraryGameSuccess,
    (state, action) => gamesAdapter.upsertOne(action.game , state)),
  on(UserLibraryApiActions.addLibraryGamesSuccess,
    (state, action) => gamesAdapter.upsertMany(action.games || [], state)),
  on(UserLibraryApiActions.getFeaturedSuccess,
    (state, action) => ({...state, featuredGames: action.games || []})),
  on(UserLibraryApiActions.setRecentGames,
    (state, action) => ({
      ...gamesAdapter.upsertMany(action.gameList.games || [], state),
      cursor: action.gameList.resumptionToken
    })
    ),
);

export function libraryReducer(
  state: FeaturedGameState | undefined,
  action: Action
): FeaturedGameState {
  return reducer(state, action);
}
//
//
// export function reducers(
//   state = userLibraryInitialState, action: actions.UserLibraryActions): UserLibraryState {
//   switch (action.type) {
//
//     case actions.UserLibraryActionTypes.SET_GAME: {
//       if (state.queryGames.filter(g => g.gameId === action.payload.gameId).length !== 0) {
//         return {
//           ...state,
//           queryGames: state.queryGames.map(g => g.gameId === action.payload.gameId ? action.payload : g),
//         };
//       }
//       return {
//         ...state,
//         queryGames: action.payload == null ? [] : [action.payload]
//       };
//     }
//
//     default: {
//       return state;
//     }
//   }
// }

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from "@ngrx/store";

import {EffectsModule} from "@ngrx/effects";
import {GameAuthorEffects} from "./store/game-author.effects";
import {gameAuthorReducer} from "./store/game-author.reducer";
import {GameAuthorEffectsLoadAuthors} from "./store/game-author.effects.load-authors";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('game-authors', gameAuthorReducer),
    EffectsModule.forFeature([GameAuthorEffects, GameAuthorEffectsLoadAuthors]),
  ]
})
export class GameAuthorsModule { }


import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionsSubject, Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {filter} from "rxjs/operators";
import {
  AuthActionTypes,
  AuthError,
  CredentialsLogin,
  ResetPasswordRequest
} from 'src/app/ngrx-auth/store/ngrx-auth.actions';
// import {AuthActionTypes, AuthError, CredentialsLogin, GoogleLogin} from "../../auth/actions/auth.actions";
import {environment} from "../../../environments/environment";
import {AccountPageActions} from "../../user-management/store/portal-user.actions";

// import {ResetPasswordRequest} from "../../auth/actions/password-management.actions";

@Component({
  selector: 'app-login-screen-no-social',
  template: `
    <div class="login-screen pos-full-canvas a200-color">
      <div class="style-image-left pos-image-left" style="">

      </div>
      <div class="pos-full-canvas-right d-flex flex-column justify-content-center align-items-center">
        <div class="outer-form d-flex flex-column align-items-center">
          <div class="primary-color font-bold-32-21-roboto mt-3 mb-4">
            {{ 'LOGIN.APP_TITLE' | translate }}
          </div>

          <div class="contrast-color-50pct font-regular-17-13-roboto mt-2 mb-3">
            {{ 'LOGIN.APP_SUB_TITLE' | translate }}
          </div>
          <form [formGroup]="form" class="w-100 mt-5">

            <mat-form-field class="w-100 font-regular-16-24-roboto ">
              <input class="contrast-color-50pct"
                     matInput placeholder="{{ 'LOGIN.EMAIL_ADDRESS' | translate }}"
                     [type]="'text'"
                     formControlName="userName">
            </mat-form-field>

            <div *ngIf="username.invalid && ( username.touched) "
                 class="alert alert-danger">

              <div *ngIf="username.errors &&  username.errors.required">
                {{"LOGIN.USERNAME_REQUIRED" | translate}}
              </div>
              <div *ngIf="username.errors && username.errors.email">
                {{"LOGIN.USERNAME_VALID" | translate}}
              </div>
            </div>
            <div *ngIf="usernameError"
                 class="alert alert-danger">

              {{usernameError | translate}}
            </div>

            <mat-form-field class="w-100 mt-2  font-regular-16-24-roboto">
              <input class="contrast-color-50pct"
                     formControlName="password"
                     matInput placeholder="{{ 'LOGIN.PASSWORD' | translate }}" [type]="'password' "
              >
            </mat-form-field>

            <div *ngIf="wachtwoord.invalid && (wachtwoord.dirty || wachtwoord.touched)"
                 class="alert alert-danger">

              <div *ngIf="wachtwoord.errors && wachtwoord.errors.required">
                  {{"LOGIN.PW_REQUIRED" | translate}}
              </div>
              <div *ngIf="wachtwoord.errors && wachtwoord.errors.minlength">
                {{"LOGIN.PW_LENGTH" | translate}}
              </div>
            </div>

            <div *ngIf="passwordError"
                 class="alert alert-danger">

              {{passwordError | translate}}
            </div>


            <div class="w-100 d-flex flex-row justify-content-between mt-3">
              <mat-checkbox color="primary"
                            formControlName="rememberMe"
              >
                <div class="contrast-color-50pct font-regular-16-24-roboto"> {{ 'LOGIN.REMEMBER_ME' | translate }}</div>
              </mat-checkbox>

              <button
                class="forgot-pw-button"
                routerLink="/forgot-password"

                mat-button>
                <div
                  class="contrast-color-50pct font-regular-16-24-roboto"> {{ 'LOGIN.FORGOT_PASSWORD' | translate }}</div>
              </button>
            </div>

            <button class="submit-button w-100 mt-3 gl-style-button-no-shadow font-medium-14-20-roboto"
                    mat-raised-button
                    color="primary" (click)="credentialsLogin()">{{ 'LOGIN.SUBMIT' | translate }}</button>
          </form>
        </div>
      </div>
    </div>

  `
})
export class LoginScreenNoSocialComponent implements OnInit, OnDestroy {

  form = new FormGroup({
    userName: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    rememberMe: new FormControl(true, Validators.required),
  });

  passwordError : string;
  usernameError : string;

  public get username():any { return this.form.get('userName'); }
  public get wachtwoord():any { return this.form.get('password'); }

  private subscription = new Subscription();

  constructor(
    public store: Store<State>,
    public _snackBar: MatSnackBar,
    public gaService: GoogleAnalyticsService,
    public actionsSubject$: ActionsSubject,
    public router: Router
  ) {
  }

  ngOnInit() {
    this.subscription.add(this.actionsSubject$.pipe(
      filter((action) => action.type === AuthActionTypes.Authenticated)
    ).subscribe(() => {
      this.router.navigate(['/games']);

    }));
    this.subscription.add(this.actionsSubject$.pipe(
      filter((action) => action.type === AuthActionTypes.AuthError)
    ).subscribe((action:AuthError) => {
      this.usernameError = undefined;
      this.passwordError = undefined;
      if (action.payload.code === 'auth/wrong-password') {
        this.passwordError = "Wachtwoord fout";

      }
      if (action.payload.code === 'auth/user-not-found') {
        this.usernameError = "LOGIN.USER_NOT_FOUND";

      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  forgotPassword() {
    this.store.dispatch(AccountPageActions.requestReset({email: 'stefaan@io-things.eu'}));
    // this.store.dispatch(new ResetPasswordRequest({
    //   email: this.form.value.userName,
    //   redirectUrl: environment.deep_link + '#/login'
    // }));
  }

  credentialsLogin() {
    this.passwordError = undefined;
    this.usernameError = undefined;
    this.store.dispatch(new CredentialsLogin(
      this.form.value.userName,
      this.form.value.password,
      this.form.value.rememberMe));
  }

}

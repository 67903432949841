import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AddAll, ARLearnActionActionTypes, Query} from './arlearn-actions.actions';
import {map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {State} from "../../core/reducers";
import * as fromRoot from "../../core/selectors/router.selector";
import {ArlearnActionsService} from "./arlearn-actions.service";


@Injectable()
export class ArlearnActionsEffects {

  query$ = createEffect(
    () => this.actions$.pipe(
      ofType(ARLearnActionActionTypes.QUERY),
      withLatestFrom(
        this.store.select(fromRoot.selectRouteParam('runId'))
      ),
      mergeMap(([action, runId]: [Query, string]) => {

        return this.arlearnActionService.getActions(runId, 1, null);
      }),
      map(arr => {
        console.log(arr);
        return new AddAll(arr);
      })
    )
  );

  constructor(private actions$: Actions,
              private store: Store<State>,
              private arlearnActionService: ArlearnActionsService
  ) {
  }
}

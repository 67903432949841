import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssetSelectorComponent} from './components/asset-selector/asset-selector.component';
import {SharedModule} from "../shared/shared.module";
import {MediaLibTabBarComponent} from './components/media-lib-tab-bar/media-lib-tab-bar.component';
import {MediaLibFoldersComponent} from './components/media-lib-folders/media-lib-folders.component';
import {MediaLibContainerComponent} from './components/media-lib-container/media-lib-container.component';
import {MediaLibFilesOverviewComponent} from './components/media-lib-files-overview/media-lib-files-overview.component';
import {StoreModule} from "@ngrx/store";
import {reducers} from "./store/media-lib.reducer";
import {EffectsModule} from "@ngrx/effects";
import {MediaLibraryEffects} from "./store/media-lib.effects";
import {AppFileTileComponent} from './components/app-file-tile/app-file-tile.component';
import {FileToUploadComponent} from './components/file-to-upload/file-to-upload.component';
import {LibraryFooterComponent} from './components/library-footer/library-footer.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {ImageBackgroundDirective} from "./directives/image-background.directive";
import {FileDragDropDirective} from "./directives/file-drag-drop.directive";
import {FileUploadComponent} from './components/file-upload.component';
import {FilestoreBackgroundImageComponent} from "./components/filestore-background-image.component";
import {FilestoreBackgroundVideoComponent} from "./components/filestore-background-video.component";
import {VideoSourceDirective} from "./directives/video-source.directive";


@NgModule({
  declarations: [
    AssetSelectorComponent,
    MediaLibTabBarComponent,
    MediaLibFoldersComponent,
    MediaLibContainerComponent,
    MediaLibFilesOverviewComponent,
    AppFileTileComponent,
    FileToUploadComponent,


    LibraryFooterComponent,
    ImageBackgroundDirective,VideoSourceDirective,
    FileDragDropDirective,
    FileUploadComponent,
    FilestoreBackgroundImageComponent,
    FilestoreBackgroundVideoComponent
  ],
  imports: [
    // BrowserModule,
    // BrowserAnimationsModule,
    CommonModule,
    SharedModule.forRoot(),
    StoreModule.forFeature('mediaLibrary', reducers),
    EffectsModule.forFeature([MediaLibraryEffects]),
    FontAwesomeModule
  ],

  entryComponents: [  LibraryFooterComponent],
  exports: [
    AssetSelectorComponent,
    MediaLibTabBarComponent,
    MediaLibContainerComponent,
    LibraryFooterComponent,
    ImageBackgroundDirective,VideoSourceDirective,
    FileDragDropDirective,
    FileUploadComponent,
    FilestoreBackgroundImageComponent,
    FilestoreBackgroundVideoComponent
  ]
})
export class MediaLibraryModule {
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SelectAssetComponent} from "../../../portal-image-management/modals/select-asset.component";
import {GameMessagePageActions} from "../../store/game-message.actions";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {MatDialog} from "@angular/material/dialog";
import {BehaviorSubject, combineLatestWith, Observable, Subscription} from "rxjs";
import {getEditMessageSelector} from "../../store/game-message.selector";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-select-image-background',
  template: `
    <div class="gi-edit-field-label">{{label | translate}}</div>
    <div class="w-100 d-flex flex-row flex-nowrap justify-content-between">
      <button

        (click)="imageCorrect()"
        class="flex-grow-1 button-height w-100"
        mat-stroked-button
        color="primary"
      >
        <mat-icon class="pinkish-grey mr-1">cloud_upload</mat-icon>
        {{'THEME.CHANGE_BACKGROUND' | translate}}
      </button>
      <button
        (click)="removeBackground()"
        class="ml-2"
        mat-stroked-button *ngIf="hasBackground$ |async">
        <mat-icon class="pinkish-grey mr-1">delete_forever</mat-icon>
      </button>

    </div>
  `,
  styles: []
})
export class SelectImageBackgroundComponent implements OnInit, OnDestroy {

  @Input() label = '';
  @Input() set key(key) {
    this._asyncKey= key;
    this.key$.next(key)
  };

  _asyncKey = 'correct';

  public key$ = new BehaviorSubject('correct')

  subscripton = new Subscription();
  hasBackground$: Observable<boolean> = this.store.select(getEditMessageSelector).pipe(
    combineLatestWith(this.key$),
    map(([m, k]) => (m?.fileReferences || {}) [k] !== undefined)
  );

  constructor(private store: Store<State>,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  imageCorrect() {
    const dialogRef = this.dialog.open(SelectAssetComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });
    this.subscripton.add(dialogRef.afterClosed().pipe(
      combineLatestWith(this.key$),
    ).subscribe(([result, key]) => {
      this.store.dispatch(GameMessagePageActions.updateFileRef({
        delete: false,
        key: key,
        value: result
      }));
    }));
  }

  removeBackground() {
    this.store.dispatch(GameMessagePageActions.updateFileRef({
      delete: true,
      key: this._asyncKey
    }));
  }

  ngOnDestroy(): void {
    this.subscripton.unsubscribe();
  }
}

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {environment} from "../../../environments/environment";
import {GameMessagesPageActions} from "../store/game-messages.actions";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {amountOfFilters} from "../store/game-messages.selector";
import {Subscription} from "rxjs";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-screen-type-filter',
  template: `
    <mat-form-field class="w-100">
      <mat-label>{{'ROW_HEADERS.SCREEN_TYPE' | translate}}</mat-label>
      <mat-select
        #typeInput
        (selectionChange)="filter($event.source.value)"
        multiple
      >
        <mat-option *ngFor="let messageType of messageTypes" [value]="messageType">
          {{'MESSAGE.'+messageType.toLocaleUpperCase() | translate}}
        </mat-option>
      </mat-select>
      <mat-icon matPrefix>filter_list</mat-icon>
    </mat-form-field>

  `,
  styles: [
  ]
})
export class ScreenTypeFilterComponent implements OnInit {
  @Input() game: Game;
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  messageTypes: string[];
  @ViewChild('typeInput') typeInput;
  subscription = new Subscription();
  constructor(public store: Store<State>,) { }

  ngOnInit(): void {
    if (this.game && this.game.webEnabled) {
      this.messageTypes = environment.messageTypeWeb;
    } else {
      this.messageTypes = environment.messageType;
    }
      this.subscription.add(this.store.select(amountOfFilters).subscribe((amount)=>{
        if (amount === 0 && this.typeInput) {
          this.typeInput.value = '';
        }
      }));
    }

    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }

  filter(messageTypes) {
    this.store.dispatch(GameMessagesPageActions.setFilter({messageTypes}));
  }

}

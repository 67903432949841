

export const topLevelMenu = [
  {
    routerLink: '/games/list',
    label: 'GAME.MYGAMES',
    permissions : []
  }, {
    routerLink: '/connections/list',
    label: 'CONTACT.CONTACTS',
    permissions : []
  }, {
    routerLink: '/collection',
    label: 'LIBRARY.LABEL',
    permissions : []
  }, {
    routerLink: '/admin/games',
    label: 'PORTAL_MANAGEMENT.LABEL',
    claims : ['admin']
  }, {
    routerLink: '/tutorial/video',
    label: 'HELP.TITLE',
    permissions : []
  },
];

export const backToManageGames = topLevelMenu[0].routerLink;

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {selectEntities} from "../../../game-themes/store/game-theme.selectors";
import {Observable, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {Dictionary} from "@ngrx/entity";
import {GameTheme} from "../../../game-themes/store/game-theme.state";
import {GameThemePageActions} from 'src/app/game-themes/store/game-theme.actions';

@Component({
  selector: 'app-screen-tile',
  template: `
    <div class="tile tile-dimensions position-relative tile-style">
      <app-hover-overlay
        [clickText]="clickText"
        [actionText]="actionText"
        [actionIcon]="actionIcon"
        (actionClicked)="actionClickedMethod($event)"
        (clickCenter)="openGameClicked()"
        [navTo]="navTo"
        [loading]="loading"
      >
        <app-filestore-background-image *ngIf="(imagePath || themeId) && !isVideo && (displayAsset ==='')"
                                        [paths]="[imagePath || ((themes |async)[themeId]?.backgroundPath)]"
                                        (isVideo)="$event?isVideo=true:isVideo=false"
        >
        </app-filestore-background-image>
        <app-filestore-background-video
          *ngIf="isVideo"
          [showControls]="false"
          [paths]="[videoPath]"
        >
        </app-filestore-background-video>
        <div [ngStyle]="{'background':'transparent url('+displayAsset+')','height': '100%', 'background-size': 'cover'}"
             *ngIf="(displayAsset !=='')">

        </div>
      </app-hover-overlay>
      <div class="position-icon pos-icon-type icon-style" *ngIf="icon">
        <i [ngClass]="iconClass"></i>
      </div>
      <div class="position-footer footer-style">
        <div class="position-footer-title footer-title-style font-regular-16-20-roboto">{{title}} </div>
        <div class="position-footer-subtitle footer-subtitle-style font-regular-12-20-roboto">{{subTitleCopy}} </div>
      </div>
    </div>
  `
})
export class ScreenTileComponent implements OnInit {

  @Input() title: string;

  @Input() set subtitle(title) {
    this.subTitleCopy = title && title.split(',').join(', ');
  };

  subTitleCopy;
  @Input() imagePath: string;
  @Input() themeId: number;
  // @Input() themePath = null;
  @Input() videoPath: any;
  @Input() clickText: any;
  @Input() navTo: any;
  @Input() icon: any;
  @Input() loading= false;
  @Input() actionText: string[];
  @Input() actionIcon: any = {};
  @Input() displayAsset = "";
  @Output() actionClicked = new EventEmitter();
  @Input() isVideo = false;

  themes: Observable<Dictionary<GameTheme>> = this.store.select(selectEntities);

  iconClass: string;

  subcription = new Subscription();

  constructor(private store: Store<State>) {
  }

  actionClickedMethod(event) {
    this.actionClicked.emit(event);
  }

  ngOnInit() {
    // if (this.themeId) {
    //   this.store.dispatch(new QueryOne(this.themeId));
    // }
    this.subcription.add(this.themes.subscribe(themeDict => {
      if (this.themeId && !themeDict[this.themeId]) {
        this.store.dispatch(GameThemePageActions.queryOne({themeId:this.themeId}));
      }
    }))
    this.iconClass = (environment.icons.filter(s => s.icon === this.icon)[0] || {}).class;

  }

  openGameClicked() {
    // this.store.dispatch(new ResetGameMessageEditAction());
  }
}

import {Pipe, PipeTransform} from '@angular/core';

import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Observable, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {getUser} from "../store/ngrx-auth.selectors";

@Pipe({
  name: 'permissionFilter',
  pure: true
})
export class PermissionFilterPipe implements PipeTransform {

  subscription: Subscription;

  constructor(
    private store: Store<State>
  ) {
  }

  transform(origValue: any, args?: any): Observable<any> {
    let value = [];
    if (args) {
      value = origValue[args];
    } else {
      value = origValue
    }
    return this.store.select(getUser).pipe(
      map((user) => {
        if (!user || !user.claims) return value;
        return value.filter(entry => {
          if (!entry.claims) {
            return true
          }
          for (const checkPermission of (entry.claims || [])) { //
            if (user.claims[checkPermission]) {
              return true;
            }
          }
          return false;
        });
      }),
      map(values => args ? {...origValue, items: values} : values)
    );
  }
}


import {Component} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {GameMessagesPageActions} from 'src/app/game-messages/store/game-messages.actions';

import Debounce from 'debounce-decorator';

@Component({
  selector: 'app-run-search-bar',
  template: `

    <div class="d-flex flex-row align-items-center justify-content-between">
      <div class="search-block">
        <mat-form-field class="search-input">
          <mat-icon class="search-icon" matPrefix>search</mat-icon>
          <input type="text" matInput
                 (input)="doSearch($event.target.value)"
                 [placeholder]="'RESULTS.START_TYPING' | translate"/>
        </mat-form-field>
      </div>
      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>

  `
})
export class RunSearchBarComponent {


  constructor(
    private store: Store<State>) {
  }

  @Debounce(300)
  doSearch(filter) {
    let f: string[] = [];
    if (filter !== '') {
      f = [filter];
    }
    this.store.dispatch(GameMessagesPageActions.setFilter({filters: f}));
  }
}

import {Component, EventEmitter, Output} from '@angular/core';
import {GamePageActions} from '../../../../store/game.actions';
import {State} from '../../../../../core/reducers';
import {Store} from "@ngrx/store";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {SelectThemeComponent} from "../../../../../game-themes/modal/select-theme.component";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-create-game-with-title',
  template: `
    <div class="maxwidth">
      <app-modal-esc-button
        class="gl-pos-modal-esc-button"
        [type]="'esc'" (buttonClick)="esc.emit()"></app-modal-esc-button>
      <app-modal-esc-button
        *ngIf="step !== 1"
        class="gl-pos-modal-back-button"
        [type]="'back'" (buttonClick)="backClick()"></app-modal-esc-button>
    </div>
    <div *ngIf="step == 1">
      <div class="maxwidth">

        <div class="pos-scratch-button-row">
        </div>

        <div class="d-flex flex-column justify-content-center align-items-center">

        </div>

        <div class="d-flex flex-column justify-content-center align-items-center">

          <div
            class="primary-color font-medium-32-43-roboto mt-2 text-align-center">
            {{ 'GAME.NEW_GAME_TITLE' | translate }}
          </div>
          <div
            class="color-222 font-medium-16-24-roboto mt-2 text-align-center">
            {{ 'GAME.NEW_GAME_SELECT_STEP1' | translate }}
          </div>

          <div
            class="color-888 font-regular-16-24-roboto mt-2 text-align-center">
            {{ 'GAME.NEW_GAME_CHANGE_POSSIBLE' | translate }}
          </div>

          <div class="game-theme-selector width-only">
            <mat-form-field class="mt-5 w-100 font-regular-16-24-roboto ">
              <input class="contrast-color-50pct"
                     matInput placeholder="{{ 'GAME.NAME_FIELD' | translate }}"
                     [type]="'text'" [(ngModel)]="title">
            </mat-form-field>
          </div>

          <div class="game-theme-selector width-only">
            <app-game-detail-access
              [owner]="true"
              (optionChange)="optionChange($event)"
            >
            </app-game-detail-access>
          </div>

          <div class="game-theme-selector width-only mt-3">
            <div class="font-regular-12-20-roboto color-54">{{'GAME.LOGIN_REQUIRED' | translate}}</div>
            <mat-slide-toggle
              (change)="gamePrivateChange($event)">
              <div *ngIf="privateMode">{{'GAME.PLAY_WITHOUT_ACCOUNT' | translate}}</div>
              <div *ngIf="!privateMode">{{'GAME.ACCOUNT_NECESS' | translate}}</div>
            </mat-slide-toggle>
          </div>

          <div class="game-theme-selector width-only mt-3">
            <div class="font-regular-12-20-roboto color-54">{{'GAME.WEB_BASED' | translate}}</div>
            <mat-slide-toggle
              [ngModel]="webSupport"
              (change)="supportToggle($event)">
              <div *ngIf="webSupport">{{ 'GAME.MOBILE_AND_WEB' | translate }}</div>
              <div *ngIf="!webSupport">{{ 'GAME.MOBILE_ONLY' | translate }}</div>
            </mat-slide-toggle>
          </div>



          <div
            class="game-theme-selector width-only">
            <div class="mt-4 w-100 ">
              <button
                (click)="continue()"
                class="w-100 button-height"
                mat-flat-button
                color="primary"
              >{{ 'GAME.NEW_GAME_CONTINUE' | translate }}
              </button>
            </div>
          </div>
        </div>
        <!--        <div class="pos-title primary-color font-medium-32-43-roboto">{{ 'GAME.NEW_GAME_TITLE' | translate }}</div>-->
        <!--        <div-->
        <!--          class="pos-subtitle contrast-color-50pct font-regular-17-13-roboto">{{ 'GAME.NEW_GAME_SUBTITLE' | translate }}-->
        <!--        </div>-->

      </div>
      <!--      <div class="pos-inner-block">-->


      <!--        <button class="pos-create-button gl-style-button-no-shadow font-medium-14-20-roboto"-->
      <!--                mat-raised-button-->
      <!--                color="primary" (click)="continue()">-->
      <!--          {{ 'GAME.NEW_GAME_CONTINUE' | translate }}-->
      <!--        </button>-->

      <!--      </div>-->
    </div>

    <div *ngIf="step == 2">
      <div class="maxwidth">
        <div class="pos-scratch-button-row">
        </div>

        <div class="d-flex flex-column justify-content-center align-items-center">

          <div
            class="primary-color font-medium-32-43-roboto mt-2 text-align-center">
            {{ 'GAME.NEW_GAME_TITLE' | translate }}
          </div>


          <div
            class="color-222 font-medium-16-24-roboto mt-2 text-align-center">
            {{ 'GAME.NEW_GAME_SELECT_STEP2' | translate }}
          </div>

          <div
            class="color-888 font-regular-16-24-roboto mt-2 text-align-center">
            {{ 'GAME.NEW_GAME_CHANGE_POSSIBLE' | translate }}
          </div>

          <div class="mt-5">
            <app-game-theme-selector-light
              [themeId]="themeId"
              (themeChange)="themeChange($event)"
            ></app-game-theme-selector-light>

          </div>

          <div
            class="game-theme-selector width-only">
            <div class="mt-5 w-100 ">
              <button
                (click)="selectOtherTheme()"
                class="w-100 button-height"
                mat-stroked-button
                color="primary"
              >
                <mat-icon class="pinkish-grey mr-1">auto_awesome</mat-icon>
                  {{'GAME.SELECT_OTHER_THEME' | translate}}
              </button>
            </div>
          </div>

          <div
            class="game-theme-selector width-only">
            <div class="mt-4 w-100">
              <button
                (click)="saveClick()"
                class="w-100 button-height"
                mat-flat-button
                color="primary"
              >{{'GAME.CREATE_BUTTON_TITLE' | translate}}
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>

  `,
  styles: [`
    .pos-scratch-button-row {
      position: relative;
      margin-top: 28px;
      height: 44px;
    }

    .pos-title {
      position: relative;
      margin-top: 11px;
      height: 38px;
      text-align: center;
    }

    .pos-subtitle {
      position: relative;
      margin-top: 18px;
      height: 23px;
      text-align: center;
    }

    .pos-inner-block {
      position: relative;
      width: 320px;
      left: 50%;
      transform: translate(-50%, 0%);
    }


    .pos-create-button {
      position: relative;
      margin-top: 37px;
      width: 100%;
      height: 44px;
    }
  `]
})
export class CreateGameWithTitleComponent {
  title: string;
  webSupport = false;

  sharing = 1;
  themeId = 1;
  privateMode = true;

  step = 1;
  @Output() esc = new EventEmitter();
  @Output() back = new EventEmitter();

  private subscription: Subscription = new Subscription();

  constructor(public store: Store<State>, public dialog: MatDialog,) {
  }

  continue() {
    this.step = 2;
  }

  saveClick() {
    this.store.dispatch(GamePageActions.createGame({game : {
        gameId: Date.now(),
        title: this.title,
        webEnabled: this.webSupport,
        description: '',
        sharing: this.sharing,
        privateMode: this.privateMode,
        theme: this.themeId
      }}));
    this.esc.emit();
  }



  optionChange($event: any) {
    this.sharing = $event;
  }

  themeChange(themeId: any) {
    console.log("themeId is", themeId);
  }

  selectOtherTheme() {
    const dialogRef = this.dialog.open(SelectThemeComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {hideOwn: true}
    });

    // dialogRef.componentInstance.selectedTheme = this.theme;

    this.subscription.add(dialogRef.componentInstance.submit
      .subscribe((theme) => {
        this.themeId = theme.themeId;
        // if (this.emitOnChange) {
        //   this.themeChange.emit(theme.themeId);
        // } else {
        //   this.store.dispatch(new SetSelectedThemeAction(theme));
        // }

        dialogRef.close();
      }));
  }
  supportToggle(event: MatSlideToggleChange) {
    this.webSupport = event.source.checked;
  }

  gamePrivateChange(event: MatSlideToggleChange) {
    this.privateMode = !event.source.checked;
  }

  backClick() {
    if (this. step == 2) {
      this.step = 1;
    } else {
      this.back.emit();
    }
  }
}

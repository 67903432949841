import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Game} from "../../../../games-management/store/game.state";
import {MatSelectChange} from "@angular/material/select";
import {GameMessagePageActions} from "../../../store/game-message.actions";
import {Store} from "@ngrx/store";
import {State} from "../../../../core/reducers";
import {GameMessage} from "../../../../game-messages/store/game-messages.state";

@Component({
  selector: 'app-create-chapter',
  template: `
    <mat-form-field class="w-100 mb-3">
      <mat-select [value]="message?.chapter || 0" (selectionChange)="change($event)">
        <mat-option [value]="0">{{'GAME.NO_CHAPTER' | translate}}</mat-option>
        <mat-option
          *ngFor="let number of array"
          [value]="number">{{'GAME.CHAPTER' | translate}} {{number}}</mat-option>
      </mat-select>
    </mat-form-field>

  `,
  styles: []
})
export class CreateChapterComponent implements OnInit {

  @Input() game: Game;
  @Input() message: GameMessage;
  @Output() chapter = new EventEmitter();
  array: any[];

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {


    this.array = [];
    for (var i = 1; i <= this.game.chapters; i++) {
      this.array.push(i);
    }

  }

  change(event: MatSelectChange) {
    this.chapter.emit(Number.parseInt(event.value));
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage: {chapter: Number.parseInt(event.value)}}));
  }
}

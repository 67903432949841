import {switchMap} from 'rxjs/operators';

import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {State} from "src/app/core/reducers";
import {getAuthState} from "../store/ngrx-auth.selectors";

@Injectable()
export class AdminAuthGuard implements CanActivate {

  constructor(
    private store: Store<State>,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.store.pipe(
      select(getAuthState),
      switchMap((userState: any) => {
        if (userState.loading || userState.user == null) {
          return EMPTY;
        } else {

          return of(userState.loggedIn && userState.user.claims.admin);
        }
      })
    );
  }

}

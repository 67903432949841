import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../../core/reducers';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {GameMessage} from '../../../game-messages/store/game-messages.state';
// import {getCurrentMessageSelector} from '../../../game-messages/store/game-messages.selector';
import {getEditMessageSelector} from '../../store/game-message.selector';
import {GameMessagePageActions} from '../../store/game-message.actions';
import {NewMessageComponent} from "../../../game-messages/modal/new-message/new-message.component";
import {MatDialog} from "@angular/material/dialog";
import {getCurrentGame} from "../../../games-management/store/game.selector";
import {canWriteCurrentGame} from "../../../game-authors/store/game-author.selectors";
import {Game} from "../../../games-management/store/game.state";

@Component({
  selector: 'app-screen-editor-type-select',
  template: `
    <div *ngIf="game$|async as game">
      <mat-form-field
        *ngIf="(message$ |async)?.type as type"
        class="w-100 mb-3">
        <mat-icon
          class="cursor-pointer"
          matSuffix
          (click)="openSelector($event, game)"
        >flip_to_front
        </mat-icon>
        <input
          (click)="openSelector($event, game)"
          [disabled]="true"
          matInput [value]="'MESSAGE.' + type.substr(type.lastIndexOf('.')+1).toLocaleUpperCase() | translate"
        >
      </mat-form-field>
    </div>
  `
})
export class ScreenEditorTypeSelectComponent implements OnInit {

  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
  public game$: Observable<Game> = this.store.select(getCurrentGame);
  @Output('onTypeChange') onTypeChange: EventEmitter<string> = new EventEmitter<string>();
  messageTypes: string[];
  public iCanWrite: Observable<boolean> = this.store.select(canWriteCurrentGame);
  value: string;
  iconClass: string;

  constructor(
    public dialog: MatDialog,
    private store: Store<State>
  ) {
    this.messageTypes = environment.messageType;
  }

  ngOnInit() {
    this.message$.subscribe(m => {
      if (m && m.type) {
        this.iconClass = (environment.icons.filter(s => s.icon === m.type)[0] || {}).class;
      }

    })

  }

  selectionChange(event: any) {
    console.log(event.value, 'changed');
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage: {type: event.value}}));
    this.onTypeChange.emit(event.value);
  }

  openSelector(event: MouseEvent, game: Game) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(NewMessageComponent, {
      width: '99%',
      data: {game, selectOnly: true},
      panelClass: ['modal-fullscreen', "modal-dialog"]
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // @ts-ignore
        this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage: {type: 'org.celstec.arlearn2.beans.generalItem.' + result.type}}));
      }
    });
  }


}

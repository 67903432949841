import {createFeatureSelector, createSelector} from '@ngrx/store';
import {categoryMappingAdapter} from "./game-category.reducer";
import {GameCategoryState} from "./game-category.state";
import * as fromCategory from "./game-category.selectors.categories"
const featureState = createFeatureSelector<GameCategoryState>('gameCategories');
const getMappings = (state: GameCategoryState) => state.gameCategoryMappings;
const getAdapter = createSelector(featureState, getMappings);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = categoryMappingAdapter.getSelectors(getAdapter);

export const getCurrentGameCategoryMappings = createSelector(selectAll, fromCategory.selectEntities, (all, categories)=>{
  return all.map(mapping => categories[mapping.categoryId] || {});
})


import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {PlayerPageActions} from "../../store/player.actions";

@Component({
  selector: 'app-pending-players-overview',
  template: `
    <app-top-level-navbar [title]="'CONTACT.CONTACTS'">
      <app-subtabs-navbar [items]="subMenuItems"></app-subtabs-navbar>
    </app-top-level-navbar>

    <div class="maxwidth">
      <app-invitations-table></app-invitations-table>
    </div>

  `
})
export class PendingPlayersOverviewComponent implements OnInit {

  subMenuItems = [
    {
      routerLink: '/connections/list',
      label: 'CONTACT.ALLCONTACTS'
    },
    {
      routerLink: '/connections/pending',
      label: 'CONTACT.PENDING'
    },
  ];

  constructor(
    private store: Store<State>
  ) {
  }

  ngOnInit() {
    this.store.dispatch(PlayerPageActions.loadPendingToMe());
  }

}

import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {GameThemeService} from "./game-theme.service";
import {AuthActionTypes} from "../../ngrx-auth/store/ngrx-auth.actions";
import {filter, map, mergeMap, withLatestFrom} from "rxjs/operators";
import {getInSyncTill} from "./game-theme.selectors";
import {GameThemeApiActions} from "./game-theme.actions";


@Injectable()
export class GameThemeEffectsLoadGlobal {

  constructor(private actions$: Actions,
              private store: Store<State>,
              private gameThemeService: GameThemeService,
  ) {
  }

  getGlobalThemes = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.Authenticated),
      withLatestFrom(
        this.store.select(getInSyncTill)
      ),
      mergeMap(([action, inSyncTill]: [any, number]) => this.gameThemeService.listAll(inSyncTill).pipe(
        map(themeList => GameThemeApiActions.getGlobalListSuccess({themeList}))
      ))
    )
  );

  resumeGetThemes = createEffect(
    () => this.actions$.pipe(
      ofType(GameThemeApiActions.getGlobalListSuccess),
      filter((action) => !!action &&
        !!action.themeList && !!action.themeList.resumptionToken && (action.themeList.themes || []).length != 0),
      mergeMap(
        (action) => this.gameThemeService.listAll(action.themeList.from, action.themeList.resumptionToken).pipe(
          map(themeList => GameThemeApiActions.getGlobalListSuccess({themeList}))
        ),
      )
    )
  )

  updateInSyncTill = createEffect(
    () => this.actions$.pipe(
      ofType(GameThemeApiActions.getGlobalListSuccess),
      filter((action) => !!action &&
        !!action.themeList && (!action.themeList.resumptionToken || (action.themeList.themes || []).length == 0)),
      map(action => GameThemeApiActions.updateGlobalListSyncTime({time: action.themeList.serverTime - 120000}))
    )
  )


}

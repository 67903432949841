import {PortalGamesApiActions} from './portal-games.actions';
import {Category, PortalGamesState,} from './portal-games.state';
import {createEntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {Game} from "../../games-management/store/game.state";

export interface OnlyGameState extends EntityState<Game> {
}

export interface CategoryState extends EntityState<Category> {
}

export function selectIdentifier(a: Game): number {
  return a.gameId;
}

export function selectCategoryIdentifier(a: Category): number {
  return a.categoryId;
}

export const gamesAdapter = createEntityAdapter<Game>(
  {selectId: selectIdentifier}
);

export const categoriesAdapter = createEntityAdapter<Category>(
  {selectId: selectCategoryIdentifier}
);

const portalGamesInitialState: PortalGamesState = {
  queryGames: [],
  portalGames: gamesAdapter.getInitialState(),
  categories: categoriesAdapter.getInitialState(),
};

export const reducer = createReducer(
  portalGamesInitialState,
  on(PortalGamesApiActions.addSearchPortalGames,
    (state, action) => ({
      ...state,
      queryGames: action.games || []
    })),
  on(PortalGamesApiActions.addPortalGame, (state, action) => ({...state,
    portalGames: gamesAdapter.upsertOne({icon: '1', ...action.game}, state.portalGames),
  })),
  on(PortalGamesApiActions.setCategories, (state, action) => ({...state,
      categories: categoriesAdapter.upsertMany(action.categories, state.categories),
    })),
  on(PortalGamesApiActions.setFeaturedResponse, (state, action) => ({...state,
    portalGames: gamesAdapter.updateOne({
      id: action.gameId,
      changes: {featured: action.featured} as any
    }, state.portalGames)
  })),
)

export function reducers(
  state: PortalGamesState | undefined,
  action: Action
): PortalGamesState {
  return reducer(state, action);
}


import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {GameMessagesPageActions} from "../store/game-messages.actions";
import {SelectAssetComponent} from "../../portal-image-management/modals/select-asset.component";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../environments/environment";
import {getFilteredMessagesSelector} from "../store/game-messages.selector";
import {map, mergeMap, withLatestFrom} from "rxjs/operators";
import {GameMessage} from "../store/types";
import {getEditMessageSelector} from "../../game-message/store/game-message.selector";
import {Router} from "@angular/router";
import {messageIdSelector} from "../../core/selectors/router.selector";
import {getCurrentGame} from "../../games-management/store/game.selector";
import {GamePageActions} from "../../games-management/store/game.actions";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-game-messages-list-picture',
  template: `

    <div class="detail-container">
      <div class="scrollbar" *ngIf="game$ | async as game">
      <div *ngIf="currentMessageId$|async as messageId">
        <app-preview-pane
          [hideControls]="true"
          [game]="game"
          *ngIf="messageId != -1"
          [navTo]="'bo/message/detail'"
          class="pos-preview-pane">
        </app-preview-pane>
      </div>
      </div>
      <app-game-detail-navbar [game]="game$|async">

      </app-game-detail-navbar>

      <div class="proper-max-width ">
        <div class="full-width-container">
          <div class="maxwidth">
            <div class="m-3">
              <button mat-flat-button color="primary"
                      (click)="selectMedia()"
              >{{'GAME.SELECT_GAME_BOARD' | translate}}
              </button>
            </div>
            <div class="scrollbar" *ngIf="game$ | async as game">
              <div *ngIf="messages$ |async as messages">
                <app-game-board [game]="game"
                                [messages]="messages "
                                [prefHeight]="prefHeight"
                                [prefWidth]="prefWidth"
                                (nodeClick)="onNodeClick($event)"
                >

                </app-game-board>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }

      .scrollbar {
        width: 100%;
        /*overflow: scroll;*/

        /*overflow-y: scroll; !* Show vertical scrollbar *!*/
        overflow-x: scroll; /* Show horizontal scrollbar */

        border: 1px solid #000;
      }

      .detail-container {
        position: relative;

      }

      @media (max-width: 992px) {
        .proper-max-width {
          margin-left: 0px;
          margin-right: 429px;
          position: relative;
          /*background: #9fd1ee;*/
        }
      }

      @media (min-width: 992px) and (max-width: 1850px) {
        .proper-max-width {
          max-width: 992px;
          margin-left: calc((100% - 992px) / 2);
          margin-right: 429px;
          position: relative;
          /*background: red;*/
        }
      }

      @media (min-width: 1850px) {
        .proper-max-width {
          max-width: 992px;
          margin: auto;
          position: relative;
          /*background: green;*/
        }
      }

      .pos-preview-pane {
        position: fixed;
        right: 0px;
        top: 0px;
        background: #FAFAFA;
        width: 429px;
        height: 100vh;
        z-index: 2;
        box-shadow: 0px 9px 18px #0000002E;
      }

      .back-button {
        position: absolute;
        top: 64px;
        right: 19px;

        /*background: red;*/
      }

      .ext-navbar-left {

        width: 1000px;
        position: absolute;
        left: -1000px;
        height: 144px
      }
    `
  ]
})
export class GameMessagesListPictureComponent implements OnInit, OnDestroy {
  currentMessageId$: Observable<string> = this.store.select(messageIdSelector);
  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);

  public prefHeight = '1920px'
  public prefWidth = '1080px'

  public game$: Observable<Game> = this.store.select(getCurrentGame);
  bucketUrl = environment.firebase.storageBucket;
  public messages$: Observable<any> = combineLatest([
    this.store.select(getCurrentGame).pipe(
      map(game => {
        return {
          url: 'https://storage.googleapis.com/' + this.bucketUrl + game.messageListScreen,
        }
      }),
      mergeMap(image => this.getImageDimension(image)),
    ),
    this.store.select(getFilteredMessagesSelector)]).pipe(
    map(([image, x]: [any, GameMessage[]]) => {
      this.prefWidth = image.width + 'px';
      this.prefHeight = image.height + 'px';
      x.forEach((m: any) => {
        if (!m.customMapX && m.authoringX) {
          m.customMapX = m.authoringX;
          m.customMapY = m.authoringY;

        } else if (!m.customMapX) {
          m.customMapX = 10;
          m.customMapY = 10;

        }
        m.customMapVisible = true;

        m.customMapX = Math.max(0, Math.min(m.customMapX, image.width));
        m.customMapY = Math.max(0, Math.min(m.customMapY, image.height));

      });

      return x;
    })
  );

  public messages_old$: Observable<any> = this.store.select(getFilteredMessagesSelector)

    .pipe(
      withLatestFrom(
        this.store.select(getCurrentGame)
      ),
      map(([x, image]: [any[], any]) => {
        console.log('image ', image);
        x.forEach((m: any) => {
          if (!m.customMapX && m.authoringX) {
            m.customMapX = m.authoringX;
            m.customMapY = m.authoringY;

          } else if (!m.customMapX) {

            m.customMapX = 100;
            m.customMapY = 100;

          }
          m.customMapVisible = true;

          m.customMapX = Math.max(0, m.customMapX);
          m.customMapY = Math.max(0, m.customMapY);

        });
        console.log("x is ", x);
        return x;
      })
    );
  // public localgame: Game;
  private gameSubscription: Subscription = new Subscription();
  // public currentFilter$: Observable<MessageFilter> = this.store.select(getFiltersSelector).pipe(
  //   take(1)
  // );

  public prefHeightOrig = 100;
  public prefWidthOrig = 100;

  constructor(
    private store: Store<State>,
    private router: Router,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {

    this.store.dispatch(GameMessagesPageActions.getMessage());
    this.store.dispatch(GameMessagesPageActions.getMessages({}));
    this.store.dispatch(GamePageActions.getGame({}));
    this.gameSubscription.add(this.game$.subscribe((game) => {
    }));
  }


  onNodeClick($event: any) {
    this.router.navigate([`game/${$event.gameId}/board/${$event.id}`]);
    setTimeout(() => {
      this.store.dispatch(GameMessagesPageActions.getMessage());
    }, 100);
  }

  ngOnDestroy(): void {

    this.gameSubscription.unsubscribe();

  }

  selectMedia() {
    const dialogRef = this.dialog.open(SelectAssetComponent, {
      panelClass: ['modal-fullscreen', "modal-dialog"],
      data: {}
    });
    this.gameSubscription.add(combineLatest([dialogRef.afterClosed(), this.game$])
      .subscribe(([result, localgame]: [any, Game]) => {
        if (result && localgame) {
          localgame.messageListScreen = result;
          this.gameSubscription.add(this.getImageDimension({
            url: 'https://storage.googleapis.com/' + this.bucketUrl + result,
          }).subscribe(response => {
            localgame.boardWidth = response.width;
            localgame.boardHeight = response.height;
            this.store.dispatch(GamePageActions.saveGame({game: localgame}));
          }));
        }
      }));
  }


  getImageDimension(image): Observable<any> {
    return new Observable(observer => {
      const img = new Image();
      img.onload = function (event) {
        const loadedImage: any = event.currentTarget;
        image.width = loadedImage.width;
        image.height = loadedImage.height;
        observer.next(image);
        observer.complete();
      }
      img.src = image.url;
    });
  }
}

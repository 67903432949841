import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {ownsCurrentGame} from "../../../game-authors/store/game-author.selectors";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {Game} from "../../../games-management/store/game.state";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
  selector: 'app-game-detail-chapter',
  template: `
    <div class="w-100 mt-5 mb-5">
      <mat-slide-toggle
        [ngModel]="(localgame?.chapters || 0) != 0"
        (change)="toggle($event)">
        <div *ngIf="(localgame?.chapters || 0) === 0">{{'GAME.CHAPTERS_OFF' | translate}}</div>
        <div *ngIf="!((localgame?.chapters || 0) === 0)">{{'GAME.CHAPTERS_ON' | translate}}</div>
      </mat-slide-toggle>
    </div>
    <div class="w-100 mb-5" *ngIf="!(localgame?.chapters === 0)">
      <mat-form-field class="w-100" >
        <mat-label>{{'GAME.CHAPTERS'|translate}}</mat-label>
        <input matInput
               [disabled]="!(iAmOwner|async)"
               [ngModel]="localgame?.chapters"
               (ngModelChange)="adjustChapters($event)">
      </mat-form-field>
    </div>
  `,
  styles: []
})
export class GameDetailChapterComponent implements OnInit {
  @Input() localgame: Game;
  @Output() changeChapters = new EventEmitter<number>();
  public iAmOwner: Observable<boolean> = this.store.select(ownsCurrentGame);

  constructor(public store: Store<State>) {
  }

  ngOnInit(): void {
  }

  adjustChapters(event: any) {
    this.localgame.chapters = Number.parseInt(event, 10);
    this.changeChapters.emit(this.localgame.chapters);
  }

  toggle(event: MatSlideToggleChange) {
    if (event.checked) {
      this.localgame.chapters = 10;
    } else {
      this.localgame.chapters = 0;

    }
    this.changeChapters.emit(this.localgame.chapters);
  }
}

import {AccountApiActions} from './portal-user.actions';
import {UserState} from './portal-user.state';
import {Action, createReducer, on} from "@ngrx/store";


export const initialState: UserState = {

};

export const reducer = createReducer(
  initialState,
  on(AccountApiActions.setUserAccount, (state, action) => ({
    ...state,
    account: action.account
  }))
);
// }

export function accountReducer(
  state: UserState | undefined,
  action: Action
): UserState {
  return reducer(state, action);
}


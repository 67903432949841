import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from "rxjs";

import {State} from '../../../core/reducers';
import {GameMessagePageActions} from '../../store/game-message.actions';
import {GameMessage} from "../../../game-messages/store/game-messages.state";
import {getEditMessageSelector, selectedColor} from "../../store/game-message.selector";
import {getCurrentGame} from "../../../games-management/store/game.selector";
import {canWriteCurrentGame} from "../../../game-authors/store/game-author.selectors";
import {Game} from "../../../games-management/store/game.state";

@Component({
  selector: 'app-screen-editor-type-picture-question',
  template: `
    <mat-form-field
      class="w-100 mb-3">
      <input matInput [placeholder]="'GAME.TITLE'|translate"
             [disabled]="!(iCanWrite|async)"
             [ngModel]="(message$|async)?.name"
             (ngModelChange)="titleChange($event)">
    </mat-form-field>


    <div class="w-100 mb-3">
      <app-color-input
        [canEdit]="(iCanWrite|async)"
        [label]="'COMMON.PRIMARY_COLOR' |translate"
        [showThemeName]="true"
        [color]="primColor$ | async"
        [unselect]="true"
        (onChange)="primColorChange($event)"
      ></app-color-input>
    </div>

    <div class="w-100 mb-3">
      <app-change-background-button
      ></app-change-background-button>
    </div>

    <mat-form-field [hintLabel]="'MESSAGE.MAX150CHAR' | translate"
                    class="w-100 mb-3">
            <textarea matInput #input
                      [disabled]="!(iCanWrite|async)"
                      [ngModel]="(message$|async)?.richText"
                      (ngModelChange)="textChange($event)"
                      placeholder="{{ 'MESSAGE.CONTENT' | translate }}"
                      maxlength="200"></textarea>
      <mat-hint align="end">{{input.value?.length || 00}}/200</mat-hint>
    </mat-form-field>

    <mat-form-field class="w-100 mb-3">

      <input matInput [placeholder]="'MESSAGE.BUTTON_TEXT'|translate"
             [disabled]="!(iCanWrite|async)"
             [ngModel]="(message$|async)?.description"
             (ngModelChange)="descriptionChange($event)">
    </mat-form-field>

    <app-font-awesome-picker
      *hasOnePermission="['advanced']"
      [disabled]="!(iCanWrite|async)"
      [message]="(message$|async)"
      selected="fas fa-camera"
      (iconChange)="iconChange($event)">
      >
    </app-font-awesome-picker>

    <div class="w-100 mb-3">
      <app-pick-location-on-map

        [locationBased]="(message$|async)?.lat"
        [showInList]="(message$|async)?.showInList"
        [lat]="(message$|async)?.lat"
        [lng]="(message$|async)?.lng"
      >

      </app-pick-location-on-map>
    </div>

    <div
      *ngIf="(game$ | async).chapters !== 0"
      class="w-100 mb-3">
      <app-create-chapter
        (chapter)="game$.chapter = $event"
        [game]="game$ | async"
        [message]="message$ | async"
      ></app-create-chapter>
    </div>

    <app-create-label
      #tooltip="matTooltip"
      [matTooltip]="'GAME.TAGS_TOOLTIP' | translate"
      matTooltipPosition="left"
      matTooltipHideDelay="500"
      class="w-100 mb-2"
      [label]="(message$|async)?.label">

    </app-create-label>
  `
})
export class ScreenEditorTypePictureQuestionComponent {

  primColor$: Observable<string> = this.store.select(selectedColor);
  message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);
  game$: Observable<Game> = this.store.select(getCurrentGame);
  iCanWrite: Observable<boolean> = this.store.select(canWriteCurrentGame);

  // isAdvanced = this.store.select(isAdvanced);

  constructor(private store: Store<State>) {
  }

  titleChange(event: any) {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage: {name: event}}));
  }

  headingChange(event: any) {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage: {heading: event}}));
  }

  descriptionChange(event: any) {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage: {description: event}}));
  }

  iconChange(event: any) {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage: {icon: event}}));
  }

  textChange(event: any) {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage: {richText: event}}));
  }

  primColorChange(color: string) {
    if (color === "default") {
      this.store.dispatch(GameMessagePageActions.clearColor());
    } else {
      this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage: {primaryColor: color}}));
    }
  }


}

import {Component, Input, OnDestroy} from '@angular/core';
import {ActionsSubject, Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {PortalGamesPageActions} from "../../portal-management/store/portal-games.actions";
import {State} from "../../core/reducers";
import {MediaLibraryApiActions, MediaLibraryPageActions} from "../store/media-lib.actions";
import {ofType} from "@ngrx/effects";

@Component({
  selector: 'app-file-upload',
  template: `
    <div class="w-100 h-100 position-relative">
      <div
        class="w-100 h-100"
        *ngIf="!absent">
        <div
          class="w-100 h-100"
          storageBackground
          [targetPath]="targetPath"
          (absent)=" absent = true"
        >
        </div>
        <div *ngIf="showRemove" class="position-absolute close-button">
          <button mat-icon-button color="warn" (click)="deleteImage()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div
        class="w-100 h-100 position-relative"
        *ngIf="absent">
        <div
          class="h-100"
          [targetPath]="targetPath"
          (isDraggingOver)="isDragging=$event"
          appFileDragDrop
        >
          <ng-content *ngIf="!isDragging" select="[nofile]"></ng-content>
          <ng-content *ngIf="isDragging" select="[nofiledrop]"></ng-content>
        </div>

      </div>
    </div>
  `,
  styles: [`
    .close-button {
      top: 10px;
      right: 10px;
      color: white;
      background-color: #FAFAFA;
    }
  `]
})
export class FileUploadComponent implements OnDestroy {
  @Input() targetPath;
  @Input() showRemove = false;
  absent = false;
  isDragging = false;
  private subs = new Subscription();

  constructor(
    private store: Store<State>,
    private actionsSubject$: ActionsSubject) {
    this.subs.add(this.actionsSubject$.pipe(
      ofType(MediaLibraryApiActions.addMedia)
    ).subscribe((action) => {
      if (this.absent) {
        this.absent = false;
      } else {
        this.absent = true;
        setTimeout(() => this.absent = false, 200);
      }

    }));
    //[MediaLibrary] Media results
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  deleteImage() {
    this.store.dispatch(PortalGamesPageActions.deleteFeaturedGameImage({targetPath: this.targetPath}));
    if (this.targetPath.startsWith('/')) {
      this.store.dispatch(MediaLibraryPageActions.unCacheUrl({path: this.targetPath}));
    } else {
      this.store.dispatch(MediaLibraryPageActions.unCacheUrl({path: '/' + this.targetPath}));
    }

  }
}

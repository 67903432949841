import {Component, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Observable} from "rxjs";
import {selectEntities} from 'src/app/game-themes/store/game-theme.selectors';
import {GameTheme} from "../../game-themes/store/game-theme.state";
import {Dictionary} from "@ngrx/entity";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-game-icon',
  template: `
    <div class="icon-outer"
         *ngIf="((iconUrl|async)[game.theme] || (iconUrl|async)['1'])?.iconPath"
    storageBackground [targetPath]="((iconUrl|async)[game.theme] || (iconUrl|async)['1'])?.iconPath"
    >

      <div class="abbr">{{game.iconAbbreviation}}</div>
    </div>
  `,
  styles: [`

    .icon-outer {
      position: relative;
      width: 68px;
      height: 68px;
    }

    .abbr {
      position: absolute;
      color: white;
      bottom: 10px;
      left: 10px;

      font-size: 20px;
      font-weight: 800;
    }
  `]
})
export class GameIconComponent implements OnInit {
  @Input() game: Game;
  iconUrl: Observable<Dictionary<GameTheme>> = this.store.select(selectEntities);

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
  }

}

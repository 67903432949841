import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {GameMessagePageActions} from "../../../../store/game-message.actions";
import {Store} from "@ngrx/store";
import {State} from "../../../../../core/reducers";
import {combineLatest, Observable} from "rxjs";
import {currentGameLabels} from "../../../../../game-messages/store/game-messages.selector";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {map, startWith} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {canWriteCurrentGame} from "../../../../../game-authors/store/game-author.selectors";

export interface Label {
  name: string;
}

@Component({
  selector: 'app-create-label',
  template: `
    <mat-form-field class="mat-form-messages">
      <mat-chip-list #chipList aria-label="Label selection">
        <mat-chip color="accent" selected
                  *ngFor="let label of labels"
                  [selectable]="selectable"
                  [removable]="true"
                  (removed)="remove(label)">
          {{label.name}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input [placeholder]="'GAME.TAGS' | translate"
               #labelInput
               [disabled]="!(iCanWrite|async)"
               [formControl]="inputCtrl"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let label of filteredLabelsAutoComplete | async" [value]="label">
          {{label}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  `,
  styleUrls: ['./create-label.component.css']
})
export class CreateLabelComponent implements OnInit, OnChanges {

  @Input() label: string = '';
  @ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;

  allGameLabels$: Observable<string[]> = this.store.select(currentGameLabels);
  filteredLabelsAutoComplete: Observable<any>;
  inputCtrl = new FormControl();

  labels: Label[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  selectable = true;
  public iCanWrite: Observable<boolean> = this.store.select(canWriteCurrentGame);

  constructor(
    public store: Store<State>
  ) {
    this.filteredLabelsAutoComplete = combineLatest([
      this.allGameLabels$,
      this.inputCtrl.valueChanges.pipe(startWith(null))
    ]).pipe(
      map(comb => {
        const filterValue = comb[1];
        return !filterValue ? comb[0] : comb[0].filter(l => l.toLowerCase().includes(filterValue));
      }),
      map(autoCompleteLabels => autoCompleteLabels.filter(acl => (this.label || '').indexOf(acl) == -1))
    );
  }

  ngOnInit() {
    if (this.label) {
      this.labels = this.label.split(',').map(s => {
        return {name: s};
      });
    }

  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (this.label) {

      this.labels = this.label.split(',').map(s => {
        return {name: s};
      });
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.labels.push({name: value.trim()});
    }

    if (input) {
      input.value = '';
    }
    this.publishLabels();
  }

  remove(label: Label): void {
    const index = this.labels.indexOf(label);

    if (index >= 0) {
      this.labels.splice(index, 1);
    }
    this.publishLabels();
  }

  publishLabels() {
    this.store.dispatch(GameMessagePageActions.gameMessageUpdate({partialMessage:{label: this.labels.map(l => l.name).join(',')}}));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.labels.push({name: event.option.viewValue});
     this.labelInput.nativeElement.value = '';
     this.inputCtrl.setValue(null);
  }


}

import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ColorPickerModalComponent} from "../../../game-messages/modal/color-picker-modal/color-picker-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {mergeAll, Observable, Subscription} from "rxjs";
import {getEditMessageSelector} from "../../../game-message/store/game-message.selector";
import {map, mergeMap} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {getCurrentGame} from "../../../games-management/store/game.selector";
import * as fromThemes from "src/app/game-themes/store/game-theme.selectors"
import {GameTheme} from "../../../game-themes/store/game-theme.state";
import {Game} from "../../../games-management/store/game.state";
@Component({
    selector: 'app-color-input',
    template: `
        <div class="w-100 d-flex flex-column">
            <div class="mt-0 ml-0 gi-edit-field-label">{{label}}</div>
            <div class="d-flex flex-row justify-content-between align-items-end">
              <div class="d-flex flex-row align-items-center">
                <div style="width: 34px; height: 34px">
                  <div class="position-absolute">
                    <div class="outer-circle"></div>
                    <div class="inner-circle"
                         [ngStyle]="{'background': color+' 0% 0% no-repeat padding-box'}"
                         #primButton (click)="openPicker()"></div>
                  </div>
                </div>
                <div class="font-regular-16-19-roboto ml-2">{{color}}</div>

              </div>
              <div
                class="font-medium-12-16-roboto mb-1"
                *ngIf="!(hasCustomColor$ |async) && showThemeName"
              >
                 {{(theme$|async)?.name }}
              </div>
              <button
                (click)="resetColorToDefault()"
                mat-button color="accent"
                *ngIf="(hasCustomColor$ |async) && showThemeName">
                  {{'MESSAGE.RESET_TO' | translate}} {{(theme$|async)?.name }}
              </button>
            </div>
        </div>
    `,
    styles: [`
      .color-rep {
        /*position: absolute;*/
        width: 112px;
        left: 49px;
        bottom: 9px;

        text-align: left;
        font: Regular 14px/20px Roboto;
        letter-spacing: 0;
        color: #000000;
        text-transform: uppercase;
        opacity: 1;
      }

        .primary-color {
            /*position: absolute;*/
            width: 161px;
            height: 59px;
        }

        .outer-circle {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 34px;
            height: 34px;

            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #D3D6D7;
            border-radius: 20px;
            opacity: 1;
        }


        .inner-circle {
            position: absolute;
            top: 6px;
            left: 6px;
            width: 22px;
            height: 22px;
            border-radius: 20px;
            opacity: 1;
        }


    `]
})
export class ColorInputComponent implements OnInit, OnDestroy {
    @ViewChild('primButton') public primButtonRef: ElementRef;

    @Input() color = '#389fff';
    @Input() label;
  @Input() showThemeName = false;
    @Input() canEdit = false;
    @Input() unselect = false;
    @Output() onChange = new EventEmitter<string>();

  hasCustomColor$: Observable<boolean> = this.store.select(getEditMessageSelector).pipe(
    map(m => (m?.primaryColor !== undefined))
  );
  theme$: Observable<GameTheme> = this.store.select(getCurrentGame).pipe(
    mergeMap(game => this.store.select(fromThemes.selectEntities)
      .pipe(
        map(themesDict => themesDict[game.theme || 0])
      ))
  );

    private subscription = new Subscription();

  constructor(private store: Store<State>,
              public dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    openPicker() {
        if (this.canEdit) {
            const dialogRef = this.dialog.open(ColorPickerModalComponent, {
                panelClass: 'white-picker-panel',
                width: '250px',
                data: {
                    positionRelativeToElement: this.primButtonRef,
                    unselect: this.unselect
                }
            });

            const sub = dialogRef.componentInstance.onColorSelect.subscribe((color) => {
                this.color = color;
            });

            this.subscription.add(sub);

            const submitSub = dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    console.log("default ", result.default);
                    if (result.default) {
                        this.onChange.emit("default");
                    } else {
                        this.color = result.color;
                        this.onChange.emit(this.color);
                    }

                }
            });

            this.subscription.add(submitSub);
        }

    }

    resetColorToDefault () {
      this.onChange.emit("default");
    }


}

import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {State} from "../../../core/reducers";
import {GameTheme} from "../../store/game-theme.state";
import {MediaLibraryPageActions} from "../../../media-library/store/media-lib.actions";

@Component({
  selector: 'app-create-theme-settings',
  template: `
    <div class="maxwidth pos-top">
      <app-modal-esc-button
          class="gl-pos-modal-esc-button"
          [type]="'esc'" (buttonClick)="onNoClick()"></app-modal-esc-button>
      <app-modal-esc-button
          class="gl-pos-modal-back-button"
          [type]="'back'" (buttonClick)="onNoClick()"></app-modal-esc-button>
    </div>
    <div class="maxwidth">
      <div class="pos-title primary-color font-medium-32-43-roboto">{{ 'THEME.MAKE_YOUR_THEME' | translate }}</div>
      <div class="theme-panel" *ngIf="theme?.themeId">
        <div class="form">
          <div class="theme-icon">
            <div>
              <label>{{'COMMON.ICON_IMAGE'|translate}}</label>
            </div>
            <app-theme-file-picker
                [path]="theme.iconPath"
                [small]="true"
                (onUpload)="downloadedImages['icon'] = true"
                (onFailure)="downloadedImages['icon'] = false"
            ></app-theme-file-picker>
          </div>

          <div class="primary-color">
            <app-color-input
                [label]="'COMMON.PRIMARY_COLOR'|translate"
                [color]="theme.primaryColor"
                [canEdit]="true"
                (onChange)="theme.primaryColor = $event"
            ></app-color-input>
          </div>
        </div>

        <div class="uploader">

          <app-theme-file-picker
              [path]="theme.backgroundPath"
              title="{{'THEME.CHOOSE_BACKGROUND' | translate}}"
              description="{{'THEME.CHOOSE_BACKGROUND_DESC' | translate}}"
              (onUpload)="downloadedImages['background'] = true"
              (onFailure)="downloadedImages['background'] = false"
          ></app-theme-file-picker>
        </div>

        <div class="uploader">
          <app-theme-file-picker
              [path]="theme.correctPath"
              description="{{'THEME.BACKGROUND_CORRECT' | translate}}"
              title="{{'MESSAGE.CORRECT_ANSWER' | translate}}"
              (onUpload)="downloadedImages['correct'] = true"
              (onFailure)="downloadedImages['correct'] = false"
          ></app-theme-file-picker>
        </div>

        <div class="uploader">
          <app-theme-file-picker
              [path]="theme.wrongPath"
              description="{{'THEME.BACKGROUND_WRONG' | translate}}"
              title="{{'MESSAGE.WRONG_ANSWER' | translate}}"
              (onUpload)="downloadedImages['wrong'] = true"
              (onFailure)="downloadedImages['wrong'] = false"
          ></app-theme-file-picker>
        </div>
      </div>

      <mat-toolbar class="theme-toolbar">
        <div class="maxwidth theme-toolbar-wrapper">
          <div class="theme-info">
            {{'PORTAL_MANAGEMENT.IMAGES.UPLOAD' | translate}}
          </div>
          <div>
            <button mat-button color="primary" (click)="onNoClick()">{{'RUNS.BACK' | translate}}</button>
            <button mat-flat-button color="primary" (click)="onSubmit()" >{{'RUNS.NEXT' | translate}}</button>
          </div>
        </div>
      </mat-toolbar>

    </div>
  `,
  styles: [`
    .pos-top {
      height: 1px;
    }
    .pos-title {
      position: relative;
      margin-top: 83px;
      height: 38px;
      text-align: center;
    }
    .theme-panel {
      display: flex;
      align-items: flex-start;
      margin-top: 3rem;
    }
    .theme-panel .form {
      margin-right: 4rem;
    }

    .uploader {
      margin: 0 10px;
      width: 263px;
      height: 418px;
    }

    .theme-icon {
      position: relative;
    }

    .theme-icon label {
      color: rgba(0, 0, 0, 0.4);
    }
    .icon-input, .primary-color {
      margin-top: 3rem;
    }
    ::ng-deep .theme-panel .theme-preview .preview-outer-pane {
      top: 0;
    }

    .theme-toolbar {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #ffffff;
    }

    .theme-toolbar-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .theme-toolbar .image {
      height: 100%;
      max-width: 40px;
      margin-right: 20px;
    }

    .theme-toolbar .theme-info {
      display: flex;
      align-items: center;
    }
  `]
})
export class CreateThemeSettingsComponent implements OnDestroy {
  public theme: GameTheme;

  constructor(public dialogRef: MatDialogRef<CreateThemeSettingsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public store: Store<State>) {
    this.theme = data.theme;
  }

  public downloadedImages = {
    icon: false,
    background: false,
    correct: false,
    wrong: false,
  }


  ngOnDestroy() {
    // this.store.dispatch(new ResetGameMessageEditAction());
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    console.log('issue close');
    this.dialogRef.close({theme: this.theme});
  }

  handleUploadFile() {
    this.store.dispatch(MediaLibraryPageActions.startUpload());
  }
}

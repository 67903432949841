import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromRoot from 'src/app/core/reducers';
import {Player} from './player.state';
import {UserState} from '../../user-management/store/portal-user.state';
import {allAccountsAdapter, PlayerState} from "./player.reducer";

export interface State extends fromRoot.State {
  accounts: PlayerState;
  user: UserState;
}

export const getPlayersFeature = createFeatureSelector<PlayerState>('accounts');
export const _getPortalUserFeature = createFeatureSelector<UserState>('user');

// const _invitationExpired = (state: PlayerState) => state.invitation != null &&
//   state.invitation.invitedBy != null &&
//   state.invitation.invitedBy.error != null;
// const _invitationBy = (state: PlayerState) => (state.invitation == null || state.invitation.invitedBy == null) ? '...' : state.invitation.invitedBy.name;

// export const invitationExpired = createSelector(getPlayersFeature, _invitationExpired);
// export const invitationBy = createSelector(getPlayersFeature, _invitationBy);

export const getAllAccounts = createSelector(getPlayersFeature, (state) => state);
export const getAllPending = createSelector(getPlayersFeature, (state: PlayerState) => state.pendingInvitations);
export const getAllPendingToMe = createSelector(getPlayersFeature, (state: PlayerState) => state.pendingInvitationsToMe);
export const getFiltersSelector = createSelector(getPlayersFeature, (state: PlayerState) => state.filter);
// export const getInvitationId = createSelector(getPlayersFeature, (state: PlayerState) => state.invitation ? state.invitation.invitationId : null);
export const getPortalUser = createSelector(_getPortalUserFeature, (state: UserState) => state.account);
// export const getLoadingComplete = createSelector(getPlayersFeature, (state: PlayerState) => state.loadingComplete);
export const getInSyncTill = createSelector(getPlayersFeature, (state: PlayerState) => state.lastSyncTime);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = allAccountsAdapter.getSelectors(getAllAccounts);

export const getConnectionAccounts = createSelector(selectAll, (accounts) =>
  accounts.filter(account => account.isConnection)
)

export const getAllPlayersAndMySelf = createSelector(getConnectionAccounts, getPortalUser, (list, me) => {
  if (!list) {
    list = [];
  }
  return !me ? list : [...list, me];
});

export const getFilteredPlayersSelector = createSelector(getConnectionAccounts, getFiltersSelector,
  (players: Player[], filters: string[]) => players.filter((player: Player) => {
    if (!filters) {
      return true;
    }
    for (let i = 0; i < filters.length; i++) {
      if (!player.name) {
        player.name = '';
      }
      if ((player.name.toUpperCase().indexOf(filters[0].toUpperCase()) === -1)
        &&
        ((player.email || '').indexOf(filters[0]) === -1)) {
        return false;
      }
    }
    return true;

  })
);

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {GameCategory, GameCategoryMapping} from "./game-category.state";
import {map, tap} from "rxjs/operators";


@Injectable({
  providedIn: 'root',
})
export class GameCategoryService {

  constructor(private http: HttpClient) {
  }

  allCategories(): Observable<GameCategory[]> {
    return this.http
      .get<GameCategory[]>(environment.api_url +`/games/library/category/nl`).pipe(
        map((object:any) => object.categoryList)
      )
  }

  ///games/library/categories/game/{gameId}/{lang}
  getCategoriesForGame(gameId: any): Observable<GameCategoryMapping[]> {
    return this.http
      .get<GameCategoryMapping[]>(environment.api_url +`/games/library/categoriesMappings/game/`+gameId).pipe(
        map((object:any) => object.gameCategoryList||[])
      )
  }

  deleteMappingId(id: any): Observable<GameCategoryMapping> {
    return this.http
      .delete<GameCategoryMapping>(environment.api_url +`/games/library/categoriesMappings/game/`+id)
  }
}

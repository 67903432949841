import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../../core/reducers';
import {GameMessagesPageActions} from '../../store/game-messages.actions';
import {Observable, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {currentGameLabels, getFilteredMessagesSelector, getFiltersSelector} from "../../store/game-messages.selector";
import {take} from "rxjs/operators";
import {GamePageActions} from "../../../games-management/store/game.actions";
import {environment} from "../../../../environments/environment";
import {selectEntities} from "../../../game-themes/store/game-theme.selectors";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {getCurrentGame} from "../../../games-management/store/game.selector";
import {GameAuthorActions} from "../../../game-authors/store/game-author.actions";
import {canWriteCurrentGame} from "../../../game-authors/store/game-author.selectors";
import {MessageFilter} from "../../store/game-messages.state";
import {Game} from "../../../games-management/store/game.state";

@Component({
  selector: 'app-game-detail-screens',
  template: `
    <app-game-detail-navbar>
    </app-game-detail-navbar>

    <div class="full-width-container">
      <div class="maxwidth">
        <div class="bibendo-center-outer">

          <app-message-search-bar></app-message-search-bar>
          <app-display-filter></app-display-filter>
          <!--          <app-search-button-->
          <!--            [placeholder]="'MESSAGE.START_TYPING_TO_SEARCH' | translate"-->
          <!--            [dispatchActionFunction]="dispatchActionFunction"-->
          <!--            [filter]="filter"-->
          <!--            [suggestLabels]="allGameLabels$ | async"-->
          <!--          >-->
          <!--          </app-search-button>-->
          <div class="bibendo-center">
            <app-new-screen-tile
            ></app-new-screen-tile>
<!--            <div *ngFor="let message of (messages$|async)">-->
<!--              {{message.name}}-->
<!--            </div>-->
            <app-screen-tile class="tile-dimensions m-2"
                             *ngFor="let message of (messages$|async)"
                             [title]="message?.name || 'test'"
                             [isVideo]="message.type === 'org.celstec.arlearn2.beans.generalItem.VideoObject'"
                             [subtitle]="message.label"
                             [imagePath]="message?.fileReferences?.background || themes[(game$ | async)?.theme]?.backgroundPath"
                             [themeId]="(game$ | async)?.theme"
                             [videoPath]="message?.fileReferences ?message?.fileReferences['video']:null"
                             [actionText]="['MESSAGE.DELETEMESSAGE', 'MESSAGE.COPYMESSAGE']"
                             [actionIcon]="{'MESSAGE.DELETEMESSAGE': 'delete_forever', 'MESSAGE.COPYMESSAGE': 'content_copy' }"
                             [clickText]="'MESSAGE.EDIT_MESSAGE' "
                             [navTo]="'message/'+message.id+'/detail'"
                             [icon]="message.icon || message.type"
                             [displayAsset]="displayAsset(message)"
                             (actionClicked)="deleteScreen(message, $event)"></app-screen-tile>

          </div>

          <!--          [navTo]="'/portal/'+message.gameId+'/message/'+message.id+'/detail'"-->
        </div>

      </div>
    </div>

  `
})
export class GameDetailScreensComponent implements OnInit, OnDestroy {
  public game$: Observable<Game> = this.store.select(getCurrentGame);
  public messages$: Observable<any> = this.store.select(getFilteredMessagesSelector);
  public currentFilter$: Observable<MessageFilter> = this.store.select(getFiltersSelector).pipe(
    take(1),
  );
  allGameLabels$: Observable<string[]> = this.store.select(currentGameLabels);
  public themes = {};

  // public dispatchAction = new SetFilterAction();
  public dispatchActionFunction = (filters: string[]) => {
    this.store.dispatch(GameMessagesPageActions.setFilter({filters}));
  }
  private filterSubscription: Subscription;
  public filter: string;
  public iCanWrite: Observable<boolean> = this.store.select(canWriteCurrentGame);
  public subscription: Subscription;

  constructor(
    public dialog: MatDialog,
    public store: Store<State>,
    public gaService: GoogleAnalyticsService
  ) {
  }

  ngOnInit() {
    if (environment.gatracking !== '') {
      this.gaService.event('OPEN_GAME', 'GAME');
    }

    this.store.dispatch(GameMessagesPageActions.getMessages({}));
    this.store.dispatch(GamePageActions.getGame({}));
    this.store.dispatch(GameAuthorActions.loadGameAuthors());
    // this.store.dispatch(new LoadGameAuthorRequestAction())


    this.filterSubscription = this.currentFilter$.subscribe((f) => this.filter = f.filters[0]);
    this.subscription = this.store.select(selectEntities)
      .subscribe(themes => this.themes = themes);
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

  deleteScreen(message, action) {
    if (action === "MESSAGE.DELETEMESSAGE") {
      this.store.dispatch(GameMessagesPageActions.deleteMessage({messageId: message.id}));
    } else if (action === 'MESSAGE.COPYMESSAGE') {
      const gameMessage = {...message, id: undefined, name: message.name + ' (kopie)'}
      this.store.dispatch(GameMessagesPageActions.newMessage({gameMessage}));
    }
  }

  // getIcon(message) {
  //   return message.icon || message.type;
  //   let icon = message.type.substr(message.type.lastIndexOf('.') + 1);
  //   return environment.messageTypeToIcon[message.type.substr(message.type.lastIndexOf('.') + 1)];
  //
  // }

  displayAsset(message) {
    if (message.type === 'org.celstec.arlearn2.beans.generalItem.ScanTag') {
      return '/assets/img/scantag/ScanTag2.png';
    }
    return '';
  }
}

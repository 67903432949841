import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../../../core/reducers";
import {environment} from "../../../../../environments/environment";
import {GpsPosition} from "../../../../game-message/component/screen-editor-type-select/component/pick-location-on-map/pick-location-on-map.component";

import {filter, first} from "rxjs/operators";
import {getCurrentGame} from "../../../../games-management/store/game.selector";
import {ownsCurrentGame} from "../../../../game-authors/store/game-author.selectors";
import {Game} from "../../../../games-management/store/game.state";


@Component({
    selector: 'app-game-detail-location',
    template: `
        <div>
            <div class="location-based">{{'GAME.LOCATION_BASED'|translate}}</div>
            <mat-slide-toggle
                    [disabled]="!(iAmOwner|async)"
                    [(ngModel)]="locationBased"
                    (change)="mapAvailable.emit($event)">{{'COMMON.ACTIVE' |translate}}
            </mat-slide-toggle>
            <div *ngIf="locationBased" class="map-area">

                <agm-map class="map"
                         [latitude]="lat"
                         [longitude]="lng">
                    <agm-marker
                            [markerDraggable]="iAmOwner|async"
                            [markerClickable]="false"
                            [latitude]="lat"
                            [longitude]="lng"
                            (dragEnd)="newLocation($event)"
                    >

                    </agm-marker>
                </agm-map>
            </div>
        </div>

    `,
    styles: [`
        .location-based {
            margin-top: 17px;
            margin-bottom: 15px;
            text-align: left;
            font: 100 12px/16px Roboto;
            letter-spacing: 0;
            color: #0000008A;
            opacity: 1;
        }

        .map-area {
            margin-top: 26px;
            width: 100%;
            height: 297px;
            background: transparent 0% 0% no-repeat padding-box;
            border: 4px solid #FFFFFF;
            opacity: 1;
        }

        .map {
            width: 100%;
            height: 100%;
            position: relative;
        }

    `]
})
export class GameDetailLocationComponent implements OnInit {

    @Input() locationBased = false;
    @Output() mapAvailable = new EventEmitter();
    @Output() locationUpdate = new EventEmitter<{ lat: number;lng: number }>();

    public iAmOwner: Observable<boolean> = this.store.select(ownsCurrentGame);

    public game$: Observable<Game> = this.store.select(getCurrentGame).pipe(filter(g => g != null));

    lat = environment.homeLocation.latitude;
    lng = environment.homeLocation.longitude;

    constructor(public store: Store<State>) {
    }

    ngOnInit() {

        if (!this.lat) {
            this.lat = environment.homeLocation.latitude;
        }
        if (!this.lng) {
            this.lng = environment.homeLocation.longitude;
        }
        this.game$.pipe(first()).toPromise().then((g) => {
            if (g.config.mapAvailable && g.lat && g.lng) {
                this.lat = g.lat;
                this.lng = g.lng;
            }
            this.locationBased = g.config.mapAvailable;

        });
    }


    newLocation($event: GpsPosition) {

        this.locationUpdate.emit({
          'lat': $event.coords.lat,
          'lng': $event.coords.lng,
        })
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from "@ngrx/store";

import {EffectsModule} from "@ngrx/effects";
import {RunAuthorEffectsLoadAuthors} from "./store/run-author.effects.load-authors";
import {RunAuthorEffects} from "./store/run-author.effects";
import {runAuthorReducer} from "./store/run-author.reducer";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('run-authors', runAuthorReducer),
    EffectsModule.forFeature([RunAuthorEffects, RunAuthorEffectsLoadAuthors]),
  ]
})
export class RunAuthorsModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgrxAuthEffects} from "./store/ngrx-auth.effects";
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from "@ngrx/store";
import {reducer} from "./store/ngrx-auth.reducer";
import {AuthGuard} from "./guards/auth.guard";
import {HasPermissionDirective} from "./directive/hasPermission.directive";
import {HasPermissionOrDirective} from "./directive/hasPermissionOr.directive";
import {AdminAuthGuard} from "./guards/admin-auth.guard";
import {PermissionFilterPipe} from "./directive/permissionFilter.pipe";


@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([NgrxAuthEffects]),
    StoreModule.forFeature('auth2', reducer)
  ],
  declarations: [HasPermissionDirective, HasPermissionOrDirective, PermissionFilterPipe],
  exports: [HasPermissionDirective, HasPermissionOrDirective, PermissionFilterPipe],
  providers: [
    AuthGuard, AdminAuthGuard
  ]
})
export class NgrxAuthModule { }

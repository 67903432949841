import {createFeatureSelector, createSelector, MemoizedSelector} from "@ngrx/store";
import {AuthState, State} from "./ngrx-auth.state";



export const getAuthState: MemoizedSelector<object, State> = createFeatureSelector<State>('auth2');


export const isAuthLoading = createSelector(
  getAuthState,
  state => state.loading
);

export const isUserLogged = createSelector(
  getAuthState,
  state => state.loggedIn && state.user
);

export const getUser = createSelector(
  getAuthState,
  state => state.user
);

import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {RunResponse} from './run-responses.state';


export const RunResponsesPageActions = createActionGroup({
  source: 'Run Response Page',
  events: {
    'Query Message Responses':  emptyProps(),
    'Query Message Responses with cursor':  props<{ fromTime: number, cursor: string }>(),
    // 'Load':  emptyProps(),
  },
});

export const RunResponsesApiActions = createActionGroup({
  source: 'Run Response Api',
  events: {
    'Add One':  props<{ response: RunResponse }>(), // not used delete?
    'Clear':  emptyProps(),
    'Add All':  props<{ responses: RunResponse[], serverTime?: number, resumptionToken?: string, }>(),
    'Toggle Selected User':  props<{ fullId: string }>(),
  },
});

import {Injectable} from "@angular/core";
import {GameServiceNew} from "./game.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {AuthActionTypes} from "../../ngrx-auth/store/ngrx-auth.actions";
import {catchError, filter, map, mergeMap, withLatestFrom} from "rxjs/operators";
import {getInSyncTill} from "./game.selector";
import {GameApiActions} from "./game.actions";
import {of} from "rxjs";
import {SetErrorAction} from "../../shared/store/shared.actions";


@Injectable()
export class GameEffectsLoadGames {
  constructor(
    private gameServiceNew: GameServiceNew,
    private actions$: Actions,
    private store$: Store<State>
  ) {
  }

  getGames$ = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.Authenticated, GameApiActions.createGameSuccess),
      withLatestFrom(
        this.store$.select(getInSyncTill)
      ),
      mergeMap(([action, inSyncTill]: [any, number]) =>
        this.gameServiceNew.listAll(inSyncTill).pipe(
          map(gameList => GameApiActions.getGameListSuccess({gameList})),
          catchError((error) => of(new SetErrorAction(error.error)))
        ),
      )
    )
  );


  resumeGetGames = createEffect(
    () => this.actions$.pipe(
      ofType(GameApiActions.getGameListSuccess),
      filter((action) => !!action &&
        !!action.gameList && !!action.gameList.resumptionToken && (action.gameList.games || []).length != 0),
      mergeMap(
        (action) => this.gameServiceNew.listAll(action.gameList.from, action.gameList.resumptionToken).pipe(
          map(gameList => GameApiActions.getGameListSuccess({gameList})),
          catchError((error) => of(new SetErrorAction(error.error)))
        ),
      )
    )
  )

  updateInSyncTill = createEffect(
    () => this.actions$.pipe(
      ofType(GameApiActions.getGameListSuccess),
      filter((action) => !!action &&
        !!action.gameList && (!action.gameList.resumptionToken || (action.gameList.games || []).length == 0)),
      map(action => GameApiActions.updateLastFullSync({time:action.gameList.serverTime - 120000}))
    )
  )

}

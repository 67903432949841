import {Component, OnInit} from '@angular/core';
// import {getCurrentMessageSelector} from '../../../game-messages/store/game-messages.selector';
import {Store} from '@ngrx/store';
import {State} from '../../../core/reducers';
import {Observable} from 'rxjs';
import {GameMessage} from '../../../game-messages/store/game-messages.state';
import {getEditMessageSelector} from "../../store/game-message.selector";

@Component({
    selector: 'app-screen-editor-navbar',
    template: `
        <div class="header primary-background-color">
            <div class="maxwidth">

                <div class="title font-medium-32-43-roboto">
                    {{(message$ |async)?.name}}
                </div>
                <div class="date">
                    {{'COMMON.LAST_MODIFICATION' | translate}}: {{(message$ |async)?.lastModificationDate | date:'mediumDate'}}
                </div>
                <ng-content></ng-content>

            </div>
        </div>
    `
})
export class ScreenEditorNavbarComponent implements OnInit {

    message$: Observable<GameMessage> = this.store.select(getEditMessageSelector);

    constructor(
        private store: Store<State>
    ) {
    }

    ngOnInit() {
    }

}

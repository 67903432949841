import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../core/reducers';
import {Observable} from "rxjs";
import * as fromSel from '../store/arlearn-actions.selectors';
import {MatTableDataSource} from "@angular/material/table";
import {ARLearnAction} from "../store/arlearn-actions.state";
import {selectEntities} from "../../game-messages/store/game-messages.selector";
import {GameMessagesPageActions} from "../../game-messages/store/game-messages.actions";

@Component({
    selector: 'app-arlearn-actions-table',
    template: `
      <div
        class="arl-table w-100">
            <table class="arl-table pos-full-width" mat-table [dataSource]="dataSource" >
                <!-- Position Column -->

                <ng-container matColumnDef="identifier">
                    <mat-header-cell *matHeaderCellDef> Id.</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.identifier}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef> action.</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.action}} </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="generalItemId">
                    <mat-header-cell *matHeaderCellDef> generalItemId</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.generalItemId}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="messageName">
                    <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <div *ngIf="messages$ | async as messages">
                        {{messages[element.generalItemId]?.name}}
                      </div>
                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </table>
        </div>


    `,
    styles: [`
        .pos-full-width {
            position: relative;
            width: 900px;
        }
    `]
})
export class ArlearnActionsTableComponent implements OnInit {
    public messages$: Observable<any> = this.store.select(selectEntities);
    displayedColumns = ['identifier', 'action', 'generalItemId',  'messageName'];
    arlearnActions$: Observable<any> = this.store.select(fromSel.selectAll);

    dataSource: MatTableDataSource<ARLearnAction>;

    constructor(private store: Store<State>) {}

    ngOnInit(): void {
        this.arlearnActions$.subscribe((arlearnActions) => {
            this.dataSource = new MatTableDataSource(arlearnActions);

        });
      this.store.dispatch(GameMessagesPageActions.getMessages({}));
    }

}

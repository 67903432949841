import {RunPlayersPageComponent} from "./pages/run-players-page.component";
import {RunSettingsPageComponent} from "./pages/run-settings-page.component";

import {ActionsOverviewComponent} from "../run-actions/pages/actions-overview.component";
import {ResponsesOverviewComponent} from "../run-responses/pages/responses-overview.component";
import {AuthGuard} from "../ngrx-auth/guards/auth.guard";
import {RunTopNavComponentComponent} from "./components/run-top-nav-component.component";
import {RunCollaboratorsPageComponent} from "./pages/run-collaborators-page.component";
import {ResultsPageLoadFirstResultComponent} from "../run-responses/pages/results-page-load-first-result.component";

export const gameRunRoutes = {
  path: 'game', canActivate: [AuthGuard],
  component: RunTopNavComponentComponent,
  children: [
    {path: ':gameId/detail/runs/:runId',
      children: [
        {path: 'players', component: RunPlayersPageComponent, pathMatch: 'full'},
        {path: 'results/:messageId', component: ResponsesOverviewComponent, pathMatch: 'full'},
        {path: 'results', component: ResultsPageLoadFirstResultComponent, pathMatch: 'full'},

        {path: 'collaborators', component: RunCollaboratorsPageComponent, pathMatch: 'full'},
        {path: 'settings', component: RunSettingsPageComponent, pathMatch: 'full'},
        {path: 'actions', component: ActionsOverviewComponent, pathMatch: 'full'},

        {path: '**', redirectTo: 'players'},
        ]
    }
  ]
};

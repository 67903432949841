import {Injectable} from '@angular/core';
import {forkJoin, from, Observable} from 'rxjs';
import {Store} from "@ngrx/store";
import {State} from "../reducers";
import {deleteObject, percentage, ref, Storage, updateMetadata, uploadBytesResumable} from '@angular/fire/storage';
import {FullMetadata, getDownloadURL, getMetadata, listAll, UploadTask} from "@firebase/storage";
import {map} from "rxjs/operators";
import {MediaLibraryApiActions, MediaLibraryPageActions} from "../../media-library/store/media-lib.actions";

@Injectable()
export class MediaLibraryService {

  constructor(
    public store$: Store<State>,
    private storage: Storage) {
  }

  getGameFiles(gameId: string, path): Observable<{folders: string[], files: string[]}> {
    return from(listAll(ref(this.storage, `game/${gameId}${path}`)))
      .pipe(map(res => {
        const returnObject: {folders: string[], files: string[]} = {folders: [], files: []};
        res.prefixes.forEach(function (folderRef) {
          returnObject.folders.push(folderRef.name);
        });
        res.items.forEach(function (itemRef) {
          returnObject.files.push(itemRef.name);
        });
        return returnObject;
      }));
  }

  getFiles(path = null): Observable<any> {


    let stream$: Observable<any>;

    if (path) {
      // this.afStorage.ref('mediaLibrary').child(path);
      // stream$ = from(this.afStorage.ref('mediaLibrary').child(path).listAll());
      stream$ = from(listAll(ref(this.storage, `mediaLibrary/${path}`)))
    } else {
      // stream$ = from(this.afStorage.ref('mediaLibrary').listAll());
      stream$ = from(listAll(ref(this.storage, `mediaLibrary`)))
    }

    return stream$.pipe(map(res => {
      const returnObject = {folder: [], items: []};

      res.prefixes.forEach(function (folderRef) {
        returnObject.folder.push({name: folderRef.name, path: folderRef.fullPath});
      });

      res.items.forEach(async function (itemRef) {
        if (itemRef.name && itemRef.name !== 'removeme.txt') {
          // const meta = (await itemRef.getMetadata()).customMetadata;
          returnObject.items.push({
            name: itemRef.name, path: itemRef.fullPath,
          });
        }
      });

      return returnObject;
    }));
  }

  updateFileMetadata(file: { path: string, assetId: string }): Observable<any> {
    return from(updateMetadata(ref(this.storage, file.path), {customMetadata: {assetId: file.assetId}}));
  }

  deleteFiles(files: string[]): Observable<any[]> {
    return undefined;
    // const batch = files.map((path) => {
    //   return from(this.afStorage.ref(path).delete());
    // });
    // return forkJoin(batch);
  }

  async deleteFolder(folder: string) {
    return this.deleteRecursively(ref(this.storage, folder));
  }


  createFolder(path) {
    return uploadBytesResumable(
      ref(this.storage, `/${path}/removeme.txt`),
      new Blob([], {type: 'plain/text'})
    );
  }

  private async deleteRecursively(listRef) {
    const data = await listAll(listRef);
    for (const storageElement of data.prefixes) {
      await this.deleteRecursively(storageElement);
    }
    for (const item of data.items) {
      await deleteObject(item);
    }
  }

  deleteGameFiles(gameId: string, files: string[]): Observable<any[]> {
    return forkJoin(files.map(
      (path) => from(deleteObject(ref(this.storage, `game/${gameId}/${path}`)))
    ));
  }

  upload(file: File, path: string) {
    const storageRef = ref(this.storage, path);
    const task: UploadTask = uploadBytesResumable(storageRef, file);
    percentage(task).subscribe((value) => {
      this.store$.dispatch(MediaLibraryApiActions.setUploadProgress({
        filename: file.name,
        value: value.progress,
        completed: (value.progress >= 100)
      }));
      if (value.progress >= 100) {
        this.store$.dispatch(MediaLibraryPageActions.startUpload());
        this.store$.dispatch(MediaLibraryPageActions.downloadMetadata({path: "/" + path, ignoreDistinct: true}))
        setTimeout(() => {
          this.store$.dispatch( MediaLibraryPageActions.getFolders());
        }, 1000);

      }
    });
    // const ref = this.afStorage.ref(path);
    // const task: AngularFireUploadTask = this.afStorage.upload(path, file);
    // return task.percentageChanges().subscribe((value: number) => {
    //   this.store$.dispatch(new UpdateUpload({
    //     filename: file.name,
    //     value: value,
    //     completed: (value >= 100)
    //   }));
    //   if (value >= 100) {
    //     this.store$.dispatch(new StartUploadAction());
    //     this.store$.dispatch(new DownloadMetadata({fullPath: "/"+path}, true))
    //     setTimeout(() => {
    //       this.store$.dispatch(new GetFolderListRequestAction());
    //     }, 1000);
    //
    //   }
    // });
  }

  queryMetadata(filePath: string): Observable<FullMetadata> {
    return from(getMetadata(ref(this.storage, filePath)));
  }

  getDownloadUrl(filePath: string): Observable<string> {
    return from(getDownloadURL(ref(this.storage, filePath)));
  }
}

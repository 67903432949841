import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {AuthActionTypes} from "../../ngrx-auth/store/ngrx-auth.actions";
import {delay, map, mergeMap, withLatestFrom} from "rxjs/operators";
import * as fromRuns from "./game-runs.selector";
import {GameRunsService} from "./game-runs.service";
import {GameRunsPageActions} from "./game-runs.actions";
import * as fromGames from "src/app/games-management/store/game.selector"
import * as fromPlayers from "src/app/run-players/store/run-players.selectors"
import * as fromAuthors from "src/app/run-authors/store/run-author.selectors"
import {Dictionary} from "@ngrx/entity";
import {GameRun, RunUser} from "./game-runs.state";
import {GamePageActions} from "../../games-management/store/game.actions";
import {RunAuthor} from "../../run-authors/store/run-author.state";
import {Game} from "../../games-management/store/game.state";

@Injectable()
export class GameRunsEffectsLoadUsers {
  constructor(
    private runService: GameRunsService,
    private actions$: Actions,
    private store$: Store<State>
  ) {
  }


  loadCorrespondingGamesPlayers = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.Authenticated),
      delay(2000),
      withLatestFrom(
        this.store$.select(fromGames.selectEntities),
        this.store$.select(fromPlayers.selectAll),
      ),
      mergeMap(([action, games, users]: [any, Dictionary<Game>, RunUser[]]) => {
        const gameIds = Array.from(new Set(users.filter(u => u && !u.deleted).map(u => u.gameId)));
        return gameIds.filter(id => !games[id])
      }),
      map(gameId => GamePageActions.getGame({gameId: gameId + ''}))
    )
  )

  loadCorrespondingGamesRunAuthors = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.Authenticated),
      delay(3000),
      withLatestFrom(
        this.store$.select(fromGames.selectEntities),
        this.store$.select(fromAuthors.selectAll),
      ),
      mergeMap(([action, games, users]: [any, Dictionary<Game>, RunAuthor[]]) => {
        const gameIds = Array.from(new Set(users.filter(u => u && (u.accessRights >= 0)).map(u => u.gameId)));
        return gameIds.filter(id => !games[id] || games[id].deleted)
      }),
      map(gameId => GamePageActions.getGame({gameId: gameId + ''}))
    )
  )

  loadCorrespondingRuns = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.Authenticated),
      delay(3000),
      withLatestFrom(
        this.store$.select(fromRuns.selectEntities),
        this.store$.select(fromPlayers.selectAll),
      ),
      mergeMap(([action, runs, users]: [any, Dictionary<GameRun>, RunUser[]]) => {

        const runIds = Array.from(new Set(users.filter(u => u && !u.deleted).map(u => u.runId)));
        return runIds.filter(id => !runs[id])
      }),
      map(runId => GameRunsPageActions.getRun({runId: runId + ''}))
    )
  )
}

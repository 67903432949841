import * as actions from './game.actions';
import {Game, GameState} from './game.state';
import {AuthActionTypes} from 'src/app/ngrx-auth/store/ngrx-auth.actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from "@ngrx/store";
import {GameApiActions, GamePageActions} from "./game.actions";

export interface GameEntityState extends EntityState<Game> {
}

export function selectIdentifier(a: Game): number {
  return a.gameId;
}

export const gameAdapter = createEntityAdapter<Game>(
  {selectId: selectIdentifier}
);

const gameInitialState: GameState = {
  games: gameAdapter.getInitialState(),
  tempGames: gameAdapter.getInitialState(),
  lastSyncTime: -1,
  lastSyncTimeParticipate: -1,
  loading: false,
  filter: []
};


export const reducer = createReducer(
  gameInitialState,
  on(GamePageActions.cloneGame, (state, action) => ({...state,
    tempGames: gameAdapter.upsertOne({...action.game, title:action.game.title+ ' (copy)'}, state.tempGames || gameAdapter.getInitialState())
  })),
  on(GamePageActions.createGame, (state, action) => ({...state,
    tempGames: gameAdapter.upsertOne(action.game, state.tempGames || gameAdapter.getInitialState())
  })),
  on(GameApiActions.updateLastFullSync, (state, action) => ({...state, lastSyncTime: action.time})),
  on(GameApiActions.getGameListSuccess, (state, action) => ({...state,
    games: gameAdapter.upsertMany(action.gameList.games || [], state.games)
  })),
  on(GameApiActions.createGameSuccess, (state, action) => ({...state,
    games: gameAdapter.upsertOne(action.game, state.games),
    tempGames: gameAdapter.removeOne(action.tempGame.gameId, state.tempGames|| gameAdapter.getInitialState())
  })),
  on(GameApiActions.saveGameSuccess, (state, action) => ({...state,
    games: gameAdapter.upsertOne(action.game, state.games)
  })),
  on(GameApiActions.deleteGameSuccess, (state, action) => ({...state,
    games: gameAdapter.removeOne(action.game.gameId, state.games)
  })),
  on(GameApiActions.getGameSuccess, (state, action) => {
    if (action.game.deleted) {
      return {...state, games: gameAdapter.removeOne(action.game.gameId, state.games)};
    }
    return {...state,
      games: gameAdapter.upsertOne({...action.game, lastLocalModificationDate: Date.now()}, state.games)
    }
  }),
  on(GamePageActions.setFilter, (state, action) => ({...state,
    filter: action.filters
  })),
)

export function gamesReducer(
  state: GameState | undefined,
  action: Action
): GameState {
  return reducer(state, action);
}


//
// export function Oldreducers(
//   state = gameInitialState, action: actions.GameAction): GameState {
//   switch (action.type) {
//
//     case AuthActionTypes.Logout : {
//       return {...gameInitialState};
//     }
//
//
//     default: {
//       return state;
//     }
//   }
// }

import * as fromRoot from "../../core/reducers";
import {createFeatureSelector, createSelector} from "@ngrx/store";

import {gameAuthorAdapter, GameAuthorState} from "./game-author.reducer";
import {getUser} from "../../ngrx-auth/store/ngrx-auth.selectors";
import {gameIdSelector} from "../../core/selectors/router.selector";

export interface State extends fromRoot.State {
  game: GameAuthorState;
}

export const getGameAuthorsFeature = createFeatureSelector<GameAuthorState>('game-authors');
export const getGameAuthor = createSelector(getGameAuthorsFeature, (state: GameAuthorState) => state);
export const getInSyncTill = createSelector(getGameAuthorsFeature, (state: GameAuthorState) => state.lastSyncrTime);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = gameAuthorAdapter.getSelectors(getGameAuthor);

export const currentGameOwners = createSelector(selectAll, gameIdSelector,
  (authorStatements,  gameId) => {
  return authorStatements
    .filter(s => s.accessRights > 0)
    .filter(s => s.gameId +'' === gameId);
  })

export const canAuthorGame = createSelector(selectAll, getUser,
  (authorStatements, user) => {
    if (!user) return {};
    const cacheClosure = {};

    authorStatements.forEach(statement => {
      if (statement.account.indexOf(user.uid) !== -1) {
        cacheClosure[statement.gameId] = statement.accessRights;
      }
    })
    return cacheClosure;
  });

export const canWriteCurrentGame = createSelector(selectAll, getUser, gameIdSelector,
  (authorStatements, user, gameId) => {
    if (!user) return false;
    let toReturn = false;
    authorStatements.forEach(statement => {
      if (statement.account.indexOf(user.uid) !== -1 && statement.gameId + '' === gameId &&
        (statement.accessRights === 1 || statement.accessRights === 2)) {
        toReturn = true;
      }
    })
    return toReturn;
  });

export const ownsCurrentGame = createSelector(selectAll, getUser, gameIdSelector,
  (authorStatements, user, gameId) => {
    if (!user) return false;
    let toReturn = false;
    authorStatements.forEach(statement => {
      if (statement.account.indexOf(user.uid) !== -1 && statement.gameId + '' === gameId &&
        (statement.accessRights === 1)) {
        toReturn = true;
      }
    })
    return toReturn;
  });

export const writeAndAdvanced = createSelector(canWriteCurrentGame, getUser, (canWrite, user) => {
  if (!user) return false;
  if (!(user.claims['make'] || user.claims['admin'])) {
    return  false;
  }
  return canWrite;
});

export const AllMyGameAuthorIds = createSelector(selectAll, getUser, (authorStatements, user)=>{
  console.log("user is ", user);
  if (user == null) {
    return [];
  }
  console.log("authorStatements is ", authorStatements.filter(s => s.accessRights >= 0));
  return authorStatements
    .filter(s => s.accessRights >= 0)
    .filter(s => s.account.indexOf(user.uid) !== -1).map(s => s.gameId+ '');
})

import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {PortalAccount} from './portal-user.state';

export const AccountPageActions = createActionGroup({
  source: 'Account Page',
  events: {
    'Retrieve Password Reset Account Info':  emptyProps(),
    'Set Initial Password':  props<{ password: string, name?: string }>(),
    'Request Reset':   props<{ email: string }>(),
  }
  });

export const AccountApiActions = createActionGroup({
  source: 'Account Api',
  events: {
    'Set User Account': props<{ account: PortalAccount }>(),

  }
});


export const portalMgtSubmenuItems = [
  {
    routerLink: '/admin/games',
    label: 'COMMON.GAMES'
  },
  {
    routerLink: '/admin/user/usrmgt',
    label: 'PORTAL_MANAGEMENT.USERS.MENU'
  },
  {
    routerLink: '/admin/images',
    label: 'PORTAL_MANAGEMENT.IMAGES.MENU'
  },
  {
    routerLink: '/admin/organisations',
    label: 'PORTAL_MANAGEMENT.ORGANISATIONS.MENU'
  },
  {
    routerLink: '/admin/themes/list',
    label: 'PORTAL_MANAGEMENT.THEME.MENU'
  },
];

export const backToAdminManageGames = portalMgtSubmenuItems[0].routerLink;
export const backToAdminManageUsers = portalMgtSubmenuItems[1].routerLink;

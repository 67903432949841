import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {MatToolbarModule} from "@angular/material/toolbar";
import {gameThemeReducer} from "./store/game-theme.reducer";
import {GameThemeEffects} from "./store/game-theme.effects";
import {SelectThemeComponent} from "./modal/select-theme.component";
import {ThemeSettingsComponentOld} from "./modal/theme-settings.component";
import {GameMessageModule} from "../game-message/game-message.module";
import {DeleteThemeComponent} from "./modal/create-theme/delete-theme.component";
import {CreateThemeNameModalComponent} from "./modal/create-theme/create-theme-name-modal.component";
import {CreateThemeSettingsComponent} from "./modal/create-theme/create-theme-settings.component";
import {SharedModule} from "../shared/shared.module";
import {ThemeFilePickerComponent} from "./modal/create-theme/theme-file-picker.component";
import {MediaLibraryModule} from "../media-library/media-library.module";
import {GameThemeEffectsLoadGlobal} from "./store/game-theme.effects.load-global";
import {GameThemeEffectsLoadMyThemes} from "./store/game-theme.effects.load-my-games";
import { SelectThemeModalComponent } from './modal/select-theme-modal.component';

@NgModule({
    imports: [
        SharedModule.forRoot(),
        CommonModule,
        StoreModule.forFeature('game-theme', gameThemeReducer),
        EffectsModule.forFeature([GameThemeEffects, GameThemeEffectsLoadGlobal, GameThemeEffectsLoadMyThemes]),

        MatToolbarModule,
        GameMessageModule,
        MediaLibraryModule,
    ],
  entryComponents: [
      SelectThemeComponent,
      ThemeSettingsComponentOld,
      DeleteThemeComponent,
      CreateThemeNameModalComponent,
      CreateThemeSettingsComponent,
  ],
  declarations: [
      SelectThemeComponent,
      // ThemeSettingsComponent,
      DeleteThemeComponent,
      CreateThemeNameModalComponent,
      CreateThemeSettingsComponent,
      ThemeFilePickerComponent,
      SelectThemeModalComponent,
  ],
    exports: [
        SelectThemeComponent,

        CreateThemeNameModalComponent,
        CreateThemeSettingsComponent,
        ThemeFilePickerComponent,
    ],

})
export class GameThemesModule { }

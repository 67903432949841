import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Game} from "./game.state";


@Injectable({
  providedIn: 'root',
})
export class GameServiceNew {

  constructor(
    private http: HttpClient) {


  }

  getGame(gameId: any): Observable<Game> {
    return this.http
      .get<any>(environment.api_url + '/game/' + gameId)
      .pipe(map(transOneGame));
  }

  createGame(game: any): Observable<Game> {
    const gameCopy = {
      ...game, gameId: undefined
    }
    return this.http
      .post<any>(environment.api_url + '/game/create', gameCopy);
  }

  cloneGame(gameId: string | number): Observable<any> {
    return this.http
      .get<any>(environment.api_url + '/game/clone/' + gameId);
  }

  updateGame(game: Game): Observable<Game> {
    delete game.endsOn;
    return this.http
      .put<any>(environment.api_url + '/game/' + game.gameId + '/update', game);
  }

  deleteGame(gameId: string): Observable<Game> {
    return this.http
      .delete<any>(environment.api_url + '/game/' + gameId);
  }


  listAll(from: number, resumptionToken?: string): Observable<GameList> {
    return this.list(from, '/game/list/', resumptionToken)
  }

  listParticipate(from: number, resumptionToken?: string): Observable<GameList> {
    return this.list(from, '/game/participate/list/', resumptionToken)
  }

  list(from: number, path: string, resumptionToken?: string): Observable<GameList> {
    let postFix = '1';
    if (!!from) {
      postFix = from + '';
    }
    if (!!resumptionToken) {
      postFix += '?resumptionToken=' + resumptionToken;
    }
    return this.http
      .get<GameList>(environment.api_url + path + postFix).pipe(
        map(list => ({
              ...list,
              games: (list.games || []).map(transOneGame)
            }
          )
        )
      );
  }

  updateEndState(payload: any, gameId: any) {
    const wrapper = {
      dependencyAsString: JSON.stringify(payload)
    };
    return this.http
      .post<any>(environment.api_url + '/game/endstate/' + gameId, wrapper);
  }

  updateGrid(show: boolean, size: number, gameId: any) {
    if (size) {
      return this.http
        .post<any>(environment.api_url + '/game/' + gameId + '/grid/size/' + size, {});
    } else {
      return this.http
        .post<any>(environment.api_url + '/game/' + gameId + '/grid/show/' + show, {});
    }

  }

}

const transOneGame = (game: Game) => {
  if (!game.config) {
    game.config = {};

  }
  if (game.chapters) {
    game.chapters = Number.parseInt(game.chapters + '', 10);
  }
  if (game.lastModificationDate) {
    game.lastModificationDate = Number.parseInt(game.lastModificationDate + '', 10);
  }
  return game;

};

export interface GameList {
  games?: any[];
  serverTime: number;
  from: number;
  resumptionToken: string
}

import {Component, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {RunResponse} from "../store/run-responses.state";
import {currentItemFilteredUsersResponses} from "../store/run-responses.selectors";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {VideoPlayerModalComponent} from "../modals/video-player-modal.component";

@Component({
  selector: 'app-video-gallery',
  template: `
    <div class="video-gallery" *ngIf="responses$ |async as responses">
      <div class="video-gallery__wrapper" *ngIf="!loading">
        <div class="video-item" *ngFor="let video of responses; let i = index" (click)="showVideo(i,  responses)">
          <app-video-gallery-tile [response]="video"></app-video-gallery-tile>
          <span class="video-icon"><i class="fas fa-play"></i></span>
        </div>
      </div>
      <div class="load-wrapper" *ngIf="loading">
        <span>{{'COMMON.LOADING'| translate}}</span>
      </div>
    </div>

  `
})
export class VideoGalleryComponent {
  public responses$: Observable<RunResponse[]> = this.store.select(currentItemFilteredUsersResponses);


  // @Input() responses: any;

  public videos: any[];

  public loading = false;

  private dialogRef;
  private selectedVideoIdx: any;

  @ViewChild('video') videoPlayer;

  constructor(
    private store: Store<State>,
    public dialog: MatDialog,
  ) {
  }

  public showVideo(videoIdx, responses) {
    this.selectedVideoIdx = videoIdx;

    this.dialogRef = this.dialog.open(VideoPlayerModalComponent, {
      panelClass: ['player-bg', 'modal-dialog'],
      data: {
        selectedIdx: videoIdx,
        responses: responses
      }
    });
  }

}

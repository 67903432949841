import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';


@Injectable()
export class PlayerService {

    constructor(private http: HttpClient) {
    }

    // getContacts(time: number): Observable<any> {
    //     // console.log("loading contacts");
    //     return this.http
    //         .get<any>(environment.api_url + '/player/myContacts' + '/' + time);
    // }

    // getContactsWithResumption(resumptionToken: string, time: number): Observable<any> {
    //     return this.http
    //         .get<any>(environment.api_url + '/player/myContacts/' + resumptionToken + '/' + time);
    // }

    // getAccounts(ids: string): Observable<any> {
    //
    //     return this.http
    //         .get<any>(environment.api_url + '/accounts/' + ids).pipe(
    //           map(x=> ({...x, accountList:(x.accountList||[]).filter(acc => !!acc)})),
    //       );
    // }

    // getPendingContacts(): Observable<any[]> {
    //     return this.http
    //         .get<any>(environment.api_url + '/player/pendingInvitations');
    // }

    // getPendingContactsToMe(): Observable<any[]> {
    //     return this.http
    //         .get<any>(environment.api_url + '/player/invitationsToMe');
    // }

    // addContactContact(body: any) {
    //     return this.http
    //         .post<any>(environment.api_url + '/player/add', body);
    // }

    // removeContact(player: PendingPlayer) {
    //     return this.http
    //         .get<any>(environment.api_url + '/player/remove/' + player.accountType + "/" + player.localId);
    // }

    // removePending(id: string) {
    //     return this.http
    //         .delete<any>(environment.api_url + '/player/pending/' + id);
    // }

    // confirmInvitationId(id: string) {
    //     return this.http
    //         .get<any>(environment.api_url + '/player/confirmInvitation/' + id);
    // }

    denyInvitationId(id: string) {
        return this.http
            .get<any>(environment.api_url + '/player/denyInvitation/' + id);
    }

    // redoInvitationId(id: string) {
    //     return this.http
    //         .get<any>(environment.api_url + '/player/reinvite/' + id);
    // }

    // getAccountForInvitation(id: string) {
    //     return this.http
    //         .get<any>(environment.api_url + '/player/invitation/' + id);
    // }

  // getOnePlayer(fullId: string) :Observable<Player> {
  //   return this.http
  //     .get<any>(environment.api_url + '/player/contact/' + fullId);
  // }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, tap} from 'rxjs/operators';


import * as RouterActions from '../actions/router.actions';

import {Store} from '@ngrx/store';
import * as fromApp from './../reducers';

@Injectable()
export class RouterEffects {

  navigate$ = createEffect(
    () => this.actions$.pipe(
      ofType(RouterActions.ActionTypes.GO),
      map((action: RouterActions.Go) => action.payload),
      tap(({path, query: queryParams, extras}) =>
        this.router.navigate(path, {queryParams, ...extras})
      )
    ), {dispatch: false});


  navigateBack$ = createEffect(
    () => this.actions$.pipe(
      ofType(RouterActions.ActionTypes.BACK),
      tap(() => this.location.back())
    ), {dispatch: false});


  navigateForward$ = createEffect(
    () => this.actions$.pipe(
      ofType(RouterActions.ActionTypes.FORWARD),
      tap(() => this.location.forward())
    ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<fromApp.State>,
    private location: Location
  ) {
  }
}

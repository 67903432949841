import {Injectable} from "@angular/core";
import {RunPlayerService} from "./run-players.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {RunPlayerApiActions, RunPlayerEffectActions, RunPlayerPageActions} from "./run-players.actions";
import {filter, map, mergeMap, withLatestFrom} from "rxjs/operators";
import * as fromRootSelector from "../../core/selectors/router.selector";
import {currentGameLastSyncTime} from "./run-players.selectors.game-player";


@Injectable()
export class RunPlayersEffectsLoadGamePlayers {
  constructor(
    private runPlayerService: RunPlayerService,
    private actions$: Actions,
    private router: Router,
    private store$: Store<State>
  ) {
  }


  loadAllGamePlayers = createEffect(
    () => this.actions$.pipe(
      ofType(RunPlayerPageActions.getPlayersForGame),
      withLatestFrom(
        this.store$.select(fromRootSelector.selectRouteParam('gameId')),
        this.store$.select(currentGameLastSyncTime)
      ),
      mergeMap(
        ([action, gameId, syncTime]) => this.runPlayerService.listPlayersForGame(gameId, syncTime).pipe(
          map(runList => RunPlayerApiActions.loadPlayersForGameSuccess({runList}))
        )
      ))
  );

  resumeLoadAllGamePlayers = createEffect(
    () => this.actions$.pipe(
      ofType(RunPlayerApiActions.loadPlayersForGameSuccess),
      filter((action) =>
        !!action && !!action.runList && !!action.runList.resumptionToken && (action.runList.users || []).length != 0),
      mergeMap(
        (action) => this.runPlayerService.listPlayersForGame(action.runList.gameId + '', action.runList.from, action.runList.resumptionToken).pipe(
          map(runList => RunPlayerApiActions.loadPlayersForGameSuccess({runList}))
        )
      )
    ));

  updateInSyncTill = createEffect(
    () => this.actions$.pipe(
      ofType(RunPlayerApiActions.loadPlayersForGameSuccess),
      filter((action) =>
        !!action && !!action.runList && (!action.runList.resumptionToken || (action.runList.users || []).length == 0)),
      map(action => RunPlayerEffectActions.updateLastGameSyncTime({
        time: {
          gameId: Number.parseInt(action.runList.gameId + ''),
          syncTime: action.runList.serverTime - 120000
        }
      }))
    ));

}

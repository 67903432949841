import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameMessage} from "../store/game-messages.state";
import {GameMessagePageActions} from "../../game-message/store/game-message.actions";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {environment} from "../../../environments/environment";
import {Game} from "../../games-management/store/game.state";

@Component({
  selector: 'app-game-board',
  template: `
    <div *ngIf="messages && messages.length !=0">
      <lib-general-items-map

        [messages]="messages"

        [background]="'https://storage.googleapis.com/'+bucketUrl+game.messageListScreen"
        [height]="prefHeight"
        [width]="prefWidth"
        [panEnabled]="false"
        [tooltipsEnabled]="true"
        (onCoordinatesChange)="onCoordinatesChange($event)"
        (onNodeClick)="nodeClick.emit($event)"
      ></lib-general-items-map>
    </div>

  `,
  styles: []
})
export class GameBoardComponent implements OnInit {

  @Input() game: Game;
  @Input() messages: GameMessage[];
  @Output() nodeClick = new EventEmitter();

  @Input() public prefHeight = '1920px'
  @Input() public prefWidth = '1080px'
  bucketUrl = environment.firebase.storageBucket;

  constructor(private store: Store<State>,) {
  }

  ngOnInit(): void {
  }


  onCoordinatesChange(gameMessage: any) {
    gameMessage.relX = gameMessage.customMapXRel;
    gameMessage.relY = gameMessage.customMapYRel;
    this.store.dispatch(GameMessagePageActions.gameMessageDirectSave({gameMessage}));
  }
}

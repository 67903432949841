import {Component} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {currentItemFilteredUsersResponses} from "../store/run-responses.selectors";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {RunResponse} from "../store/run-responses.state";
import {PhotoPlayerModalComponent} from "../modals/photo-player-modal.component";

@Component({
  selector: 'app-photo-gallery',
  template: `
    <div class="photo-gallery" *ngIf="responses$ |async as responses">
      <div *ngIf="!loading" class="masonry">
        <div
          *ngFor="let img of responses; let i = index"
          (click)="showSlider(i,  responses)"
          class="masonry-brick"
        >
          <app-photo-gallery-tile
            [response]="img"
          ></app-photo-gallery-tile>

        </div>
      </div>
      <div class="load-wrapper" *ngIf="loading">
        <span>{{'COMMON.LOADING'| translate}}</span>
      </div>
    </div>
  `
})
export class PhotoGalleryComponent {
  public loading = false;
  public responses$: Observable<RunResponse[]> = this.store.select(currentItemFilteredUsersResponses);
  private dialogRef;

  constructor(
    private store: Store<State>,
    public dialog: MatDialog,
  ) {
  }


  public showSlider(idx, responses) {
    this.dialogRef = this.dialog.open(PhotoPlayerModalComponent, {
      panelClass: ['player-bg', 'player-bg-fullwidth', 'modal-dialog'],
      data: {
        selectedIdx: idx,
        responses: responses
      }
    });
  }
}

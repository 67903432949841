import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {GameRun} from "../store/game-runs.state";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {currentRunWithEdits} from "../store/game-runs.selector";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {GameRunsPageActions} from "../store/game-runs.actions";
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-settings-fields',
  template: `
    <div class="title primary-color font-regular-24-24-roboto gl-pos-between-fields mb-3">{{(run$|async)?.title}}</div>
    <div class="">
      <mat-form-field class="w-100">
        <mat-label>{{'RUNS.GROUP_NAME'|translate}}</mat-label>

        <input matInput placeholder="Title"

               [ngModel]="(run$|async)?.title"
               (ngModelChange)="runTitleChange($event)">

      </mat-form-field>


      <mat-slide-toggle
        class="font-regular-11-15-roboto  color-black-8a"
        [ngModel]="(run$|async)?.runConfig?.selfRegistration"
        [labelPosition]="'before'"
        (change)="changeSelfRegistration($event)">{{'RUNS.SELF_REGISTRATION'|translate}}
      </mat-slide-toggle>
      <div class="d-flex flex-row-reverse align-items-center" *ngIf="(run$|async)?.runConfig?.selfRegistration">
        <button [cdkCopyToClipboard]="getQrCode((run$|async)?.runId)" mat-icon-button>
          <mat-icon>content_copy</mat-icon>
        </button>
        <div>{{getQrCode((run$|async)?.runId)}}</div>
      </div>
      <div class="mt-4 w-100">
        <button
          routerLink="../../../../runs"
          class="w-100 button-height"
          mat-flat-button
          color="primary"
          (click)="submit()"
        >{{ 'ACTIONS.SAVE' | translate }}
        </button>
      </div>
    </div>
  `
})
export class SettingsFieldsComponent implements OnInit {

  @Output() showQrCode = new EventEmitter<boolean>();
  run$: Observable<GameRun> = this.store.select(currentRunWithEdits);
  title = '';
  type = '';

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
  }

  runTitleChange(title: string) {
    this.store.dispatch(GameRunsPageActions.updateRun({
      run: {title}
    }));
  }


  changeSelfRegistration(event: MatSlideToggle) {
    console.log("$ev", event);
    this.store.dispatch(GameRunsPageActions.updateRun({
      run: {
        runConfig: {
          selfRegistration: event.checked
        }
      }
    }));
  }

  submit() {
    this.store.dispatch(GameRunsPageActions.saveRun());
  }

  getQrCode(runId: number) {
    return environment.deep_link + 'run/' + runId;
  }
}

import {gameThemeAdapter, GameThemeState} from './game-theme.reducer';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromRootSelector from "../../core/selectors/router.selector";
import {getCurrentGame} from "../../games-management/store/game.selector";


export const gameThemesState = createFeatureSelector<GameThemeState>('game-theme');
export const getInSyncTill = createSelector(gameThemesState, (state) => state.lastSyncTime);
export const getInSyncTillMy = createSelector(gameThemesState, (state) => state.lastSyncTimeMy);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = gameThemeAdapter.getSelectors(gameThemesState);

export const CATEGORY_CUSTOM_THEMES = 'My themes';

export const selectThemeCategories = createSelector(selectAll, state => {
    return state
        .map(x => x.category)
        .filter((item, i, array) => {
            return item && array.indexOf(item) === i && item !== CATEGORY_CUSTOM_THEMES;
        })
        .sort((a, b) => a.localeCompare(b))
        .concat(CATEGORY_CUSTOM_THEMES);
})

export const getCurrentTheme = createSelector(selectEntities, fromRootSelector.selectRouteParam('themeId'),
  (entities, themeId)=>{
  return entities[themeId];
})


export const getCurrentGameTheme = createSelector(selectEntities, getCurrentGame,
  (entities, game)=>{
    if (!game) return entities[1];
    return entities[game.theme || 1];
  })


export const globalThemes = createSelector(selectAll, state => state.filter(t => t.global));

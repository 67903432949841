import {createFeatureSelector, createSelector,} from '@ngrx/store';

import * as fromRoot from 'src/app/core/reducers';
import {UserState} from './portal-user.state';

export interface State extends fromRoot.State {
    user: UserState;
}

export const _getPortalUserState = createFeatureSelector<UserState>('user');




export const getMyAccount = createSelector(_getPortalUserState, (state)=> state.account);

import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {State} from 'src/app/core/reducers';
import {filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {GameMessageApiActions, GameMessagePageActions} from './game-message.actions';
import {GameMessagesApiActions} from '../../game-messages/store/game-messages.actions';
import {getEditMessageSelector} from "./game-message.selector";
import {beforeUnloadHandler} from "../../utils/beforeunload.handler";
import {selectRouteParam} from "../../core/selectors/router.selector";
import {GameMessagesServiceNew} from "../../game-messages/store/game-messages.service";
import {GamePageActions} from "../../games-management/store/game.actions";

@Injectable()
export class GameMessageEffects {
  constructor(
    private actions$: Actions,
    private gameMessageServiceNew: GameMessagesServiceNew,
    public store: Store<State>,
  ) {
  }

  editMessageFromRouter = createEffect(
    () => this.actions$.pipe(
      ofType(GameMessagePageActions.gameMessageEdit),
      withLatestFrom(this.store.select(selectRouteParam('messageId'))),
      filter(([_, messageId]) => messageId != '-1'),
      switchMap(([_, messageId]) => this.gameMessageServiceNew.getMessage(messageId)),
      map(gameMessage => GameMessageApiActions.gameMessageEditComplete({gameMessage}))
    ));

  saveMessage = createEffect(
    () => this.actions$.pipe(
      ofType(GameMessagePageActions.gameMessageSave),
      withLatestFrom(this.store.select(getEditMessageSelector)),
      mergeMap(
        ([_, message]) => this.gameMessageServiceNew.postMessage(message).pipe(
          map(gameMessage => GameMessagesApiActions.gameMessageSaveComplete({gameMessage}))
        )
      ),
    ));


  directSaveMessage = createEffect(
    () => this.actions$.pipe(
      ofType(GameMessagePageActions.gameMessageDirectSave),
      tap(() => {
        window.onbeforeunload = beforeUnloadHandler;
        this.store.dispatch(GamePageActions.setLoading({loading: true}));
      }),
      mergeMap(
        (action) =>
          this.gameMessageServiceNew.postMessage(action.gameMessage).pipe(
            map(gameMessage => GameMessagesApiActions.gameMessageSaveComplete({gameMessage})),
            tap(() => {
              window.onbeforeunload = null;
            }),
            tap(() => this.store.dispatch(GamePageActions.setLoading({loading: false}))),
          )
      )
    ));
}

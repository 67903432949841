import {map, tap} from 'rxjs/operators';

import {Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {State} from "src/app/core/reducers";

import {Auth, authState} from '@angular/fire/auth';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private store: Store<State>,
    private router: Router,
    private auth: Auth
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return authState(this.auth).pipe(
      tap(u => {
        // console.log('user is', u)
        if (!u)  {
          this.router.navigate(['/login']);
        }
      }),
      map(u => !!u )
    );
  }
}

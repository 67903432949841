import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {GameList} from "./game.service";
import {GameTheme} from "../../game-themes/store/game-theme.state";
import {Game} from "./game.state";


export const GamePageActions = createActionGroup({
  source: 'Game Page',
  events: {
    'Create Game': props<{ game: Game }>(),
    'Save Game': props<{ game: Game }>(),
    'Get Game': props<{ gameId?: string }>(),
    'Delete Game': props<{ gameId: string }>(),
    'Clone Game': props<{ game: Game }>(),
    'Set Filter': props<{ filters: string[] }>(),
    'Update End State': props<{ dependency: any }>(),
    'Grid Update': props<{ size?: number, show?: boolean }>(),
    'Select Theme': props<{ theme: GameTheme }>(),
    'Set Loading': props<{ loading: boolean }>(),
  },
});


export const GameApiActions = createActionGroup({
  source: 'Game API',
  events: {
    'Update Last Full Sync': props<{ time: number }>(),
    'Get Game Success': props<{ game: Game }>(),
    'Get Game List Success': props<{ gameList: GameList }>(),
    'Create Game Success': props<{ game: Game, tempGame: Game }>(),
    'Save Game Success': props<{ game: Game }>(),
    'Delete Game Success': props<{ game: Game }>(),
  },
});
//
// export const GameActionTypes = {
//
//   SET_GAMES_FILTER: '[Games] Set message filter',
// };

//
// export class SetGamesFilterAction implements Action {
//   type = GameActionTypes.SET_GAMES_FILTER;
//
//   constructor(public payload: { filters: string[] } = {filters: []}) {
//   }
//
//   setFilter(filter: string[]) {
//     this.payload.filters = filter;
//   }
// }



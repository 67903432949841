import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-account-section',
  template: `
    <div class="account-dropdown">
      <app-user-drop-down-display></app-user-drop-down-display>
      <select-language *ngIf="showLanguage"></select-language>
    </div>
  `
})
export class AccountSectionComponent implements OnInit {
  showLanguage: boolean = environment.showTranslate;
  constructor() { }

  ngOnInit(): void {
  }

}

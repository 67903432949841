

export interface AuthState {
  user: State;
}

export interface State {
  loggedIn: boolean;
  user: User | null;
  loading: boolean;
  error: {
    code: string;
    message: string;
  };
  success: boolean;
}



export interface Credentials {
  email: string;
  password: string;
  remember?: boolean;
}

export interface IUser {
  uid?: string;
  displayName: string;
  email: string;
  photoURL?: string;
  phoneNumber: string;
  emailVerified?: boolean;
}

export class User implements IUser {

  constructor(public uid: string,
              public displayName: string,
              public email: string,
              public phoneNumber: string,
              public photoURL?: string,
              public emailVerified?: boolean,
              public claims?: any //iot extension
  ) {
  }
}

import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {GameMessage, MultipleChoiceAnswerItem} from '../../game-messages/store/game-messages.state';

export const GameMessageApiActions = createActionGroup({
  source: 'Game Message Api',
  events: {
    'Game Message Edit Complete': props<{ gameMessage: GameMessage }>(),
  },
});

export const GameMessagePageActions = createActionGroup({
  source: 'Game Message Page',
  events: {
    'Game Message Edit': emptyProps(),
    'Game Message Save': emptyProps(),
    'Game Message Direct Save': props<{ gameMessage: GameMessage }>(),
    'Set Message Preview': props<{ previewType: string, data?: Partial<MultipleChoiceAnswerItem> }>(),
    'Game Message Update': props<{ partialMessage: Partial<GameMessage> }>(),
    'Add Answers': props<{ answerType: string, amount?: number }>(),
    'Update Answer': props<{ delete: boolean; id: string; field?: string; value?: any }>(),
    'update file ref': props<{ delete: boolean; key: string; value?: any }>(),
    'clear file ref': props<{ key: string}>(),
    'update answer dependency': props<{  delete: boolean; id?: number; action?: string;}>(),
    'Clear location': emptyProps(),
    'Clear color': emptyProps(),
  },
});

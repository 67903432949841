import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AccountService} from '../../core/services/account.service';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {AccountApiActions, AccountPageActions} from './portal-user.actions';

import {SetErrorAction} from "../../shared/store/shared.actions";
import * as userActions from 'src/app/ngrx-auth/store/ngrx-auth.actions';
import {selectRouteParam} from "../../core/selectors/router.selector";
import {Store} from "@ngrx/store";
import {State} from "../../core/reducers";
import {Router} from "@angular/router";
import {PortalAccount} from "./portal-user.state";

@Injectable()
export class UserEffects {
  constructor(
    private router: Router,
    private accountService: AccountService,
    private actions$: Actions,
    public store: Store<State>
  ) {
  }


  loadUser = createEffect(
    () => this.actions$.pipe(
      ofType(userActions.AuthActionTypes.Authenticated),
      switchMap(payload => this.accountService.get()),
      map(account => AccountApiActions.setUserAccount({account})),
      catchError((error) => {
        return of(new SetErrorAction(error.error));
      })
    ));

  retrieveAccountInfoForPasswordReset = createEffect(
    () => this.actions$.pipe(
      ofType(AccountPageActions.retrievePasswordResetAccountInfo),
      withLatestFrom(this.store.select(selectRouteParam('token'))),
      mergeMap(([action, token]) => this.accountService.getAccountViaToken(token)),
      map((account) => AccountApiActions.setUserAccount({account})),
    )
  );


  setInitialPw$ = createEffect(
    () => this.actions$.pipe(
      ofType(AccountPageActions.setInitialPassword),
      withLatestFrom(this.store.select(selectRouteParam('token'))),
      mergeMap(([action, token]) => {
        return this.accountService.setInitialPw(action.password, token, action.name).pipe(
          tap(x => {
            return this.router.navigate(['/login']);
          }),

        );
      }),
      map((account: PortalAccount) => AccountApiActions.setUserAccount({account})),
    ),

    // FeatureActions.actionOne is not dispatched
  );

  requestReset = createEffect(
    () => this.actions$.pipe(
      ofType(AccountPageActions.requestReset),
      mergeMap((action) => {
        return this.accountService.requestNewPassword(action.email).pipe(
          map((_) => action)
        );
      }),
      tap((action) => {
        this.router.navigate(['/login'])
      }),
    ),
    {dispatch: false}
  )

}

import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {GameApiActions, GamePageActions} from './game.actions';

import {State} from 'src/app/core/reducers';
import {catchError, distinct, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {ErrorActions, SetErrorAction} from '../../shared/store/shared.actions';
import {getCurrentGame, selectEntities} from "./game.selector";
import {GameServiceNew} from "./game.service";
import {selectRouteParam} from "../../core/selectors/router.selector";
import {GameMessagesServiceNew} from "../../game-messages/store/game-messages.service";


const translationStrings = {
  'game404': 'httpexception.game-does-not-exist',
  'game401': 'httpexception.not-authorized-to-view-this-game',
  'gamedel404': 'httpexception.game-does-not-exist',
  'gamedel401': 'httpexception.not-authorized-to-delete-this-game',
}

@Injectable()
export class GameEffects {
  constructor(
    private gameService: GameServiceNew,
    private messagesService: GameMessagesServiceNew,
    private actions$: Actions,
    private router: Router,
    private store$: Store<State>
  ) {
  }

  createGame = createEffect(
    () => this.actions$.pipe(
      ofType(GamePageActions.createGame),
      mergeMap(
        (action) => this.gameService.createGame(action.game).pipe(
          map(game => GameApiActions.createGameSuccess({game, tempGame: action.game})),
          catchError((error) => of(new SetErrorAction(error.error)))
        )
      )
    ));

  getGame = createEffect(
    () => this.actions$.pipe(
      ofType(GamePageActions.getGame),
      withLatestFrom(
        this.store$.select(selectRouteParam('gameId'))
      ),
      distinct(([action, gameId]) => action.gameId || gameId),
      mergeMap(
        ([action, gameId]) => this.gameService.getGame(
          action.gameId || gameId
        ).pipe(
          map(game => GameApiActions.getGameSuccess({game})),
          catchError((error) => of(ErrorActions.http({
            ...error.error.error,
            time: Date.now(),
            route: '/games/list',
            translation: translationStrings['game' + (error.error.error.code)]
          })))
        )
      )
    ));

  deleteGame = createEffect(
    () => this.actions$.pipe(
      ofType(GamePageActions.deleteGame),
      withLatestFrom(
        this.store$.select(getCurrentGame)
      ),
      mergeMap(
        ([action, game]) => this.gameService.deleteGame(
          action.gameId
        ).pipe(
          map(game => GameApiActions.deleteGameSuccess({game})),
          catchError((error) => of(ErrorActions.http({
            ...error.error.error,
            time: Date.now(),
            route: '/games/list',
            translation: translationStrings['gamedel' + (error.error.error.code)]
          })))
        )
      )
    ));


  cloneGame = createEffect(
    () => this.actions$.pipe(
      ofType(GamePageActions.cloneGame),
      withLatestFrom(
        this.store$.select(selectEntities)
      ),
      mergeMap(
        ([action, entities]) => this.gameService.cloneGame(
          action.game.gameId
        ).pipe(
          map(game => GameApiActions.createGameSuccess({game, tempGame: action.game})),
          catchError((error) => of(new SetErrorAction(error.error))),
        )
      )
    ));

  save = createEffect(
    () => this.actions$.pipe(
      ofType(GamePageActions.saveGame),
      mergeMap(
        (action) => this.gameService.updateGame(action.game).pipe(
          map(game => GameApiActions.saveGameSuccess({game}))
        )
      ),
    ));

  saveEndState = createEffect(
    () => this.actions$.pipe(
      ofType(GamePageActions.updateEndState),
      withLatestFrom(this.store$.select(getCurrentGame)),
      switchMap(
        ([action, game]) => this.gameService.updateEndState(action.dependency, game.gameId).pipe(
          map(game => GameApiActions.saveGameSuccess({game})),
        )
      )
    ), {dispatch: false});

  saveGrid = createEffect(
    () => this.actions$.pipe(
      ofType(GamePageActions.gridUpdate),
      withLatestFrom(this.store$.select(getCurrentGame)),
      switchMap(
        ([action, game]) => this.gameService.updateGrid(action.show, action.size, game.gameId).pipe(
          map(game => GameApiActions.saveGameSuccess({game})),
        )
      )
    ));
}
